
import React, { useEffect, useRef, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'

import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'


import { useNavigate } from 'react-router-dom'
import { useApp } from '../../contextAPI/AppContext'
import LongLilacBtn from '../../components/Buttons/LongLilacBtn'
import ProgressItem from './Components/ProgressItem'
import { addToProgressItem, getEventWishList } from '../../firebase/controller/eventsController'
import { useAuth } from '../../contextAPI/AuthContext'
import ConfirmationBox from '../../components/ConfirmationBox'
import CompleteDetails from './Components/CompleteDetails'

const GetItems = () => {
    const {setTitle,setShowNav,setNavFunction, setNavType} = useNav()
    const [isDisabled, setIsDisabled] = useState(false)
    const [error, setError] = useState(null)
    const {itemCount, setItemCount} = useApp()
    const [items, setItems] = useState([])
    const [editItem, setEditItem] = useState(null)
    const [viewMode, setViewMode] = useState(false)
    const [showCompleteDetails, setShowCompleteDetails] = useState(false)
    const [dud,setDud] = useState(0)
    const eventId = localStorage.getItem("eventId")
    const {user} = useAuth()
    const navigate = useNavigate()

    useEffect(()=>{


        if(!eventId){
          navigate("/notfound")
          return
        }
        
        setTitle("WishBlend")

        setNavFunction(()=>()=>navigate("/wishBlend"))
        setNavType("back")
        setShowNav(true)

        getEventWishList(eventId,(error,value)=>{
          if(!error && value){
            console.log(value)
            setItems(value)
          }else{
            console.log(error)
            setError("Error getting wishblend")
          }
        },"created")
    },[])
   
  

    const onEditItem = (item)=>{
      setEditItem(item.id)
    }
    
    const onSetItem = (operation) =>{
        console.log(itemCount)
        const itemChange = itemCount
        if(itemChange[editItem] &&  itemChange[editItem] !== 0){
            operation == "+" ? itemChange[editItem]++ : itemChange[editItem]--
        }else if(operation == "+"){
            itemChange[editItem] = 1
        }
        if(itemChange[editItem] == 0){
            delete itemChange[editItem]
        }
        setItemCount(itemChange)
    }

    const onGetItems = (username,phoneNumber) => {

      

      setIsDisabled(true)
     
      const itemsCountArray = []
      Object.keys(itemCount).forEach(key=>{
          itemsCountArray.push({
            id:key,
            count: itemCount[key]
          })
      })
      
      addToProgressItem(eventId,itemsCountArray,user.id,(user.displayName|| user.username || username), (user.phoneNumber || phoneNumber),(error)=>{
        if(error){ 
          console.log(error)
          setIsDisabled(false)
        }
      })
      setItemCount({})
      navigate("/success",{state:{path:"/wishblend",text:"Saved Successfully"}})
    }

    const checkDetailsComplete = ()=>{

      if((user.username || user.displayName) && user.phoneNumber){
        onGetItems()
       return        
      }
      setShowCompleteDetails(true)
     }

    if(showCompleteDetails){
      return(
        
        <CompleteDetails user={user} setShowCompleteDetails={setShowCompleteDetails}  showCompleteDetails={showCompleteDetails} blend={onGetItems}/>
      )
    }

  return (
    <>
     {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    
    <div className='container' style={{padding: "30px 0 20px"}}>
        <h1 className='poppins-20px-500' style={{margin:"20px"}}>What are you getting?</h1>
    
       <div style={viewMode && {height:"inherit",overflow: "scroll", width:"100%",paddingBottom: "60px"}||{height:"inherit",overflow: "scroll", width:"100%"}}>
            {
                items.map((item)=><ProgressItem key={item.id} name={item.itemName} totalItemsCount={item.itemCount} promisedCount={item.promisedCount||0} setItem={onSetItem} id={item.id} editItem= {editItem} onClick={()=>!viewMode ? onEditItem(item):{}} setDud={setDud} viewMode={viewMode} committersCount={item.committersCount} committersName={item.committersName}/>)
            }
       </div>
       <div style={{ width:"100%", padding: "0 20px"}}>
         {!viewMode &&<LongPurpleBtn name="Next" inactive={Object.keys(itemCount).length == 0} onClick={()=>setViewMode(true)}/>}
         {viewMode && <LongLilacBtn name="Edit" onClick={()=>setViewMode(false)} />}
         {viewMode &&<LongPurpleBtn name="Blend" inactive={Object.keys(itemCount).length == 0 || isDisabled} onClick={checkDetailsComplete}/>}
       </div>
    </div>
    </>
  )
}

export default GetItems
