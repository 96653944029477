import React, { useEffect, useRef, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'
import EventCarpools from './EventCarpools'
import DefaultCarpools from './DefaultCarpools'
import { getLocation } from '../../Utils/util'





const Carpools = () => {
    
 

    const {setShowNav, setTitle,setShowMenu} = useNav()
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [filterType, setFIlterType] = useState(localStorage.filterType || "default")
    const [locationPermissionError, setLocationPermissionError] = useState(null)
    useEffect(() => {
      
  
    getLocation((err,data)=>{
      if(!err && data){
        setLatitude(data.latitude)
        setLongitude(data.longitude)
      }else{
        console.log(err)
        if(err.message  === "User denied Geolocation"){
          setLocationPermissionError(err.code)
        }
      }
    })

    }, []);
    

    useEffect(()=>{
        console.log(latitude,longitude)
    },[latitude,longitude])
    
    useEffect(()=>{
        
        setShowNav(false)
        setTitle("Carpool")
        setShowMenu(true)
        
      
    },[])

    useEffect(()=>{
      console.log(filterType)
      localStorage.setItem("filterType", filterType)
    },[filterType])

  
  return (
    <>

      {filterType === "event" && <EventCarpools setFilterType={setFIlterType} latitude={latitude} longitude={longitude} locationPermissionError={locationPermissionError}/>}
      {filterType === "default" && <DefaultCarpools setFilterType={setFIlterType}  latitude={latitude} longitude={longitude} locationPermissionError={locationPermissionError}/>}
    </>
  )
}

export default Carpools
