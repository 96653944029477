import React from 'react'
import LAyOutSample from './LAyOutSample'
import LongLilacBtn from "../../../../components/Buttons/LongLilacBtn"
import LongPurpleBtn from "../../../../components/Buttons/LongPurpleBtn"
import DangerBtn from "../../../../components/Buttons/DangerBtn"
import XBtn from "../../../../components/Buttons/XBtn"
const styles = {


  survey:{
    width: "90vw",
    padding: "10px",
    backgroundColor: "#18181A",
    top:"25vh",
    textAlign: "center",
    color: "#ffffff",
    borderRadius: "15px",
    zIndex: "99999",
    opacity: "1",
    marginBottom:"20px"
    
  },

  surveyDiv:{
    textAlign: "left",
    height:"60px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    borderRadius: "15px",
    
  }
}

const ReturnTripSurvey = ({updateReturnTrip, showChangeLoc,poolData,changeLocationRef,setShowChangeLoc}) => {
  return (
   
  <LAyOutSample>
    { !showChangeLoc &&
        <div style={styles.survey}>
          <h3>open ride for return journey?</h3>
          <LongLilacBtn onClick={()=>updateReturnTrip("same")} name={`Yes, same destination`}/>
          <LongPurpleBtn onClick={()=>updateReturnTrip("change")} name="Yes, but change location"/>

        </div>
        ||
        <div  style={styles.survey}>
          <h3>Enter your new location</h3>
          <input defaultValue={poolData.poolerLoc} ref={changeLocationRef}/>
          <LongPurpleBtn onClick={()=>updateReturnTrip("change")} name="change location"/>
          <DangerBtn  onClick={()=>setShowChangeLoc(false)} name="cancel"/>

        </div>
    }
   
        <XBtn type="circle" onClick={()=>updateReturnTrip()}/>
  
     
    </LAyOutSample>
  )
}

export default ReturnTripSurvey
