import React, { useRef, useState } from 'react'
import { useAuth } from '../../../contextAPI/AuthContext'

import {motion} from "framer-motion"
import { useNav } from '../../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'
import { validateConfirmEmail, validateConfirmPhone, validateEmail, validateFullName, validatePhone } from '../../../Utils/formValidation'
import LongPurpleBtn2 from '../../../components/Buttons/LongPurpleBtn2'
import FormError from '../../../components/FormError'
import GreyInputBox from '../../../components/InputsBox/GreyInputBox'
import { updateUser } from '../../../firebase/controller/usersController'

const CompleteDetails = ({blend,setShowCompleteDetails,showCompleteDetails}) => {

    const {user, setUser, isLoggedIn} = useAuth()
    const [isDisabled, setIsDisabled] = useState(false)
    const phoneRef = useRef()
    const fullNameRef = useRef()
    const {setNavFunction, setNavType} = useNav()
    const navigate = useNavigate()
    const [phoneError,setPhoneError] = useState(null)
    const [fullNameError, setFullNameError] = useState(null)
    
    useState(()=>{
        setNavFunction(()=>()=>{
            setShowCompleteDetails(false)
            setNavFunction(()=>()=>navigate("/event-page"))
            setNavType("back")
        })
        setNavType("cancel")
    },[])

    const onNext = ()=>{

        setIsDisabled(true)
         // Validate WhatsApp number
         
         const phoneError = phoneRef?.current ?  validatePhone(phoneRef.current.value) : null
         setPhoneError(phoneError)
  
         // Validate location
    

         const fullNameError = fullNameRef?.current ? validateFullName(fullNameRef.current.value) : null
         setFullNameError(fullNameError)


        
        if( phoneError|| fullNameError){
            console.log("error")
            setIsDisabled(false)
            return
        }

        const data  = {}
        
       
        if(phoneRef?.current){
            data.phoneNumber = phoneRef.current.value
        }
        if(fullNameRef?.current){
            data.displayName = fullNameRef.current.value
            data.username = fullNameRef.current.value
        }

        console.log(id)

        if(isLoggedIn){
            try {
                updateUser(id,data)
            } catch (error) {
                console.log(error)
            }
            
        }

        console.log(data)
        setUser((user)=>({...user, ...data }))
        setShowCompleteDetails(false)
        blend(data.displayName, data.phoneNumber)

    
    }

    const {email, username,id, displayName, phoneNumber} = user
  return (
    <motion.div className='container'
        initial={{ height: 0 }}
        animate={{ height: showCompleteDetails ? '100%' : 0 }}
        exit={{ height: 0 }}
        transition={{ type: 'tween' }}
    >
       <div style={{height:"15%", width:"100%", display:"flex", alignItems:"center",justifyContent:"center"}}>
                <h3 className="poppins-20px-500">Complete Details</h3>
            </div>
            <div style={{flex:1, width:"100%", overflow:"scroll", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                {!phoneNumber && <><div style={{position:"relative", width:"90%"}}>
                    <GreyInputBox placeholder="Phone number" inputRef={phoneRef} required={true}/>
                </div>
                <FormError error={phoneError}/></>}

                {!(displayName || username)&& <><div style={{position:"relative", width:"90%"}}>
                    <GreyInputBox placeholder="full name" inputRef={fullNameRef} required={true}/>
                </div>
                <FormError error={fullNameError}/></>}

            </div>
            <div style={{height:"15%", width:"100%", display:"flex", alignItems:"center"}}>
                <LongPurpleBtn2 inactive={isDisabled} name="Blend" onClick={onNext}/>
             </div>
    </motion.div>
  )
}

export default CompleteDetails
