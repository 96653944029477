import React, { useEffect, useState } from 'react'
import { onMessageListener, requestForToken } from '../firebase/config'
import { Toaster, toast } from 'react-hot-toast';
import { useAuth } from '../contextAPI/AuthContext';


const Notification = () => {
  const {setUser} = useAuth()
    const [notification, setNotification] = useState(null);
    
    const notify = () =>  toast(<ToastDisplay/>);
    function ToastDisplay() {
      return (
        <div>
          <p><b>{notification?.title}</b></p>
          <p>{notification?.body}</p>
        </div>
      ); 
    };
  
    useEffect(() => {
      if (notification?.title){
       notify()

      }
    }, [notification])
   
    useState(()=>{
      requestForToken().then((token)=>setUser(prev=>({...prev,messageToken: token})));
    
    },[])
    

    
  
    onMessageListener()
      .then((payload) => {
        setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     

      })
      .catch((err) => console.log('failed: ', err));
  
    return (
      <Toaster/>
    )
}

export default Notification
