import React from 'react'
import FormError from '../FormError'


const styles = {

  formInput:{
    backgroundColor: "#202024",
    border: "none",
    padding: "0",
    height:" auto",
    width: "100%",
    resize: "none",
    outline: "nonde",
  }
}

function FormTypeE({title, placeHolder, value, onChange,error}) {
  return (
    <div>
    <h4 className='poppins-heavy'>{title}</h4>
    <textarea  rows="5" style={styles.formInput} className='inter-400' placeholder={placeHolder} value={value} onChange={(e)=>onChange(prev=>({...prev,eventDesc:e.target.value}))}/>
    <FormError error={error}/>
    </div>
  )
}

export default FormTypeE
