import React, { useEffect, useState } from 'react'
import { useNav } from '../../../contextAPI/NavContaxt'
import PassengerCard from './Components/PassengerCard'
import ConfirmationBox from '../../../components/ConfirmationBox'
import { useNavigate } from 'react-router-dom'
import HailerCard from './Components/HailerCard'
import { useAuth } from '../../../contextAPI/AuthContext'



const styles = {
  passengerQuickAction:{
    width: "100vw",
    height: "90vh",
    overflow: "scroll",
    position: "relative",
    top:"2vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "60px",
  },
 
}

const PassengerQuickAction = ({passengers,rejectPassenger,setOpenPassengerQA,type, addNewPassenger,eventLoc}) => {

  const {setNavFunction, setNavType, setTitle} = useNav()
  const [ showCallConfirmationBox,setShowCallConfirmationBox] = useState(null)
  const [ showRemoveConfirmationBox, setShowRemoveConfirmationBox] = useState(null)
  const [numberToCall, setNumberToCall] = useState(null)
  const navigate = useNavigate()
  

  const actionButtons = [
    {
      title: "Whatsapp call",
      onClick: ()=>{window.open(`https://wa.me/${numberToCall}`)}
      
    },
    {
      title: "Phone call",
      onClick: ()=>{window.open(`tel:${numberToCall}`)}
      
    },
   
  ]

  useEffect(()=>{
    
    setNavFunction(()=>()=>{
      setOpenPassengerQA(false)
      setNavType("back")
      setTitle("Contact poolees")
      setNavFunction(()=>()=>navigate("/carpools"))
    })
    setTitle("Quick Action")
    setNavType("cancel")
  },[])


  const makeCall = (number)=>{
  
    setShowCallConfirmationBox(true)
   
    setNumberToCall(number.replace(/^0/, '+234'))
  }

  return (
    <>
    <div style={styles.passengerQuickAction}>
       

        {

            passengers.map(passenger=>{
              console.log(passenger)
               if(passenger.poolHailerStatus === "created"){
                return(<HailerCard cardInfo={passenger} accept={addNewPassenger} reject={rejectPassenger} isPooler={type === "pooler"} eventLoc={eventLoc}/>)
               }
               else{
                  
                  return(<PassengerCard onClick={setShowRemoveConfirmationBox} cardInfo={passenger} makeCall={makeCall} isPooler={type === "pooler"} eventLoc={eventLoc}/>)
               }
                  
                
            })
        }
         
    
        
    </div>
    { showCallConfirmationBox && <ConfirmationBox para="How would you like to make your call?" actionButtons={actionButtons} setShowConfirmationBox={setShowCallConfirmationBox} cancelBtn={true} />}
    { showRemoveConfirmationBox && <ConfirmationBox para="Remove Passenger?" actionButtons={[{title:"remove passenger", onClick:()=>rejectPassenger(showRemoveConfirmationBox,"remove")}]} setShowConfirmationBox={setShowRemoveConfirmationBox} cancelBtn={true}/>}
    </>
  )
}

export default PassengerQuickAction
