import React, { useEffect, useState } from 'react'
import EventDetail from "../../../components/TicketDetails/EventDetail"
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../../contextAPI/NavContaxt'

const styles = {
    div: {
        
    }
}

const TicketPurchaseSuccess = ({name,location, ticket, setTicketSuccess}) => {

  const navigate = useNavigate()
  const {setNavFunction} = useNav()

   useEffect(()=>{
     setNavFunction(()=>()=>{
       setNavFunction(()=>()=>navigate("/event-page"))
       setTicketSuccess(null)
     })
   },[])

  return (
    <div style={{backgroundColor:"#18181a", width:"100%", borderRadius:"15px", marginBottom:"20px", display:"flex", flexDirection:"column",justifyContent:"space-between",alignItems:"center", padding:"30px 20px"}}>
      <div style={{display:"flex", width:"100%",marginBottom:"50px"}}>
        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="35" cy="35" r="32.5" stroke="#20C963" stroke-width="5"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M47.5632 26.6036C48.1734 27.2138 48.1734 28.2032 47.5632 28.8133L32.9799 43.3966C32.3697 44.0068 31.3805 44.0068 30.7702 43.3966L22.4369 35.0633C21.8267 34.4531 21.8267 33.4639 22.4369 32.8537C23.0471 32.2435 24.0364 32.2435 24.6466 32.8537L31.8751 40.082L45.3536 26.6036C45.9638 25.9934 46.953 25.9934 47.5632 26.6036Z" fill="#20C963"/>
        </svg>
        <div style={{display:"flex", flexDirection:"column", marginLeft:"20px"}}>
            <h1 className='poppins-20px-500'>Congratulations</h1>
            <p style={{color:"#A0A3AF", width:"100px"}} className='poppins-10px-300'>Your ticket has been booked</p>
        </div>
      </div>
      <div style={{width:"100%", border:"1px solid #ffffff", borderRadius:"15px", padding:"20px"}}>
        <h1 className='poppins-20px-500' style={{marginBottom:"10px"}}>Event Details</h1>
        <EventDetail title="Ticket ID" value={JSON.parse(ticket).ticketId}/>
        <EventDetail title="Event Name" value={name}/>
        <EventDetail title="Event Location" value={location}/>
      </div>
    </div>
  )
}

export default TicketPurchaseSuccess
