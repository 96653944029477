import React, { useRef, useState } from 'react'
import GreyInputBox from '../../components/InputsBox/GreyInputBox'
import FormError from '../../components/FormError'
import TimeForm from '../../components/FormElements/TimeForm2'
import RepeatType from './components/RepeatType'
import Work from './components/Work'
import Home from './components/Home'
import Centres from './components/Centres'
import LongPurpleBtn2 from '../../components/Buttons/LongPurpleBtn2'
import { useAuth } from '../../contextAPI/AuthContext'

import RepeatOptions from './components/RepeatOptions'
import { validateLocation, validateSeatNumber } from '../../Utils/formValidation'
import { validateStartTime } from '../CreateEvent/formValidation'
import CarpoolForEvents from '../CarpoolForEvents/CarpoolForEvents'
import ConfirmationBox from '../../components/ConfirmationBox'
import { getLocation } from '../../Utils/util'
import { createEventCarpool } from '../../firebase/controller/poolController'
import { useNavigate } from 'react-router-dom'
import UploadProgress from '../../components/UploadProgress'


const CreateEventPool = () => {

  
  const [repeatType, setRepeatType] = useState("Once")
  const [showRepeatOption, setShowRepeatOption] = useState(false) 
  const [seatInputError, setSeatInputError] = useState(null)
  const [locationInputError, setLocationInputError] = useState(null)
  const [timeInputError, setTimeInputError] = useState(null)
  const navigate = useNavigate()
  const locationRef = useRef()
  const seatRef = useRef()
  const {user,isLoggedIn} = useAuth()
  const [addressFor, setAddressFor] = useState(null)
  //{home:user.homeAddress,work: user.workAddress, centers: user.centersAddress}
  const [error, setError] = useState(null)
  const [time, setTime] = useState("")
  const [destination, setDestination] = useState("toevent")
  const [eventToCarpool, setEventToCarpool] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [showCreatePoolInfo, setShowCreatePoolInfo] = useState(false)



  const createPool = () => {

     
      const timeError = validateStartTime(time)
    
      setTimeInputError(timeError)
  
      const locationError = validateLocation(locationRef.current.value)
      setLocationInputError(locationError)
  
      const seatError = validateSeatNumber(seatRef.current.value)
      setSeatInputError(seatError)

      if(locationError || timeError || seatError){
      
        return
      }

      if(!showCreatePoolInfo){
         setShowCreatePoolInfo(true)
         return
      }else{
        setShowCreatePoolInfo(false)
      }

      setIsCreating(true)

    const [hour, min] = time.split(":")

    console.log(eventToCarpool)
    const originalEventDate = eventToCarpool.eventDate.toDate()
    const newDate = new Date(originalEventDate)
  
    newDate.setHours(hour); 
    newDate.setMinutes(min); 
    newDate.setSeconds(0); 
    newDate.setMilliseconds(0);
    console.log(newDate)
    let poolData = {

      poolType:"pool",
      poolDate: newDate,
      poolerLoc: locationRef.current.value,
      repeat: repeatType,
      eventLocation: eventToCarpool.eventLocation || eventToCarpool.location ,
      eventName: eventToCarpool.eventName,
      eventId: eventToCarpool.id,
      seats: Number(seatRef.current.value),
      costPerSeat: 0,
      status:"created",
      createDate:  new Date(),
      poolAdmin: user.id,
      poolerName: user.username,
      availableNumber: user.phoneNumber,
     
       
    }
    if(user.profile){
      poolData.profile= user.profile
  }
  if(destination === "fromevent"){
    poolData.repeat = "Once"
    poolData.returnTripSurvey = {
      status:true,
      formerLoc: poolData.poolerLoc,
      locationChanged: false,
      time: new Date().getTime(),
      formerLocCord:  null
    }
  
    
  }
  getLocation(async(err,payload)=>{
    
    if(!err && payload){
        try {
            poolData.coordinates={latitude: payload.latitude, longitude:payload.longitude}
            await createEventCarpool(poolData)

            localStorage.setItem("eventId", eventToCarpool.id)
            localStorage.setItem("shortcutEventId", eventToCarpool.id)
            localStorage.setItem("eventName", eventToCarpool.eventName)
            localStorage.setItem("role","pooler")
            navigate("/carpool")
            
            
        } catch (error) {
            console.log(error)
            setIsCreating(false)
            setError("Error creating carpool!")

        }
        
    }else{
      console.log(err)
      setIsCreating(false)
    }
})
  }

  if(!eventToCarpool){
    
      return(<CarpoolForEvents setEventToCarpool={setEventToCarpool} eventToCarpool={eventToCarpool}/>)
    
  }

  return (
    <>
    <div style={{marginTop:"50px",width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
    <div style={{width:"100%", backgroundColor:"#18181A", borderTopLeftRadius:"30px",borderTopRightRadius:"30px", padding:"30px 20px 10px",color:"#ffffff"}}>
      <div style={{textAlign:"center",width:"100%", marginBottom:"20px"}}>
        <h1 className='poppins-20px-500'>Event carpool</h1>
      </div>
      <div className='poppins-15px-200' style={{ display:"flex", justifyContent:"space-between", marginBottom:"20px", color:"#fff"}}>
        <p style={destination==="toevent" ? {color:"#8E69F0"}:{}} onClick={()=>setDestination("toevent")}>To Event</p>
        <p style={destination==="fromevent" ? {color:"#8E69F0"}:{}} onClick={()=>setDestination("fromevent")}>From Event</p>
      </div>
      <p className='poppins-15px-200' style={{marginBottom:"10px"}}>{destination==="toevent" ?"Where are you?":"Where are you going"}</p>
      <GreyInputBox inputRef={locationRef} onChange={()=>{}} placeholder="Enter address"/>
      <FormError error={locationInputError}/>
   
      {/* <div style={{display:"flex", justifyContent:"flex-start", margin:"20px 0"}}>
        <Home addressFor={addressFor} onClick={setAddressFor}/>
        <Work addressFor={addressFor} onClick={setAddressFor}/>
        <Centres addressFor={addressFor} onClick={setAddressFor}/>

      </div> */}
      <p className='poppins-15px-200' style={{marginBottom:"10px"}}>Seat Available</p>
      <GreyInputBox inputRef={seatRef} onChange={()=>{}} placeholder="enter seat number" type={"number"}/>
      <FormError error={seatInputError}/>
      <p className='poppins-15px-200' style={{margin:"10px 0"}}>When are you going?</p>
      <TimeForm onChange={setTime} value={time}/>
      <FormError error={timeInputError}/>
      { destination ==="toevent" &&<p className='poppins-15px-200' style={{margin:"10px 0"}}>Repeat this pool?</p>}
      {destination==="toevent" && <RepeatType repeatType={repeatType} onClick={()=>setShowRepeatOption(true)}/>}
      <LongPurpleBtn2 name="Create Pool" onClick={()=>{createPool()}} />
    </div>
  </div>
  {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} errorType={true}/>}
  {eventToCarpool && false && <ConfirmationBox para={`Create pool for ${eventToCarpool.eventName} ?`} setShowConfirmationBox={setEventToCarpool} cancelBtn={true} actionButtons={[{title:"create pool", onClick:()=>createPool()}]}/>}
  {showRepeatOption  && <RepeatOptions setShowOption={setShowRepeatOption}  repeatType={repeatType} setRepeatType={setRepeatType}  type2={true}/>}
  {isCreating && <UploadProgress progress="Creating pool"/>}
  {showCreatePoolInfo  && <ConfirmationBox title="Pool Location" infoBox="Make sure you're in the location youre pooling from" actionButtons={[{title:"Proceed", onClick:createPool}]} cancelBtn={true} setShowConfirmationBox={setShowCreatePoolInfo}/>}
  
  </>
  )
}

export default CreateEventPool
