import React from 'react'

const style = {
   padding: "20px 150px 40px 0px",
 
   
}

const InfoBox = ({info}) => {
  return (
    <div style={style}>
       <p>
       {info}
        </p>
    </div>
  )
}

export default InfoBox
