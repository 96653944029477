import React, { useEffect, useRef, useState } from 'react'
import GreyInputBox from '../../components/InputsBox/GreyInputBox'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import ShareInvite from './ShareInvite'
import { useNav } from '../../contextAPI/NavContaxt'

const Personalized = ({eventName,setPersonalized}) => {
    const [isShare,setIsShare] = useState(false)
    const nameRef = useRef()
    const { setNavFunction} = useNav()
    
    useEffect(()=>{
        setNavFunction(()=>()=>setPersonalized(false))
    },[])
  return (
    <>
    <div className='container'>
        <p style={{margin:"100px 0 50px", width:"50%", textAlign:"center"}}>Invite your  friend to {eventName} </p>
        <div style={{width:"100%", flex:1}}>
        <div style={{marginBottom:"40px"}}>
            <GreyInputBox placeholder="Your friend's name" inputRef={nameRef}/>
        </div>
     
        </div>
        <div style={{width:"100%", padding:"10px 0"}}>
        <LongPurpleBtn name="Share" onClick={()=>setIsShare(true)}/>
        </div>
    </div>
    {isShare && <ShareInvite setIsShare={setIsShare} showCopyLink={false} personalized={nameRef.current.value}/>}
    </>
  )
}

export default Personalized
