import React from 'react'

const style = {
    backgroundColor: "#8E69F0",
    width: "100%",
    height: "40px",
    borderRadius: "20px",
    

}
const LongLilacBtn = ({name,onClick}) => {
  return (
    <button style={style} onClick={onClick}>{name}</button>
  )
}

export default LongLilacBtn
