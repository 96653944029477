import React, { useEffect, useRef, useState } from 'react'
import { useApp } from '../../contextAPI/AppContext'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'

import Items from './Components/Items'
import LongLilacBtn from '../../components/Buttons/LongLilacBtn'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import { getEventWishList } from '../../firebase/controller/eventsController'
import ConfirmationBox from '../../components/ConfirmationBox'
const SuggestedItems = () => {

    const {setTitle,setShowNav,setNavFunction, setNavType} = useNav()
 
    const eventId = localStorage.getItem("eventId")
    const {items, setItems} = useApp()
    const [selectedItems, setSelectedItems] = useState([])
    const [isAllSelected, setIsAllSelected] = useState(false)
    const [error, setError] = useState(null)
    const navigate = useNavigate()
    
   
    useEffect(()=>{
        setItems([])
        if(!eventId){
          navigate("/notfound")
          return
        }
        setTitle("WishBlend")

        setNavFunction(()=>()=>navigate("/wishblend"))
        setNavType("back")

        setShowNav(true)
        
        getEventWishList(eventId,(err,data)=>{
          if(!err && data){
           
            const itemsWithSelectedParameters = data.map(item=> ({...item,selected:false}))
            setItems(itemsWithSelectedParameters)
          }else{
            console.log(err)
            setError("Error getting current wishblend")
          }
        },"suggested")
        

        
    },[])
    
   useEffect(()=>{

        const selectedItem = items.filter(item=> item.selected).map(({itemName,id,itemCount,status,promisedCount})=>({itemName,id,itemCount,status,promisedCount}))
        setSelectedItems(selectedItem)
   },[items])
    const onSelected = (id) =>{
      

       const itemsChanges = items.map(item=>item.id === id ? {...item,selected:!item.selected,status:"created"}:item)

       setItems(itemsChanges)
     
    }

    const selectAll = ()=>{
       let itemsChanges = isAllSelected ?items.map(item=> ({...item,selected:false})) :items.map(item=> ({...item,selected:true}))
       setIsAllSelected(prev=>!prev)
        setItems(itemsChanges)
        setSelectedItems(itemsChanges)
    }
   
    const onAdd = () =>{
        console.log(selectedItems)
        setItems(selectedItems)
        navigate("/create-wish-blend",{state:{action:"approve"}})
    }
  return (
    <>
   {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    <div className='container' style={{padding: "30px 0 20px"}}>
        <h1 className='poppins-20px-500' style={{margin:"20px"}}>Suggested Items</h1>
      {  <div style={{padding:"10px 20px 0", width:"100%"}}>
    
 
       </div>}
       <div style={ {height:"inherit",overflow: "scroll", width:"100%",paddingBottom: "60px"}}>
            {
                items.map((item)=><Items key={item.id} name={item.itemName}  onClick={()=>onSelected(item.id)}   isSelected={item.selected}/>)
            }
       </div>
       <div style={{ width:"100%", padding: "0 20px"}}>
     
         { items.length > 1 && <LongLilacBtn name="Select All" onClick={selectAll}/>}
         { <LongPurpleBtn name="Add" inactive={selectedItems.length === 0} onClick={onAdd}/>}
       </div>
    </div>
    </>
  )
}

export default SuggestedItems
