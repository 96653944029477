import React from 'react'
import {motion} from 'framer-motion'

const PoolTypeSwitch = ({poolSwitch,setPoolSwitch, text1, text2}) => {
    console.log(poolSwitch === text1);
  return (
    <div style={{display:"flex", width:"100%"}}>
      <div 
      style={{width:"50%",textAlign:"center",  paddingBottom:"5px"}}
      onClick={()=>{setPoolSwitch(text1)}}
      >

        <p>{text1}</p>
      {poolSwitch == text1 && <motion.div 
            style={{backgroundColor:`${poolSwitch == text1 ? "#8E69F0":"#2D2F3C"} `, height:"1px", marginTop:"5px"}}
            initial={{ width: 0 }}
            animate={{ width: '100%' }}
            exit={{width: 0}}
            transition={{ duration: 0.5 }}
        ></motion.div>}
      </div>
      <div 
      style={{width:"50%",textAlign:"center", paddingBottom:"5px"}}
      onClick={()=>{setPoolSwitch(text2)}}
      >
        <p>{text2}</p>
        {poolSwitch ==text2 && <motion.div 
         style={{backgroundColor:`${poolSwitch == text2? "#8E69F0":"#2D2F3C"}` , height:"1px", marginTop:"5px"}}
         initial={{ x: '100%' }}
         animate={{ x: 0 }}
       
         transition={{ duration: 0.5 }}
         ></motion.div>}
    </div>
    </div>
  )
}

export default PoolTypeSwitch
