import axios from 'axios'



const baseUrlNotification = "https://novusui-notification.onrender.com/api/v1/"




export const sendEmailVerificationToken = async(token,email, callback)=>{
     
    try {
         await axios.post("https://novusui-event-kyc.onrender.com/ap1/v1/email-verification/send-email",
        {
            email
        },
        {
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
      })
      
      callback(false)
    } catch (error) {
        console.log(error)
        callback(true)
    }
    

    
}

export const validateEmailToken = async(token,code,callback)=>{
    try {
         await axios.post("https://novusui-event-kyc.onrender.com/ap1/v1/email-verification/validate-code",
        {
            token:code
        },
        {
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
      })
      callback(false)
    } catch (error) {
        console.log(error)
        callback(true)
    }
} 


export const sendPhoneVerificationToken = async(token,phone, callback)=>{

   const formattedNumber = "+234" + phone.split("").splice(1,phone.length).join("")

   console.log(formattedNumber)
     
    try {
         await axios.post("https://novusui-event-kyc.onrender.com/ap1/v1/phone-verification/send-otp",
        {
            phone: formattedNumber
        },
        {
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
      })
      
      callback(false)
    } catch (error) {
        console.log(error)
        callback(true)
    }
    

    
}


export const validateOTP = async(token,code,phone,callback)=>{
    const formattedNumber = "+234" + phone.split("").splice(1,phone.length).join("")
    try {
         await axios.post("https://novusui-event-kyc.onrender.com/ap1/v1/phone-verification/validate-otp",
        {
            token:code,
            phone: formattedNumber
        },
        { 
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
      })
      callback(false)
    } catch (error) {
        console.log(error)
        callback(true)
    }
} 


export const registerToTopic = async(topic,registrationToken,token,callback)=>{
    try {
          await axios.post(baseUrlNotification +"registertotopic",
        {                   
            topic,
            registrationToken
        },
        { 
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
        }
        )
        callback(false)
    } catch (error) {
        
    }
}
export const unRegisterFromTopic = async(topic,registrationToken,token,callback)=>{
    try {
          await axios.post(baseUrlNotification+"unregistertotopic",
        {
            topic,
            registrationToken
        },
        { 
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
        }
        )
        callback(false)
    } catch (error) {
        callback(true)
    }
}

export const sendMsgToTopic = async(topic,notification,token,callback)=>{
    console.log(topic)
    try {
          await axios.post(baseUrlNotification+"sendmsgtotopic",
        {
            topic,
            notification
        },
        { 
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
        }
        )
        callback(false)
    } catch (error) {
        
        callback(error)
    }
}

export const sendMsgToRegToken = async(registrationToken,notification,token,callback)=>{
    try {
          await axios.post(baseUrlNotification+"sendmsgtoregtoken",
        {
            notification,
            registrationToken
        },
        { 
            headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
        }
        )
        callback(false)
    } catch (error) {
        callback(true)
    }
}


