import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PictureBox from '../../../components/PictureBox'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import LongLilacBtn from '../../../components/Buttons/LongLilacBtn'
import VerificationComplete from '../VerificationComplete'
import { useAuth } from '../../../contextAPI/AuthContext'
import BackFunctionBtn from '../../../components/Buttons/BackFunctionBtn'
import { useNav } from '../../../contextAPI/NavContaxt'

const styles = {
   
    infoBox: {
       
        margin: "10px 0",

    },
    infoBoxDiv:{
        width: "100%",
        padding: "0 20px",
        marginBottom: "20px"
    },
    btnBox: {
        width:"100%",
        position: "absolute",
        bottom:"40px",
        padding: "0 20px",
        marginTop:"50px",
        display: "flex",
        flexDirection: "column",
       
    }

}

const UploadId = () => {

    const location = useLocation()
    const [title, setTitle] = useState()
    const {user} = useAuth()
    const {verification} = user
    const navigate = useNavigate()

    const {setNavFunction, setNavType} = useNav()

    useEffect(()=>{
      setNavFunction(()=>{
        return ()=>navigate( verification?.id === "completed" && navigate("/kyc-list" || (location?.state?.type !== "SF" && navigate("/choose-id") || navigate("/confirm-id"))))
      })
      setNavType("back")
    },[])

    useEffect(()=>{

        switch(location?.state?.type){
            case "DL": setTitle("Driver's License")
            break;
            case "NID": setTitle("National identity card")
            break;
            case "IP": setTitle("International passport")
            break;
            case "SF": setTitle("Selfie")
            break;
        }
    },[])
    
  return (
    <>
     {/* {
        location?.state?.type !== "SF" &&
        <BackFunctionBtn onClick={()=>verification?.id === "completed" ? navigate("/kyc-list") : navigate("/choose-id")}/>

    }
    {
        location?.state?.type === "SF" &&
        <BackFunctionBtn onClick={()=>verification?.id === "completed" ? navigate("/kyc-list") : navigate("/confirm-id")}/>

    } */}
    {verification?.id === "completed" && <VerificationComplete/>}
    {verification?.id !== "completed" &&
    <div className='container'>
      <p className='poppins-14px-400' style={styles.infoBox}>{title}</p>
      <PictureBox/>
      <div style={styles.btnBox}>
        <LongLilacBtn name="Retake photo"/>
        <LongPurpleBtn name="Upload photo"/>
      </div>
    </div>
  }
  </>
  )
}

export default UploadId
