import React, { useEffect, useState } from 'react'
import { useNav } from '../../../contextAPI/NavContaxt'
import { getRatings } from '../../../firebase/controller/eventsController'
import Rating from './Rating'


const ViewRatings = ({setViewRating,eventId}) => {

    const {setNavFunction, setNavType} = useNav()
    const [ratings, setRatings] = useState([])
    

    useEffect(()=>{
        setNavFunction(()=>()=>{
            setViewRating(false)
            
        })
        
        setNavType("cancel")
        getEventRatings()
        
        
    },[])

     const getEventRatings = async() =>{
        try {
           const ratings = await getRatings(eventId) 
           setRatings(ratings)
           console.log(ratings)
        } catch (error) {
            console.log(error)
        }
       
     }
  return (
    <div className="container" style={{paddingTop:"30px"}}>
        {
            ratings.map(rating=><Rating username={rating.username || "Unknown"} rating={rating.star} comment={rating.comment} profile={rating.profile}/>)
        }
    </div>
  )
}

export default ViewRatings
