
import React from 'react'
import { ClipLoader } from 'react-spinners'
import {motion} from "framer-motion"
const styles = {
    container : {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex:"9999"
    },
    transDiv: {
       flex:"1",
        opacity: "0.27",
        width: "100%",
        backgroundColor: "#4C2CBF"
    },
    middleDiv:{
     
        width:"100%",
        backgroundColor:"#2c234e",
        display:"flex",
        flexDirection:  "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"0 20px"
    },
    confirmationBox: {
        backgroundColor: "#18181A",
        padding: "40px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "15px",
        color: "#ffffff"
    },
    generalMargin: {
      margin: "10px 0"
    },

    errorType:{
      border:"1px solid #ff3a3a",

    }

   
}

const UploadProgress = ({progress}) => {
  return (
    <div style={styles.container}>
      <div style={styles.transDiv}>

      </div>
      <div style={ styles.middleDiv}>
        <motion.div 
        style={ styles.confirmationBox}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
         
        >
            <ClipLoader size={150} color={'#4B2CBA'}/>
            <p>{progress}</p>
        </motion.div>
       
      </div>
      <div  style={styles.transDiv}>

      </div>
    </div>
  )
}

export default UploadProgress
