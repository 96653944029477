import React from 'react'
import NavFunction from '../../components/NavFunction'
import { useNavigate } from 'react-router-dom'
const styles= {
    bottomNav:{
        position: "fixed",
        bottom:"30px",
        zIndex:"10",
        width:"100%",
        display: "flex",
        padding: "0 20px",
        alignItems: "center",
        justifyContent : "space-between"
    },
    navBtn:{
        height:"40px",
        width: "fit-content",
        padding: "0 30px",
        borderRadius: "10px",
        backgroundColor: "#8E69F0"
    }
}


const ButtomNav = ({selectedTab, tabs, setSelectedTab}) => {

    const navigate = useNavigate()

    const next =()=> {

        if(selectedTab == tabs.length-1){
            
            navigate("/login")
            return
        }

        setSelectedTab(prev=>++prev)
    }
  return (
    <div style={styles.bottomNav}>
        {selectedTab > 0 && <NavFunction type="back" onClick={()=>setSelectedTab(prev=>--prev)}/> || <div></div>}
        <button style={styles.navBtn} onClick={next}>{selectedTab == tabs.length-1 && "Get started" ||"Next"}</button>
    </div>
  )
}

export default ButtomNav
