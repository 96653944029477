import React from 'react'

const styles = {
    outterCircle: {
        width: "300px",
        height: "300px",
        border: "3px solid #20C963",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "40px",
    },

    innerCircle: {
        width: "220px",
        height: "220px",
        backgroundColor: "#EFFFF5",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    infoDiv: {
        marginTop: "20px"
    }

}
const VerificationInProcess = () => {
  return (
    <div className='island'>
      <div style={styles.outterCircle}>
        <div style={styles.innerCircle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M112.689 49.8104C114.52 51.641 114.52 54.609 112.689 56.4395L68.9394 100.189C67.1088 102.02 64.1413 102.02 62.3104 100.189L37.3104 75.1894C35.4799 73.3587 35.4799 70.3912 37.3104 68.5606C39.141 66.73 42.109 66.73 43.9396 68.5606L65.625 90.2456L106.061 49.8104C107.891 47.9799 110.859 47.9799 112.689 49.8104Z" fill="#20C963"/>
            </svg>
        </div>
      </div>
      <p className='poppins-200px-600'>We are working on it</p>
      <div style={styles.infoDiv}>
        <p className='poppins-white-medium'>
            Please  check your email for your account 
            update status, This process can take up to 
            48 HRS
        </p>
      </div>
    </div>
  )
}

export default VerificationInProcess
