import React from 'react'

const EditImage = () => {
  return (
    <div style={{width:"30px", height:"30px", backgroundColor:"#FFFFFF", borderRadius:"7px", display:"flex", justifyContent:"center", alignItems:"center", position:"absolute", top:"20px", left:"20px"}}>
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.0193 0C8.11875 0 8.21415 0.03951 8.2845 0.109838L10.4058 2.23115C10.5522 2.37761 10.5522 2.61504 10.4058 2.76149L3.51149 9.6558C3.46438 9.7029 3.40556 9.73658 3.3411 9.75345L0.469779 10.5035C0.340974 10.5371 0.203979 10.4999 0.109846 10.4058C0.0157062 10.3117 -0.0214638 10.1747 0.0121812 10.0459L0.762181 7.1745C0.779019 7.11007 0.812731 7.05128 0.859846 7.0041L7.7541 0.109838C7.82445 0.03951 7.91985 0 8.0193 0Z" fill="#8E69F0"/>
        </svg>
    </div>
  )
}

export default EditImage
