import React from 'react';

import { AuthProvider } from './contextAPI/AuthContext';
import Nav from './components/Nav';
import "./App.css"
import "./fonts.css"
import "./buttons.css"
import "./layout.css"
import { AppProvider } from './contextAPI/AppContext';
import { ErrorMsgProvider } from './contextAPI/ErrorMsgContext';
import { NavProvider } from './contextAPI/NavContaxt';
import { CreateEventProvider } from './contextAPI/CreateEventContext';
import { NetworkErrorProvider } from './contextAPI/NetworkErrorContext';



function App() {
  return (
    <div id='app'>
      
    <AuthProvider>
      <NetworkErrorProvider>
      <ErrorMsgProvider>
      <AppProvider>
        <CreateEventProvider>
        <NavProvider>
        <Nav/>
        </NavProvider>
        </CreateEventProvider>
      </AppProvider>
      </ErrorMsgProvider>
      </NetworkErrorProvider>
    </AuthProvider>
    </div>
  )
}

export default App;
