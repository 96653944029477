import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contextAPI/AuthContext'
import BlinkingLocationIcon from '../../Carpools/Components/BlinkingLocationIcon'


const EventShortCut = () => {

    const eventName = localStorage.getItem("eventName")
    const createdBy = localStorage.getItem("createdBy")
    const {user} = useAuth()
    const navigate = useNavigate()

    const goToEvent = async() => {
        localStorage.setItem("eventId",localStorage.shortcutEventId)
        console.log(createdBy, user.id)
          if(createdBy === user.id){
           
            navigate("/event-owner-hs")
            return
          }
          navigate("/event-page")
      }

  return (
    <div onClick={()=>goToEvent()} style={{height:"40px", borderRadius:"20px", border:"2px solid #8E69F0", marginTop:"10px", padding:"10px", display:"flex",alignItems:"center",justifyContent:"space-between", alignSelf:"flex-start"}}>
        
        {/* <svg width="15" height="15" viewBox="0 0 15 15"  fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"10px"}}>
            <circle cx="7.5" cy="7.5" r="7.5" fill="#20C963"/>      
        </svg> */}
        <BlinkingLocationIcon/>

      <p style={{color:"#A0A3AF"}} className='poppins-15px-400'>{eventName}</p>
    </div>
  )
}

export default EventShortCut
