import React from 'react'
import Detail from './TicketDetails/EventDetail'
import { QRCodeCanvas } from 'qrcode.react'

const Ticket = ({eventData,ticket,setFullCodeScreen}) => {
  return (
    <div style={{flex:"1", width:"100%", background:"#18181a", borderRadius:"15px", display:"flex", flexDirection:"column",justifyContent:"space-between",alignItems:"center", padding:"20px"}} >
           <Detail title="Event Name" value={eventData?.eventName}/>
            <Detail title="Event Price" value={eventData?.ticket?.price || eventData?.eventPrice}/>
            <Detail title="Event Location" value={eventData?.location || eventData?.eventLocation}/>
            <div style={{padding:"0 20px 20px",backgroundColor:"#808081" ,margin:"20px 0"}} onClick={()=>setFullCodeScreen(true)}>
                <div style={{width:"110%", textAlign:"end"}}>
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.2771 6.7334C1.2771 6.67815 1.29905 6.62516 1.33812 6.58609C1.37719 6.54702 1.43018 6.52507 1.48543 6.52507C1.54069 6.52507 1.59368 6.54702 1.63275 6.58609C1.67182 6.62516 1.69377 6.67815 1.69377 6.7334V8.01257L1.69793 8.0084L4.2271 5.47924C4.26633 5.44001 4.31954 5.41797 4.37502 5.41797C4.4305 5.41797 4.4837 5.44001 4.52293 5.47924C4.56216 5.51847 4.5842 5.57167 4.5842 5.62715C4.5842 5.68263 4.56216 5.73584 4.52293 5.77507C4.4021 5.8959 4.28127 6.01257 4.16043 6.1334C3.43543 6.8584 2.7146 7.5834 1.9896 8.3084H3.26877C3.32402 8.3084 3.37701 8.33035 3.41608 8.36942C3.45515 8.40849 3.4771 8.46148 3.4771 8.51674C3.4771 8.57199 3.45515 8.62498 3.41608 8.66405C3.37701 8.70312 3.32402 8.72507 3.26877 8.72507H1.48543C1.45897 8.72646 1.43253 8.72194 1.40803 8.71185C1.38353 8.70176 1.36158 8.68635 1.34377 8.66674C1.3396 8.66257 1.33543 8.66257 1.33543 8.6584C1.30388 8.63079 1.28443 8.59189 1.28127 8.55007C1.27752 8.51757 1.2771 6.7334 1.2771 6.7334Z" fill="white"/>
                    <path d="M8.72287 1.48299V3.26633C8.72287 3.32158 8.70092 3.37457 8.66185 3.41364C8.62278 3.45271 8.56979 3.47466 8.51454 3.47466C8.45929 3.47466 8.4063 3.45271 8.36723 3.41364C8.32816 3.37457 8.30621 3.32158 8.30621 3.26633V1.98716L8.30204 1.99133C7.45621 2.83299 6.61315 3.67605 5.77287 4.52049C5.73364 4.55972 5.68044 4.58176 5.62496 4.58176C5.56948 4.58176 5.51627 4.55972 5.47704 4.52049C5.43781 4.48126 5.41577 4.42805 5.41577 4.37258C5.41577 4.3171 5.43781 4.26389 5.47704 4.22466C5.59787 4.10383 5.71871 3.98716 5.83537 3.86632C6.56454 3.14132 7.28537 2.41632 8.01037 1.69132H6.73121C6.67595 1.69132 6.62296 1.66938 6.58389 1.63031C6.54482 1.59124 6.52287 1.53825 6.52287 1.48299C6.52287 1.42774 6.54482 1.37475 6.58389 1.33568C6.62296 1.29661 6.67595 1.27466 6.73121 1.27466H8.51454C8.541 1.27327 8.56744 1.27779 8.59194 1.28788C8.61644 1.29797 8.63839 1.31338 8.65621 1.33299C8.66037 1.33716 8.66454 1.33716 8.66454 1.34133C8.68416 1.35914 8.69957 1.38109 8.70965 1.40559C8.71974 1.43009 8.72426 1.45653 8.72287 1.48299Z" fill="white"/>
                </svg>
                </div>
                <div style={{padding:"10px",backgroundColor:"#ffffff"}}>
                    <QRCodeCanvas value={ticket}/>
                </div>
            </div>
            <p style={{color:"#fff"}} onClick={()=>setFullCodeScreen(true)}>Download ticket</p>
    </div>
  )
}

export default Ticket
