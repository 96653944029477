import React from 'react'

const styles = {
    containerFLexRow:{
        width: "100%",
        height: "100px",
        display: "flex",
        margin: "5px 0",
        borderTopLeftRadius: "20px",
        borderBottomLeftRadius: "20px",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        backgroundColor: "#18181A",
        alignItems: "center"
    },
    container:{
        
        width: "90%",
        height: "100px",
        padding: "20px 20px",
        display: "flex",
        flexDirection: "column",
     
       
      
    },
    input:{
        backgroundColor: "#18181A",
        padding: "0",
        border: "0",
        outline: "none",
      
    },
    

    editIcon:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "20px",
      height: "20px",
      backgroundColor: "#2D2F3C",
      borderRadius: "100%"
      
    },
    verified: {
        backgroundColor: "#20C963"
    }
 
 }
const KYCBox = ({title, info, isVerified, onClick}) => {
  return (
    <div style={styles.containerFLexRow} onClick={onClick}>
    <div style={styles.container}>
      <h3 className='poppins-15px-500'>{title}</h3> 
      <div className='poppins-10px-300' style={styles.input} >
        <p>{info}</p>
      </div>
    </div>
    {isVerified && <div style={{...styles.editIcon, ...styles.verified}}>
         <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7715 5.6451C12.979 5.85256 12.979 6.18894 12.7715 6.3964L7.81321 11.3547C7.60574 11.5622 7.26942 11.5622 7.06193 11.3547L4.2286 8.52138C4.02113 8.31391 4.02113 7.97759 4.2286 7.77012C4.43606 7.56265 4.77243 7.56265 4.9799 7.77012L7.43758 10.2278L12.0203 5.6451C12.2278 5.43764 12.5641 5.43764 12.7715 5.6451Z" fill="white"/>
            </svg>
    </div>
    }
    {  
     !isVerified && 
    <div style={styles.editIcon}>  
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.06185 5.2911C7.26934 5.08363 7.60566 5.08363 7.81313 5.2911L10.6465 8.12445C10.8539 8.33192 10.8539 8.66824 10.6465 8.87571L7.81313 11.709C7.60566 11.9165 7.26934 11.9165 7.06185 11.709C6.85438 11.5016 6.85438 11.1653 7.06185 10.9578L9.5195 8.50008L7.06185 6.0424C6.85438 5.83493 6.85438 5.49856 7.06185 5.2911Z" fill="white"/>
        </svg>
        
     </div>
    }
    </div>
  )
}

export default KYCBox
