import { Timestamp } from 'firebase/firestore'
import React from 'react'

const styles = {
    container :{
        borderRadius: "10px",
        minheight: "50px",
        width: "100%",
        display: "flex",
        padding: "5px 10px",
        backgroundColor : "#18181A",
        margin: "5px 0",
        justifyContent: "space-between"
    },
    eventDetails:{
       width: "65%",
       height: "100%",
      marginLeft: "10px",
      display:"flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    
    fare:{
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignitems : "center",
        height: "100%",
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
    }
}
const TicketCard = ({onClick,event}) => {
    const {eventLocation, eventName, eventDate} = event
   
   
    const date = new Date(eventDate.seconds * 1000);
   
    const month = date.toLocaleString('en-US', { month: 'numeric' });
    const day = date.toLocaleString('en-US', { day: 'numeric' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });
    
  return (
    <div style={styles.container} onClick={onClick}>
    <div style={styles.eventDetails}>
      <p className='poppins-15px-500'>{eventName}</p>
      <p className='poppins-10px-275'>
          {eventLocation}
      </p>
    
    </div>
 
    <div style={styles.fare}>
      <p style={{alignSelf:"center"}} className='poppins-10px-275'>{`${day}/${month}/${year}`}</p>
    </div>
  </div>
  )
}

export default TicketCard
