import React, { useEffect, useRef, useState } from 'react'
import ProfileInfoEdit from '../../../components/ProfileInfoEdit'
import { useAuth } from '../../../contextAPI/AuthContext'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import { findNextKYC } from '../../../Utils/util'
import { useApp } from '../../../contextAPI/AppContext'
import { useNavigate } from 'react-router-dom'
import { validateFirstName, validateLastName, validateSex } from '../../../Utils/formValidation'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'


const styles = {
    pageTitle: {
        margin:"20px 0"
    },
    btnBox: {
        width:"100%",
        position: "absolute",
        bottom:"40px",
        padding: "0 20px",
        
    }
}

const BasicInfo = () => {

    const {user, setUser, } = useAuth()
    const {displayName, sex, firstName, lastName,id,verification} = user
    const navigate = useNavigate()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const sexRef = useRef()
    const [next, setNext] = useState("/kyc-list")
    const {verificationList, nextList} = useApp()
    const KYCnumber = 0


  
    const [firstNameError, setFirstNameError] = useState(null)
    const [lastNameError, setLastNameError] = useState(null)
    const [sexError, setSexError] = useState(null)


    useEffect(()=>{
       const next = findNextKYC(KYCnumber, verificationList)
       if(next !== -1){
        setNext(nextList[next])
       }
    },[])

    useEffect(()=>{
        firstNameRef.current.value = firstName || ""
        lastNameRef.current.value = lastName || ""
        sexRef.current.value = sex || ""
    },[])

    

   
    const confirmFirstName = () => {
        const firstNameError = validateFirstName(firstNameRef.current.value)
        setFirstNameError(firstNameError)
  
        return firstNameError
    }

    const confirmLastName = () => {
        const lastNameError = validateLastName(lastNameRef.current.value)
        setLastNameError(lastNameError)
   
        return lastNameError
     }
     const confirmSex = () => {
        const sexError = validateSex(sexRef.current.value)
        setSexError(sexError)
   
        return sexError
     }


     const onClick = async() => {

       const firstNameError = confirmFirstName()
       const lastNameError = confirmLastName()
       const sexError = confirmSex()

       if(firstNameError || lastNameError || sexError){
          return
       }

       const data = {

       }

       if(firstName !== firstNameRef.current.value.trim())
            data.firstName = firstNameRef.current.value
        if(lastName !== lastNameRef.current.value.trim())
            data.lastName = lastNameRef.current.value
        if(sex !== sexRef.current.value.trim())
            data.sex = sexRef.current.value
        
        
        data.verification = {
            ...verification,
            basicInfo: "completed"
        }
        try {
            const usersRef = collection(db, "users")
            const userRef = doc(usersRef, id)
       
            await updateDoc(userRef,data,{ merge: true })
            
            setUser(prev=>({
             ...prev,
             ...data
            }))

        
            navigate("/kyc-list")
            // navigate(next)
        } catch (error) {
            
        }
        
    }
  
  return (
   
    
    <div className='container' >
        <h1 className='poppins-20px-500' style={styles.pageTitle}>Confirm your basic information</h1>
        <ProfileInfoEdit title="First name" isEditable={true} infoRef={firstNameRef} formError={firstNameError} onChange={confirmFirstName}/>
        <ProfileInfoEdit title="Last name" isEditable={true} infoRef={lastNameRef} formError={lastNameError} onChange={confirmLastName}/>
        <ProfileInfoEdit title="Sex" isEditable={true} infoRef={sexRef} formError={sexError} onChange={confirmSex}/>
        <div style={styles.btnBox}>
            <LongPurpleBtn name="Next" onClick={onClick}/>
        </div>
    </div>
    
 
  )
}

export default BasicInfo
