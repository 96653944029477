import React from 'react'
import FormError from "./FormError"

const styles = {
    containerFLexRow:{
        width: "100%",
        height: "auto",
        display: "flex",
        margin: "10px 0",
    },
    container:{
        backgroundColor: "#18181A",
        width: "90%",
        height: "auto",
        padding: "10px 20px",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        display: "flex",
        flexDirection: "column",
       
      
    },
    input:{
        backgroundColor: "#18181A",
        padding: "0",
        border: "0",
        outline: "none",
        height: "auto"
      
    },
    

    editIcon:{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "10%",
      backgroundColor: "#18181A",
      borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
    }
 
 }
const ProfileInfoEdit = ({title, infoRef, isEditable, onChange, isUsernameAvailable, formError,focus}) => {
  console.log(infoRef)
  return (
    <div style={styles.containerFLexRow} onClick={focus}>
    <div style={styles.container}>
      <h3 className='poppins-10px-300'>{title}</h3>
       <input className='poppins-15px-500' style={styles.input} ref={infoRef} disabled={!isEditable} onChange={onChange} />
     
      <FormError error={formError}/>
    </div>
    <div style={styles.editIcon}  >
        { isEditable  &&<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 20 20" fill="none">
            <path d="M18.2583 2.57651C17.5761 1.98064 16.8085 1.64014 15.8704 1.64014C14.9322 1.64014 13.9941 2.06576 13.3118 2.74676L3.3335 12.7915L3.93049 13.3875L4.52749 13.9833L6.83018 16.2817L7.42717 16.8776L8.02416 17.4735L18.0878 7.42866C19.4523 6.06666 19.5377 3.8534 18.2583 2.57651Z" fill="white"/>
            <path d="M5.52291 17.1631L3.80704 15.4472L3.15338 14.7936L2.49971 14.1399C2.33629 14.3851 2.25459 14.7119 2.17288 14.957L0.865552 18.9607C0.783841 19.2876 0.865552 19.5326 1.02896 19.7778C1.19238 19.9412 1.51921 20.0229 1.84604 19.9412L5.84974 18.7156C6.17658 18.6339 6.4217 18.4705 6.66683 18.3887L6.09487 17.8168L5.52291 17.1631Z" fill="white"/>
        </svg>}
        {/* {
          isEditable && !formError && <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 17 17" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7715 5.6451C12.979 5.85256 12.979 6.18894 12.7715 6.3964L7.81321 11.3547C7.60574 11.5622 7.26942 11.5622 7.06193 11.3547L4.2286 8.52138C4.02113 8.31391 4.02113 7.97759 4.2286 7.77012C4.43606 7.56265 4.77243 7.56265 4.9799 7.77012L7.43758 10.2278L12.0203 5.6451C12.2278 5.43764 12.5641 5.43764 12.7715 5.6451Z" fill="#20C963"/>
        </svg>
        }
        {
         isEditable && formError && <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M8.00006 6.93945L1.6361 0.575439L0.575439 1.6361L6.93936 8.00005L0.575439 14.364L1.6361 15.4247L8.00006 9.06075L14.3641 15.4247L15.4247 14.364L9.06076 8.00005L15.4247 1.6361L14.3641 0.575439L8.00006 6.93945Z" fill="#FF3A3A"/>
        </svg>
        } */}
      </div>
      
    </div>
  )
}

export default ProfileInfoEdit
