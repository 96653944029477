import React from 'react'

const FilterType = ({name,onClick,filterType}) => {
  return (
    <div className='poppins-12px-300' style={filterType ?{display:"flex", justifyContent:"center",alignItems:"center", height:"30px", padding:"10px", borderRadius:"10px",color:"#A0A3AF", backgroundColor:"#18181A", marginLeft:"10px",border:"1px solid #4a2bba"}:{display:"flex", justifyContent:"center",alignItems:"center", height:"30px", padding:"10px", borderRadius:"10px",color:"#A0A3AF", backgroundColor:"#18181A", marginLeft:"10px"}} onClick={onClick}>
      <p>{name}</p>
    </div>
  )
}

export default FilterType
