import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useCreateEvent } from '../../contextAPI/CreateEventContext'

const pictures = {
    "Birthday":["birthday1.png"],
    "Wedding":["wedding1.png"],
    "Hang Out": ["hangout1.png","hangout2.png","hangout3.png","hangout4.png","hangout5.png"],
    "Concert":["concert1.png","concert2.png"],
    "Random":["random1.png","random2.png"],

}

const styles = {
    pictures: {width:"100%",height:"200px", margin:"10px 0", borderRadius:"15px"}
}

const Picture = ({src,onClick})=>{
    return(
        <div style={styles.pictures} onClick={onClick}>
            <img src={src} style={{width:"100%", height:"100%", borderRadius:"15px"}} loading='lazy'/>
        </div>
    )
}
const EventCoverPictures = ({pickPicture,setPickPicture,setChoosePicture}) => {

    const {setNavFunction} = useNav()
    const { setEventDetails} = useCreateEvent()

    useEffect(()=>{
        setNavFunction(()=>()=>setPickPicture(null))
    },[])

    const selectImg = (pic) =>{
        console.log(pic)
        setEventDetails(prev=>({...prev,eventCoverImg:pic}))
        setPickPicture(null)
        setChoosePicture(false)
    }
  return (
    <div className='container'>
        <h1 className='poppins-20px-500' style={{margin:"20px"}}>{pickPicture}</h1>
        {
            pictures[pickPicture].map(pic=><Picture src={pic} onClick={()=>selectImg(pic)}/>)
        }
    </div>
  )
}

export default EventCoverPictures
