import React from 'react'

const style = {
  
    width: "100%",
    height:  "300px",
    backgroundColor: "#D9D9D9",
}
const PictureBox = () => {
  return (
    <div style={style}>
      
    </div>
  )
}

export default PictureBox
