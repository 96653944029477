
import {  useNavigate } from "react-router-dom"
// import "../../index.css"
import { useEffect, useState } from "react"
import { useAuth } from "../../../contextAPI/AuthContext"
import { collection, doc, increment, onSnapshot, query, runTransaction, updateDoc, where } from "firebase/firestore"
import { db } from "../../../firebase/config"
import { useApp } from "../../../contextAPI/AppContext"
import ChatApp from "../../../components/ChatApp/ChatApp"
import { useMsg } from "../../../contextAPI/ErrorMsgContext"
import { useNav } from "../../../contextAPI/NavContaxt"
import MessageBox from "../../../components/MessageBox2"
import LongPurpleBtn2 from "../../../components/Buttons/LongPurpleBtn2"
import DangerBtn from "../../../components/Buttons/DangerBtn"
import Spinner from "../../../components/Spinner"
import { registerToTopic, sendMsgToTopic, unRegisterFromTopic } from "../../../api"
import ConfirmationBox from "../../../components/ConfirmationBox"
import PoolerCard from "../components/PoolerCard"
import PooleeCard from "../components/PooleeCard"
import PassengerQuickAction from "../ContactAsPooler/PassengerQuickAction"
import StatusCard from "../components/StatusCard"
import { confirmCheckIn } from "../utils"
import { checkInController } from "../../../firebase/controller/poolController"
import TipsForPoolees from "../../../components/TipsForAJollyRide/TipsForPoolees"
import { convertPoolTime } from "../../../Utils/util"


const Contact = ({setSwitchScreen})=>{

   
    const [poolId, setPoolId] = useState(null)
    const {user,token} = useAuth() 
    // const [poolType, setPoolType] = useState(null)
    const [validate, setValidate] = useState(false)
    const [validateMessage, setValidateMessage] = useState("Send a message to your pooler")
    const [pool, setPool] = useState(null)
    const [leaveAMsg,setLeaveAMsg] = useState(false)
    const [showTextArea, setShowTextArea] = useState(false)
    const [textareaValue, setTextareaValue] = useState('');
    //const textareaValueRef = useRef()
    const [buttonMsg, setButtonMsg] = useState("Too short")
    const [poolHailerRef, setPoolHailerRef] = useState(null)
    const [poolHailer, setPoolHailer] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [poolMsgsRef, setPoolMgsRef] = useState(null)
    const [poolMsgs, setPoolMsgs] = useState([])
    const [openChat, setOpenChat] = useState(false)
    const eventId = localStorage.getItem("eventId")
    const {poolId: appPoolId,setPoolId:setAppPoolId,eventData, tempPoolId} = useApp()
    const navigate = useNavigate()
    const {setMsgType} = useMsg()
    const {setTitle, setNavFunction, setNavType, setShowNav} = useNav()
    const [isWaiting, setIsWaiting] = useState(true)
    const poolCollection = collection(db,"pool")
    const [poolDoc, setPoolDoc] = useState(null)
    const [showConfirmationBox,setShowConfirmationBox] = useState(null)
    const [showConfirmCheckIn, setShowConfirmCheckIn] = useState(null)
    const [poolees, setPoolees] = useState([])
    const [viewPoolees, setViewPoolees] = useState(false)
    const usersRef= collection(db, "users")
    const userRef = doc(usersRef, user.id )
    const [msg, setMsg] = useState(null)
    const [color, setColor] = useState(null)
    const [checkedIn, setCheckedIn] = useState(null)
    const [error, setError] = useState(null)

    useEffect(()=>{
    
    
      
      if(appPoolId !== "pending"){
         setPoolId(appPoolId)
         const poolDoc = doc(poolCollection,(appPoolId))
         setPoolDoc(poolDoc)
      }else{
        setPoolId(tempPoolId)
        const poolDoc = doc(poolCollection,(tempPoolId))
        setPoolDoc(poolDoc)
      }
      
      setTitle("Contact Pooler")
      setNavType("back")
      setShowNav(true)

      if(!eventId){
        navigate("/notfound")
      }
    
     
    },[])

    useEffect(()=>{
     if(poolDoc){
      const poolHailersSubcollection = collection(poolDoc, 'poolHailers')
      const q = query(poolHailersSubcollection, where("poolHailerStatus","in",["joined","created","accepted"])) 
      const unsubscribeHaillers = onSnapshot(q, (snapshot) => {
       
        setPoolees([])
        
        snapshot.docs.forEach((change) => {
          // Handle changes (added, modified, removed)
          const data = change.data()
          const hailerData = {
            id:change.id,
            ...data
          }
         
            setPoolees((prev)=>[...prev,hailerData])

        });
      },(error)=>{
        console.log(error)
        setMsgType("failure")
      });
      return () => unsubscribeHaillers();
     }
},[poolDoc])

    useEffect(()=>{
      
      try {
        
      
      const poolMsgsRef = collection(poolDoc,"poolMessages")
      setPoolMgsRef(poolMsgsRef)
      
      const poolMsgsUnsubscribe = onSnapshot(poolMsgsRef,(snapshot)=>{
      
      
       setPoolMsgs([])
       const sortedMgs =snapshot.docs.map(doc=>({
          id:doc.id,
          ...doc.data()
       })).sort((a,b)=>(a.time - b.time))
  
       setPoolMsgs(sortedMgs)
      
     },(error)=>{
        console.log(error)
        setMsgType("failure")
     })
      
     return()=> poolMsgsUnsubscribe()
    
    } catch (error) {
        setMsgType("failure")
    }
      
    },[poolDoc])


    useEffect(()=>{

        if(pool && pool.status ){
          
            setIsWaiting(false)
        }
    },[pool])

 

  // useEffect(()=>{
  //   if((poolHailer?.poolHailerStatus !== "accepted" || poolHailer?.poolHailerStatus !== "joined" )  && !openChat){
  //     setNavFunction(()=>{
  //       return ()=>setSwitchScreen(false)
  //     })
  //     setNavType("back")
  //   }
  // },[poolHailer,openChat])

   useEffect(()=>{
       //listen to update in pool collection
       
       
      if(poolHailer){ 

      
       const  unsubscribePool = onSnapshot(poolDoc,(docSnapshot)=>{

         if(docSnapshot.exists()){
        
           const data  = docSnapshot.data()
           setPool(data)
          
          
           if((data.status === "created" || data.status === "closed") &&( poolHailer.poolHailerStatus === "accepted" || poolHailer.poolHailerStatus === "joined" )){
              setValidate(true)
              setLeaveAMsg(false)
              setValidateMessage("Send a message to your pooler")
              setMsg("Accepted")
              setColor({primary:"#20C963", secondary:"#202827"})
           }
           else if(poolHailer.poolHailerStatus === "rejected"){
            setValidate(false)
            setLeaveAMsg(false)
            setValidateMessage("Pooler rejected your offer! Maybe not a match")
            setMsg("Rejected by pooler")
            setColor({primary:"#FF3A3A", secondary:"#2C2125"})
          
           }else if((data.status === "created" || data.status === "closed") && poolHailer.poolHailerStatus === "cancelled"){
             setValidate(true)
             setLeaveAMsg(false)
             setMsg("Cancelled by pooler")
             setColor({primary:"#FF3A3A", secondary:"#2C2125"})
         
           }else if(poolHailer.poolHailerStatus === "removed"){
          
            setValidate(false)
            setLeaveAMsg(false)
            setValidateMessage("pool is closed or cancelled")
            setMsg("Cancelled by pooler")
            setColor({primary:"#FF3A3A", secondary:"#2C2125"})
           }
           else if(data.status == "created"){
            setValidate(true)
            setLeaveAMsg(true)
            setValidateMessage("Send a message to your pooler")
            setMsg("Requested")
            setColor({primary:"#8E69F0",secondary:"#26242E"})

           }

           else{
            setValidate(false)
            setLeaveAMsg(false)
            setValidateMessage("pool is closed or cancelled")
            setMsg("Closed")
            setColor({primary:"#FF3A3A", secondary:"#2C2125"})
           }

         }else{
          setValidate(false)
          setLeaveAMsg(false)
          setValidateMessage("pool is closed or cancelled")
          setMsg("Closed")
          setColor({primary:"#FF3A3A", secondary:"#2C2125"})
         }
        
       },(error)=>{
        console.log(error)
        setMsgType("failure")
       })
  
       
        
       return ()=> unsubscribePool()
      
      }
      
      
   
   },[poolHailer])


   useEffect(()=>{
    
    if(poolId){
   
     
      try {
        const poolCollection = collection(db,"pool")
        const poolDoc = doc(poolCollection,(poolId))
 
        const poolHailersSubcollection = collection(poolDoc, 'poolHailers') 
        const poolHailerDoc = doc(poolHailersSubcollection, user.id)
     
        
     
        setPoolHailerRef(poolHailerDoc)
        const unsubscribeHaillers = onSnapshot(poolHailerDoc,(snapshot)=>{
          
          if(snapshot.exists()){
            const data  ={ ...snapshot.data(),id:snapshot.id}
            setPoolHailer(data)

          }else{
         
            navigate("/notfound")
          }

        },(error)=>{
          console.log(error)
          setMsgType("failure")
        })

        
       return ()=> unsubscribeHaillers()
      } catch (error) {

        setMsgType("failure")
      }
    }


   },[poolId])

   useEffect(()=>{
       if(poolHailer && pool && eventData){
        const poolDate = convertPoolTime(pool.poolDate, eventData.eventDate)
        const checkedIn = confirmCheckIn({poolDate, returnTripSurvey:pool.returnTripSurvey},poolHailer,eventData)
        setCheckedIn(checkedIn)
       }
   },[poolHailer, pool, eventData])


   const checkIn = async() => {
 
    try {
      await checkInController(poolHailerRef,checkedIn.type)
      
      setCheckedIn(prev=>({...prev, status: true}))
    } catch (error) {
      setError("Error checking in")
      console.log(error)
    }
   
 }
   // opens chat area
   const joinCarpoolGroup = async()=>{
    
    try {
      setOpenChat(true)
   
      setNavFunction(()=>{
        return ()=> setOpenChat(false)
      })
      setNavType("cancel")


      // setShowNav(false)
      
     
        if(poolHailer.poolHailerStatus === "accepted"){

          registerToTopic(poolId + "poolee", user.messageToken,token, (error)=>{
            if(!error){
                console.log("subscribed")
            }
          })

        
          setAppPoolId(poolId)

         
          await runTransaction(db,async(transaction)=>{
          // transaction.update(userEventDocRef,{poolId})
          transaction.update(poolHailerRef,{
            poolHailerStatus:"joined",
            joinedAt: new Date()
          })
          transaction.update(poolDoc,{pooleeCount: increment(1)})
          const userRidesRef = collection(userRef,"userPoolHistory")
          const userRideRef = doc(userRidesRef, poolId)
          transaction.update(userRideRef, {status:"joined"})
         })
          
         if(pool.messageToken){
          const notification = {
            title: `someone joined your pool`,
            body:  `${user.username || user.displayName} joined for ${pool.eventName}`
          }
          sendMsgToTopic(poolId + "pooler",notification,token,(error)=>{
            if(!error){
              
            }
          })
         }
        }
        else if(poolHailer.poolHailerStatus === "cancelled"){
          registerToTopic(poolId + "poolee", user.messageToken,token, (error)=>{
            if(!error){
                console.log("subscribed")
            }
          })

          
          const userRidesRef = collection(userRef,"userPoolHistory")
          const userRideRef = doc(userRidesRef,(poolId))

          await runTransaction(db,async(transaction)=>{

            transaction.update(poolHailerRef,{
              poolHailerStatus:"joined",
              reJoinedAt: new Date()
            })

            transaction.update(userRideRef,{active: true})
          })
       
        }
    } catch (error) {
      
    }
      

     
  };

  const onCloseChat = ()=>{
   
    setTitle("Contact Pooler")
    setNavFunction(()=>{
      return ()=> {
        
        if(localStorage.viewMode === "event"){
          navigate("/event-page")
        }else if(localStorage.viewMode === "carpool"){
          navigate("/")
        }
      }
    })
    setNavType("back")
    setShowNav(true)
  
  }

  useEffect(()=>{
    if(!openChat){
      onCloseChat()
     
    }
  },[openChat])


  const cancelRide = async(e,confirmedReject) => {
   

    // setIsDisabled(true)
    const poolCollection = collection(db,"pool")
    const poolDoc = doc(poolCollection,(poolId))
    const poolHailersSubcollection = collection(poolDoc, 'poolHailers') 
    const poolHailerDocREf = doc(poolHailersSubcollection,user.id)
     
     try {

      const userRidesRef = collection(userRef,"userPoolHistory")
      const userRideRef = doc(userRidesRef,(poolId))
     
      if(user.messageToken){
      unRegisterFromTopic(poolId,user.messageToken,token,(error)=>{
        if(!error){
          console.log("successfully unregistered")
        }
      })
    }


    console.log(pool.poolDate.seconds , (new Date().setHours(0,0,0,0)/1000))

   

        if(validate && !(eventData?.ends.seconds < (new Date().setHours(0,0,0,0)/1000) || (pool.poolType ==="default" && pool.poolDate.seconds < (new Date().setHours(0,0,0,0)/1000)))){
         
            if(confirmedReject){
       
              
                await runTransaction(db,async(transaction)=>{

                  
                 
                  // rejected when the ride is still opem
                  // transaction.update(userEventDocRef,poolStatus)
                  if(poolHailer.poolHailerStatus === "created"){
                    transaction.update(poolDoc,{hailerCount: increment(-1)})
                  }else if(poolHailer.poolHailerStatus === "joined"){
                    transaction.update(poolDoc,{pooleeCount: increment(-1)})
                  }
                  transaction.update(poolHailerDocREf,{
                    poolHailerStatus: "rejected",
                    timeRejectedByPoolee: new Date()
                  })
                  transaction.update(userRideRef,{status: "rejectedByUser",active:false})
                 

                })
                 
              
                if(pool.messageToken){
                  const notification = {
                  title: "Oops! someone left your pool",
                  body: `${user.username || user.displayName } left the pool (${eventData.eventName})`
               }
                 sendMsgToTopic(poolId + "pooler",notification,token,(error)=>{
                  if(!error){
                    console.log("message sent")
                  }
                 })
               }
             
              
              setShowConfirmationBox(null)
            }else{
              setShowConfirmationBox("Are you sure you want to cancel ride?")
              setIsDisabled(false)
              return
            }
        }else{
          
          console.log("rejected")
          

          await runTransaction(db,async(transaction)=>{

            transaction.update(userRideRef,{active:false})
            // transaction.update(userEventDocRef,poolStatus)
            // if( ["joined", "accepted","created"].includes(poolHailer.poolHailerStatus)){
              
            //   let data =  poolHailer.poolHailerStatus === "joined" ? {pooleeCount: increment(-1)} : poolHailer.poolHailerStatus === "accepted" ? {} : {hailerCount: increment(-1)}
            //   transaction.update(poolDoc,{[`poolees.${user.id}`]:"cancelled",...data} )
            //   transaction.update(poolHailerDocREf,{
            //     poolHailerStatus: "cancelled"
            //   })
            //   console.log("this that")
            //   console.log(poolDoc.id)
              
            // }
 
          })

       }
       
     
     setSwitchScreen()
     
      
     } catch (error) {
       console.error(error)
       setMsgType("failure")
       setIsDisabled(false)

     }  

  }

  const onChangeTextArea = (e)=>{

    setTextareaValue(e.target.value)
    if(e.target.value.length < 10){
      
      setButtonMsg("too short")
    }
    else if(e.target.value.length > 50){
      setButtonMsg("it's getting too looooooooong")
    }
    else{
      setButtonMsg("looking good")
    }


  }

  const saveShortMsg = async() => {

    if(textareaValue.length > 10 && textareaValue.length <= 50){
      setIsDisabled(true)
     
      const poolHailersSubcollection = collection(poolDoc, 'poolHailers')
      const newHailerDocRef = doc(poolHailersSubcollection, user.id);

      
  
              //create data for hailer
  
      const hailerData = {
        shortMsg: textareaValue
      }
      
    
      
      try {
        await updateDoc(newHailerDocRef, hailerData)
        setTextareaValue("")
         
       console.log(pool)
        if(pool.messageToken){
          const notification = {
          title: `${user.username || user.displayName } is Hailing`,
          body: textareaValue
       }
         sendMsgToTopic(poolId + "pooler",notification,token,(error)=>{
          if(!error){
            console.log("message sent")
          }
         })
       }
      } catch (error) {
        console.error(error)
        setMsgType("failure")
        setIsDisabled(false)
      
      }
      
      setIsDisabled(false)
   
       
       setShowTextArea(false)
    }
     
   

  }

  if(openChat){
    const firestoreDate = eventData?.eventDate.toDate() || pool.poolDate.toDate()
    const currentDate = new Date();
    const diffInMs = firestoreDate - currentDate  ;
    
    const diffInHours = diffInMs / (1000 * 60 * 60);
  
    if (diffInHours <= 24) {
    return(
       <ChatApp poolMsgs={poolMsgs} poolMsgsRef={poolMsgsRef} setOpenChat={onCloseChat} poolId={poolId} poolAdminMsgToken={pool.messageToken} poolHailerStatus ={poolHailer.poolHailerStatus}/>
      
    )
    }else{
      return(
      <TipsForPoolees/>
      )
    }
  }

    return(

      
      isWaiting && <Spinner/> ||
    
       
     <>
     
      <div className="container">
      
       {
         viewPoolees &&

         <PassengerQuickAction passengers={poolees}  setOpenPassengerQA={setViewPoolees} type="poolee" eventLoc={eventData?.location}/>
        
        ||
        
         <div style={{width:"100%"}}>
         <PoolerCard poolData={pool} eventDate={eventData?.eventDate}/>
        <div style={{padding:"0 0 20px", width:"100%"}}>
          <StatusCard msg={msg} color={color}/>
        </div>
        <PooleeCard viewPoolees={setViewPoolees} poolees={poolees}/>
        
         <MessageBox displayName={user.displayName} validateMessage={validateMessage} validate={validate} leaveAMsg={leaveAMsg} onChangeTextArea={onChangeTextArea} onClick={joinCarpoolGroup} textareaValue={textareaValue} poolHailerStatus={poolHailer.poolHailerStatus} checkedIn={checkedIn?.status} checkIn={checkIn} setShowConfirmCheckIn={setShowConfirmCheckIn}/>
        
        { validate && poolHailer.poolHailerStatus ==="created" && 
          <LongPurpleBtn2 inactive={isDisabled} onClick={saveShortMsg} name="send"/>
        }

        {validate && poolHailer.poolHailerStatus ==="joined" && <LongPurpleBtn2 onClick={joinCarpoolGroup} name="open chat"/>}
        {validate && poolHailer.poolHailerStatus === "accepted"  && <LongPurpleBtn2 onClick={joinCarpoolGroup} name="Accept Ride & Join Chat"/>}
        
         <DangerBtn inactive={(!poolId ) || isDisabled} onClick={cancelRide} name="Cancel Ride"/>

         {/* <div style={styles.bottomBtn}>
            <CircleUpBtn onClick={()=>setOpenQuickAction(true)}/>
        </div> */}
        
          
         </div>
 
       }
     
    
      </div>
      
      
     
     {showConfirmationBox && <ConfirmationBox para={showConfirmationBox} 
        title="Pool is Active!"
       
        actionButtons={[{onClick: (e)=>cancelRide(e,true), title:"Cancel Ride"}]} 
        cancelBtn={true} 
        setShowConfirmationBox={setShowConfirmationBox}/>}
        {
          showConfirmCheckIn && <ConfirmationBox para={"Make sure you are in the ride before checking in"} title={"Security CheckIn"} cancelBtn={true} actionButtons={[{title:"Confirm Check in", onClick:checkIn}]}  setShowConfirmationBox={setShowConfirmCheckIn}/>
        }
        {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`}  errorType={true}/>}
        {
          (((eventData && (( eventData.repeat === "none" && eventData.ends.seconds < new Date().setHours(0,0,0,0)/1000) || ( pool.repeat === "Once" && pool.poolDate.seconds < (new Date().setHours(0,0,0,0))/1000))) || ( pool.poolType === "default" && pool.poolDate.seconds < (new Date().setHours(0,0,0,0)/1000)))) && <ConfirmationBox title="Pool Expired"  para="This pool has expired" errorType={true} actionButtons={[{title:"cancel pool", onClick:(e)=>cancelRide(e,true)}]} />
        }
      </>
    )

}

export default Contact