import React from 'react'
const style = {
    maxWidth: "313px",
    maxHeight: "240px",
    width:"80vw",
    height:"30vh",
    borderRadius: "200px",
    border: "1px solid #8d68f0",
    backgroundColor: "#DFF7E6"
}
const TabImg = ({src}) => {
  return (
    <div style={style}>
      <img style={style} src={src}/>
    </div>
  )
}

export default TabImg
