import React from 'react'
import XBtn from '../../../components/Buttons/XBtn'

const styles = {
    container : {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex:"9999"
    },
    transDiv: {
       flex:"1",
        opacity: "0.27",
        width: "100%",
        backgroundColor: "#4C2CBF"
    },
    middleDiv:{
     
        width:"100%",
        backgroundColor:"#2c234e",
        display:"flex",
        flexDirection:  "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"0 20px"
    },
    repeatOptions: {
        backgroundColor: "#18181A",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "15px",
        color: "#ffffff",
        width:"80vw"
    },
    generalMargin: {
      margin: "10px 0"
    },

    

   
}

const repeatTypes = ["Once", "Daily", "Mon to Fri", "Weekly", "Custom"]
const repeatTypes2 = ["Once", "As event repeats"]
 
const RepeatOptions = ({setShowCustom, setShowOption, setRepeatType, repeatType, type2}) => {


    const onSetRepeatType = (type)=>{

        if(type === "Custom"){
          
            setShowCustom(true)
            return
        }
        setRepeatType(type)

    }

    
  return (
    <div style={styles.container}>
      <div style={styles.transDiv}>

      </div>
      <div style={ styles.middleDiv}>
        <div style={styles.repeatOptions}>
            { !type2 &&
                repeatTypes.map(type=>(
                    
                      type === repeatType &&
                        
                        <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", margin:"10px 0", color:"#8E69F0"}} onClick={()=>onSetRepeatType(type)}>
                            <p>{type}</p>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.51266 3.32078C7.63471 3.44281 7.63471 3.64068 7.51266 3.76272L4.596 6.67937C4.47396 6.80141 4.27612 6.80141 4.15407 6.67937L2.4874 5.01271C2.36536 4.89066 2.36536 4.69283 2.4874 4.57079C2.60944 4.44875 2.80731 4.44875 2.92934 4.57079L4.37504 6.01646L7.07075 3.32078C7.19279 3.19874 7.39062 3.19874 7.51266 3.32078Z" fill="#8E69F0"/>
                            </svg>
                        </div>
                       ||
                       <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", margin:"10px 0"}} onClick={()=>onSetRepeatType(type)}>
                            <p>{type}</p>    
                        </div>

                ))
            }{
                type2 && 
                repeatTypes2.map(type=>(
                    type === repeatType &&
                        
                        <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", margin:"10px 0", color:"#8E69F0"}} onClick={()=>onSetRepeatType(type)}>
                            <p>{type}</p>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.51266 3.32078C7.63471 3.44281 7.63471 3.64068 7.51266 3.76272L4.596 6.67937C4.47396 6.80141 4.27612 6.80141 4.15407 6.67937L2.4874 5.01271C2.36536 4.89066 2.36536 4.69283 2.4874 4.57079C2.60944 4.44875 2.80731 4.44875 2.92934 4.57079L4.37504 6.01646L7.07075 3.32078C7.19279 3.19874 7.39062 3.19874 7.51266 3.32078Z" fill="#8E69F0"/>
                            </svg>
                        </div>
                       ||
                       <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", margin:"10px 0"}} onClick={()=>onSetRepeatType(type)}>
                            <p>{type}</p>    
                        </div>
                ))
            }
        </div>
        <div style={{marginTop:"20px"}}>
        <XBtn type="circle" onClick={()=>setShowOption(false)}/>
        </div>
      </div>
      <div  style={styles.transDiv}>

      </div>
    </div>
  )
}

export default RepeatOptions
