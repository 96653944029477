import React, { useRef } from 'react'
import { submitRating } from '../../../firebase/controller/eventsController'

const TextRating = ({eventId,userId, username,profile}) => {

  const commentRef = useRef()
  
  const rate = ()=>{
    
    console.log(commentRef.current.value)
   
    if(commentRef.current.value.length === 0 || commentRef.current.value === "done! thank you"){
      commentRef.current.value = ""
      return
    }
    submitRating(eventId, userId, {comment:commentRef.current.value},username,profile,(err)=>{
      if(!err){
        commentRef.current.value = "done! thank you"
        setTimeout(()=>{
          commentRef.current.value = ""
        },1500)
      }else{
        console.log(err)
      }
    })
    
     

    
  }

  return (
    <div style={{width:"100%", display:"flex",alignItems:"center", padding:"0 10px", backgroundColor:"#2D2F3C", borderRadius:"20px"}}>
      <input placeholder='Describe your experience' style={{height:"40px", backgroundColor:"#2D2F3C", border:"none", color:"#A0A3AF", outline:"none"}} ref={commentRef}/>
      <button style={{width:"30px", height:"30px", backgroundColor:"#8E69F0"}} onClick={rate}>
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.28033 5.5303C0.987437 5.8232 0.512558 5.8232 0.219668 5.5303C-0.0732225 5.2374 -0.0732225 4.76256 0.219668 4.46967L4.2197 0.469668C4.5126 0.176777 4.9874 0.176777 5.2803 0.469668L9.2803 4.46967C9.5732 4.76256 9.5732 5.2374 9.2803 5.5303C8.9874 5.8232 8.5126 5.8232 8.2197 5.5303L5.5 2.81066V12.5C5.5 12.9142 5.1642 13.25 4.75 13.25C4.3358 13.25 4 12.9142 4 12.5V2.81066L1.28033 5.5303Z" fill="white"/>
        </svg>
      </button>
    </div>
  )
}

export default TextRating
