import React from 'react'
import {motion} from "framer-motion"


const Poolee = ({info,onClick}) =>{

    const {username, location, profile, poolHailerStatus} = info
    
   
   return(
    <> 
    
    <motion.div 
    style={{minWidth:"25%", display:"flex", flexDirection:"column", alignItems:"center", marginRight:"10px", textAlign:"center"}} onClick={onClick}
    initial={{ scale: 0.9 }}
    animate={{  scale: 1 }}
    exit={{ scale: 0.9 }}
    transition={{ duration: 0.2 }}
    >
        <div style={{width:"40px", height:"40px", backgroundColor:"#ffffff", borderRadius:"100%", marginBottom:"10px"}}>
          <img src={profile} style={poolHailerStatus === "created" ?{width:"100%", height:"100%", borderRadius:"100%",objectFit:"cover", border:"1px solid #4B2CBA"}:{width:"100%", height:"100%", borderRadius:"100%",objectFit:"cover", border:"1px solid #20C963"}}></img> 
        </div>
        <p className='poppins-10px-300'>{username}</p>
        <p className='poppins-10px-300'>{location}</p>
    </motion.div>
    </>
   )
}

const PooleeCard = ({poolees, viewPoolees}) => {

   
    

    
  return (
    <motion.div 
    style={{ width:"100%", backgroundColor:"#18181A", marginBottom:"20px", borderRadius:"20px", padding:"20px", display:"flex", flexDirection:"column"}}
    initial={{ scale: 0.9 }}
    animate={{  scale: 1 }}
    exit={{ scale: 0.9 }}
    transition={{ duration: 0.2 }}
    >
      <div style={{display:"flex", justifyContent:"space-between",color:"#8E69F0", alignItems:"center"}}>
        <p className='poppins-17px-300' onClick={()=>viewPoolees(true)}>Poolees</p>
      
      </div>
      <div style={{display:"flex", justifyContent:"center", alignItems:"center", flex:"1"}}>
       {poolees.length ===0 && <p>No poolees yet</p>}
       {
       poolees.length > 0 && 
        <div style={{display:"flex", overflow:"scroll", width:"100%", flex:1, marginTop:"20px"}}>
         
            {
                poolees.map(poolee=><Poolee info={poolee}  onClick={()=>viewPoolees(true)}/>)
            }
        </div>
        }
      </div>
    </motion.div>
  )
}

export default PooleeCard
