import React from 'react'
import {motion} from "framer-motion"

const styles = {
  
    formInput:{
     backgroundColor: "#202024",
     border: "none",
     padding: "0",
     height: "fit-content",
     width: "100%",
     resize: "none",
     outline: "none",
   }
 }
const TicketFilterDropDown = ({value,onChange, onSelectFilter}) => {
  return (
    <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.8 }}
    transition={{ duration: 0.3 }}
     style={{position:"absolute", right:"10px", top:"30px", backgroundColor:"#18181A", padding:"30px"}}
     >
      <div style={{padding:"10px 0"}}>
      <input type='date'style={styles.formInput} className='poppins-20px-500' value={value} onChange={(e)=>{onChange(e.target.value); onSelectFilter("prev",e.target.value)}} />
      </div>
      <div style={{padding:"10px 0"}} onClick={()=>{onSelectFilter()}}>
        <p>Latest tickets</p>
      </div>
    </motion.div>
  )
}

export default TicketFilterDropDown
