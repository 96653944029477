import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../Utils/util'
import { useNavigate } from 'react-router-dom'
import BottomNavEvent from '../../components/BottomNav/BottomNavEvent'
import BottomNavCarpool from '../../components/BottomNav/BottomNavCarpool'
import { useApp } from '../../contextAPI/AppContext'

const Community = () => {

    const {setTitle,setShowNav,setNavFunction} = useNav()
    const {viewMode} = useApp()
    const [startX, setStartX] = useState(null);
    const [endX, setEndX] = useState(null);
    const navigate = useNavigate()

    useEffect(()=>{
        setTitle("Community")
        setShowNav(true)
        setNavFunction(()=>()=>navigate("/"))
    },[])
  return (
    <div 
    className='island'
    onTouchStart={(e)=>{handleTouchStart(e,setStartX)}}
    onTouchMove={(e)=>{handleTouchMove(e,setEndX)}}
  onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,(direction)=>{
    if(direction ==="left"){
      navigate("/profile")
   
    }else{
      if(localStorage.viewMode === "carpool"){
        navigate("/tips")
      }else{
        navigate("/tickets")
      }
      
    }
  })}
    >
        <h1 style={{color:"#fff"}}>Comming soon</h1>
     
    </div>
  )
}

export default Community
