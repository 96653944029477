import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import InfoBox from './InfoBox'
import KYCBox from '../../components/KYCBox'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contextAPI/AuthContext'

const styles = {
    btnBox: {
        width:"100%",
        // position: "absolute",
        bottom:"40px",
        padding: "0 ",
        
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
        
    }
}

const KYCList = () => {
   
    const {setTitle, setShowNav} = useNav()
    const {user} = useAuth()
    const {verification} = user
    const [isAccountVerified, setIsAccountVerified] = useState(false)
   
    
    const navigate = useNavigate()
    useEffect(()=>{
        setTitle("Verity Your identify")
         setShowNav(false)
         if(verification?.basicInfo === "completed"   ){
            setIsAccountVerified(true)
         }
    },[])
  return (
    <div className='container'>
      <InfoBox info="Verify your identity for a safe 
        and easy experience "/>
      <div style={{flex:1, width:"100%"}}>
      <KYCBox title="Confirm basic information" info="Make sure your basic data are correct" onClick={()=> navigate("/basic-info")} isVerified={verification?.basicInfo === "completed" }/>
      {/* <KYCBox title="Verify email" info="Confirm your email" onClick={()=> navigate("/confirm-email")} isVerified={verification?.email === "completed" }/> */}
      {/* <KYCBox title="Verify Phone number" info="Confirm your phone number" onClick={()=> navigate("/confirm-phone-number")} isVerified={verification?.phoneNumber === "completed" }/>
      <KYCBox title="Submit proof of identity" info="Submit NID, Driver's license, or International Passport"  onClick={()=> navigate("/confirm-id")} isVerified={verification?.id === "completed" }/> */}
      </div>
      <div style={styles.btnBox}>
        <LongPurpleBtn name="Next"  inactive={!isAccountVerified} onClick={()=>navigate("/")}/>
      </div>
    </div>
  )
}

export default KYCList
