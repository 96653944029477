import React from 'react'

const styles = {
    active:{
      backgroundColor: "#4C2CBFF7",
      width: "100%",
      height: "40px",
      borderRadius: "10px",
    },
    inactive:{
      backgroundColor:"#616263",
    },
    selected:{
      backgroundColor:"#281182"
    }
    

}
const LongPurpleBtn2 = ({name,onClick, inactive, isSelected}) => {
  return (
    <>
    {inactive && <button style={{...styles.active, ...styles.inactive}} disabled={inactive}>{name}</button> }
    {!inactive && !isSelected &&<button style={styles.active} onClick={onClick}>{name}</button>}
    {!inactive && isSelected && <button style={{...styles.active, ...styles.selected}} onClick={onClick}>{name}</button>}
    </>
  )
}

export default LongPurpleBtn2
