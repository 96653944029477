import React from 'react'

const styles = {
  xCancelBtn:{
    width: "10vw",
    height: "10vw",
    backgroundColor: "inherit",
    fontSize: "large",
  },
  xCircle:{
    width:"50px",
    height:"50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor:"#18181A",
    borderRadius:"100%",
    
  }
}

const XBtn = ({onClick,type}) => {
  return (
    (type === "circle" && <button style={styles.xCircle} onClick={onClick}>X</button> )||
    <button style={styles.xCancelBtn} onClick={onClick}>X</button>
  )
}

export default XBtn
