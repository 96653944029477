import React, { useEffect, useState } from 'react'
import { useAuth } from '../contextAPI/AuthContext'
import { useNav } from '../contextAPI/NavContaxt'
import {motion} from 'framer-motion'

import { useApp } from '../contextAPI/AppContext'

const WelcomeUser = () => {

    const {user} = useAuth()
    const { setShowNav, setTitle} = useNav()
   
    const {setViewMode} = useApp()

    useEffect(()=>{
        setShowNav(false)
        setTitle("Welcome " + (user.displayName))
    },[])

   
  return (
    <div 
    className='container' 
    style={{display:"flex", flexDirection:"column", justifyContent:"space-around"}}
    
    >
      <motion.div 
      style={{width:"100%", backgroundColor:"#18181A", borderRadius:"20px", marginBottom:"20px"}}
      initial={{scale:0.9}}
      animate={{scale:1}}
      transition={{type:"spring", damping: 25, stiffness:200}}
      onClick={()=>{localStorage.setItem("viewMode","carpool");  setViewMode("carpool") }}
      >
        <img style={{width:"100%"}} src='carpoolbuddy.png'></img>
        <div style={{padding:"10px 20px 20px"}}>
            <h1 className='poppins-15px-400'>Carpool</h1>
            <p className='poppins-10px-275' style={{fontStyle:"italic"}}>join or create a ride</p>
        </div>
      </motion.div>
      <motion.div 
      style={{ width:"100%", backgroundColor:"#18181A", borderRadius:"20px", padding:"50px 40px 20px"}}
      initial={{scale:0.9}}
      animate={{scale:1}}
      transition={{type:"spring", damping: 25, stiffness:200}}
      onClick={()=>{localStorage.setItem("viewMode","event"); setViewMode("event") }}
      >
        <div style={{  backgroundColor:"#202024", borderRadius:"20px", marginBottom:"20px"}}>
           <img src='eventSample2.png' style={{width:"100%"}}></img>
           <div style={{padding: "10px 20px 20px"}}>
            <p className='poppins-15px-200'>Novus UI anniversery</p>
            <p className='poppins-10px-275' style={{fontStyle:"italic"}}>28 Tuesday, August 2024</p>
           </div>
        </div>
        <h1 className='poppins-15px-400'>Events</h1>
        <p className='poppins-10px-275' style={{fontStyle:"italic"}}>Explore events</p>
      </motion.div>
    </div>
  )
}

export default WelcomeUser
