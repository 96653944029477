import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../contextAPI/NavContaxt'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import Attendees from './Components/Attendees'
import { useApp } from '../../contextAPI/AppContext'
import ConfirmationBox from '../../components/ConfirmationBox'
import { getTickets, getTicketsByDate } from '../../firebase/controller/ticketController'
import TicketFilterDropDown from './Components/TicketFilterDropDown'
import Spinner from '../../components/Spinner'


const ScanTicket = () => {
    const {setNavFunction, setTitle, setShowNav, setNavType} = useNav()
    const navigate = useNavigate()
    const eventId = localStorage.getItem("eventId")
    const {eventData} = useApp()
    const [tickets, setTickets] = useState(null)
    const [error, setError] = useState(null)
    const [showTIcketFilter, setShowTIcketFilter] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [dateValue, setDateValue] = useState(null)
    
    useEffect(()=>{
      
        if(!eventId && !eventData){
          navigate("/notfound",{state:{eventLink:"/event-page"}})
        }
        setShowNav(true)
        setTitle("Ticket")
        setNavFunction(()=>()=>{
          navigate("/event-page")
         
        })
        setNavType("back")

        
    },[])

    useEffect(()=>{
    
     onGetCurrentTickets()
    },[])

    const onGetCurrentTickets = ()=>{

      getTickets(eventId,(error, data)=>{
        if(!error && data){
          setTickets(data)
          setIsLoading(false)
        }else if(!error && !data){
          setTickets([])
          setIsLoading(false)
        }else if(error){
          setError("Error getting tickets")
          console.log(error)
          setIsLoading(false)
        }
        
    })
    }

    const onShowScanner = () => {
      navigate("/scanner")
    }

    const onSelectFilter = (type,date,current)=>{
      console.log(current)
      setIsLoading(true)
       if(type === "prev" && date ){
        console.log(date)
        setIsLoading(true)
        setShowTIcketFilter(false)
        getTicketsByDate(eventId,date,(error, data)=>{
          if(!error && data){
            setTickets(data)
            setIsLoading(false)
          }else if(!error &&!data){
            setTickets([])
            setIsLoading(false)
          }
            else if(error){
            setError("Error getting tickets")
            setIsLoading(false)
            console.log(error)
          }
         
      })
       }else{
        setShowTIcketFilter(false)
        onGetCurrentTickets()
        
       }
    }

  return (
    <>
    
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} errorType={true} />}
    <div className='container'>
      <div style={{marginTop:"20px", marginBottom:"40px",paddingLeft:"10px", width:"100%", display:"flex", justifyContent:"space-between", position:"relative"}}>
        <h1 className='poppins-20px-500'>Attendees</h1>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>setShowTIcketFilter((prev)=>!prev)}>
          <path d="M12 5.25C12.4142 5.25 12.75 5.58579 12.75 6C12.75 6.41421 12.4142 6.75 12 6.75C11.5858 6.75 11.25 6.41421 11.25 6C11.25 5.58579 11.5858 5.25 12 5.25ZM12 11.25C12.4142 11.25 12.75 11.5858 12.75 12C12.75 12.4142 12.4142 12.75 12 12.75C11.5858 12.75 11.25 12.4142 11.25 12C11.25 11.5858 11.5858 11.25 12 11.25ZM12 17.25C12.4142 17.25 12.75 17.5858 12.75 18C12.75 18.4142 12.4142 18.75 12 18.75C11.5858 18.75 11.25 18.4142 11.25 18C11.25 17.5858 11.5858 17.25 12 17.25Z" fill="white" stroke="white" stroke-width="1.5"/>
        </svg>

        {showTIcketFilter && <TicketFilterDropDown onSelectFilter={onSelectFilter} onChange={setDateValue} value={dateValue}/>}
      </div>
      {isLoading && <Spinner/>}
     { !isLoading && <div style={{flex:"1", width:"100%"}}>
       {
        tickets && tickets.map(ticket=><Attendees  key={ticket.id} id={ticket.id} name={ticket.userName} status={ticket.status}/>)
       }
       </div>}
       <div style={{width:"100%", height:"10%"}}>
         <LongPurpleBtn name="Scan Tickets" onClick={onShowScanner}/>
       </div>
     
    </div>
    
    </>

  )
}

export default ScanTicket
