import React from 'react'


const style = {
  
    width: "20px",
    height: "20px",
    borderRadius:"100%",
    backgroundColor:"#5A7093",
    position: "absolute",
    right: "10px",
    bottom: "12vh",
    color: "#f0f0f0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  
}

const ScrollToBotttomBtn =({ chatAreaRef }) => {
    const scrollToBottom = () => {
        
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight
    }
  
    return (
        <button style={style} onClick={scrollToBottom}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M6 9l6 6 6-6"/>
            <path d="M6 16l6 6 6-6"/>
        </svg>
       </button>
    )
  }

export default ScrollToBotttomBtn
