import { useRef, useEffect, useState } from "react"
import { useAuth } from "../../contextAPI/AuthContext"
import "../../index.css"
import { collection, doc, updateDoc } from "firebase/firestore"
import { db } from "../../firebase/config"
import {  useNavigate } from "react-router-dom"
import { useMsg } from "../../contextAPI/ErrorMsgContext"
import { useNav } from "../../contextAPI/NavContaxt"
import DropDownForm from "./DropDownForm"
import FormError from "../../components/FormError"
import { validateAdditionalInfo, validateConvenientPickUpLocation,  validateLocation, validatePhone, validateRideType, validateSeatCost, validateSeatsAvailable } from "../../Utils/formValidation"
import GreyInputBox from "../../components/InputsBox/GreyInputBox"
import LongPurpleBtn2 from "../../components/Buttons/LongPurpleBtn2"
import { registerToTopic, sendMsgToTopic } from "../../api"
import ConfirmationBox from "../../components/ConfirmationBox"

// import { randomRequests } from "../util.mjs"


const styles = {
    islandForm:{
        width: "100%",
        height: "100%" 
      }
}

const UserInfo = ()=>{

    const {user, setUser,token} = useAuth()
    // const [requesting, setRequesting] = useState(false)
    const [phoneError, setWaNumError] = useState(null)

    const [locationError, setLocationError] = useState(null)
    const [convPULocError, setConvPULocError] = useState(null)
    const [addInfoError, setAddInfoError] = useState(null)
    const [seatsAvailError, setSeatsAvailError] = useState(null)
    const [seatCostError, setSeatCostError] = useState(null)
    const [rideTypeError, setRideTypeError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const [poolTypeError, setPoolTypeError] = useState(null)
    const [error, setError] = useState(null)

    const {waNum,  location, convPULoc, addInfo, seatsAvail, seatsCost, phoneNumber, rideType, availableNumber,messageToken} = user 

    
    const navigate = useNavigate()
 

    const {setMsgType} = useMsg()
    const {setTitle,setNavFunction,setNavType,setShowNav} = useNav()

  
    
    //form ref 
    
    const phoneRef = useRef()

    const locationRef = useRef()
    const convPULocRef = useRef()
    const addInfoRef = useRef()
    const seatsAvailRef = useRef()
    // const seatCostRef = useRef()
    const rideTypeRef = useRef()
    const poolTypeRef = useRef()


    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
  
    useEffect(() => {
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            },
            error => {
              console.error('Error getting location:', error);
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      };
  
      getLocation();
    }, []);

    useEffect(()=>{
        console.log(latitude,longitude)
    },[latitude,longitude])
    
    useEffect(() => {

     
        setMsgType("normal")
        setTitle("Carpool")
        setNavFunction(()=>()=>navigate("/carpools"))
        setNavType("back")
        setShowNav(true)

        // if(!eventId || !eventName || !updateRole ){
        //     navigate("/notfound")
        // }
        
        // removed user.role
      
         //|| localStorage.getItem("updateRole") === "pooler"


        
          
        seatsAvailRef.current.value = seatsAvail || ""
        // seatCostRef.current.value = seatsCost || ""
        rideTypeRef.current.value = rideType || "carpoolOffer"
       

        phoneRef.current.value = availableNumber || phoneNumber || waNum || "" 

        locationRef.current.value = location || ""
        convPULocRef.current.value = convPULoc || ""
        addInfoRef.current.value = addInfo || ""
       
        
    
    }, [])
     
    // submit user info update 
    const onNext = async(e)=>{
        e.preventDefault()
        
        setIsDisabled(true)
        // Validate WhatsApp number
        const phoneError = validatePhone(phoneRef.current.value)
        setWaNumError(phoneError)

      

        // Validate location
        const locationError = validateLocation(locationRef.current.value)
        setLocationError(locationError)

        // Validate convenient pick-up location
        const convPULocError = validateConvenientPickUpLocation(convPULocRef.current.value)
        setConvPULocError(convPULocError)

        // Validate additional info
        const addInfoError = validateAdditionalInfo(addInfoRef.current.value)
        setAddInfoError(addInfoError)


        const seatsAvailError = validateSeatsAvailable(seatsAvailRef.current.value)
            setSeatsAvailError(seatsAvailError)

        // const seatCostError = validateSeatCost(seatCostRef.current.value)
        // setSeatCostError(seatCostError)

        //Validate ride type
        const  rideTypeError = validateRideType(rideTypeRef.current.value)
        setRideTypeError(rideTypeError)
        
        const poolTypeError = poolTypeRef.current.value !== "defaultPool" && poolTypeRef.current.value !== "eventPool" 
        if(poolTypeError ){
            
            setPoolTypeError("Select a pool type")
        }
        // If any error exists, prevent form submission
      
        if (phoneError  || locationError || convPULocError || addInfoError || seatsAvailError || seatCostError || rideTypeError || poolTypeError) {

            setIsDisabled(false)
            return
        }
        
        const data = {

            availableNumber: phoneRef.current.value,
            location: locationRef.current.value,
            convPULoc: convPULocRef.current.value,
            addInfo: addInfoRef.current.value,
            seatsAvail: seatsAvailRef.current.value,
            rideType: rideTypeRef.current.value
        }
        
    
        // setRequesting(true)
        const usersCollection = collection(db, "users")
        
        const userDoc = doc(usersCollection,user.id)


        
        try {

            await updateDoc(userDoc,data)
            //update user in AuthContext

            
            setUser((prev)=>({
                ...prev,
                ...data 
            }))

            let poolInfo = {
           
                poolerLoc: data.location,
                convPULoc: data.convPULoc,
                poolAdmin: user.id,
                poolerName: user.username,
                availableNumber,
                seats: Number(data.seatsAvail),
                costPerSeat: 0,
                poolType: data.rideType,
                status:"created",
                createDate:  new Date()
               
            }

            if(poolTypeRef.current.value === "defaultPool"){
                navigate('/create-default-pool',{state:{poolInfo}})
            }else if(poolTypeRef.current.value === "eventPool"){
                navigate('/poolforevent',{state:{poolInfo}})
            }

            
       
            // if(user.role){
        }catch(error){
            console.log(error)
            setError("An error has occured")
        }
    }



   
  

    return (    
     
        <>
        
        <form onSubmit={onNext} style={styles.islandForm}>
        <div className="container" >
            <div style={{height:"15%", width:"100%",  display:"flex", alignItems:"center",justifyContent:"center"}}>
            <h3 className="poppins-20px-500">Let's pair you</h3>
            </div>
            <div style={{flex:1, width:"100%", overflow:"scroll", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <div style={{position:"relative", width:"90%"}}>

                <GreyInputBox placeholder="Available number" inputRef={phoneRef} required={true}/>
            </div>
            <FormError error={phoneError}/>


            <div style={{position:"relative", width:"90%"}}>
                <GreyInputBox placeholder="Location" inputRef={locationRef} required={true}/>
            </div>
            <FormError error={locationError}/>

            <div style={{position:"relative", width:"90%"}}>
                <GreyInputBox placeholder="Convenient pick-up location" inputRef={convPULocRef} required={true}/>
            </div>
            <FormError error={convPULocError}/>

            <div style={{position:"relative", width:"90%"}}>
                <GreyInputBox placeholder="Additional info" inputRef={addInfoRef} required={true}/>
            </div>
            <FormError error={addInfoError}/>
            
            <div style={{position:"relative", width:"90%"}}>
            <GreyInputBox placeholder="Seats available" inputRef={seatsAvailRef} required={true}/>
                   
            </div>
                <FormError error={seatsAvailError}></FormError>
                <DropDownForm inputRef={rideTypeRef}  title="Type of Ride" opts={[{value:"carpoolOffer",placeholder:"My Vehicle"},{value:"pool",placeholder:"Share Rides (Bolt, Uber and the likes)"}]}/>
                <FormError error={rideTypeError}/>
                <DropDownForm inputRef={poolTypeRef} title="What are you pooling for?" opts={[{value:"defaultPool",placeholder:"Default Pool"},{value:"eventPool",placeholder:"Pool for an event"}]} />
                <FormError error={poolTypeError}/>
                {/* <div style={{position:"relative", width:"90%"}}>
                    <GreyInputBox placeholder="Seat Cost" inputRef={seatCostRef} />
                </div> */}
              
           
            </div>
           
            <div style={{height:"15%", width:"100%", display:"flex", alignItems:"center"}}>
             <LongPurpleBtn2 inactive={isDisabled} name="looks good"/>

             </div>
        </div>
        
        </form>  

         {user.verification?.basicInfo !== "completed" && <ConfirmationBox title="Oops! something is not right" para={`Hi ${user.displayName}, it it looks like you are not eligible to use carpool, please ensure you have done your KYC`}  actionButtons={[{title:"Fix issue", onClick:()=>{navigate("/edit-profile")}}]}  errorType={true}/>}
         {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
        </>   
    )
}
    
export default UserInfo
