import React, { useEffect, useRef, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import GreyInputBox from '../../components/InputsBox/GreyInputBox'
import IncrementBox1 from './Components/IncrementBox1'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import Items from './Components/Items'
import { generateRandomString } from '../../Utils/util'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApp } from '../../contextAPI/AppContext'
import LongLilacBtn from '../../components/Buttons/LongLilacBtn'
import { useCreateEvent } from '../../contextAPI/CreateEventContext'
import { createEventWishBlends, getEventWishList } from '../../firebase/controller/eventsController'
import ConfirmationBox from '../../components/ConfirmationBox'


const CreateWishBlend = () => {
    const { setEventDetails} = useCreateEvent()
    const {setTitle,setShowNav,setNavFunction, setNavType} = useNav()
    const [itemNameLength, setItemNameLength] = useState(0)
    const itemNameRef = useRef()
    const [itemCount, setItemCount] = useState(0)
    const {items, setItems} = useApp([])
    const [wishBlend, setWishBlend] = useState(null)
    const [editItem, setEditItem] = useState(null)
    const [viewMode, setViewMode] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const  state = location?.state
    const eventId = localStorage.getItem("eventId")
    const [error, setError] = useState(null)

    useEffect(()=>{
        if(!state || (state.action === "edit" && !eventId)){
            navigate("/notfound")
            return
        }
        

        itemNameRef.current.value = ""
        setTitle("WishBlend")
        
        if(state.action === "edit"){
            
            setNavFunction(()=>()=>navigate("/wishblend"))
            getEventWishList(eventId,(err,data)=>{
                console.log("wishblen")
                if(!err && data){
                    setItems([])
                    console.log(data)
                    setWishBlend(data)
                }else{
                    console.log(err)
                }
            },"created")
        }else if(state.action ==="create"){
            setNavFunction(()=>()=>navigate("/createevent"))
        }else{
            console.log(items)
            setNavFunction(()=>()=>navigate("/wishblend"))
        }

        setNavType("back")

        setShowNav(true)
    },[])
   

    const onEditItem = (item)=>{
        
        itemNameRef.current.value = item.itemName
        setEditItem(item.id)
        setItemCount(item.itemCount)
        setItemNameLength(item.itemName.length)
    }
    
    const onSetItem = () =>{
        if(wishBlend){
            console.log(editItem)
            const editedItem = wishBlend.find(blend=> blend.id === editItem) || {itemName:itemNameRef.current.value, itemCount, id:generateRandomString(10), status: "created", promisedCount: 0}
            console.log(editedItem)
            // let isPresentInItems = false
            
            let newItems =[]
            let newWBItems =[]

            if(editItem){
                let isPresentInItems = false
                newItems= items.map(item=>{
                    if(item.id === editItem){
                        isPresentInItems =true
                        return {
                            ...item,
                            itemName:itemNameRef.current.value,
                            itemCount,
                           
                        }
                    }else{
                        return item
                    }
                })

                 newWBItems= wishBlend.map(item=>{
                    if(item.id === editItem){
                        
                        return {
                            ...item,
                            itemName:itemNameRef.current.value,
                            itemCount,
                            
                        }
                    }else{
                        return item
                    }
                })

                if(!isPresentInItems){
                    newItems.push({
                        ...editedItem,
                        itemName:itemNameRef.current.value,
                        itemCount
                    })
                }
                
                console.log(newItems)
                setItems(newItems)
                setWishBlend(newWBItems)
            }else{

              
            

                setItems(prev=>[...prev,editedItem])
                setWishBlend(prev=>[...prev,editedItem])

               
            }

           
            setEditItem(null)
            
        }
        else if(editItem){
            console.log(editItem)
            const newItems= items.map(item=>{
                if(item.id === editItem){
                    return {
                        ...item,
                        itemName:itemNameRef.current.value,
                        itemCount,
                        status: "created"
                    }
                }else{
                    return item
                }
            })
            console.log(newItems)
            setItems(newItems)
            setEditItem(null)
        }else{
            setItems(prev=>[...prev,{id:generateRandomString(10),itemName:itemNameRef.current.value.toLowerCase(),itemCount,status: "created", promisedCount: 0}])
        }
        
        itemNameRef.current.value = ""
        setItemNameLength(0)
    }

    const addWishBlend = ()=>{
        setIsDisabled(true)
       if(state.action === "create"){
            
            setEventDetails(prev=>({...prev, featuresSelected:[...prev.featuresSelected,state.type]}))
            navigate("/success",{state:{path:"/createevent",text:"Saved Successfully"}})
       }else{

        console.log(items)
            createEventWishBlends(eventId,items,(err)=>{
                if(!err){
                    navigate("/success",{state:{path:"/wishblend",text:"Saved Successfully"}})
                }else{
                    console.log(err)
                    setIsDisabled(false)
                    setError("Error creating wishblend")
                }
            })
       }
        
    }  
    return (
    <>
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    <div className='container' style={{padding: "30px 0 60px"}}>
        <h1 className='poppins-20px-500' style={{margin:"20px"}}>Create your Ingredients</h1>
      { !viewMode && <div style={{padding:"10px 20px 0", width:"100%"}}>
    
        <GreyInputBox placeholder="item Name" inputRef={itemNameRef} onChange={()=>setItemNameLength(itemNameRef.current.value.length)} />
      
        <IncrementBox1  setItemCount={setItemCount} itemCount={itemCount}/>
       { !editItem && itemCount!==0 && itemNameLength >0 && <h3 className='poppins-15px-200' style={{width:"100%", color: "#8E69F0", margin:"10px 0"}} onClick={onSetItem}>Add new Item</h3>}
       { editItem && itemCount!==0 && itemNameLength >0 && <h3 className='poppins-15px-200' style={{width:"100%", color: "#8E69F0", margin:"10px 0"}} onClick={onSetItem}>Edit Item</h3>}
       </div>}
       <div style={viewMode && {height:"inherit",overflow: "scroll", width:"100%",paddingBottom: "60px"}||{height:"inherit",overflow: "scroll", width:"100%"}}>
            { 
              !wishBlend &&  items.map((item)=><Items key={item.id} name={item.itemName} count={item.itemCount} onClick={()=>!viewMode ? onEditItem(item):{}}/>)
            }
            {
                wishBlend?.map((item)=><Items key={item.id} name={item.itemName} count={item.itemCount} onClick={()=>!viewMode ? onEditItem(item):{}}/>)
            }
       </div>
       <div style={{position:"fixed",bottom: "20px", width:"100%", padding: "0 20px"}}>
         {!viewMode &&<LongPurpleBtn name="Next" inactive={items.length == 0} onClick={()=>setViewMode(true)}/>}
         {viewMode && <LongLilacBtn name="Edit" onClick={()=>setViewMode(false)}/>}
         {viewMode &&<LongPurpleBtn name="Blend" inactive={items.length == 0 || isDisabled} onClick={addWishBlend}/>}
       </div>
    </div>
    </>
  )
}

export default CreateWishBlend
