import React from 'react'
import ProfileDiv1 from '../../../components/ProfileDiv1'
import StarRating from '../../../components/StarRating'

const Rating = ({username, rating, comment,profile}) => {
  return (
    <div style={{width:"100%", padding:"20px"}}>
     <div style={{display:"flex"}}>
        <ProfileDiv1 profile={profile}/>
        <div style={{marginLeft:"10px"}}>
            <p className='poppins-15px-400'>{username}</p>
            <StarRating starRating={rating} onClick={()=>{}}/>
        </div> 
     </div>
     <div style={{marginLeft:"20px"}}>
        <p className='poppins-15px-200'>{comment}</p>
     </div>
    </div>
  )
}

export default Rating
