import React, { useEffect, useState } from 'react'
import FormElement1 from '../../components/FormElements/FormElement1'
import FormTypeE from '../../components/FormElements/FormTypeE'
import EventFeatures from '../../components/EventFeatures/EventFeatures'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNav } from '../../contextAPI/NavContaxt'
import CalenderForm from '../../components/FormElements/CalenderForm'
import TimeForm from '../../components/FormElements/TimeForm'
import DropDownForm from '../../components/FormElements/DropDownForm'
import MapForm from '../../components/FormElements/MapForm'
import { useAuth } from '../../contextAPI/AuthContext'
import { validateDate, validateDescription, validateEndTIme, validateEventName, validateLink, validateLocation, validateStartTime } from './formValidation'
import { collection, doc, runTransaction} from 'firebase/firestore'
import { db } from '../../firebase/config'
import { firebaseTimeStampFormatter } from './utills'
import LongPurpleBtn from "../../components/Buttons/LongPurpleBtn"
import Spinner from '../../components/Spinner'
import { useCreateEvent } from '../../contextAPI/CreateEventContext'
import CreateEventImage from '../../components/CreateEventImage'
import ChoosePicture from './ChoosePicture'
import InfoPage from '../../components/InfoPage'
import ConfirmationBox from '../../components/ConfirmationBox'
import { addToCache, deleteFromCache } from '../../Utils/indexedDBService'
import { handleFileInputChange } from '../../firebase/controller/imageUploadController'

const styles = {
  eventImage:{
    /* height: 65%; */
    borderRadius: "20px",
   
  },
   eventImageImg:{
    width: "100%",
    height: "100%",
  },

  
    eventFeatures:{
     
      display: "flex",
      flexDirection: "column",
      padding: "10px 0",
      width: "inherit",
     
    },
  
    eventFeaturesContainer:{
   
      padding: "10px 0",
      flex: "1",
      display: "flex",
      justifyContent:"flexStart",
      overflowX: "scroll",
      overflowY: "hidden",
    },
    eventForm:{
      width: "100%",
      marginTop: "10px",
    }
   
}

const EditEvent = () => {

  const navigate = useNavigate()
  const {setTitle,setNavFunction, setNavType} = useNav()
  const [featureInfo, setFeatureInfo] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [eventData] = useState(useLocation()?.state?.eventData)
  const {user} = useAuth()
  const [error, setError] = useState(null)

  const {
    eventNameErr, setEventNameErr,
    eventLocationErr, setEventLocationErr,
    eventDescriptionErr, setDescriptionErr,
    eventDateErr, setEventDateErr,
    eventSTErr, setEventSTErr,
    eventETErr, setEventETErr,
    clearContext,
    eventDetails, setEventDetails,
    linkErr,setLinkErr
    } = useCreateEvent()

  const [isWaiting, setIsWaiting] = useState(true)
  const [choosePicture,setChoosePicture] = useState(false)

  const [showConfirmationBox,setShowConfirmationBox] = useState(null)
  // error usestate


  const [spinnerMsg, setSpinnerMsg] = useState(null)
 
 useEffect(()=>{
 
    if(!choosePicture){
      setNavFunction(()=>()=>{
        navigate("/event-page")
        clearContext()
      })
      setNavType("cancel")
      setIsWaiting(false)
    
  }
 },[choosePicture])

  const onCreateEvent = async(e,isConfirmed)=> {

    
    setIsDisabled(true)
    

    const eventLocationErr = validateLocation(eventDetails.eventLoc)
    setEventLocationErr(eventLocationErr)

    const eventDescriptionErr = validateDescription(eventDetails.eventDesc)
    setDescriptionErr(eventDescriptionErr)

    const eventNameErr = validateEventName(eventDetails.eventName)
    setEventNameErr(eventNameErr)

    const eventDateErr = validateDate(eventDetails.eventDate,"edit")
    setEventDateErr(eventDateErr)

    const eventSTErr = validateStartTime(eventDetails.eventStartTime)
    setEventSTErr(eventSTErr)

    const eventETErr = validateEndTIme(eventDetails.eventStartTime,eventDetails.eventEndTime)
    setEventETErr(eventETErr)

    const linkErr = validateLink(eventDetails.link)
    setLinkErr(linkErr)

    if(isConfirmed){
       
       setShowConfirmationBox(null)
    }
    else if (linkErr || eventLocationErr || eventDescriptionErr || eventNameErr || eventDateErr || eventSTErr || (eventETErr && eventETErr !== "less than" )) {
      console.log("sjnds")
      setIsDisabled(false)
      return
    }

    if(eventETErr === "less than"  && !isConfirmed){
      
      setShowConfirmationBox("Your event end time is less than your start time. Does this program end the next day?")
      setIsDisabled(false)
      return
    }

    
    setSpinnerMsg((eventData.id) && "updating event" || "creating event")
    setIsWaiting(true)
    

    
  
    try {
     
        // save to events
    const eventCollectionRef = collection(db, "events")
    const eventRef =  doc(eventCollectionRef, eventData.id)
    
    
    const imgURL = await handleFileInputChange(eventDetails.eventCoverImgFile,eventRef.id)
  
    // save to db
    const data = {
      eventName: eventDetails.eventName.trim(),
      location: eventDetails.eventLoc.trim(),
      eventDate: firebaseTimeStampFormatter(eventDetails.eventDate, eventDetails.eventStartTime),
      ends: firebaseTimeStampFormatter(eventDetails.eventDate, eventDetails.eventEndTime),
      description: eventDetails.eventDesc.trim(),
      repeat: eventDetails.eventRepeatType,
      createdBy: user.id,
      coverImg: imgURL || eventDetails.eventCoverImg,
      eventVisibility: eventDetails.eventVisibility,
      link: eventDetails.link.trim()
    }
   
    
  
  
    if(isConfirmed){
      const date = new Date(data.ends)
      date.setDate(date.getDate() + 1)
      data.ends = date
      
    }

   
  

      // save to userevents
    const usersRef = collection(db, "users")
    const userRef = doc(usersRef,user.id)
    const usereventsRef = collection(userRef, "userevents")
    const usereventRef = doc(usereventsRef, eventRef.id)
    
    await runTransaction(db,async(transaction)=>{
        transaction.set(eventRef,data,{merge:true})
        transaction.set(usereventRef, data, {merge:true})
        const expirationDate = data.ends.seconds * 1000 < Date.now() ? data.ends.seconds * 1000 :  Date.now()
        await addToCache(eventRef.id,expirationDate,{...data,id:eventRef.id},"hostedEvent")
        await deleteFromCache(eventRef.id,"cache")
        
        clearContext()
        // await addToCache(user.id + "_" + eventRef.id,expirationDate,{...data,id:eventRef.id},"cache")
    })
 
    // await setDoc(eventRef, data,{merge:true})
    // await setDoc(usereventRef, data, {merge:true})
   


    setIsWaiting(false)
    setIsDisabled(false)
  
    localStorage.setItem("eventId",eventRef.id)
    localStorage.setItem("eventName",eventDetails.eventName)
    localStorage.setItem("createdBy",user.id)
    localStorage.setItem("shortcutEventId",eventRef.id)
    navigate("/invite")

  } catch (error) {
    setIsDisabled(false)
    setIsWaiting(false)
    setError("Error editing event")
  }
  }

  useEffect(()=>{
    clearContext()

    console.log(eventDetails)
    setIsWaiting(true)
   
    setTitle("update Event")

    const {eventName,description,eventVisibility,location, repeat, coverImg, link} =eventData

   console.log(eventVisibility)
    
    const eventDate = eventData.eventDate;
    const ends = eventData.ends
    const startDate = new Date(eventDate.seconds * 1000);
    const day = String(startDate.getDate()).padStart(2, '0');
    const month = String(startDate.getMonth() + 1).padStart(2, '0');
    const year = String(startDate.getFullYear()).slice(-2);
    const endDate = new Date(ends.seconds * 1000)
    
    const formattedDate =`20${year}-${month}-${day}`;
    const formattedStartTime = startDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',hour12: false });
    // `${startDate.getHours()}:${startDate.getMinutes()}`;

    const formattedEndTIme = endDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric',hour12: false });
    //`${endDate.getHours()}:${endDate.getMinutes()}`

    setEventDetails(prev=>({...prev,eventName,eventDesc:description,eventVisibility,eventLoc:location, eventRepeatType:repeat, eventDate:formattedDate, eventStartTime: formattedStartTime,eventEndTime:formattedEndTIme,eventCoverImg:coverImg || "concert1.png", eventCoverImgFile:"",link:link || ""}))
    console.log(formattedDate, formattedStartTime,formattedEndTIme)
    setIsWaiting(false)

  },[])


  if(choosePicture){
    return(
      <ChoosePicture setChoosePicture={setChoosePicture} />
    )
  }
  if(featureInfo){
    return(
        <InfoPage 
        type={featureInfo.type} 
        onClick={()=>{
          setFeatureInfo(null)
          setNavFunction(()=>()=>navigate("/"))
          setNavType("back") 
        }} 
        img={featureInfo.img}
        />
    )
  }
  if(showConfirmationBox){
    return(
        <ConfirmationBox para={showConfirmationBox} 
        actionButtons={[{onClick: (e)=>{
         
          onCreateEvent(e,true)

        }, title:"proceed"}]} 
        cancelBtn={true} 
        setShowConfirmationBox={setShowConfirmationBox}/>
    )
  }
  
  
  return (
    <>
    {isWaiting && <Spinner msg={spinnerMsg}/>}
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    {!isWaiting &&
      <div className='container'> 
      <div onClick={()=>setChoosePicture(true)}>
      <CreateEventImage src={eventDetails.eventCoverImg} />
      </div>
      <div style={styles.eventForm}>
        <div>
          <FormElement1 title="Add event name" placeHolder="Event Name" value={eventDetails.eventName} onChange={setEventDetails} error={eventNameErr} type="eventName"/>
        </div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <CalenderForm title="Set Date" value={eventDetails.eventDate} onChange={setEventDetails} error={eventDateErr}></CalenderForm>
          <TimeForm title="Start Time" placeHolder="10:00 AM" value={eventDetails.eventStartTime} onChange={setEventDetails} type={'eventStartTime'} error={eventSTErr}></TimeForm>
        </div>
        <div className='flex-row' style={{display:"flex", justifyContent:"space-between",padding: "20px 0"}}>
          <DropDownForm title="Repeat" placeHolder="Once" type="eventRepeatType"  value={eventDetails.eventRepeatType} onChange={setEventDetails} options={["none","daily","weekly"]}/>
          <TimeForm title="End Time" placeHolder="10:00 PM" value={eventDetails.eventEndTime} onChange={setEventDetails}   type={'eventEndTime'} ></TimeForm>
        </div>
          <MapForm title="Set location" placeHolder="Eko Hotel" value={eventDetails.eventLoc} onChange={setEventDetails}  error={eventLocationErr}/>
          <div style={{width:"100%", margin:"10px 0"}}>
            <DropDownForm title="Event visibility" placeHolder="Private"  value={eventDetails.eventVisibility} onChange={setEventDetails} type={"eventVisibility"} options={["private","public"]}/>
          </div>
          <FormTypeE title="Add Description" placeHolder="Write a description for your event" value={eventDetails.eventDesc} onChange={setEventDetails} error={eventDescriptionErr}/>
          <div>
           <FormElement1 title="Add a link" placeHolder="https://community.novusui.com" value={eventDetails.link} onChange={setEventDetails} error={linkErr} type="link"/>
          </div>
        </div>
        
      
      {!eventData?.id && <div style={styles.eventFeatures}>
        <h6 className='poppins-heavy'>Make available for event</h6>
          <div style={styles.eventFeaturesContainer}>
            <EventFeatures  onClick={()=>setFeatureInfo({type:"carpool", img:"carpoolIll.png"})} featureType="carpool" isEditEvent={true} />
            <EventFeatures  onClick={()=>setFeatureInfo({type:"wishblend", img:"wishblendill.jpg"})} featureType="wishBlend" isEditEvent={true} />
            <EventFeatures onClick={()=>setFeatureInfo({type:"getTicket", img:"ticketIll.jpg"})} featureType="getTicket" isEditEvent={true} />
            <EventFeatures  onClick={()=>alert("not available")} featureType="experienceEvent" isEditEvent={true} />
            <EventFeatures  onClick={()=>alert("not available")} featureType="games" isEditEvent={true} />
          </div>
      </div>}
      {/* <div style={{width:"100%"}}>
          <DropDownForm title="Event visibility" placeHolder="Once"  value={eventRepeatType} onChange={setEventRepeatType}/>
      </div> */}
      <div style={{width:"100%", padding:"10px 0"}}>
        <LongPurpleBtn onClick={onCreateEvent} inactive={isDisabled} name={eventData?.id ? "Update Event":"Create Event"}/>
      </div>
    </div>
   }
   </>
  )
}

export default EditEvent
