import React from 'react'
import { useCreateEvent } from '../contextAPI/CreateEventContext'
import { useNavigate } from 'react-router-dom'
import { validateEventName, validateStartTime } from '../screens/CreateEvent/formValidation'
import { useAuth } from '../contextAPI/AuthContext'

const btnStyle = {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
}

const styles = {
    addBtn :{
       ...btnStyle,
       backgroundColor: "#70D77B"
    },

    removeBtn : {
        ...btnStyle,
        backgroundColor: "#EE6464"
    }
   
}



const AddEventFeatureBtn = ({featureType,setShowCBforCarpool}) => {

   
    const {featuresSelected,eventDetails, setEventDetails, setEventNameErr,  setEventSTErr} = useCreateEvent()
    console.log(featuresSelected)
    const navigate = useNavigate()
     const {user} = useAuth()
    const {verification} =  user

  
    const addOrRemoveFeature = (add,type) =>{
        
        switch(featureType){
            case "carpool" :
                if(add){
                    if(verification?.basicInfo !== "completed" ){
                        setShowCBforCarpool(true)
                        return
                     }
                    
                    setEventDetails(prev=>({...prev, featuresSelected:[...prev.featuresSelected,type]}))
                }else{
               

                    setEventDetails(prev=>{
                        let featuresSelected = prev.featuresSelected.filter(selected=> selected != type)
                        return(
                            {
                                ...prev,
                                featuresSelected
                            }
                        )
                    })

                }
            
            break
            case "wishBlend": 
                if(add){
                    navigate("/create-wish-blend",{state:{type,action:"create"}})
                    
                    
                }else{
                 
                    setEventDetails(prev=>{
                        let featuresSelected = prev.featuresSelected.filter(selected=> selected != type)
                        return(
                            {
                                ...prev,
                                featuresSelected
                            }
                        )

                    })
                }
                
            
            break

            case "getTicket":
                if(add){
                    console.log("this")
                    const eventNameErr = validateEventName(eventDetails.eventName)
                    setEventNameErr(eventNameErr)
                    
                    const eventSTErr = validateStartTime(eventDetails.eventStartTime)
                    setEventSTErr(eventSTErr)

                    if(eventNameErr || eventSTErr){

                        return
                    }

                    navigate("/create-ticket",{state:{type}})
                }else{
                   
                    setEventDetails(prev=>{
                        let featuresSelected = prev.featuresSelected.filter(selected=> selected != type)
                        return(
                            {
                                ...prev,
                                featuresSelected
                            }
                        )

                    })
                }
            
            break

            default: console.log("feature doesnt exist")
        }
        
    }
  return (
    <>
     
    {
        !eventDetails.featuresSelected.includes(featureType) && 
        <div style={styles.addBtn} onClick={(e)=>{e.stopPropagation(); addOrRemoveFeature(true,featureType)}}>
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M7.5 10.625V4.375" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
             <path d="M4.375 7.5H10.625" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
        </svg>
    </div> 
    }
    {
        eventDetails.featuresSelected.includes(featureType) &&
        <div style={styles.removeBtn} onClick={(e)=>{e.stopPropagation(); addOrRemoveFeature(false,featureType)}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.40625 7.03125H13.5938V7.96875H1.40625V7.03125Z" fill="white"/>
            </svg>
        </div>
    }
    </>
  )
}

export default AddEventFeatureBtn
