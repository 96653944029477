import React from 'react'

const DropDownForm = ({title, type,value,onChange,options} ) => {
   
    const styles = {
        dropDown: {
            backgroundColor: "#202024",
            color: "#FFF",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            ineHeight: "normal",
            border: "none",
            outline: "none"
        }
    }
  
  return (
    <div className='form-type-1' >
        <h4 className='inter-400'>{title}</h4>
        {/* <input type='dropdown' className='form-type-1-input poppins-heavy' placeholder={placeHolder}>{inputText}</input>  */}
        <select id="repeat" name="repeat" value={value} onChange={(e)=>onChange(prev=>({...prev,[type]:e.target.value}))} style={styles.dropDown}>
            {
                options.map(option=><option value={option}>{option}</option>)
            }
            
        </select>
    </div>
  )
}

export default DropDownForm
