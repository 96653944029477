const nameRegex = /^[a-zA-Z-]{1,20}$/
const phoneRegex = /^0\d{10}$/
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const usernameRegex = /^(?!_+$)[A-Za-z_][A-Za-z0-9_]{0,11}$/
const forbiddenCharactersRegex = /[^A-Za-z0-9_]/g


  function validateName(name) {
    return nameRegex.test(name);
  }
export const validateFirstName = (value)=>{
    if(value.length < 2){
        return "too short"
    }
    if( value.length > 20){
        return "too long" 
    }
    if(!validateName(value)){
        return "invalid"
    }

    return null
}

export const validateLastName = (value)=>{
    if(value.length < 2){
        return "too short"
    }
    if( value.length > 20){
        return "too long" 
    }
    if(!validateName(value)){
        return "invalid"
    }
    
    return null
}

export const validatePhone = (value)=>{
   
    if(!phoneRegex.test(value)){
        return "invalid"
    }
    
    return null
}

export const validateConfirmPhone = (phone,confirmPhone)=>{

    if(phone.trim() !== confirmPhone.trim()){
        return "Doesn't match phonenumber"
    }
    return null
}
export const validateEmail = (value)=>{
   
    if(!emailRegex.test(value)){
        return "invalid"
    }
    
    return null
}
export const validateConfirmEmail = (email,confirmEmail)=>{

    if(email.trim() !== confirmEmail.trim()){
        return "Doesnt match email"
    }
    return null
}

export const validateFullName = (value)=>{
    if(value.length < 4){
        return "full name plase"
    }
    return null
}

export const validateUsername = (value)=>{

    
    if(forbiddenCharactersRegex.test(value)){
        return "can only contain letters, numbers, or _"
    }
    if(value.length >12){
        return "too long"
    }
    if(value.length <2){
        return "too short"
    }
    if(!usernameRegex.test(value)){
        return "start with a letter or an underscore _"
    }
    return null
}


export const validateSex = (value)=>{
 
    if(["male","female"].includes(value.toLowerCase().trim())){
        console.log(value)
        return null
    }
        
    return "male or female"
}






export function validateLocation(value) {
    if (value.trim() === '') {
        return 'Location is required'
    }
    return null
}

export function validateSeatNumber(value){
    if(Number(value) > 4  || Number(value) <1){
        return 'seat should be between 1 and 4'
    }
    return null
}

export function validateConvenientPickUpLocation(value) {
    // Remove leading and trailing whitespace from the input
    value = value.trim();
    
    // Split the input by comma and trim each part
    const locations = value.split(',').map(part => part.trim());
  
    // Check if there are more than 3 locations
    if (locations.length > 3) {
      return 'You can only specify up to 3 convenient pickup locations separated by a comma';
    }
  
    return null
  }            

export function validateAdditionalInfo(value) {
    if (value.length > 200) {
        return 'Additional info should not exceed 200 characters'
    }
    return null
}

export function validateSeatsAvailable(value) {
    if (isNaN(value)) {
        return 'Seats available must be a number';
      }
    return null
}

export function validateSeatCost(value) {
    if (isNaN(value)) {
        return 'Seats available must be a number';
      }
    return null
}

export function validateRideType(value){
    console.log(value)
    if(["pool","carpoolOffer"].includes(value))
        return null
    return "invalid ride type"
}