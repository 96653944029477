import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useLocation, useNavigate } from 'react-router-dom'
import CreateEventImage from '../../components/CreateEventImage'
import Detail from '../../components/TicketDetails/EventDetail'
import FormInputText from './Components/FormInputText'
import { useCreateEvent } from '../../contextAPI/CreateEventContext'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import ConfirmationBox from '../../components/ConfirmationBox'

const CreateTIcket = () => {

   const {setTitle, setNavFunction,setNavType} = useNav()
   const { setEventDetails,eventDetails} = useCreateEvent()
   const [showConfimationBox, setShowConfirmationBox] = useState(false)
   const navigate = useNavigate()
   const location = useLocation()
   const state = location.state

   const actionButtons = [
    {
        title:"Okay",
        onClick:()=>{

        
            setEventDetails(prev=>({...prev, featuresSelected:[...prev.featuresSelected,state.type]}))
            navigate("/createevent",{state:{keepData:true}})
        }
    }
   ]
    useEffect(()=>{
        if(!state){
            
            navigate("/createevent")
        }
        setTitle("Ticket")
        setNavFunction(()=>()=>navigate("/createevent"))
        setNavType("back")
    },[])
  return (
    <>
    <div className='container'>
      <CreateEventImage src={"/eventpic2.png"}/>
      <div style={{flex:"1", width:"100%"}} >
        <Detail title="Event Name" value={eventDetails.eventName}/>
        <Detail title="Event Time" value={eventDetails.eventStartTime}/>
        <FormInputText title="Available Tickets" value={eventDetails.ticketCount} onChange={setEventDetails}/>
        <Detail title="Ticket Price" value="Free"/>
        <Detail/>
      </div>
      <div style={{height: "10vh", width:"100%"}}>
      <LongPurpleBtn name="Create Ticket" inactive={!Number(eventDetails.ticketCount)} onClick={()=>setShowConfirmationBox(true)}/>
      </div>
    </div>
    {showConfimationBox && <ConfirmationBox title="Confirm ticket" para="click ok to create ticket" infoBox="Note after clicking okay you will not be able to edit the ticket" actionButtons={actionButtons}/>}
    </>
  )
}

export default CreateTIcket
