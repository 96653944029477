import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
// import TabsContainer from './TabsContainer'
// import CarpoolHistort from './CarpoolHistort'
import EventHistory from './EventHistory'
import {and, collection, doc, getDocs, or, query, where } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useAuth } from '../../contextAPI/AuthContext'
import { useNavigate } from 'react-router-dom'
import { addToCache, getAllFromStore, getDbCheck, setDbCheck } from '../../Utils/indexedDBService'
import TabsContainer from './TabsContainer'







const History = () => {

    const {setTitle, setShowNav,setNavFunction, setNavType} = useNav()
    const [selectedTab, setSelectedTab] = useState(1)
    const {user} = useAuth()
    // const [userPoolHistory, setUserPoolHistory] = useState([])
    const [eventHistory, setEventHistory] = useState([])
    const [yourEvents, setYourEvents] = useState([])
    const usersRef = collection(db,"users")
    const userRef = doc(usersRef,user.id)
    const navigate = useNavigate()
    

    // const getUserPoolHistory = async()=>{
        
    //     const userPoolHistRef = collection(userRef, "userPoolHistory")
    //     const poolQuery = query(
    //       userPoolHistRef,
    //      or(and(where('role', '==','pooler'), where("active","==",true)) ,and(where('status', 'in', ['accepted','requested']), where("active","==",true)))
         
    //     );
    //     const userPoolHistDocs = (await getDocs(poolQuery)).docs.map(doc=> ({...doc.data(),id:doc.id}))
    //     console.log(userPoolHistDocs)
    //     setUserPoolHistory(userPoolHistDocs)
    // }

    // const getUserTickets = async()=>{
    //   const userEventsRef = collection(userRef, "userevents")
    //   const q= query(userEventsRef,  where("createdBy", "!=", user.id))
    //   const userEventsDocs = (await getDocs(q)).docs.map(doc=> ({...doc.data(),id:doc.id}))
    //   console.log(userEventsDocs)
    //   setUserEvents(userEventsDocs)
    // }
    const getUserEvents = async()=>{

      const now = new Date();
      const thirtyDaysFromNow = new Date();
      thirtyDaysFromNow.setDate(now.getDate() - 30);
      const eventsRef = collection(db,"events")
        
      const q= query(eventsRef,and( where("createdBy", "==", user.id), where("ends", ">=", thirtyDaysFromNow)))
      const yourEventsDocs  = (await getDocs(q)).docs.map(doc=> ({...doc.data(),id:doc.id}))
    
       
        setYourEvents(yourEventsDocs)

        yourEventsDocs.forEach(element => {
          addToCache(user.id + "_"+element.id,element.ends.seconds*1000,element,"hostedEvent")
        });
        setDbCheck("hostedEvent")
    }

    const getHostedEvents = async()=>{

     const dbCheck = await getDbCheck("hostedEvent")
     if(dbCheck){
      const hostedEvents = (await getAllFromStore("hostedEvent")).map(event=>event.data)
     setYourEvents(hostedEvents)
      return
     }
     getUserEvents()

    }

    const getEventHistory = async()=>{
      const eventHistory  =  (await getAllFromStore("cache")).map(event=>event.data)
      setEventHistory(eventHistory)
    }

    useEffect(()=>{
        setTitle("History")
        setShowNav(true)
        setNavFunction(()=>()=>
            navigate("/")
          
        )
        setNavType("back") 
        //get user's pool history
        // getUserPoolHistory()
        getHostedEvents()
        getEventHistory()
        // getUserTickets()
        


    },[])
  return (
    <div className='container' style={{paddingTop:"30px"}} >
        <TabsContainer selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
        {

        selectedTab ==2 &&  yourEvents.map((event)=><EventHistory  event={event} key={event.id}/>)
            
        }
        {
         selectedTab ==1 && eventHistory.map((event)=><EventHistory  event={event} key={event.id}/>)
        }
       
    </div>
  )
}

export default History
