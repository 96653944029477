import { collection, doc, getDoc, where,query, onSnapshot, updateDoc, or, and } from "firebase/firestore"
import { db } from "../config"
import { formatTimestamp } from "../../Utils/util"


export const usersRef = collection(db,"users")

export const getTicketFromDb  = async(userId,eventId,callback)=>{
     
    const userRef = doc(usersRef,userId )
    const eventsRef = collection(userRef, "userevents")
    const eventRef = doc(eventsRef,eventId)

    try {
       const eventData = await getDoc(eventRef)
       if(eventData.data()?.ticket){
        callback(null,eventData.data()?.ticket)
       }else{
        callback(null,null)
       }
    } catch (error) {
        callback(error) 
    }
    
}



// export const getUserActivePools = async(userId)=>{
//     const userRef = doc(usersRef,userId )
//     const poolsRef = collection(userRef, "userPoolHistory")
//     // const q = query(poolsRef,where("eventId","==",eventId),or(and(where("status","in",['requested','accepted']), where("active","==",true)),and(where("role","==","pooler"),where("active","==",true))))

//     const q1 = query(poolsRef,  where("status", "in", ['requested', 'accepted', 'rejectedByPooler']), where("active", "==", true));
//     const q2 = query(poolsRef,  where("role", "==", "pooler"), where("active", "==", true));

//     try {
//         const poolDocs1 = await getDocs(q1);
//     const poolDocs2 = await getDocs(q2);

//     const pools = [];
        
//     poolDocs1.forEach(pool => {
//         pools.push({ pool: pool.id, ...pool.data() });
//     });
//     poolDocs2.forEach(pool => {
//         pools.push({ pool: pool.id, ...pool.data() });
//     });
      
//         return pools
//     } catch (error) {
//         throw error
//     }
// }
export const getUserActivePools = (userId, storedEvent, callback) => {
    const userRef = doc(usersRef, userId);
    const userPoolsRef = collection(userRef, "userPoolHistory");
    const poolsRef = collection(db, "pool")

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    
    const q1 = storedEvent ?  query(userPoolsRef, where("eventId","==",storedEvent),where("role", "==", "poolee"), where("active","==",true)) : query(userPoolsRef, where("role", "==", "poolee"), where("active","==",true));
    const q2 = storedEvent?  query(poolsRef,where("poolAdmin","==",userId), where("eventId","==",storedEvent), where("status", "in", ["created", "closed"])) : query(poolsRef,where("poolAdmin","==",userId), where("status", "in", ["created","closed"]));

try{ 
    const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
        const pools = [];
        querySnapshot.forEach((doc) => {
            pools.push({ pool: doc.id, ...doc.data() });
        });
       console.log(pools)
        callback(null,"poolee",pools);
    });

    const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
        const pools = [];
        querySnapshot.forEach((doc) => {
            pools.push({ pool: doc.id, ...doc.data() });
        });
       console.log(pools)
        callback(null, "pooler",pools);
    });

    return () => {
        unsubscribe1();
        unsubscribe2();
    };
}catch(e){
   
    callback(e)
  
}
};


export const updateProfilePicture = async(userId, profile)=>{

    const userRef = doc(usersRef,userId)

    try {
        await updateDoc(userRef, {profile} )
    } catch (error) {
        throw error
    }

}


export const getDefaultPools = async(userId,callback)=>{

     const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);


    const userRef = doc(usersRef, userId);
    const userPoolsRef = collection(userRef, "userPoolHistory");
    const poolsRef = collection(db, "pool")


    const q1 =  query(userPoolsRef, and(where("role", "==", "poolee"), where("active","==",true), where("poolType","==","default"),or(where("poolDate",">=",currentDate),where("repeat","in",["Daily","Weekly","Custom"]))));
    const q2 =  query(poolsRef,where("poolAdmin","==",userId), where("status", "in", ["created","closed"]),where("poolType","==","default"), where("poolDate",">=",currentDate));

try{ 
    const unsubscribe1 = onSnapshot(q1, (querySnapshot) => {
        const pools = [];
        querySnapshot.forEach((doc) => {
            const formattedDate = formatTimestamp(doc.data().poolDate)
            pools.push({ pool: doc.id, ...doc.data(), formattedDate });
        });
       console.log(pools)
        callback(null,"poolee",pools);
    });

    const unsubscribe2 = onSnapshot(q2, (querySnapshot) => {
        const pools = [];
        querySnapshot.forEach((doc) => {
            
            const formattedDate = formatTimestamp(doc.data().poolDate)
            pools.push({ pool: doc.id, ...doc.data(),formattedDate });
        });
       console.log(pools)
        callback(null, "pooler",pools);
    });

    return () => {
        unsubscribe1();
        unsubscribe2();
    };
}catch(e){
    callback(e)
}
}


export const updateUser =  async(userId,data)=>{

    const userRef = doc(usersRef,userId)

    try {
        await updateDoc(userRef,data)
        return null
    } catch (error) {
        return error
    }
    
}
