import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contextAPI/AuthContext'
import { useNav } from '../contextAPI/NavContaxt'
import NavFunction from './NavFunction'
import ConfirmationBox from './ConfirmationBox'
import {motion, AnimatePresence} from "framer-motion"
import { useApp } from '../contextAPI/AppContext'
import { useNetworkError } from '../contextAPI/NetworkErrorContext'
import NoNetwork from './NoNetwork'
import useNetworkStatus from '../CustomHooks/useNetworkStatus'

const styles = {
  topBar:{
    marginTop:"2vh",
    height: "60px",
    width: "100%",
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center",
    zIndex: "9999",
   position:'relative'
    
  },
  navBlock:{
    width: "45px",
    height:"40px",
    backgroundColor: "#2C2E3A",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  hamburgerMenu:{
    height: "inherit",
    width: "inherit",
    backgroundColor: "#8E69F0",
    borderRadius: "inherit",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
  },

  ham:{
    height: "3px",
    width: "3px",
    backgroundColor: "#fff",
    borderRadius: "50%",
  },

  menu:{
    width: "100vw",
    height: "100vh",
    position: "absolute",
    top: "0",
    right: "0",
    backgroundColor: "#202024",
    zIndex: "999",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "left",

  },
  dish:{

    padding: "10px 10px",

    color: "#ffffff",
  },
 
  lastDish:{

    padding: "10px 0",

    color:"#FF3A3A",
  }
}

const NavBar = () => {

    const [showNavMenu,  setShowNavMenu] = useState(false)
    const {logout,isLoggedIn} = useAuth()
    const navigate = useNavigate()
    const {showNav,setShowNav, title, setTitle, navFunction,setNavFunction,navType, showMenu} = useNav() 
    const [showConfirmationBox, setShowConfirmationBox] = useState(false)
    const {viewMode, setViewMode} = useApp()
    const {hasNetworkError, setHasNetworkError, setShowGlobalNetworkError,showGlobalNetworkError} = useNetworkError()
    const isOnline = useNetworkStatus()
    
  
    useEffect(()=>{
  
      if(!isOnline){
        setHasNetworkError(true)
        setShowGlobalNetworkError(true)
      }else{
        setHasNetworkError(false)
        setShowGlobalNetworkError(false)
      }
    },[isOnline])

    
    
    
    useEffect(()=>{
      setNavFunction(()=>{
        return ()=>navigate(-1)
      })

      window.addEventListener("popstate",navFunction)
      return ()=> window.removeEventListener|("popstate", navFunction)
      
    },[])
 
    const navmenuAction = async(type)=>{
        
        switch(type){
          
            case "createevent": {
               setShowNav(true)
               setViewMode("event")
               setTitle('Create Event')
               setShowNavMenu(false)
               navigate(`/${type}`,{state:{eventData:null}})
               
            }
            break
            case "profile": {
              setShowNavMenu(false)
              navigate(`/${type}`)
            }
            break
            case "history":{
              setShowNavMenu(false)
              setViewMode("event")
              navigate(`/${type}`)
            }
            break;
            case "go-to-carpool":{
              setViewMode('carpool')
              localStorage.setItem("viewMode", 'carpool')
              setViewMode("carpool")
              navigate("/")
              setShowNavMenu(false)
            }
            break;
            case "go-to-event":{
              setViewMode('event')
              localStorage.setItem("viewMode", 'event')
              navigate("/")
              setShowNavMenu(false)
            }
            break;
            default: {
              setShowNavMenu(false)
              navigate(`/`)
              
            }
            
        }

    }

    const onLogout = ()=>{
       logout()
       setShowConfirmationBox(false)
       setTitle("NovusUI Events")
       setShowNavMenu(false)
       navigate("/")
    }

    const actionButtons = [{title:"Confirm logout",onClick:onLogout}]

    const onClickMenu = ()=>{
      setShowNavMenu(prev=>!prev)
      // if(showNavMenu){
      //    setShowNav(false)
      // }else{
         
      // }
    }

    const handleNavFunction = () =>{
     setShowNavMenu(false)
      navFunction()
    }

    // if(showConfirmationBox){
    //   return(
    //     <ConfirmationBox para="Are you sure you want to logout ?" cancelBtn={true} setShowConfirmationBox={setShowConfirmationBox} actionButtons={actionButtons}/>
    //   )
    // }
  return (
    <>
       <nav style={styles.topBar} >
         {showGlobalNetworkError && <NoNetwork/>}
         {
          showNav  && <NavFunction onClick={handleNavFunction} type={navType}/>
         }
          {!showNav && <div style={{...styles.navBlock,backgroundColor:"#202024"}}></div>
         }
         
         <h3 className='page-title' style={{textTransform:"capitalize"}}>
          {title}
         </h3>
         { showMenu && 
         <div style={styles.navBlock}>
            <div style={styles.hamburgerMenu} onClick={()=>onClickMenu()}>
           {showNavMenu &&
           <img src='X.svg'></img>
          ||
           <img src='/Menu.svg'></img>
          }
          </div>
          
         </div>
        }
          
        </nav>
        
        { showNavMenu && showMenu &&
        <motion.div 
        style={styles.menu}
        initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.2 }}
        >
            
            { <div style={styles.dish} onClick={()=>navmenuAction("go-to-event")}>Events</div>}
            {<div style={styles.dish} onClick={()=>navmenuAction("createevent")}>Create event</div>}
            {isLoggedIn &&  <div style={styles.dish} onClick={()=> navmenuAction("profile")}>Profile</div>}
            {isLoggedIn &&  <div style={styles.dish} onClick={()=>navmenuAction("history")}>History</div>}
            {isLoggedIn &&  <div style={styles.lastDish} onClick={()=>navmenuAction("go-to-carpool")}>Carpool</div>}
            {isLoggedIn && <div style={styles.lastDish}onClick={()=>setShowConfirmationBox(true)}>Logout</div>}
            {!isLoggedIn && <div style={styles.dish}onClick={()=>navigate("/login")}>LogIn</div>}
        </motion.div>
        
        } 
       
        {
          showConfirmationBox && <ConfirmationBox para="Are you sure you want to logout ?" cancelBtn={true} setShowConfirmationBox={setShowConfirmationBox} actionButtons={actionButtons}/>
        }  
    </>
  )
}

export default NavBar
