import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import { useNavigate } from 'react-router-dom'
import ShareInvite from './ShareInvite'
import Personalized from './Personalized'


const styles = {

    roleInfo:{
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-start",
        marginLeft: "20px",
        marginTop: "20px",
        
      },
      info:{
        width: "65%",
        marginLeft: "10px",
        color:"#FFFFFF"
      },
      learnMore:{
        color: "#8E69F0",
        textDecoration: "underline",
      }
    
}

const GetInvite = () => {

    const {setTitle, setNavFunction, setNavType} = useNav()
    const eventName = localStorage.getItem("eventName")
    const eventId = localStorage.getItem("eventId")
    const navigate = useNavigate()
    const [isShare,setIsShare] = useState(false)
    const [personalized, setPersonalized] = useState(false)

    useEffect(()=>{


        if(!eventId){
            navigate("/notfound")
        }

        setTitle("Invite friends")
        setNavFunction(()=>()=>navigate("/event-page"))
        setNavType("back")

    },[personalized])


  return ( 
    
        <>
        {!personalized && <div className='island'>
            <h3 className="poppins-20px-500 island-title">{eventName}</h3>
            <div style={{width:"90%"}}>
                <LongPurpleBtn onClick={() =>setIsShare(true)} name="General invite"/>
                <LongPurpleBtn onClick={() => {setPersonalized(true)}}   name="Personalized invite"/>
            </div>
            <div className="poppins-12px-300" style={styles.roleInfo}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z" fill="white"/>
                    <path d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12ZM12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75Z" fill="white"/>
                </svg>
                <p style={styles.info}>
                    Select if you would like to Send a general Invite or  
                    personalized invite
                </p>
            </div>
        </div>}
        
        {!personalized && isShare && <ShareInvite setIsShare={setIsShare} showCopyLink={true}/>}
        {personalized && <Personalized eventName={eventName} setPersonalized={setPersonalized}/>}

        </>
    
         
    )

  
}

export default GetInvite
