import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const style = {
    width: "150px",
    height: "150px",
    backgroundColor: "#20C963",
    marginBottom: "50px",
    borderRadius:"100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}
const SuccessfulPage = () => {
    
    const location= useLocation()
    const navigate = useNavigate()
    const {path,text} = location.state
    useEffect(()=>{
        setTimeout(()=>{
            navigate(path,{state:{keepData:true}})
        },2000)
    },[])

  return (
    <div className='island'>
        <div style={style}>
            <svg width="54" height="36" viewBox="0 0 54 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1262 1.20679C53.3466 2.42716 53.3466 4.40583 52.1262 5.6262L22.9595 34.7927C21.7391 36.0132 19.7607 36.0132 18.5402 34.7927L1.87353 18.1261C0.653158 16.9057 0.653158 14.9273 1.87353 13.7069C3.09391 12.4865 5.07258 12.4865 6.29295 13.7069L20.7499 28.1636L47.707 1.20679C48.9274 -0.0135899 50.9057 -0.0135899 52.1262 1.20679Z" fill="#D9D9D9"/>
            </svg>
        </div>
        <p className='poppins-20px-500'>{text}</p>
    </div>
  )
}

export default SuccessfulPage
