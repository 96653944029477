import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const NavContext = createContext();

export const NavProvider = ({ children }) => {
  

const [showNav, setShowNav] = useState(true)
const [showMenu,setShowMenu] = useState(true)
const [ title, setTitle] = useState("")
const [navFunction, setNavFunction] = useState(null)
const [navType, setNavType] = useState("back")

 

  return (
    <NavContext.Provider value={{showNav, setShowNav, title, setTitle, navFunction, setNavFunction,navType, setNavType,showMenu,setShowMenu}}>
      {children}
    </NavContext.Provider>
  );
};

export const useNav = () => {
  return useContext(NavContext);
};
