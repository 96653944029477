import React, { useEffect, useRef, useState } from 'react'
import PassengerQuickAction from './PassengerQuickAction'
import ChatApp from '../../../components/ChatApp/ChatApp'
import { collection, doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'
import { useMsg } from '../../../contextAPI/ErrorMsgContext'
import { useApp } from '../../../contextAPI/AppContext'
import { useAuth } from '../../../contextAPI/AuthContext'
import { useNav } from '../../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'
import MessageBox from '../../../components/MessageBox2'
import DangerBtn from '../../../components/Buttons/DangerBtn'
import ReturnTripSurvey from "./Components/ReturnTripSurvey"
import { registerToTopic } from '../../../api'
import ConfirmationBox from '../../../components/ConfirmationBox'
import PoolerCard from '../components/PoolerCard'
import PooleeCard from '../components/PooleeCard'
import TipsForPoolers from '../../../components/TipsForAJollyRide/TipsForPoolers'
import { getLocation } from '../../../Utils/util'

const styles = {
  bottomBtn : {
    position:"absolute",
    bottom: "20px"
  },
  bottomDiv: {
    position:"absolute",
    bottom: "0",
    width: "100%",
    padding: "15px 30px",
    backgroundColor: "#18181A",
    color: "#FFFFFF",
    borderRadius: "20px 20px 0 0",
  }, 
  actionBtn: {
    padding: "5px 0"
  }

}

const PoolerHS = ({passengers,cancelPool,rejectPassenger,isDisabled,poolDocRef,poolId,setIsDisabled,poolData, addNewPassenger}) => {

  
   const poolMsgsRef = collection(poolDocRef,"poolMessages")
   const [openPassengerQA, setOpenPassengerQA] = useState(false)
   const [poolMsgs, setPoolMsgs] = useState([])
   const [openChat, setOpenChat] = useState(false)
   const [showConfirmationBox, setShowConfirmationBox] = useState(false)
  //  const [eventData, setEventData] = useState(null)
   const [returnTripSurvey,setReturnTripSurvey] = useState(null)
   const [showChangeLoc, setShowChangeLoc] = useState(false)
   const {setMsgType} = useMsg()
   const {eventData, setShowNotification} = useApp()
   const changeLocationRef = useRef(null)
   const {user,token} = useAuth()
   const {displayName, username} = user
   const {setTitle, setNavFunction, setNavType, setShowNav} = useNav()
   const navigate = useNavigate()
  const [error, setError] = useState(null)
  //  useEffect(()=>{

  //   try {
     
  //   const unsubscribeEventSnapShop = onSnapshot(eventDocRef,(snapshot)=>{
  //       if(snapshot.exists()){
  //         const eventData = {
  //           eventId,
  //           ...snapshot.data()
  //         }
  //         setEventData(eventData)
  //         if((eventData.ends.seconds - (Math.floor(Date.now() / 1000))) <= 1800 && !poolData.returnTripSurvey ){
  //           setReturnTripSurvey(true)
  //           setIsDisabled(true)
  //         }else{
  //           setReturnTripSurvey(false)
  //           setIsDisabled(false)
  //         }
  //       }else{
  //          setMsgType("failure")
  //       }
  //   },(error)=>{
  //      console.log(error)
  //      setMsgType("failure")
  //   })

  //   return()=> unsubscribeEventSnapShop()
  //   } catch (error) {
  //     console.log(error)
  //     setMsgType("failure")
  //   }
    
  //  },[])
  useEffect(()=>{
    
  
    if((eventData?.ends.seconds - (Math.floor(Date.now() / 1000))) <= 1800 && (eventData?.ends.seconds - (Math.floor(Date.now() / 1000))) >= -5400 && !poolData.returnTripSurvey?.status){
      setReturnTripSurvey(true)
      setIsDisabled(true)
    }else{
      setReturnTripSurvey(false)
      setIsDisabled(false)
    }

    console.log(user)
    registerToTopic(poolId + "pooler", user.messageToken,token, (error)=>{
      if(!error){
          console.log("subscribed")
      }
    }) 
  


},[])
   
   useEffect(()=>{

    try {
      
     

      const poolMsgsUnsubscribe = onSnapshot(poolMsgsRef,(snapshot)=>{
       
      setPoolMsgs([])
      const sortedMgs =snapshot.docs.map(doc=>({
        id:doc.id,
        ...doc.data()
      })).sort((a,b)=>(a.time - b.time))

      setPoolMsgs(sortedMgs)
      
    },(error)=>{
      console.log(error)
      setMsgType("failure")
    })
     
    return()=> poolMsgsUnsubscribe()
      
    } catch (error) {
      console.log(error)
      setMsgType("failure")
    }
    
    
     
   },[])

   const updateReturnTrip = async(status)=> {
     
    getLocation(async(err,data)=>{
      if(!err,data){
        try {
      
          if(status === "same"){
            await updateDoc(poolDocRef,{
              locationCoordinate: data,
              returnTripSurvey:{
              status:true,
              formerLoc: poolData.poolerLoc,
              locationChanged: false,
              time: new Date().getTime(),
              formerLocCord: poolData.locationCoordinate || null ,
            } 
          })
           setIsDisabled(false)
           setReturnTripSurvey(false)
          }else if(status==="change"){
           
              await updateDoc(poolDocRef,{
                locationCoordinate: data,
                returnTripSurvey:{
                status:true,
                formerLoc: poolData.poolerLoc,
                newLoc: changeLocationRef.current.value,
                locationChanged: true,
                time: new Date().getTime(),
                formerLocCord: poolData.locationCoordinate || null,
              }
              })
    
           
              setShowChangeLoc(false)
              setIsDisabled(false)
              setReturnTripSurvey(false)
            
            
          }else{
            await updateDoc(poolDocRef,{
              
              returnTripSurvey:{
              status: false,
              formerLoc: poolData.poolerLoc,
              locationChanged: false,
              time: new Date().getTime(),
              formerLocCord: poolData.locationCoordinate || null,
            }
          })
          setIsDisabled(false)
           setReturnTripSurvey(false)
          }
        } catch (error) {
          
          setError("error updating return trip")
        }
      }else{
         setError("error getting location")
      }
    })
   
       
   }

   useEffect(()=>{
    console.log(poolData)
    console.log(poolData.poolDate.seconds > (new Date().setHours(0,0,0,0))/1000, eventData?.ends.seconds > (new Date().setHours(0,0,0,0))/1000,(new Date().setHours(0,0,0,0))/1000,poolData.poolDate.seconds)
   },[eventData])

   const onOpenChat = ()=>{
    setOpenChat(true)
    setNavFunction(()=>{
      return ()=> setOpenChat(false)
    })
    setNavType("cancel")
    setShowNotification(false)
   }

   useEffect(()=>{
    if(!openChat ){
      setTitle("Contact Poolees")
    setNavFunction(()=>{
      return ()=> {
        console.log("daddy dadas")
        if(localStorage.viewMode === "event"){
          navigate("/event-page")
        }else if(localStorage.viewMode === "carpool"){
          navigate("/")
        }
      }
    })
    setNavType("back")
    setShowNav(true)
  
    }
   },[openChat])


 

   if(openChat){
    
  
    const firestoreDate = eventData?.eventDate.toDate() ||  poolData.poolDate.toDate()
    const currentDate = new Date();
    const diffInMs = firestoreDate - currentDate  ;
    console.log(diffInMs);
    const diffInHours = diffInMs / (1000 * 60 * 60);
    console.log(diffInHours)
    if (diffInHours <= 24) {
      return(
        <ChatApp poolMsgsRef={poolMsgsRef} poolMsgs={poolMsgs} setOpenChat={setOpenChat} poolId={poolId}/>
      )
    } else {
      return(
        
        <TipsForPoolers/>
      )
    }
    
   }
   
  return (
  
    openPassengerQA &&

     <PassengerQuickAction type="pooler" passengers={passengers} rejectPassenger={rejectPassenger} setOpenPassengerQA={setOpenPassengerQA} addNewPassenger={addNewPassenger} eventLoc={eventData.location}/>
    
    ||
    
      <>
     {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`}  errorType={true}/>}
       
      {
        <div className='container' > 
          <PoolerCard poolData={poolData} eventDate={eventData?.eventDate}/>
          <PooleeCard  poolees={passengers} viewPoolees={setOpenPassengerQA} />
          <MessageBox onClick={onOpenChat} type="pooler" displayName={username || displayName} validateMessage="Send a message to your poolees"/>
          <DangerBtn name="Cancel Pool" inactive={isDisabled} onClick={()=>{setShowConfirmationBox(true)}} />
        </div> 
      }
      
      {
        returnTripSurvey && <ReturnTripSurvey updateReturnTrip={updateReturnTrip} cancelPool={cancelPool} showChangeLoc={showChangeLoc} poolData={poolData} changeLocationRef={changeLocationRef} setShowChangeLoc={setShowChangeLoc}/>
      }
      {
        showConfirmationBox && <ConfirmationBox title={"Cancel pool?"} cancelBtn={true} setShowConfirmationBox={setShowConfirmationBox} actionButtons={[{title:"Cancel pool", onClick:cancelPool}]}/>
      }
      
      {
         (((eventData && ((eventData.repeat === "none" && eventData.ends.seconds < (new Date().setHours(0,0,0,0)/1000)) || ( poolData.repeat === "Once" && poolData.poolDate.seconds < (new Date().setHours(0,0,0,0))/1000))) || ( poolData.poolType === "default" && poolData.poolDate.seconds < (new Date().setHours(0,0,0,0)/1000)))) && <ConfirmationBox title="Pool Expired"  para="This pool has expired" errorType={true} actionButtons={[{title:"cancel pool", onClick:cancelPool}]}/>
      }
      </>
    
     
   
  )
} 

export default PoolerHS
