import React from 'react'

const styles = {
    normal:
    {
      width: "45%",
      borderRadius: "10px",
      backgroundColor: "#18181A",
      color: "#8E69F0",
      padding: "5px 0"
    },
    selected: {
        backgroundColor: "#8E69F0",
        color: "#FFFFFF",
    }
}
const HistoryTab = ({title, isSelected,onClick}) => {
  return (
    isSelected 
    &&
    <button style={{...styles.normal,...styles.selected}} className='poppins-12px-300' onClick={onClick}>{title}</button>
    ||
    <button style={styles.normal} className='poppins-12px-300' onClick={onClick}>{title}</button>
  )
}

export default HistoryTab
