import React, { useEffect,  useState } from 'react'
import FormElement1 from '../../components/FormElements/FormElement1'
import FormTypeE from '../../components/FormElements/FormTypeE'
import EventFeatures from '../../components/EventFeatures/EventFeatures'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNav } from '../../contextAPI/NavContaxt'
import CalenderForm from '../../components/FormElements/CalenderForm'
import TimeForm from '../../components/FormElements/TimeForm'
import DropDownForm from '../../components/FormElements/DropDownForm'
import MapForm from '../../components/FormElements/MapForm'
import { useAuth } from '../../contextAPI/AuthContext'
import { validateDate, validateDescription, validateEndTIme, validateEventName, validateLink, validateLocation, validateStartTime } from './formValidation'
import { collection, doc, runTransaction,   } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { firebaseTimeStampFormatter } from './utills'
import LongPurpleBtn from "../../components/Buttons/LongPurpleBtn"
import Spinner from '../../components/Spinner'
import { useCreateEvent } from '../../contextAPI/CreateEventContext'
import { useApp } from '../../contextAPI/AppContext'
import { createEventWishBlends } from '../../firebase/controller/eventsController'
import CreateEventImage from '../../components/CreateEventImage'
import ChoosePicture from './ChoosePicture'
import InfoPage from '../../components/InfoPage'
import ConfirmationBox from '../../components/ConfirmationBox'
import { addToCache } from '../../Utils/indexedDBService'
import { handleFileInputChange } from '../../firebase/controller/imageUploadController'
import { useNetworkError } from '../../contextAPI/NetworkErrorContext'
import NetworkError from '../../components/NetworkError'


const styles = {
  eventImage:{
    /* height: 65%; */
    borderRadius: "20px",
   
  },
   eventImageImg:{
    width: "100%",
    height: "100%",
  },
  eventCard:{
    backgroundColor: "#18181A",
    width: "100%",
    maxHeight: "50vh",
    margin: "10px 0",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  
    eventFeatures:{
     
      display: "flex",
      flexDirection: "column",
      padding: "10px 0",
      width: "inherit",
     
    },
  
    eventFeaturesContainer:{
   
      padding: "10px 0",
      flex: "1",
      display: "flex",
      justifyContent:"flexStart",
      overflowX: "scroll",
      overflowY: "hidden",
    },
    eventForm:{
      width: "100%",
      marginTop: "10px",
    }
   
}

const CreateEvent = () => {

  const navigate = useNavigate()
  const {setNavFunction, setNavType} = useNav()
  const [featureInfo, setFeatureInfo] = useState(null)
  const [isDisabled, setIsDisabled] = useState(false)
  const [showCBforCarpool, setShowCBforCarpool] = useState(false)
  const {user,isLoggedIn} = useAuth()
  const {items} = useApp()
  const [error, setError] = useState(null)
  const {hasNetworkError} = useNetworkError()
 
  const {
    eventNameErr, setEventNameErr,
    eventLocationErr, setEventLocationErr,
    eventDescriptionErr, setDescriptionErr,
    eventDateErr, setEventDateErr,
    eventSTErr, setEventSTErr,
    eventETErr, setEventETErr,
    eventDetails, setEventDetails,
    linkErr,setLinkErr,
    clearContext
    } = useCreateEvent()
  const [isWaiting, setIsWaiting] = useState(true)
  const [choosePicture,setChoosePicture] = useState(false)

  const [showConfirmationBox,setShowConfirmationBox] = useState(null)
  // error usestate


  const [spinnerMsg, setSpinnerMsg] = useState(null)

 
 useEffect(()=>{
     
      setNavFunction(()=>()=>navigate("/"))
      setNavType("back")
      setIsWaiting(false)
 },[])

  const onCreateEvent = async(e,isConfirmed)=> {
    
    setIsDisabled(true)
    
    const eventLocationErr = validateLocation(eventDetails.eventLoc)
    setEventLocationErr(eventLocationErr)

    const eventDescriptionErr = validateDescription(eventDetails.eventDesc)
    setDescriptionErr(eventDescriptionErr)

    const eventNameErr = validateEventName(eventDetails.eventName)
    setEventNameErr(eventNameErr)

    const eventDateErr = validateDate(eventDetails.eventDate)
    setEventDateErr(eventDateErr)

    const eventSTErr = validateStartTime(eventDetails.eventStartTime)
    setEventSTErr(eventSTErr)

    const eventETErr = validateEndTIme(eventDetails.eventStartTime,eventDetails.eventEndTime)
    setEventETErr(eventETErr)

    const linkErr = validateLink(eventDetails.link)
    setLinkErr(linkErr)

    if(isConfirmed){
       
       setShowConfirmationBox(null)
    }
    else if (linkErr || eventLocationErr || eventDescriptionErr || eventNameErr || eventDateErr || eventSTErr || (eventETErr && eventETErr !== "less than" )) {
      setIsDisabled(false)
      return
    }

    if(eventETErr === "less than"  && !isConfirmed){
      setShowConfirmationBox("Your event end time is less than your start time. Does this program end the next day?")
      setIsDisabled(false)
      return
    }

    try {
     
    setSpinnerMsg( "creating event")
    setIsWaiting(true)
    
    // event referece
    
    const eventCollectionRef = collection(db, "events")
    const eventRef =  doc(eventCollectionRef)

    const imgURL = await handleFileInputChange(eventDetails.eventCoverImgFile,eventRef.id)

    // save to db
    const data = {
      eventName:eventDetails.eventName.trim(),
      location: eventDetails.eventLoc.trim(),
      eventDate: firebaseTimeStampFormatter(eventDetails.eventDate, eventDetails.eventStartTime),
      ends: firebaseTimeStampFormatter(eventDetails.eventDate, eventDetails.eventEndTime),
      description: eventDetails.eventDesc.trim(),
      repeat: eventDetails.eventRepeatType,
      createdBy: user.id,
      coverImg: imgURL || eventDetails.eventCoverImg,
      featureAvailable: eventDetails.featuresSelected,
      eventVisibility:eventDetails.eventVisibility,
      link: eventDetails.link.trim()
      
    }
   
 
    if(isConfirmed){
      const date = new Date(data.ends)
      date.setDate(date.getDate() + 1)
      data.ends = date
      
    }

    if(eventDetails.featuresSelected.includes("getTicket")){
      data["ticket"] = {
        availableTicket: Number(eventDetails.ticketCount),
        soldTicket: 0,
        price:"free"
      }
    }
    console.log(data)
    // save to events
  
    if(items.length > 0){
      console.log(items)

      createEventWishBlends(eventRef.id,items,(error)=>{
        if(error){
          throw error
         
        }
      })
    }

      // save to userevents
    const usersRef = collection(db, "users")
    const userRef = doc(usersRef,user.id)
    const usereventsRef = collection(userRef, "userevents")
    const usereventRef = doc(usereventsRef, eventRef.id)

    await runTransaction(db,async(transaction)=>{
      transaction.set(eventRef,data,{merge:true})
      transaction.set(usereventRef, data, {merge:true})
      await addToCache(eventRef.id,data.ends,{...data,id:eventRef.id},"hostedEvent")
      await clearContext()
  })

    // await setDoc(eventRef, data,{merge:true})
    // await setDoc(usereventRef, data, {merge:true})
   
    setIsWaiting(false)
    setIsDisabled(false)
  
    localStorage.setItem("eventId",eventRef.id)
    localStorage.setItem("eventName",eventDetails.eventName)
    localStorage.setItem("createdBy",user.id)
    localStorage.setItem("shortcutEventId",eventRef.id)
    navigate("/invite")

  } catch (error) {
    setIsDisabled(false)
    setIsWaiting(false)
    console.log(error)
    setError("Error creating event")
  }
  }

  if(choosePicture){
    return(
      <ChoosePicture setChoosePicture={setChoosePicture} />
    )
  }

  if(featureInfo){
    return(
        <InfoPage type={featureInfo.type} 
        onClick={()=>{
          setFeatureInfo(null)
          setNavFunction(()=>()=>navigate("/"))
          setNavType("back") 
        }} 
      img={featureInfo.img}/>
    )
  }
  if(showConfirmationBox){
    return(
        <ConfirmationBox para={showConfirmationBox} 
        actionButtons={[{onClick: (e)=>{
          onCreateEvent(e,true)
        }, title:"proceed"}]} 
        cancelBtn={true} 
        setShowConfirmationBox={setShowConfirmationBox}/>
    )
  }
  
  
  return (
    <>
    {showCBforCarpool && <ConfirmationBox errorType={true} title="Verification Needed" para="You need to complete some basic KYC to add the carpool feature" cancelBtn={true} setShowConfirmationBox={setShowCBforCarpool} actionButtons={[{onClick: ()=>navigate("/edit-profile"), title: "Complete KYC"}]}/>}
    {!isLoggedIn && <ConfirmationBox errorType={true} title="Login" para="Login to create event" actionButtons={[{onClick: ()=>navigate("/login"), title: "Login"},{onClick: ()=>navigate("/"), title: "cancel"}]} />}
    {isWaiting && <Spinner msg={spinnerMsg}/>}
    {!isWaiting &&
      <div 
      className='container'
     
      > 
      <div onClick={()=>setChoosePicture(true)}>
        <CreateEventImage src={eventDetails.eventCoverImg} isEditable={true}/>
      </div>
      <div style={styles.eventForm}>
        <div>
          <FormElement1 title="Add event name" placeHolder="Event Name" value={eventDetails.eventName} onChange={setEventDetails} error={eventNameErr} type="eventName"/>
        </div>
        <div style={{display:"flex", justifyContent:"space-between"}}>
          <CalenderForm title="Set Date" value={eventDetails.eventDate} onChange={setEventDetails} error={eventDateErr}></CalenderForm>
          <TimeForm title="Start Time" placeHolder="10:00 AM" value={eventDetails.eventStartTime} onChange={setEventDetails} type='eventStartTime' error={eventSTErr}></TimeForm>
        </div>
        <div className='flex-row' style={{display:"flex", justifyContent:"space-between",padding: "20px 0"}}>
          <DropDownForm title="Repeat" placeHolder="Once"  value={eventDetails.eventRepeatType} onChange={setEventDetails} type="eventRepeatType" options={["none","daily","weekly"]}/>
          <TimeForm title="End Time" placeHolder="10:00 PM" value={eventDetails.eventEndTime} onChange={setEventDetails} type='eventEndTime' ></TimeForm>
        </div>
          <MapForm title="Set location" placeHolder="Eko Hotel" value={eventDetails.eventLoc} onChange={setEventDetails} error={eventLocationErr}/>
          <div style={{width:"100%", margin:"10px 0"}}>
            <DropDownForm title="Event visibility" placeHolder="Private"  value={eventDetails.eventVisibility} onChange={setEventDetails} type='eventVisibility' options={["private","public"]}/>
          </div>
            <FormTypeE title="Add Description" placeHolder="Write a description for your event" value={eventDetails.eventDesc} onChange={setEventDetails} error={eventDescriptionErr}/>
          <div>
           <FormElement1 title="Add a link" placeHolder="https://community.novusui.com" value={eventDetails.link} onChange={setEventDetails} error={linkErr} type="link"/>
        </div>
        </div>
        
      
       <div style={styles.eventFeatures}>
        <h6 className='poppins-heavy'>Make available for event</h6>
          <div style={styles.eventFeaturesContainer}>
            <EventFeatures  onClick={()=>setFeatureInfo({type:"carpool", img:"carpoolIll.png"})} featureType="carpool" isEditEvent={true} setShowCBforCarpool={setShowCBforCarpool} />
            <EventFeatures  onClick={()=>setFeatureInfo({type:"wishblend", img:"wishblendill.jpg"})} featureType="wishBlend" isEditEvent={true} />
            <EventFeatures  onClick={()=>setFeatureInfo({type:"getTicket", img:"ticketIll.jpg"})} featureType="getTicket" isEditEvent={true} />
            <EventFeatures  onClick={()=>alert("not available")} featureType="experienceEvent" isEditEvent={true} />
            <EventFeatures  onClick={()=>alert("not available")} featureType="games" isEditEvent={true} />
          </div>
      </div>
      {/* <div style={{width:"100%"}}>
          <DropDownForm title="Event visibility" placeHolder="Once"  value={eventRepeatType} onChange={setEventRepeatType}/>
      </div> */}
      <div style={{width:"100%", padding:"10px 0"}}>
        <LongPurpleBtn onClick={onCreateEvent} inactive={isDisabled} name="Create Event"/>
      </div>
    </div>
   }
   {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
   {hasNetworkError && <NetworkError/>}
   </>
  )
}

export default CreateEvent
