import React, { useEffect } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import MoreInfo from '../../components/MoreInfo'

const EventOwnerHS = () => {

    const {setNavFunction, setTitle, setShowNav, setNavType} = useNav()
    const navigate = useNavigate()
    const eventName = localStorage.getItem("eventName")
    useEffect(()=>{
        setShowNav(true)
        setTitle("Event Details")
        setNavFunction(()=>()=>navigate("/"))
        setNavType("back")
    },[])
  return (
    <div className='island'>
            <h3 className="poppins-20px-500 island-title">{eventName}</h3>
            <div style={{width:"90%"}}>
                <LongPurpleBtn onClick={() =>{navigate("/event-page")}} name="View / Edit event"/>
                <LongPurpleBtn onClick={() => {navigate("/scan-ticket")}}   name="View attendees / Scan ticket"/>
                <div style={{marginTop:"10px"}}>
                    <MoreInfo info="Select if you would like to edit your WishBlend list or you want to get item for the event."/>
                </div>
            </div>
           
        </div>
  )
}

export default EventOwnerHS
