import React, { useEffect, useRef, useState } from 'react'
import { useNav } from '../../../contextAPI/NavContaxt'
import GreyInputBox from '../../../components/InputsBox/GreyInputBox'
import { useAuth } from '../../../contextAPI/AuthContext'
import FormError from '../../../components/FormError'
import LongPurpleBtn2 from '../../../components/Buttons/LongPurpleBtn2'
import { carpoolRequestTransaction } from '../../../firebase/controller/transactionsController'
import { useNavigate } from 'react-router-dom'
import { validateLocation, validatePhone } from '../../../Utils/formValidation'
import {motion} from "framer-motion"

const ContactForm = ({setShowContactForm, showContactForm}) => {

    const phoneRef = useRef()
    const locationRef = useRef()
    const destinationRef = useRef()
    const {user} = useAuth()
    const {waNum,  location,  phoneNumber,availableNumber,messageToken} = user
    const {setTitle,setNavFunction, setNavType, setShowNav, setShowMenu} = useNav()
    const [phoneError, setWaNumError] = useState(null)
    const [locationError, setLocationError] = useState(null)
    const [destinationError, setDestinationError] = useState(null)
    const [isDisabled, setIsDisabled] = useState(null)
    const navigate = useNavigate()
    const poolType = localStorage.filterType
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const {returnTripSurvey} = showContactForm
  
    useEffect(() => {
      console.log(showContactForm)
      const getLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              setLatitude(position.coords.latitude);
              setLongitude(position.coords.longitude);
            },
            error => {
              console.error('Error getting location:', error);
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
        }
      };
  
      getLocation();
    }, []);

    useEffect(()=>{
        console.log(latitude,longitude)
    },[latitude,longitude])

    useEffect(() => {

     
 
        setTitle("Carpool")
        setNavFunction(()=>()=>{
          setShowNav(false)
          setTitle("Carpool")
          setShowMenu(true)
          setShowContactForm(null)
        })
        setNavType("cancel")
        setShowNav(true)

        phoneRef.current.value = availableNumber || phoneNumber || waNum || "" 
        if(locationRef.current){
          locationRef.current.value = location || ""
        }
        
       
    
    }, [])


    const onNext = async(e)=>{
        e.preventDefault()
        setIsDisabled(true)

        // Validate WhatsApp number
        const phoneError = validatePhone(phoneRef.current.value)
        setWaNumError(phoneError)

        // Validate location
        const locationError = locationRef?.current ? validateLocation(locationRef.current.value) :null
        setLocationError(locationError)

        const destinationError = destinationRef?.current ? validateLocation(destinationRef.current.value) : null

        if (phoneError  || locationError || destinationError ){
            setIsDisabled(false)
            return
        }

        console.log(showContactForm)

        
        const pooleeInfo = {
            ...showContactForm,
            phoneNumber:phoneRef.current.value,
            
        }
       
        if(locationRef.current){
          pooleeInfo.location = locationRef.current.value
        }

        if(destinationRef.current){
          pooleeInfo.destination = destinationRef.current.value
        }
       

        if(user.profile){
          pooleeInfo.profile = user.profile
        }

        console.log(pooleeInfo)
       
        try {
            
            await carpoolRequestTransaction(pooleeInfo)
            localStorage.setItem("eventId",pooleeInfo.eventId || null)
            localStorage.setItem("activePool",pooleeInfo.poolId)
            localStorage.setItem("shortcutEventId",pooleeInfo.eventId )
            localStorage.setItem("role","poolee")
            navigate("/carpool")
        } catch (error) {
            setIsDisabled(false)
            console.log(error)
        }
    }
  return (
    <> 
     
        <motion.div className='container'
         initial={{ height: 0 }}
         animate={{ height: showContactForm ? '100%' : 0 }}
         exit={{ height: 0 }}
         transition={{ type: 'tween' }}
        >
            <div style={{height:"15%", width:"100%", display:"flex", alignItems:"center",justifyContent:"center"}}>
                <h3 className="poppins-20px-500">Join Carpool</h3>
            </div>
            <div style={{flex:1, width:"100%", overflow:"scroll", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                <div style={{position:"relative", width:"90%"}}>
                    <p>Available number</p>
                    <GreyInputBox placeholder="Available number" inputRef={phoneRef} required={true}/>
                </div>
                <FormError error={phoneError}/>
                { (!returnTripSurvey?.status || poolType === "default") && <><div style={{position:"relative", width:"90%"}}>
                    <p>Location</p>
                    <GreyInputBox placeholder="Location" inputRef={locationRef} required={true}/>
                </div>
                <FormError error={locationError}/></>}
                {(poolType === "default" || returnTripSurvey?.status)  && <><div style={{position:"relative", width:"90%"}}>
                    <p>Destination</p>
                    <GreyInputBox placeholder="Destination" inputRef={destinationRef} required={true}/>
                </div>
                <FormError error={destinationError}/></>}
            </div>
            <div style={{height:"15%", width:"100%", display:"flex", alignItems:"center"}}>
                <LongPurpleBtn2 inactive={isDisabled} name="looks good" onClick={onNext}/>
             </div>
        </motion.div>
      
    </> 
  )
}

export default ContactForm
