import React from 'react'

const style = {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    backgroundColor: "#8E69F0",
}
const SendBtn = ({sendMsg}) => {
  return (
    <button style={style} onClick={sendMsg}>
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <g clip-path="url(#clip0_531_2224)">
            <path d="M7.03337 8.96383L9.53216 1.6341C9.78204 0.926111 9.07404 0.218126 8.36608 0.468003L1.03633 2.96678C0.203406 3.2583 0.203406 4.42437 1.07798 4.67429L3.5351 5.34062C4.07651 5.50721 4.53462 5.92367 4.65954 6.46504L5.32587 8.92216C5.57579 9.79675 6.74187 9.79675 7.03337 8.96383Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip0_531_2224">
                <rect width="10" height="10" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    </button>
  )
}

export default SendBtn
