import React from 'react'

const TabDesc = ({title,desc}) => {
  return (
    <div style={{margin: "40px 0"}}>
      <h1 className='poppins-20px-500'>{title}</h1>
      <p className='poppins-15px-200'>{desc}</p>
    </div>
  )
}

export default TabDesc
