import React, { useEffect, useRef, useState } from 'react'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import CodeInput from '../../../components/InputsBox/CodeInput'
import FormError from '../../../components/FormError'
import { useAuth } from '../../../contextAPI/AuthContext'
import VerificationComplete from '../VerificationComplete'
import { useLocation, useNavigate } from 'react-router-dom'
import { useApp } from '../../../contextAPI/AppContext'
import { findNextKYC } from '../../../Utils/util'
import { sendPhoneVerificationToken, validateOTP } from '../../../api'
import { useNav } from '../../../contextAPI/NavContaxt'

const styles = {
    pageTitle: {
        margin:"20px 0",
        width:"85%",
    
    },
    btnBox: {
        width:"100%",
        marginTop:"50px",
        
    },
    inputDiv:{
        width:"100%",
        padding: "20px 0"
    },
    infoBox: {
        color:"#FFFFFF",
        margin: "10px 0",
    },
    resendCode: {
        textAlign: "right",
        width:"100%",
        paddingTop: "10px",
        color: "#8E69F0",
        font: "Poppins",
        fontWeight: "300",
        fontSize: "15px"
    }
}
const ConfirmPhoneNumberCode = () => {
    const [error, setError] = useState(null)
    const [next, setNext] = useState("/kyc-list")
    const {user,token,setUser} = useAuth()
    const {phoneNumber} = user
    const {verificationList, nextList} = useApp()
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const navigate =  useNavigate()
    const location = useLocation()
    const {state} = location
    
    const KYCnumber = 2

    const inpRef1 = useRef()
    const inpRef2  = useRef()
    const inpRef3 = useRef()
    const inpRef4= useRef()
    const inpRef5= useRef()
    const inpRef6= useRef()

    const {setNavFunction, setNavType} = useNav()

  

    useEffect(()=>{
        //check if this page was navigated properly
    if(!state && user.verification?.phoneNumber !== "completed"){
        navigate("/notfound")
    }

       const next = findNextKYC(KYCnumber, verificationList)
       console.log(next)
       if(next !== -1){
        setNext(nextList[next])
       }
       
       if(user){
        console.log(user)
        setNavFunction(()=>{
            return ()=>navigate(user.verification?.phoneNumber === "completed" ? navigate("/kyc-list") : navigate("/confirm-phone-number"))
            })
            setNavType("back")
       }
       
    },[user])

    const onChangeInput = (e)=>{
        console.log(e.target.value)
        
     
        switch(e.target.id){
            case "1" : {
                if(e.target.value === '')
                {
                  
                }else{
                    
                    inpRef2.current.focus()
                }
                
            };
            break
            case "2" : {
              
                if(e.target.value === '')
                {
                  inpRef1.current.focus()
                }else{ 
                    inpRef3.current.focus()
                }
               
            }
            break
            case "3" : {
                if(e.target.value === '')
                {
                    inpRef2.current.focus()
                }else{
                    inpRef4.current.focus()
                }
            }
            break
            case "4" : {
                if(e.target.value === '')
                {
                    inpRef3.current.focus()
                }else{
                    inpRef5.current.focus()
                }
            };
            break
            case "5" : {
                if(e.target.value === '')
                {
                    inpRef4.current.focus()
                }else{
                    inpRef6.current.focus()
                }
            };
            break
            case "6" : {
                if(e.target.value === '')
                {
                    inpRef5.current.focus()
                }else{
                    
                }
            };
        }
    }

    const onNext = async() =>{
        setError(null)
       
        if(inpRef1.current.value && inpRef2.current.value && inpRef3.current.value && inpRef4.current.value && inpRef5.current.value && inpRef6.current.value ){

            const code = inpRef1.current.value + inpRef2.current.value + inpRef3.current.value + inpRef4.current.value +inpRef5.current.value + inpRef6.current.value
            console.log(code)
            if(code.length === 6){
                setIsBtnDisabled(true)
                validateOTP(token,code,phoneNumber,(error)=>{
                    if(!error){
                        setUser(prev=>({
                            ...prev,
                            verification: {
                                ...user.verification,
                                phoneNumber: "completed"
                            }
                        }))
                        navigate(next)
                    }else{
                        setError("Invalid code")
                        setIsBtnDisabled(false)
                    }
                })
                
            }else{
                setError("Code incomplete")
            }
        }else{
            setError("Code incomplete")
            setIsBtnDisabled(false)
        }
    }

    const resendCode = ()=>{
        setIsBtnDisabled(true)
        sendPhoneVerificationToken(token,phoneNumber,(error)=>{
            if(!error){
              
            }else{
                setIsBtnDisabled(false)
            }
        })
    }
    
  return (
    <>
    {
        
        //  <BackFunctionBtn onClick={()=>verification?.phoneNumber === "completed" ? navigate("/kyc-list") : navigate("/confirm-phone-number")}/>

    }
    {user.verification?.phoneNumber === "completed" && <VerificationComplete/>}
    {user.verification?.phoneNumber !== "completed" &&
    <div className='container'>
    <div style={styles.pageTitle}>
        <h1 className='poppins-20px-500'>Verify your phone number</h1>
        <p className='poppins 14px-400' style={styles.infoBox}>Enter the four digit code sent to your phone number</p>
        <div style={styles.inputDiv}>
            <FormError error={error}/>
        </div>
        <CodeInput onChange={onChangeInput} inpRef1={inpRef1} inpRef2={inpRef2} inpRef3={inpRef3} inpRef4={inpRef4} inpRef5={inpRef5} inpRef6={inpRef6}/>
        <div style={styles.resendCode}>
            <p onClick={resendCode}>Resend code</p>
        </div>
        <div style={styles.btnBox}>
        <LongPurpleBtn name="Next" onClick={onNext} inactive={isBtnDisabled} />
        </div>
    </div>
    </div>
    }
    </>
  )
}

export default ConfirmPhoneNumberCode