import React, { useEffect, useRef, useState } from 'react'
import FormError from '../../../components/FormError'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import CodeInput from '../../../components/InputsBox/CodeInput'
import { useAuth } from '../../../contextAPI/AuthContext'
import VerificationComplete from '../VerificationComplete'
import BackFunctionBtn from '../../../components/Buttons/BackFunctionBtn'
import { useLocation, useNavigate } from 'react-router-dom'
import { findNextKYC } from '../../../Utils/util'
import { useApp } from '../../../contextAPI/AppContext'
import { validateEmailToken } from '../../../api'
import { useNav } from '../../../contextAPI/NavContaxt'
import ReactCodeInput from 'react-code-input'


const styles = {
    pageTitle: {
        margin:"20px 0",
        width:"85%",
    
    },
    btnBox: {
        width:"100%",
        marginTop:"50px",
        
    },
    inputDiv:{
        width:"100%",
        padding: "20px 0"
    },
    infoBox: {
        color:"#FFFFFF",
        margin: "10px 0",
    },
    resendCode: {
        textAlign: "right",
        width:"100%",
        paddingTop: "10px",
        color: "#8E69F0",
        font: "Poppins",
        fontWeight: "300",
        fontSize: "15px"
    }
}


const ConfirmEmailCode = () => {

    const [error, setError] = useState(null)
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [next, setNext] = useState("/kyc-list")
    const {user,token,setUser} = useAuth()
    const {verification} = user
    const navigate = useNavigate()
    const location = useLocation()
    const {state} = location
    const {verificationList, nextList} = useApp()
    const {setNavFunction, setNavType} = useNav()
    const KYCnumber = 1
    const [codeInput, setCodeInput] = useState("")
    const inpRef1 = useRef()
    const inpRef2  = useRef()
    const inpRef3 = useRef()
    const inpRef4= useRef()
  


    useEffect(()=>{
       
        //check if this page was navigated properly
        if(!state && user.verification?.email !== "completed"){
            navigate("/notfound")
        }
       const next = findNextKYC(KYCnumber, verificationList)
       if(next !== -1){
        setNext(nextList[next])
       }
       


      setNavFunction(()=>{
        return ()=>navigate(verification?.email === "completed" ? navigate("/kyc-list") : navigate("/confirm-email"))
      })
      setNavType("back")

    },[])

    const onChangeInput = (e)=>{
        console.log(e.target.value)
        

     
        switch(e.target.id){
            case "1" : {
                if(e.target.value === '')
                {
                  
                }else{
                    
                    inpRef2.current.focus()
                }
                
            };
            break
            case "2" : {
              
                if(e.target.value === '')
                {
                  inpRef1.current.focus()
                }else{ 
                    inpRef3.current.focus()
                }
               
            }
            break
            case "3" : {
                if(e.target.value === '')
                {
                    inpRef2.current.focus()
                }else{
                    inpRef4.current.focus()
                }
            }
            break
            case "4" : {
                if(e.target.value === '')
                {
                    inpRef3.current.focus()
                }else{
                    
                }
            };
        }
    }

    const onNext = async() =>{
        setError(null)
        
        if(inpRef1.current.value && inpRef2.current.value && inpRef3.current.value && inpRef4.current.value){
            setIsBtnDisabled(true)
            const code = inpRef1.current.value + inpRef2.current.value + inpRef3.current.value + inpRef4.current.value
            console.log(code)
            if(code.length === 4){
                validateEmailToken(token,code,(error)=>{
                    if(!error){
                        setUser(prev=>({
                            ...prev,
                            verification: {
                                ...verification,
                                email: "completed"
                            }
                        }))
                        navigate(next)
                    }else{
                        setIsBtnDisabled(false)
                    }
                })
                
            }
        }else{
            setError("Invalid code")
        }
    }
    
    const changeCodeInput = (value)=> {
        console.log(value)
    }
  return (

    <>
    {/* {
         <BackFunctionBtn onClick={()=>verification?.email === "completed" ? navigate("/kyc-list") : navigate("/confirm-email")}/>
    } */}
    {verification?.email === "completed" && <VerificationComplete/>}
    {verification?.email !== "completed" &&
    <div className='container'>
        <div style={styles.pageTitle}>
            <h1 className='poppins-20px-500'>Verify your email</h1>
            <p className='poppins-14px-400' style={styles.infoBox}>Enter the four digit code sent to your email</p>
            <div style={styles.inputDiv}>
                <FormError error={error}/>
            </div>

            <CodeInput onChange={onChangeInput} inpRef1={inpRef1} inpRef2={inpRef2} inpRef3={inpRef3} inpRef4={inpRef4}/>
            {/* <ReactCodeInput type='number' fields={4} value={codeInput} onChange={changeCodeInput}/> */}
            <div style={styles.resendCode}>
                <p onClick={()=>{}}>Resend code</p>
            </div>
            <div style={styles.btnBox}> 
            <LongPurpleBtn name="Next" onClick={onNext} inactive={isBtnDisabled}/>
            </div>
        </div>
    </div>
      } 
    </>
  )
}

export default ConfirmEmailCode
