import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';


const AppContext = createContext();



export const AppProvider = ({ children }) => {

  const {user} = useAuth()
 const [verification, setVerification] = useState(null)
  const [userSelectedEvent, setUserSelectedEvent] = useState(null)
  const [updateRole, setUpdateRole] = useState(null)
 
  const [chosenEvent, setChosenEvent] = useState(null)
  const [eventData, setEventData] = useState(null)
  const [carpoolId, setCarpoolId] = useState(null)
  const [poolId, setPoolId] = useState(null)
  const [yourPoolId, setYourPoolId] = useState(null)
  const [tempPoolId,setTmpPoolId] = useState(null)
  const [userEventDocRef,setUserEventDocRef] = useState(null)
  const [items, setItems] = useState([])
  //used in progressITEMS PAGE
  const [itemCount, setItemCount] = useState({})
  const [showNotification, setShowNotification] = useState(false)
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode"))
  const [intro, setIntro] = useState(localStorage.getItem("intro"))

  const nextList =  [
    "/basic-info",
    "/confirm-email",
    // "/confirm-phone-number",
    // "/confirm-id",
]
const verificationList = [
    verification?.basicInfo === "completed",
    verification?.email === "completed",
    // verification?.phoneNumber === "completed",
    // verification?.id === "completed" || verification?.id ==="pending"
]
useEffect(()=>{
 if(user){
  console.log(user.verification)
    setVerification(user.verification)
 }
},[user]) 

  return (
    <AppContext.Provider value={{userSelectedEvent, setUserSelectedEvent , 
                                updateRole, setUpdateRole,
                                chosenEvent, setChosenEvent,
                                eventData, setEventData,
                                carpoolId, setCarpoolId,
                                poolId, setPoolId,
                                yourPoolId, setYourPoolId,
                                userEventDocRef,setUserEventDocRef,
                                verificationList,nextList,
                                tempPoolId,setTmpPoolId,
                                items,setItems,
                                itemCount, setItemCount,
                                showNotification, setShowNotification,
                                viewMode, setViewMode,
                                intro,setIntro
                                }}>
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
