import React from 'react'
import EditImage from './EditImage'

const styles = {
    eventImage:{
      /* height: 65%; */
      borderRadius: "20px",
     
    },
     eventImageImg:{
      width: "100%",
      height: "100%",
      borderRadius: "20px",
    },
    eventCard:{
      backgroundColor: "#18181A",
      width: "100%",
      margin: "10px 0",
      borderRadius: "20px",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    
    
    
}

const CreateEventImage = ({src, isEditable}) => {
  console.log(src)
  return (
    <div style={styles.eventCard}>
        <div style={styles.eventImage}>
          {isEditable && <EditImage/>}
          <img src={src} style={styles.eventImageImg}></img>
        </div>
      </div>
  )
}

export default CreateEventImage
