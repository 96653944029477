import React, { useRef, useState } from 'react'
import TabImg from '../../components/TabImg'
import TabDesc from './TabDesc'
import {motion} from "framer-motion"
const Tab3 = ({setSelectedTab}) => {

  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const divRef = useRef(null);

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setEndX(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (startX && endX) {
      const diff = endX - startX;
      if (diff > 50) {
        // Swipe right
        console.log('Swiped right');
        setSelectedTab(1)
      } else if (diff < -50) {
        // Swipe left
        console.log('Swiped left');
    
      }
    }
    setStartX(null);
    setEndX(null);
  };

    const desc = "Our WishBlend feature, turning events into a collaborative feast."
  return (
    <motion.div 
    key='tab3' 
    style={{display:"flex",flexDirection:"column", justifyContent: "flex-start", alignItems:"center",height:"70%"}}
    ref={divRef}
    onTouchStart={(e)=>{handleTouchStart(e,setStartX)}}
    onTouchMove={(e)=>{handleTouchMove(e,setEndX)}}
    onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,()=>setSelectedTab(2))}
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.8 }}
    transition={{ type: 'spring', stiffness: 150, damping: 25 }}
    >
      <TabImg src={"/wishblendill.jpg"} />
      <TabDesc title="WishBlend" desc={desc}/>
    </motion.div>
  )
}

export default Tab3
