import React from 'react'

const Whatsapp = ({title, url}) => {

    const modifiedLink = url.replace(/ /g, "%20")
    const shareOnWhatsApp = () => {
        window.open(`https://wa.me/?text=${encodeURIComponent(`${title}: ${modifiedLink}`)}`);
      };
      
  return (
    <div onClick={shareOnWhatsApp}>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1637_918)">
                <path d="M34.0025 5.81512C32.1687 3.96431 29.9848 2.49698 27.5783 1.49864C25.1717 0.500288 22.5904 -0.00911068 19.985 0.000123326C9.0675 0.000123326 0.17 8.89512 0.16 19.8151C0.16 23.3126 1.075 26.7151 2.8025 29.7276L0 40.0001L10.51 37.2451C13.4175 38.8274 16.6748 39.6568 19.985 39.6576H19.995C30.915 39.6576 39.81 30.7626 39.82 19.8326C39.8225 17.2277 39.3095 14.6481 38.3107 12.2423C37.3118 9.83655 35.8493 7.6522 34.0025 5.81512ZM19.985 36.3026C17.0325 36.3036 14.1343 35.5091 11.595 34.0026L10.995 33.6426L4.76 35.2776L6.425 29.1951L6.035 28.5676C4.38452 25.9434 3.51162 22.9052 3.5175 19.8051C3.5175 10.7401 10.91 3.34512 19.995 3.34512C22.1594 3.34124 24.3031 3.76581 26.3026 4.59435C28.302 5.42289 30.1177 6.63902 31.645 8.17262C33.1776 9.70025 34.3925 11.5162 35.2198 13.5157C36.0471 15.5152 36.4703 17.6587 36.465 19.8226C36.455 28.9201 29.0625 36.3026 19.985 36.3026ZM29.0225 23.9676C28.53 23.7201 26.0975 22.5226 25.64 22.3526C25.185 22.1901 24.8525 22.1051 24.5275 22.6001C24.195 23.0926 23.245 24.2151 22.96 24.5376C22.675 24.8701 22.38 24.9076 21.885 24.6626C21.3925 24.4126 19.795 23.8926 17.905 22.2001C16.43 20.8876 15.4425 19.2626 15.1475 18.7701C14.8625 18.2751 15.12 18.0101 15.3675 17.7626C15.585 17.5426 15.86 17.1826 16.1075 16.8976C16.3575 16.6126 16.44 16.4026 16.6025 16.0726C16.765 15.7376 16.6875 15.4526 16.565 15.2051C16.44 14.9576 15.4525 12.5151 15.035 11.5301C14.635 10.5576 14.2275 10.6926 13.9225 10.6801C13.6375 10.6626 13.305 10.6626 12.9725 10.6626C12.7214 10.6689 12.4743 10.7269 12.2467 10.8332C12.0192 10.9395 11.816 11.0916 11.65 11.2801C11.195 11.7751 9.9225 12.9726 9.9225 15.4151C9.9225 17.8576 11.6975 20.2051 11.9475 20.5376C12.1925 20.8701 15.4325 25.8676 20.405 28.0176C21.58 28.5301 22.505 28.8326 23.2275 29.0626C24.415 29.4426 25.4875 29.3851 26.3425 29.2626C27.2925 29.1176 29.27 28.0626 29.6875 26.9051C30.0975 25.7451 30.0975 24.7551 29.9725 24.5476C29.85 24.3376 29.5175 24.2151 29.0225 23.9676Z" fill="#20C963"/>
            </g>
            <defs>
                <clipPath id="clip0_1637_918">
                    <rect width="40" height="40" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    </div>
  )
}

export default Whatsapp
