import React from 'react'

const RepeatType = ({repeatType, onClick}) => {
  return (
    <div style={{width:"120px", height:"40px", borderRadius:"10px", backgroundColor:"#2e2f3c", marginBottom:"10px", display:"flex"}} onClick={onClick}>
      <div style={{width:"65%",display:"flex", justifyContent:"center", alignItems:"center", color:"#A0A3AF"}}>
        <p className='poppins-14px-400'>{repeatType}</p>
      </div>
      <div style={{width:"35%", borderLeft:"2px dotted", display:"flex", justifyContent:"center", alignItems:"center"}}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 14.9395L4.99999 7.93945L3.93933 9.00012L12 17.0608L20.0606 9.00012L19 7.93945L12 14.9395Z" fill="#A0A3AF"/>
        </svg>
      </div>
    </div>
  )
}

export default RepeatType
