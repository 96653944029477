import React from 'react'

const styles = {
    chatContainer: {
        width:"100%",
        padding: "5px 30px",
        display:"flex",
        justifyContent: "flex-end"
    },
    chat:{
        backgroundColor: "#18181A",
        minHeight: "30px",
        borderRadius: "10px",
        color: "#FFFFFF",
        padding: "10px 15px"

    }
}

const MyChat = ({msg}) => {
  return (
    <div style={styles.chatContainer}>
        <div style={styles.chat}>

                 <p className='popping-300-normal'>{msg.chat}</p>
        </div>
    </div>
  )
}

export default MyChat