import React, { useEffect, useState } from 'react'
import ProfilePicture from '../../components/ProfilePicture'
import ProfileInfoEdit from '../../components/ProfileInfoEdit'
import { useAuth } from '../../contextAPI/AuthContext'
import { useRef } from 'react'
import RedOutlineBtn from '../../components/Buttons/RedOutlineBtn'
import GreenOutLineBtn from '../../components/Buttons/GreenOutLineBtn'
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../contextAPI/NavContaxt'
import { collection, doc, getDoc, getDocs, onSnapshot, setDoc, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { validateUsername,validateEmail, validatePhone, validateFirstName, validateLastName } from '../../Utils/formValidation'
import ConfirmationBox from '../../components/ConfirmationBox'
import MessageBox from '../../components/MessageBox'
import UploadProgress from '../../components/UploadProgress'




const EditProfile = () => {

    const {user,setUser} = useAuth()
    const {displayName, email, waNum, username, verification, firstName, lastName,phoneNumber, id} = user
    const [usernames, setUsernames] = useState([])
    const [isBtnDisabled, setIsBtnDisabled] = useState(false)
    const [showConfirmationBox, setShowConfirmationBox] = useState(false)
    const [error, setError] = useState(null)
     const navigate = useNavigate()
    const firstNameRef = useRef()
    const lastNameRef = useRef()
    const usernameRef = useRef()
    const emailRef = useRef()
    const phoneNumberRef = useRef()
    const {setTitle,setNavFunction} = useNav()
    const [showUploadProgress, setShowUploadProgress] = useState(false)
    const [progress, setProgress] = useState(0)
    const actionButtons = [
      {
        title: "Yes, Delete my Account",
        onClick: ()=>{}
        
      },
     
    ]
   //form error state
  
   const [emailError, setEmailError] = useState(null)
   const [firstNameError, setFirstNameError] = useState(null)
   const [lastNameError, setLastNameError] = useState(null)
   const [userNameError, setUserNameError] = useState(null)
   const [phoneNumberError, setPhoneNumberError] = useState(null)
   const usernamesRef = collection(db,"usernames")

    useEffect(()=>{
      
        setTitle("Profile")
        
        const splitname =  displayName ?  displayName.split(" ") : ["",""]
        firstNameRef.current.value = firstName || splitname[0] || ""
        lastNameRef.current.value = lastName || splitname[splitname.length - 1] || ""
        usernameRef.current.value = username || ""
        emailRef.current.value = email || ""
        phoneNumberRef.current.value = phoneNumber || waNum || ""
    },[])

    useEffect(()=>{
      

      
      const unsubscribeUsernames =onSnapshot(usernamesRef,(snapshot)=>{
          
        const username =  snapshot.docs.map(doc=>doc.data().username)
        console.log(username)
        setUsernames(username)
      },(error)=>{
         console.log(error)
         setError("An error occurred")
      })

      return()=> unsubscribeUsernames()
     
    },[])

  const confirmUsername =()=> {
     console.log(usernames)

     let  userNameError = validateUsername(usernameRef.current.value)
     setUserNameError(userNameError)
     const availUsername = usernames.find(username=> username === usernameRef.current.value)
     
     
     if(availUsername && (availUsername !== username)){
   
         setUserNameError("I'm not Available")
         userNameError ="I'm not Available"
     }
     usernameRef.current.value = usernameRef.current.value.toLowerCase()
     return userNameError

  }
  
  const confirmEmail = ()=> {
    
   const emailError = validateEmail(emailRef.current.value)
   
   setEmailError(emailError)

   return emailError
  }

  const confirmPhoneNumber = ()=> {
     
   const phoneNumberError = validatePhone(phoneNumberRef.current.value)
   
   setPhoneNumberError(phoneNumberError)
   return phoneNumberError
  }

  const confirmFirstName = () => {
      const firstNameError = validateFirstName(firstNameRef.current.value)
      setFirstNameError(firstNameError)

      return firstNameError
  }

  const confirmLastName = () => {
     const lastNameError = validateLastName(lastNameRef.current.value)
     setLastNameError(lastNameError)

     return lastNameError
  }


  

  const onVerifyAccount = async()=> {
       
    const emailError = confirmEmail()
    const userNameError= confirmUsername()
    const firstNameError = confirmLastName()
    const phoneNumberError = confirmPhoneNumber()
    const lastNameError = confirmFirstName()

    
    
    if(emailError || userNameError || firstNameError || lastNameError || phoneNumberError){
      return
    }
     setIsBtnDisabled(true)
    const data = {
         
    }
  try {

   if(firstName !== firstNameRef.current.value.trim())
         data.firstName = firstNameRef.current.value
    if(lastName !== lastNameRef.current.value.trim())
         data.lastName = lastNameRef.current.value
    if(username !== usernameRef.current.value.toLowerCase().trim()){
      data.username = usernameRef.current.value.toLowerCase().trim()

      const usernameDocRef = doc(usernamesRef,id)
      
      const username = await getDoc(usernameDocRef)
      
      
      
      let setCount = username?.setCount

      if(setCount){
         setCount++
      }else{
         setCount = 0
      }

      console.log(data.username)
      await setDoc(usernameDocRef , {username:data.username, setCount},{merge:true})
    }
         
    if(phoneNumber !== phoneNumberRef.current.value.trim())
         data.phoneNumber = phoneNumberRef.current.value.trim()
    if(email !== emailRef.current.value.trim())
         data.email =  emailRef.current.value.trim()

   
     const usersRef = collection(db, "users")
     const userRef = doc(usersRef, id)

     await updateDoc(userRef,data,{ merge: true })
     
     setUser(prev=>({
      ...prev,
      ...data
     }))
     navigate("/kyc-list")
  } catch (error) {
     console.log(error)
     isBtnDisabled(false)
     setError("Error in verification")
     
  }
    

  
    

    
  }




  return (
    <>
     {showUploadProgress && <UploadProgress progress={progress} />}
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    {<div className='container'>
      <div style={{marginBottom: "20px"}}>
        <ProfilePicture edit={true} img={user.profile} setProgress={setProgress} setShowUploadProgress={setShowUploadProgress}/>
       </div> 
       <div style={{width:"100%"}} >
            <ProfileInfoEdit title="First name" focus={()=>firstNameRef.current.focus()} infoRef={firstNameRef} isEditable={!verification?.basicInfo} formError={firstNameError} onChange={()=>confirmFirstName()} />
            <ProfileInfoEdit title="Last name" focus={()=>lastNameRef.current.focus()} infoRef={lastNameRef} isEditable={!verification?.basicInfo} onChange={()=> confirmLastName()} formError={lastNameError}/>
            <ProfileInfoEdit title="Username"  focus={()=>usernameRef.current.focus()} infoRef={usernameRef} isEditable={true} onChange={()=>confirmUsername()} formError={userNameError} isLowerCase={true}/>
       </div>
       <div style={{width:"100%", marginTop:"20px"}}>
            <ProfileInfoEdit title="Email"  focus={()=>emailRef.current.focus()} infoRef={emailRef} isEditable={false} onChange={()=> confirmEmail()} formError={emailError}/>
            <ProfileInfoEdit title="Phone number" focus={()=>phoneNumberRef.current.focus()} infoRef={phoneNumberRef} isEditable={!verification?.phoneNumber} onChange={()=>confirmPhoneNumber()} formError={phoneNumberError}/>
       </div>
       <div style={{ flex:"1", width:"100%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
             <GreenOutLineBtn name="Verify account" onClick={onVerifyAccount} inactive={isBtnDisabled}/>
             <RedOutlineBtn name="Delete account" onClick={()=>setShowConfirmationBox(true)}/>
       </div>
    </div>}
    { showConfirmationBox && <ConfirmationBox title="We will miss you" para="Are you sure you want to delete your account ?" actionButtons={actionButtons} setShowConfirmationBox={setShowConfirmationBox} cancelBtn={true} />} 
   
    </>
  )
}

export default EditProfile
