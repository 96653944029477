import React, { useEffect } from 'react'
import { useAuth } from '../../../contextAPI/AuthContext'
import VerificationComplete from '../VerificationComplete'
import PhoneNumber from './PhoneNumber'
// import BackFunctionBtn from '../../../components/Buttons/BackFunctionBtn'
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../../contextAPI/NavContaxt'




const ConfirmPhoneNumber = () => {
   
    const {user} = useAuth()
    const {verification} = user
    const navigate = useNavigate()

    const {setNavFunction, setNavType, setShowNav} = useNav()

    useEffect(()=>{
      setShowNav(true)
      setNavFunction(()=>{
        return ()=>navigate("/kyc-list")
      })
      setNavType("back")
    },[])
  return (
    <>
     {/* {
          <BackFunctionBtn onClick={()=>navigate("/kyc-list")}/>

       } */}
        {verification?.phoneNumber === "completed" && <VerificationComplete/>}
        {verification?.phoneNumber !== "completed" && <PhoneNumber/>}
    </>
  )
}

export default ConfirmPhoneNumber
