import React, {useRef, useState } from 'react'
import {  useNavigate } from 'react-router-dom'
import GreyInputBox from '../../components/InputsBox/GreyInputBox'
import Spinner from "../../components/Spinner"
import LongPurpleBtn2 from '../../components/Buttons/LongPurpleBtn2'
import TimeForm from '../../components/FormElements/TimeForm2'
import Home from './components/Home'
import Work from './components/Work'
import Centres from './components/Centres'
import RepeatType from './components/RepeatType'
import RepeatOptions from './components/RepeatOptions'
import CustomRepeat from './components/CustomRepeat'
import FormError from '../../components/FormError'
import { validateLocation, validateSeatNumber } from '../../Utils/formValidation'
import { validateStartTime } from '../CreateEvent/formValidation'
import { firebaseTimeStampFormatter } from '../CreateEvent/utills'
import { useAuth } from '../../contextAPI/AuthContext'
import { checkForCarpoolLimit, createDefaultPool } from '../../firebase/controller/poolController'
import { getLocation } from '../../Utils/util'
import ConfirmationBox from '../../components/ConfirmationBox'
import UploadProgress from '../../components/UploadProgress'
import useNetworkStatus from '../../CustomHooks/useNetworkStatus'
import { useNetworkError } from '../../contextAPI/NetworkErrorContext'

const CreateDefaultPool = () => {

  
  const [time, setTime] = useState("")
  const navigate = useNavigate()
  const [addressFor, setAddressFor] = useState(null)
  const [repeatType, setRepeatType] = useState("Once")
  const [showRepeatOption, setShowRepeatOption] = useState(false)
  const [showCustom, setShowCustom] = useState(false)
  const [customOption, setCustomOption] = useState([])
  const [destinationInputError, setDestinationInputError] = useState(null)
  const [seatInputError, setSeatInputError] = useState(null)
  const [locationInputError, setLocationInputError] = useState(null)
  const [timeInputError, setTimeInputError] = useState(null)
  const destRef = useRef()
  const locationRef = useRef()
  const seatRef = useRef()
  const {user} = useAuth()
  const [error, setError] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [isLimitReached, setIsLimitReached] = useState(false)
  
  const [showCreatePoolInfo, setShowCreatePoolInfo] = useState(false)
  const isOnline = useNetworkStatus()
  const {setShowGlobalNetworkError} = useNetworkError()
  
 
  const onSetCustomOption = (option)=>{
   
    if(customOption.includes(option)){
      setCustomOption(prev=>prev.filter(p=> p !== option))
      return
    }

    setCustomOption(prev=>[...prev,option])
  }

  
  const createPool = async() =>{

    if(!isOnline){

      setShowGlobalNetworkError(true)
      return
   }
   
    const destinationError =  validateLocation(destRef.current.value)
    setDestinationInputError(destinationError)

    const timeError = validateStartTime(time)
    
    setTimeInputError(timeError)

    const locationError = validateLocation(locationRef.current.value)
    setLocationInputError(locationError)

    const seatError = validateSeatNumber(seatRef.current.value)
    setSeatInputError(seatError)
    console.log(locationError, timeError)
    if(locationError || timeError || destinationError || seatError){
      
      return
    }
 
    if(!showCreatePoolInfo){
      setShowCreatePoolInfo(true)
      return
   }else{
     setShowCreatePoolInfo(false)
   }
    setIsCreating(true)

    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    

    let poolData = {

      poolType:"default",
      poolDate: firebaseTimeStampFormatter(formattedDate,time),
      poolerLoc: locationRef.current.value,
      repeat: repeatType,
      poolerDest: destRef.current.value,
      seats: Number(seatRef.current.value),
      costPerSeat: 0,
      status:"created",
      createDate:  new Date(),
      poolAdmin: user.id,
      poolerName: user.username,
      availableNumber: user.phoneNumber,
               
       
    }
   
    if(user.profile){
      poolData.profile = user.profile
      
    }



    const parts = time.split(':');
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);
    
    const targetTime = new Date();
    targetTime.setHours(hours, minutes, 0, 0); // Set the target time
    
    const currentTime = new Date(); // Current time
    
    if(["Once","Daily","Weekly"].includes(repeatType)){
      
      
      if (targetTime < currentTime) {
        let date = new Date(poolData.poolDate)

        if(repeatType === "Daily" || repeatType === "Once"){
          date.setDate(date.getDate() + 1)
        }else{
          date.setDate(date.getDate() + 7)
        }
        
        
        poolData.poolDate = date
       }

       console.log(poolData)

    
      }else if(repeatType === "Mon to Fri"){
         poolData.repeatPattern = [1,2,3,4,5]

         if(new Date().getDay() === 6){
          const date = new Date(poolData.poolDate)
          date.setDate(date.getDate() + 2)
          poolData.poolDate= date
         }else if(new Date().getDay() === 0 || targetTime < currentTime){
          const date = new Date(poolData.poolDate)
          date.setDate(date.getDate() + 1)
          poolData.poolDate= date
         }

         

      }else{
       
          poolData.repeatPattern = customOption
          
          if(!customOption.includes(new Date().getDay()) || targetTime < currentTime ){
            
            const maxNumber = Math.max(...customOption)
            const date = new Date(poolData.poolDate)
            if(maxNumber > new Date().getDay()){

              let count  = new Date().getDay() + 1;
              for(count; count <= maxNumber; count++){

                if(customOption.includes(count)){
                  date.setDate(date.getDate() + (count  - new Date().getDay()))
                  poolData.poolDate= date
                  break
                }
              }
       
              
            }else{
              const minNumber = Math.min(...customOption)
              date.setDate(date.getDate() + ((7 +minNumber)  - new Date().getDay()))
              poolData.poolDate = date
            }
          }
         
      }
  

        getLocation(async(err,payload)=>{
          if(!err && payload){
              try {
                  const limitReached = await checkForCarpoolLimit(user.id)
                  if(!limitReached){
                    const coordinates={latitude: payload.latitude, longitude:payload.longitude}
                    const poolId = await createDefaultPool(poolData, user.id, addressFor,coordinates)
                    console.log(poolId)
                    localStorage.setItem("activePool",poolId)
                    localStorage.setItem("eventId",null)
                    navigate("/carpool")
                  }
                  else{
                    setIsLimitReached(true)
                  }
                 
              } catch (error) {
                console.log("this")
                console.log(error)
                setError("Error creating pool")
                  setTimeout(()=>{
                    setIsCreating(false)
                  },[2000])
                   
                  
              }
              
          }else{
            console.log("no this")
            console.log(err)
                  setError("Error creating pool")

            setTimeout(()=>{
              setIsCreating(false)
            },[2000])
            console.log(err)
          }
     })
       
      
     
  }
  

  return (
    <>
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} errorType={true}/>}
    {isLimitReached && <ConfirmationBox title={"Limit reached!"} para={`You cannot have more than 3 default pools`} errorType={true} cancelBtn={true} setShowConfirmationBox={setIsLimitReached} actionButtons={[{title:"Go to carpools",onClick: ()=>navigate("/carpools")}]} />}
    <div style={{marginTop:"50px", width:"100%", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <div style={{width:"100%", backgroundColor:"#18181A", borderTopLeftRadius:"30px",borderTopRightRadius:"30px", padding:"30px 20px 10px",color:"#ffffff"}}>
        <div style={{textAlign:"center",width:"100%", marginBottom:"40px"}}>
          <h1 className='poppins-20px-500'>Everyday carpool</h1>
        </div>
        <p className='poppins-15px-200' style={{marginBottom:"10px"}}>Where are you?</p>
        <GreyInputBox inputRef={locationRef} onChange={()=>{}} placeholder="Enter address"/>
        <FormError error={locationInputError}/>
        <p className='poppins-15px-200' style={{marginBottom:"10px"}}>Where are you going?</p>
        <GreyInputBox inputRef={destRef} onChange={()=>{}} placeholder="Enter address"/>
        <FormError error={destinationInputError}/>
        {/* <div style={{display:"flex", justifyContent:"flex-start", margin:"20px 0"}}>
          <Home addressFor={addressFor} onClick={setAddressFor}/>
          <Work addressFor={addressFor} onClick={setAddressFor}/>
          <Centres addressFor={addressFor} onClick={setAddressFor}/>

        </div> */}
        <p className='poppins-15px-200' style={{marginBottom:"10px"}}>Seat Available</p>
        <GreyInputBox inputRef={seatRef} onChange={()=>{}} placeholder="enter seat number" type={"number"}/>
        <FormError error={seatInputError}/>
        <p className='poppins-15px-200' style={{margin:"10px 0"}}>When are you going?</p>
        <TimeForm onChange={setTime} value={time} />
        <FormError error={timeInputError}/>
        <p className='poppins-15px-200' style={{margin:"10px 0"}}>Repeat this pool?</p>
        <RepeatType repeatType={repeatType} onClick={()=>setShowRepeatOption(true)}/>
        <LongPurpleBtn2 name="Create Pool" onClick={()=>{createPool()}} />
      </div>
    </div>
      {showRepeatOption && !showCustom && <RepeatOptions setShowOption={setShowRepeatOption}  repeatType={repeatType} setRepeatType={setRepeatType} setShowCustom={setShowCustom}/>}
      {showCustom && <CustomRepeat setShowCustom={setShowCustom}  customOption={customOption} setCustomOption={onSetCustomOption} setRepeatType={setRepeatType} setShowOption={setShowRepeatOption} inactive={customOption.length === 0}/>}
      {isCreating && <UploadProgress progress={error ? error :"Creating pool"}/>}
      {showCreatePoolInfo  && <ConfirmationBox title="Pool Location" infoBox="Make sure you're in the location youre pooling from" actionButtons={[{title:"Proceed", onClick:createPool}]} cancelBtn={true} setShowConfirmationBox={setShowCreatePoolInfo}/>}
    </>
  )
}

export default CreateDefaultPool
