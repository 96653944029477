import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import TabImg from '../../components/TabImg'
import TabDesc from './TabDesc'
import {motion} from "framer-motion"
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../Utils/util'


const Tab1 = ({setSelectedTab}) => {

  const [active, setActive] = useState(true)

  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const divRef = useRef(null);

 useEffect(()=>{
  const unsubscribe = setTimeout(()=>{
    
    setSelectedTab(1)
},5000)
return()=>clearTimeout(unsubscribe)
 },[])
 

    const desc = "Discover our Carpool feature to save money, reduce your carbon footprint, and connect with fellow attendees."
  return (
    <motion.div 
    ref={divRef}
    key='tab1' 
    style={ !active ? {display:"flex",flexDirection:"column", justifyContent: "flex-start", alignItems:"center",height:"60%" }: {display:"flex",flexDirection:"column", justifyContent: "flex-start", alignItems:"center",height:"70%"}} 
    onTouchStart={(e)=>handleTouchStart(e,setStartX)}
    onTouchMove={(e)=>handleTouchMove(e,setEndX)}
    onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,()=>{ setSelectedTab(1)})}
    initial={{ opacity: 0, scale: 0.8 }}
       animate={{ opacity: 1, scale: 1 }}
       exit={{ opacity: 0, scale: 0.8 }}
       transition={{ type: 'spring', stiffness: 200, damping: 10 }}
    >
      <TabImg src="/Carpool-bro.svg"/>
      <TabDesc title="Carpool" desc={desc}/>
    </motion.div>
  )
}

export default Tab1
