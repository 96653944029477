import React, { useEffect, useRef, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate, useLocation } from 'react-router-dom'
import SearchBar from '../../components/SearchBar'
import { getAllCarpoolEvent} from '../../firebase/controller/eventsController'
import ConfirmationBox from '../../components/ConfirmationBox'
import { checkForActiveEventPool, createEventCarpool } from '../../firebase/controller/poolController'
import { useAuth } from '../../contextAPI/AuthContext'
import { getLocation } from '../../Utils/util'
import { addToCache, clearStore, getAllFromStore, getDbCheck, setDbCheck } from '../../Utils/indexedDBService'
import { Timestamp } from 'firebase/firestore'
import useNetworkStatus from '../../CustomHooks/useNetworkStatus'
import { useNetworkError } from '../../contextAPI/NetworkErrorContext'


const CarpoolForEvents = ({eventToCarpool, setEventToCarpool}) => {

    const {setNavFunction} = useNav()
    const searchRef = useRef()
    const [searchEvent, setSearchEvent] = useState([])
    const [events, setEvents] = useState([])
    const navigate  = useNavigate()
    const state = useLocation().state
    const [error, setError] = useState(null)
    const [poolAlreadyExists,setPoolAlreadyExist] = useState(null)
    const {user} = useAuth()
    const [searchValue, setSearchValue] = useState("")
    const isOnline = useNetworkStatus()
    const {setShowGlobalNetworkError} = useNetworkError()
    const [suggestions, setSuggestions] = useState([])

    useEffect(() => {
      
        const fetchData = async () => {

            try {
            const dbCheck = await getDbCheck("carpoolEvents")
            if(dbCheck){
                
                const events = (await getAllFromStore("carpoolEvents")).map(event=>({...event.data,eventDate:new Timestamp(event.data.eventDate.seconds,event.data.eventDate.nanoseconds),ends:new Timestamp(event.data.ends.seconds,event.data.ends.nanoseconds)}))
                setEvents(events)
                
            }else{
            
                const events = await getAllCarpoolEvent();
                setEvents(events)
                await clearStore("carpoolEvents")
                events.forEach(event=>{
                    addToCache(user.id + "_"+event.id, event.ends.seconds * 1000, event, "carpoolEvents")
                })
                
                if(events.length > 0){
                    await setDbCheck("carpoolEvents")
                }
                
            }  
            } catch (error) {
                console.log(error);
            }
        };
    
        fetchData();
        getSuggestions()
      
    }, []);
    

   
    const checkIfCarpoolExist = async(event)=>{
         
         if(!isOnline){

            setShowGlobalNetworkError(true)
            return
         }
        try {
            const value = await checkForActiveEventPool(event.id,user.id)
            console.log(value)
            if(value){
                setPoolAlreadyExist(value)
            }else{
                setEventToCarpool(event)
            }
            
        
        } catch (error) {
            setError("error checking if carpool exists")
        }
       
    }
    const navigateToExistingPool = ()=> {
    
        localStorage.setItem("activePool", poolAlreadyExists.id)
        localStorage.setItem("eventId", poolAlreadyExists.eventId)
        //used this to avoid conflict between regular carpool and event carpool (this is meant for the shortcut in EventsScreen and for the search shortcut in EventCarpools )
        localStorage.setItem("shortcutEventId", poolAlreadyExists.eventId)
        localStorage.setItem("eventName", poolAlreadyExists.eventName)
        navigate("/carpool")
    }

    const search = (e) =>{
       setSearchValue(e.target.value)
        const filtered = events.filter(event => event.eventName.toLowerCase().includes(e.target.value.trim().toLowerCase()) && e.target.value.trim() !== "" )
        console.log(filtered)
        setSearchEvent(filtered)
      }


   
    const getSuggestions = async()=>{
        const eventHistory  =  (await getAllFromStore("cache")).map(event=>({...event.data,eventDate:new Timestamp(event.data.eventDate.seconds,event.data.eventDate.nanoseconds),ends:new Timestamp(event.data.ends.seconds,event.data.ends.nanoseconds)})).filter(event=>event.featureAvailable.includes("carpool"))
        console.log(eventHistory)
        setSuggestions(eventHistory)
    }
  return (
    <>
    <div className='container'>
      <h3 className="poppins-20px-500" style={{marginTop:"70px"}}>Carpool for events</h3>
      <p className='poppins-15px-20' style={{margin:"40px 0 10px"}}>What event are you pooling for?</p>
      <SearchBar onChange={search} searchValue={searchValue}/>
      {searchEvent.length > 0 && 
        <div style={{backgroundColor:"#2e2f3c",padding:"10px", width:"100%", marginTop:"10px"}}>
            <p className='inter-10px-400'>"Search"</p>
          {
            searchEvent.map(event=><p style={{margin:"10px 0"}} onClick={()=>checkIfCarpoolExist(event)}>{event.eventName}</p> )
          }
        </div>
        }
         {searchValue.length === 0 && suggestions.length >0 &&
        <div style={{backgroundColor:"#2e2f3c",padding:"10px", width:"100%", marginTop:"10px"}}>
            <p className='inter-10px-400'>"Suggestion"</p>
          {
            suggestions.map(event=><p style={{margin:"10px 0"}} onClick={()=>checkIfCarpoolExist(event)}>{event.eventName}</p> )
          }
        </div>
        }
    </div>
     
     {poolAlreadyExists && <ConfirmationBox para={`A pool Already exists for ${poolAlreadyExists.eventName} ?`} setShowConfirmationBox={setPoolAlreadyExist} cancelBtn={true} actionButtons={[{title:"Go to carpool", onClick:navigateToExistingPool}]}/>}
     {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    </>
  )
}

export default CarpoolForEvents
