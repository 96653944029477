import React from 'react'

const GreenOutLineBtn = ({name, onClick,inactive}) => {
    const styles = {
      active: { 
        backgroundColor: "#18181A",
        border: "1px solid #70D77B",
        borderRadius: "10px",
        height:"50px",
        width: "100%"
      },
      inactive:{
        backgroundColor: "#18181A",
        border: "1px solid #18181A",
        borderRadius: "10px",
        height:"50px",
        width: "100%",
        color:"#616263",
      }
    }
  return (
    <>
      {inactive && <button style={styles.inactive} onClick={onClick} disabled={inactive}>{name}</button>}
      {!inactive && <button style={styles.active} onClick={onClick}>{name}</button>}
    </>
  )
}

export default GreenOutLineBtn
