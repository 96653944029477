import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'
import CreateEventImage from '../../components/CreateEventImage'
import { useApp } from '../../contextAPI/AppContext'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import Spinner from '../../components/Spinner'
import { useAuth } from '../../contextAPI/AuthContext'
import {  saveTicket } from '../../firebase/controller/ticketController'
import { getTicketFromDb } from '../../firebase/controller/ticketController'
import ExpandTicket from './Components/ExpandTicket'
import TicketPurchaseSuccess from './Components/TicketPurchaseSuccess'
import CarpoolCTA from '../../components/CarpoolCTA'
import ConfirmationBox from "../../components/ConfirmationBox"
import Ticket from '../../components/Ticket'
import TicketDetails from '../../components/TicketDetails/TicketDetails'
import { addToCache, getFromCache } from '../../Utils/indexedDBService'
import CompleteDetails from './Components/CompleteDetails'

const style = {
    margin: {
      margin:"30px 0"
    }
}

const GetTicket = () => {

    const {setNavFunction,setNavType,setTitle} = useNav()
    const navigate = useNavigate()
    // const  [eventData,setEventData] = useState({})
    const {eventData} = useApp()
    const [isLoading, setIsLoading] = useState(true)
    const [ticket, setTicket] =  useState(null)
    const [fullCodeScreen, setFullCodeScreen] = useState(false)
    const {user} = useAuth()
    const [ticketSuccess,setTicketSuccess] = useState(false)
    const [error, setError] = useState(null)
    const [showCompleteDetails, setShowCompleteDetails] = useState(false)
    useEffect(()=>{
        if(!fullCodeScreen){
            setNavFunction(()=>()=>navigate("/event-page"))
        }
        
    },[fullCodeScreen])

    useEffect(()=>{
        
        if(!eventData){

            navigate("/notfound",{state:{eventLink:"/event-page"}})
        }
        setTitle("Ticket")
        setNavType("back")
        getTicketFromIndex()
    
    },[eventData])



    

  const getTicketFromIndex = async() =>{
    console.log(eventData,user)

    const cachedData = await getFromCache(user.id + "_" + eventData.id,"tickets")
    console.log(cachedData)
    if (cachedData) {
       setTicket(cachedData.ticket)
       setIsLoading(false)
      return
    }
     
    getTicketFromDb(user.id, localStorage.eventId,async(err,data)=>{
      if(!err && data){
          // setEventData(data[0])
          console.log(data)
          const dataString = JSON.stringify({ownerId:data[0].ownerId, eventId:data[0].eventId, ticketId:data[0].id})
          setTicket(dataString)
          setIsLoading(false)
          await addToCache(user.id + "_"+eventData.id,eventData.ends.seconds * 1000,{eventData:data[0],ticket:dataString},"tickets")
      }else if(!err && !data){
        console.log("no data")
          setIsLoading(false)
      }
          else{
          setIsLoading(false)
          console.log(err)
          setError("Error getting ticket")
      }
  })
  }

   const checkDetailsComplete = ()=>{

    if((user.username || user.displayName) && user.phoneNumber && user.email){
      getTicket()
     return        
    }
    setShowCompleteDetails(true)
   }

    const getTicket = (displayName,phoneNumber)=>{
       setIsLoading(true)

        if(eventData?.ticket.price === "free"){
           saveTicket(user.id,eventData,(user.username || user.displayName || displayName),(user.phoneNumber || phoneNumber),async(err,data)=>{

            if(!err){
                setTicket(data)
            
                await addToCache(user.id + "_"+eventData.id,eventData.ends.seconds * 1000,{eventData,ticket:data},"tickets")
                if(eventData?.featureAvailable?.includes("carpool")){
                    setTicketSuccess(true)
                    setNavFunction(()=>()=>setTicketSuccess(false) )
                }
                setIsLoading(false)
                

            }else{
                console.log(err)
                setError("Error getting ticket")
            }
           })
          
        }
    }

  if(isLoading){
    return(
        <Spinner/>
    )
  }
  
  if(fullCodeScreen){
    return(
        <ExpandTicket setFullCodeScreen={setFullCodeScreen} ticket={ticket} eventName={eventData.eventName}/>
    )
  }
  if(showCompleteDetails){
    return(
      <CompleteDetails user={user} setShowCompleteDetails={setShowCompleteDetails} getTicket={getTicket} showCompleteDetails={showCompleteDetails}/>
    )
  }

  if(ticket && ticketSuccess){
  return(
    <div className='container'>
        <TicketPurchaseSuccess name={eventData?.eventName} location={eventData?.location} ticket={ticket} setTicketSuccess={setTicketSuccess}/>
        <CarpoolCTA user={user} eventData={eventData} setIsLoading={setIsLoading} navigate={navigate}/>
    </div>
  )
  }
  return (
    <>
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    <div className='container'>
      <CreateEventImage isEditable={false} src={eventData?.coverImg || "/eventpic2.png"}/>
      {!ticket &&
        <TicketDetails eventData={eventData}/>
      }
      {
        ticket && <Ticket ticket={ticket} eventData={eventData} setFullCodeScreen={setFullCodeScreen}/>

      }
      {!ticket && <div style={{height: "5vh", width:"100%"}}>
      <LongPurpleBtn name="Get Ticket"  onClick={checkDetailsComplete}/>
      </div>
        }
    </div>
    </>
  )
}

export default GetTicket
