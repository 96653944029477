import React from 'react'


const style = {
    
    width: "inherit",
    padding: "20px",
    borderBottom: "20px" ,
    backgroundColor:" #18181A",
    borderRadius: "20px",
}



const About = ({eventDescription}) => {
  return (
    <div style={style}>
        <h2 className='poppins-normal'>About this event</h2>
        <p className='inter-light'>
            {eventDescription}
        </p>
    </div>
  )
}

export default About
