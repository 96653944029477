import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React from 'react'
import { storage } from '../firebase/config';
import { useAuth } from '../contextAPI/AuthContext';
import { updateProfilePicture } from '../firebase/controller/usersController';


const styles = {
   profile:{ width: "100px",
    height: "100px",
    backgroundColor: "#D9D9D9",
    borderRadius: "100%",
    display: "flex",
    justifyContent: "flex-end",
    position:"relative"
    },
    edit:{
        backgroundColor:"#8E69F0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px",
        height: "20px",
        borderRadius: "100%",
        position:"absolute"
    }
}



const ProfilePicture = ({edit,img,setProgress, setShowUploadProgress}) => {

    const {user, setUser} = useAuth()
 
    const storageRef = ref(storage, `profile/${user.id}/profile.jpg`)

    let uploadTask

  const handleFileInputChange = (e) => {

    
    uploadTask = uploadBytesResumable(storageRef,e.target.files[0])
    setShowUploadProgress(true)
    uploadTask.on('state_changed',(snapshot)=>{
        const progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgress(progress)
         
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'success':
               setShowUploadProgress(false)
               break;
            case 'running':
              console.log('Upload is running');
              break;
            case 'error':
              setShowUploadProgress(false)
              break;

          }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/unauthorized':
            // User doesn't have permission to access the object
            break;
          case 'storage/canceled':
            // User canceled the upload
            break;
    
          // ...
    
          case 'storage/unknown':
            // Unknown error occurred, inspect error.serverResponse
            break;
        }
      },
      () => {
        // Upload completed successfully, now we can get the download URL
       setProgress("changing profile")
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
        
          try {
            updateProfilePicture(user.id, downloadURL)
            setUser(prev=>({...prev, profile: downloadURL}))
            
             setShowUploadProgress(false)
          } catch (error) {
            console.log(error)
          }
          
        })
        ;}
      )
    
  };


  

  const handleUploadButtonClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', handleFileInputChange);
    fileInput.click();
  };
  
  

  return (
    <>
   
    <div style={styles.profile}>
       {edit && <div style={styles.edit} onClick={handleUploadButtonClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
            <g clip-path="url(#clip0_859_824)">
                <path d="M9.12892 1.28826C8.7878 0.990318 8.404 0.820068 7.93496 0.820068C7.46588 0.820068 6.9968 1.03288 6.65567 1.37338L1.6665 6.39577L1.965 6.69373L2.2635 6.99164L3.41485 8.14085L3.71334 8.43881L4.01184 8.73673L9.04367 3.71433C9.72592 3.03333 9.76859 1.9267 9.12892 1.28826Z" fill="white"/>
                <path d="M2.76121 8.58169L1.90328 7.72373L1.57644 7.3969L1.24961 7.07007C1.1679 7.19265 1.12705 7.35607 1.08619 7.47861L0.432532 9.48048C0.391676 9.6439 0.432532 9.76644 0.514236 9.88903C0.595944 9.97073 0.759361 10.0116 0.922778 9.97073L2.92463 9.3579C3.08804 9.31707 3.21061 9.23536 3.33317 9.19449L3.04719 8.90853L2.76121 8.58169Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_859_824">
                    <rect width="10" height="10" fill="white"/>
                </clipPath>
            </defs>
        </svg>
       </div>}
       <img src={img} style={{width:"100%", height:"100%", objectFit:"cover",borderRadius:"100%"}}></img>
    </div>
    </>
  )
}

export default ProfilePicture
