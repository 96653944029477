import React, { useState } from 'react'

const style = {
    checkedIn:{
        backgroundColor:"grey"
    },
    notCheckedIn:{
        backgroundColor:"#4B2CBA",
        
    },
    general:{
        width:"100%"
    }
}
const CheckInBtn = ({checkedIn, onClick}) => {


  return (
    <button style={checkedIn ? {...style.general,...style.checkedIn}:{...style.general,...style.notCheckedIn}} disabled={checkedIn} onClick={onClick}>{checkedIn ? "Checked in":"Check In"}</button>
  )
}

export default CheckInBtn
