import React, { useEffect, useRef } from 'react'
import { useNav } from '../../../contextAPI/NavContaxt'
import { QRCodeCanvas } from 'qrcode.react'
import { toPng } from 'html-to-image';

const ExpandTicket = ({setFullCodeScreen,ticket, eventName}) => {
 
    const { setNavFunction, setNavType, setShowNav} = useNav()

    const divRef = useRef()

    const handleDownloadImage = () => {
      if (divRef.current === null) {
        return;
      }
  
      toPng(divRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download =  eventName;
          link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    };

    useEffect(()=>{
        setNavFunction(()=>()=>{setFullCodeScreen(false); setNavType("back");})
        setNavType("cancel")
        setShowNav(true)
       
    },[])
  return (
    <div className='island'>

        
        <div ref={divRef} style={{padding:"10px",backgroundColor:"#ffffff", marginBottom:"20px"}}>
        <p style={{color:"#000"}}>{eventName}</p>
        <QRCodeCanvas value={ticket} size={300}/>
        </div>
          <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleDownloadImage}>
          <path d="M12 7L12 14M12 14L15 11M12 14L9 11" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16 17H12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/>
          <path d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z" stroke="#fff" stroke-width="1.5"/>
          </svg>
    </div>
    
  )
}

export default ExpandTicket
