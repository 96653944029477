import { collection, doc, getDoc, getDocs, increment, limit, orderBy, query, runTransaction, set, setDoc, updateDoc, where, writeBatch} from 'firebase/firestore'
import { db } from '../config'
import { usersRef } from './usersController'
import { generateRandomString } from '../../Utils/util'



const eventsRef = collection(db,"events")


export const createEventWishBlends = async(eventId, wishBlendList,callback) =>{
   
    console.log(eventId)
     console.log(wishBlendList)
    const eventRef = doc(eventsRef,eventId)
    const wishBlendsRef = collection(eventRef,"wishBlend")

    const batch = writeBatch(db)

    wishBlendList.forEach(wishBlend => {

        const wishBlendRef = doc(wishBlendsRef, wishBlend.id)
       
         batch.set(wishBlendRef,wishBlend) 
    });

    try {
    
         await batch.commit();
         callback(false)
       
        
    } catch (error) {
        callback(error)
    }
    
}

export const getEventWishList = async(eventId,callback,filter)=>{

    const eventRef = doc(eventsRef,eventId)
    const wishBlendsRef = collection(eventRef,"wishBlend")
    const q = query(wishBlendsRef,where("status", "==", filter),orderBy("itemName"))
    
   try {
   const wishBlendListDoc= filter ?  await getDocs(q) : await getDocs(wishBlendsRef)
   const wishBlendData = []
    wishBlendListDoc.forEach(data=>wishBlendData.push(data.data()))
   callback(false,wishBlendData)
   } catch (error) {
     callback(error)
   }
   
    
}

export const addToProgressItem = async(eventId,wishBlendList,userId,username,phoneNumber,callback)=>{

    const eventRef = doc(eventsRef,eventId)
    const wishBlendsRef = collection(eventRef,"wishBlend")
    const alternateUserId = generateRandomString(10)
    const batch = writeBatch(db)
     console.log(username)
    wishBlendList.forEach(wishBlend => {

        const wishBlendRef = doc(wishBlendsRef, wishBlend.id)
       
         batch.update(wishBlendRef,{promisedCount:increment(wishBlend.count),[`committersName.${userId || alternateUserId }`]:username, [`committersCount.${userId || alternateUserId}`]:increment(wishBlend.count)} )

        //  const committersRef = collection(wishBlendRef,"commiters")
        //  const committerRef = doc(committersRef,userId)
         
        //  const commiterData = {
        //     promisedCount: increment(wishBlend.count),
        //  }

        //  if(username){
        //     commiterData.username = username
        //  }
        //  if(phoneNumber){
        //     commiterData.phoneNumber = phoneNumber
        //  }
        //  batch.set(committerRef, commiterData,{merge:true})
         
    });

    try {
        await batch.commit();
        callback(false)
    } catch (error) {
        callback(error)
    }
}

export const getTickets= async(eventId,callback)=>{
    
    const eventRef = doc(eventsRef,eventId)
    const attendeesRef = collection(eventRef,"tickets")

    try {
       const attendeesDocs =  await getDocs(attendeesRef)
       
       const tickets =[]
       attendeesDocs.forEach(doc=>{
        tickets.push({id:doc.id,...doc.data()})
       })
       callback(false,tickets)
    } catch (error) {
        callback(error)
    }
}



export const saveTicket = async(userId,eventId,userName,phoneNumber,callback)=>{

   
    

    //save to event

    const eventRef = doc(eventsRef,eventId)
    const ticketsRef = collection(eventRef,"tickets")
    const ticketRef = doc(ticketsRef)


    const data = {
        ticketId: ticketRef.id,
        ownerId: userId,
    }
    const dataString = JSON.stringify(data)
    const userRef = doc(usersRef,userId)
    const userEventsRef = collection(userRef, "userevents")
    const userEventRef = doc(userEventsRef,eventId)

    console.log(userId, userName, dataString)
    
    const ticketDetail = {
        userId,
        userName,
        status:false
    }

    if(phoneNumber){
        ticketDetail.phoneNumber = phoneNumber
    }
    try {
        await runTransaction(db,async(transaction)=>{
            transaction.set(ticketRef,ticketDetail)
            console.log()
            transaction.set(userEventRef,{ticket:dataString})
        })
        callback(false,dataString)
    } catch (error) {
        callback(error)
    }
    
    
    
}


export const scanTicket = async(ticketId,callback) => {

    console.log(ticketId)
 
    try {
    const ticketsRef = collection(db,"tickets")
    const ticketRef = doc(ticketsRef,ticketId)
   
    
  
        const ticket = await getDoc(ticketRef)
       
        if(ticket.exists()){
            if(ticket.data().status === true){
                await updateDoc(ticketRef,{scannedAt: new Date()})
                callback(false,ticket.data())
            }else if(ticket.data().status === false){
                await updateDoc(ticketRef,{status:true, scannedAt: new Date()})
                callback(false,ticket.data())
            }else{
                callback(false,null)
            }
        }else{
            callback(false,null)
        }
    } catch (error) {
        console.log(error)
        callback(error)
    }
 
}

export const checkEventExists = async(eventId,callback)=>{
    
    const eventRef = doc(eventsRef,eventId)

    try {
       const eventDoc= await getDoc(eventRef)
       if(eventDoc.exists()){
            callback(null,eventDoc.data())
       }else{
            callback(null,null)
       }
    } catch (error) {
        callback(error)
    }
}

export const submitRating = async(eventId,userId,rating, username,profile,callback)=>{

    console.log(eventId,userId,rating)
    const eventRef = doc(eventsRef,eventId)
    const ratingsRef = collection(eventRef, "ratings")
    const ratingRef = doc(ratingsRef,userId)
  
    const ratingData = {
        ...rating,
        username
    }
    if(profile){
        ratingData.profile = profile
    }
     
    try {
        await setDoc(ratingRef, ratingData,{merge:true})
        callback(null)
    } catch (error) {
        callback(error)
    }

}

export const getAllCarpoolEvent = async ()=>{

   try {
    const currentTime = new Date();

    currentTime.setHours(0, 0, 0, 0);
    const sevenDaysFromCurrentTime =  new Date()
    sevenDaysFromCurrentTime.setHours(0,0,0,0)
    const currentDate = sevenDaysFromCurrentTime.getDate()
    sevenDaysFromCurrentTime.setDate(currentDate + 8)
    const q = query(eventsRef, 
        where("featureAvailable", "array-contains", "carpool"),
        where("ends", ">=", currentTime),
        where("eventDate", "<=", sevenDaysFromCurrentTime )
    );
    const querySnapshot = await getDocs(q);
    const events = [];
    querySnapshot.forEach((doc) => {
        events.push({ id: doc.id, ...doc.data() });
    });
    return events;
   } catch (error) {
     throw error
   }
}


export const getRatings = async(eventId)=>{
    try {
        const eventRef = doc(eventsRef, eventId)
        const ratingsRef = collection(eventRef,"ratings")

        const q = query(ratingsRef, limit(10))

        const ratingDocs = await getDocs(q)

        const ratings =ratingDocs.docs.map(doc=>({id:doc.id, ...doc.data()}))

        return ratings

    } catch (error) {
        throw error
    }
}