import React from 'react'
import FormError from '../FormError'

const styles = {

   formInput:{
     backgroundColor: "#202024",
     border: "none",
     padding: "0",
     height: "fit-content",
     width: "100%",
     resize: "none",
     outline: "none"
   }
}

const MapForm = ({title, placeHolder,  error, value, onChange}) => {
  return (
    <div style={{paddingBottom: "10px"}}>
        <h4 className='inter-400'>{title}</h4>
        <input type='map' style={styles.formInput} className='poppins-20px-500' placeholder={placeHolder} value={value} onChange={(e)=>onChange(prev=>({...prev,eventLoc:e.target.value}))} />
        <FormError error={error}/>
    </div>
  )
}

export default MapForm
