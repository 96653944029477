import React from 'react';

const WavingHandSVG = ({hailerCount}) => (
  <div style={{display:'flex', justifyContent:"center", alignItems:"center", padding:"1px 3px", borderRadius:"5px"}}>
   <img src='/waving.svg' style={{height:"15px", width:"15px"}}></img>
   <p style={{color:"#8d68f0", fontSize:"13px", marginLeft:"2px"}}>{hailerCount}</p>
   </div>
);

export default WavingHandSVG;
