import React from 'react'
import FormError from '../FormError'

const TimeForm = ({title, placeHolder, value,onChange,type,error}) => {
  const styles = {
   padding: {
      paddingLeft:"10px",
      paddingBottom: "5px"
    },
    formInput:{
      backgroundColor: "#202024",
      border: "none",
      padding: "0",
      height: "fit-content",
      width: "100%",
      resize: "none",
      outline: "none"
    }
}
  return (
    <div style={styles.padding}>
        <h4 className='inter-400'>{title}</h4>
        <input type='time' style={styles.formInput} className='poppins-20px-500' placeholder={placeHolder} onChange={(e)=>onChange(prev=>({...prev,[type]:e.target.value}))} value={value}/>
         <FormError error={error}/>
    </div>
  )
}

export default TimeForm
