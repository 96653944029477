import React, { useState } from 'react'
import  {ClipLoader} from "react-spinners"


const Spinner = ({msg}) => {

  
  const [longLoading, setLongLoading] = useState("")
 


  useState(()=>{
    
  
     const interval = setInterval(() => {
        setLongLoading("taking too long, wait a moment or swipe down to refresh")
     }, 45000);

     return()=>{ 
      
      clearInterval(interval)
    }
  },[])

 
  return (
   
    <div className='island'>
       <ClipLoader size={150} color={'#4B2CBA'}/>

       <p style={{color: "#FFFFFF",textAlign:"center"}}>{msg}</p>
       <p style={{color: "#FFFFFF",textAlign:"center"}}>{longLoading}</p>

    </div>
   
  )
}

export default Spinner
