import React, { useEffect, useState } from 'react'
import { getLatestTicketController } from '../../firebase/controller/ticketController'
import { useAuth } from '../../contextAPI/AuthContext'
import ConfirmationBox from '../../components/ConfirmationBox'
import Ticket from '../../components/Ticket'
import BottomNav from '../../components/BottomNav/BottomNavEvent'
import CreateEventImage from '../../components/CreateEventImage'
import { useNav } from '../../contextAPI/NavContaxt'
import ExpandTicket from '../Ticket/Components/ExpandTicket'
import Spinner from '../../components/Spinner'
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../Utils/util'
import { useNavigate } from 'react-router-dom'
import ShowTickets from './ShowTickets'
import { addToCache, getClosestExpiration, getDbCheck, setDbCheck } from '../../Utils/indexedDBService'
import { useNetworkError } from '../../contextAPI/NetworkErrorContext'
import useNetworkStatus from '../../CustomHooks/useNetworkStatus'

const TicketScreen = () => {

    const {user} = useAuth()
    const [error, setError] = useState(null)
    const [ticket, setTicket] = useState(null)
    const [eventData, setEventData] = useState(null)
    const [fullCodeScreen,setFullCodeScreen] = useState(false)
    const {setTitle, setShowNav, setNavFunction} = useNav()
    const [isLoading, setIsloading] = useState(true)
    const [showOtherTickets, setShowOtherTickets] = useState(null)
    const [startX, setStartX] = useState(null);
    const [endX, setEndX] = useState(null);
    const navigate = useNavigate()
    const {setShowGlobalNetworkError} = useNetworkError()
    const isOnline = useNetworkStatus()

    useEffect(()=>{
      setTitle("tickets")
     getLatestTicketFromIndex()
     
    },[])

    const getLatestTicket = ()=>{

      if(!isOnline){
        setShowGlobalNetworkError(true)
    
        return
        
      }

    getLatestTicketController(user.id,async(err,data)=>{
        console.log("here")
        console.log(err)
        if(!err && data){
          setEventData(data[0])
          console.log(data[0])
          const dataString =  JSON.stringify({eventId: data[0].eventId, ownerId: data[0].ownerId, ticketId:data[0].id})
          setTicket(dataString)
        
          // data[0].ends.seconds *1000
          await addToCache(user.id + "_" + data[0].eventId, Date.now(), {ticket:dataString, eventData:data[0]},"tickets" )

   
          if(data[0]){

            await setDbCheck("latestTicket")
          }
          
        }else if(!err && !data){
         
          setTicket(null)
        }else{
          console.log(err)
          
          setError("Error getting latest ticket")
        }

        setIsloading(false)
        
    })
    }

    const getLatestTicketFromIndex = async()=>{
       
     const dbCheck = await getDbCheck("latestTicket")
     console.log(dbCheck)
      if(dbCheck){
         console.log("this")
       const ticketData = await getClosestExpiration("tickets")
       console.log(ticketData)
       if(ticketData){
        setEventData(ticketData.eventData)
       setTicket(ticketData.ticket)
       }
       
       setIsloading(false)
        return
      }

      getLatestTicket()
    }

    

    useEffect(()=>{
        if(!fullCodeScreen){
            setShowNav(false)
        }
    },[fullCodeScreen])

    
  

    if(fullCodeScreen){
      return(
          <ExpandTicket setFullCodeScreen={setFullCodeScreen} ticket={ticket} eventName={eventData.eventName}/>
      )
    }
    
  

    if(showOtherTickets){
        
      return(
        <ShowTickets  setShowOtherTickets={setShowOtherTickets} />
      )
    }


  return (
    <>
    
      {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} errorType={true} />}
      <div 
      className='container' 
      style={{marginBottom:"60px"}}
      onTouchStart={(e)=>{handleTouchStart(e,setStartX)}}
      onTouchMove={(e)=>{handleTouchMove(e,setEndX)}}
    onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,(direction)=>{
      if(direction ==="left"){
        navigate("/community")
      }else{
        navigate("/")
      }
    })}
      >
      {isLoading &&<Spinner/>}
        {
        ticket && <Ticket ticket={ticket} eventData={eventData} setFullCodeScreen={setFullCodeScreen}/>

       }
       {!isLoading && ticket   && <div style={{padding:"20px 0", alignSelf:"end"}} onClick={()=>setShowOtherTickets(true)}>
         <div className='poppins-10px-200' style={{display:"flex", alignItems:"center", padding:"10px", backgroundColor:"#18181A", borderRadius:"20px"}}>
          <p>
            Other tickets
            

          </p>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.30806 6.2248C8.55216 5.98073 8.94783 5.98073 9.19191 6.2248L12.5252 9.55816C12.7693 9.80225 12.7693 10.1979 12.5252 10.442L9.19191 13.7753C8.94783 14.0194 8.55216 14.0194 8.30806 13.7753C8.06398 13.5312 8.06398 13.1356 8.30806 12.8915L11.1994 10.0001L8.30806 7.10869C8.06398 6.86461 8.06398 6.46888 8.30806 6.2248Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M4.34419 5.95649C4.5883 5.71242 4.98396 5.71242 5.22805 5.95649L8.56138 9.28985C8.80546 9.53394 8.80546 9.9296 8.56138 10.1737L5.22805 13.507C4.98396 13.7511 4.5883 13.7511 4.34419 13.507C4.10011 13.2629 4.10011 12.8673 4.34419 12.6232L7.23555 9.73177L4.34419 6.84038C4.10011 6.5963 4.10011 6.20057 4.34419 5.95649Z" fill="white"/>
            </svg>
         </div>
       </div>}
       {
        !isLoading && !ticket && <div className='island'>
          <p>No tickets yet</p>
        </div>
       }
      </div>
      <BottomNav selectedIndex={1}/>
    </>
    
  )
}

export default TicketScreen
