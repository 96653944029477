import React from 'react'
import EventCal from './Components/EventCal';
import EventDetails from './Components/EventDetails';
import {motion} from "framer-motion"


const styles = {
    eventImage:{
        /* height: 65%; */
        borderRadius: "20px",
       
      },
       eventImageImg:{
        width: "100%",
        height: "100%",
        borderRadius: "20px"
      },
      eventCard:{
        backgroundColor: "#18181A",
        width: "100%",
      
        margin: "10px 0",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }
}

const EventCard = ({event, goToEvent,id,lastEventElementRef}) => {
 
    const timestamp = event.eventDate;
    const date = new Date(timestamp.seconds * 1000);
    const dayOfWeek = date.toLocaleString('en-US', { weekday: 'long' });
    const month = date.toLocaleString('en-US', { month: 'long' });
    const shortMonth = date.toLocaleString('en-US', { month: 'short' });
    const day = date.toLocaleString('en-US', { day: 'numeric' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });
    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    return (
       lastEventElementRef ? <motion.div 
        style={styles.eventCard} 
        id={event.id} 
        ref={lastEventElementRef}
        onClick={goToEvent} 
        key={id}
        initial={{scale:0.9}}
        animate={{scale:1}}
        transition={{type:"spring", damping: 25, stiffness:200}}
        >
            <div style={styles.eventImage}>
              
                <img data-src={event.coverImg||"/concert.png"} style={styles.eventImageImg} ></img>
             
                {/* <Img imagePath={event.coverImg||"/concert.png"}/> */}
            </div> 
            <EventCal day={day} shortMonth={shortMonth}/>
           <EventDetails day={day} dayOfWeek={dayOfWeek} month={month} year={year} eventName={event.eventName} location={event.location} time={time}/>
        
        </motion.div>
        :
        <motion.div 
        style={styles.eventCard} 
        id={event.id} 
        onClick={goToEvent} 
        key={id}
        initial={{scale:0.9}}
        animate={{scale:1}}
        transition={{type:"spring", damping: 25, stiffness:200}}
        >
            <div style={styles.eventImage}>
              
                <img data-src={event.coverImg||"/concert.png"} style={styles.eventImageImg} ></img>
             
                {/* <Img imagePath={event.coverImg||"/concert.png"}/> */}
            </div> 
            <EventCal day={day} shortMonth={shortMonth}/>
           <EventDetails day={day} dayOfWeek={dayOfWeek} month={month} year={year} eventName={event.eventName} location={event.location} time={time}/>
        
        </motion.div>
  )
}

export default EventCard
