import React from 'react'
import {motion} from "framer-motion"

const NoNetwork = () => {
  return (
    <motion.div 
    style={{width:"100%", position:"absolute", padding:"50px"}}
    initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
    >
        <div style={{padding:"2px", backgroundColor:"#2c2f3c", textAlign:"center", color:"#fff"}}>
          <p>No Network</p>
        </div>
      
    </motion.div>
  )
}

export default NoNetwork
