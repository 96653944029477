import React from 'react'

const styles = {
    chatContainer: {
        width:"100%",
        padding: "10px 30px",
        display:"flex",
        
    },
    chat:{
        backgroundColor: "#2D2F3C",
        minHeight: "50px",
        borderRadius: "10px",
        color: "#FFFFFF",
        padding: "5px 15px"
        
    }
}

const YourChat = ({msg}) => {
    return (
        <div style={styles.chatContainer}>
            <div style={styles.chat}>
                <h3 style={{color:"#8E69F0", marginBottom:"8px"}} className='poppins-12px-400'>{"@"+msg.sender.name || "unknown"}</h3>
                     <p className='popping-300-normal'>{msg.chat}</p>
            </div>
        </div>
      )
}

export default YourChat