import React, { useEffect, useState } from 'react'
import CreateEventPool from './CreateEventPool'
import CreateDefaultPool from './CreateDefaultPool'
import PoolTypeSwitch from './components/PoolTypeSwitch'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../Utils/util'

const CreatePool = () => {

    const [poolSwitch, setPoolSwitch] = useState("For event")
    const {setNavFunction, setShowNav, setNavType, setTitle} = useNav()
    const navigate = useNavigate()
    const [startX, setStartX] = useState(null);
    const [endX, setEndX] = useState(null);

    useEffect(()=>{
      setTitle("Carpool")
      setNavFunction(()=>()=> navigate("/carpools"))
      setShowNav(true)
      setNavType("back")
     
    
    },[])

  return (
    <div className='container'
    onTouchStart={(e)=>handleTouchStart(e,setStartX)}
         onTouchMove={(e)=>handleTouchMove(e,setEndX)}
         onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,(direction)=>{
          if(direction == "left"){
            setPoolSwitch("Regular")
          }else{
            setPoolSwitch("For event")
          }
         })}
    >
        <PoolTypeSwitch poolSwitch={poolSwitch} setPoolSwitch={setPoolSwitch} text1="For event" text2="Regular"/>
      {poolSwitch === "For event" && <CreateEventPool />}
      {poolSwitch === "Regular" && <CreateDefaultPool />}
    </div>
  )
}

export default CreatePool
