import React, { useEffect, useState } from 'react'
import { getTicketsFromDb } from '../../firebase/controller/ticketController'
import TicketCard from './TicketCard'
import { useNav } from '../../contextAPI/NavContaxt'
import { useAuth } from '../../contextAPI/AuthContext'
import ExpandTicket from '../Ticket/Components/ExpandTicket'
import { addToCache, getAllFromStore, getDbCheck, setDbCheck } from '../../Utils/indexedDBService'
import ConfirmationBox from '../../components/ConfirmationBox'
import useNetworkStatus from '../../CustomHooks/useNetworkStatus'
import { useNetworkError } from '../../contextAPI/NetworkErrorContext'
import NetworkError from '../../components/NetworkError'

const ShowTickets = ({setShowOtherTickets}) => {

    const [tickets, setTickets] = useState([])
    const {setShowNav, setNavType, setNavFunction} = useNav()
    const [showTicketDetails, setShowTicketDetails] = useState(false)
    const [error, setError] = useState(null)
    const {user} = useAuth()
    const isOnline = useNetworkStatus()
    const {hasNetworkError, setHasNetworkError} = useNetworkError()
    useEffect(()=>{
        getTickets()
    },[])

    // useEffect(()=>{

       
    //       setShowNav(true)
    //       setNavType("cancel")
    //       setNavFunction(()=>()=> {setShowOtherTickets(false);setShowNav(false);setNavType("back")})
        
    // },[])


    const getTickets = async()=>{
        try {
           
            const dbCheck = await getDbCheck("otherTickets")

       if(dbCheck){
        console.log(dbCheck)
         const tickets =  await getAllFromStore("tickets")
         const ticketsData = tickets.map(ticket=>ticket.data.eventData)
         console.log(ticketsData)
         if(tickets){
            setTickets(ticketsData)
         }
         
        return
       }
       if(!isOnline){
        throw new Error('Network offline')
    }else{
        setHasNetworkError(false)
    }
        getTicketsFromDb(user.id, async(err,data)=>{
            if(!err && data){
                
                setTickets(data)
                
                data.forEach(element => {
                    const dataString =  JSON.stringify({eventId: element.eventId, ownerId: element.ownerId, ticketId: element.id })
                    addToCache(user.id + "_" +element.eventId,element.ends.seconds *1000,{ticket:dataString,eventData:element},"tickets")
                });
                await setDbCheck("otherTickets")
            }else if(!err && !data){
               setTickets([])
            }else{
                setError("Error getting other tickets")
                console.log(err)
            }
        })
        } catch (error) {
            if(error.message === "Network offline"){
                setHasNetworkError(true)
            }
        }
       
    }


    
    useEffect(()=>{
          
           if(!showTicketDetails){
            setShowNav(true)
            setNavType("cancel")
            setNavFunction(()=>()=> {setShowOtherTickets(false);setShowNav(false);setNavType("back")})
           }
            
        
    },[showTicketDetails])

    // useEffect(()=>{
    //     setShowNav(true)
    //     setNavType("cancel")
    //     setNavFunction(()=>{
    //         setShowOtherTickets(false)
    //         setShowNav(true)
    //     })
    // },[])
  
   
    

  

    
    if(showTicketDetails){

        const ticket =  JSON.stringify({eventId: showTicketDetails.eventId, ownerId: showTicketDetails.ownerId,ticketId: showTicketDetails.id})
        console.log(showTicketDetails.eventId,showTicketDetails.ownerId)
        return(
            <ExpandTicket setFullCodeScreen={setShowTicketDetails} ticket={ticket} eventName={showTicketDetails.eventName}/>

        )
    }
  return (
    <>

    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} errorType={true}/>}
    <div className='container'>
       {tickets.map(ticket=><TicketCard event={ticket} onClick={()=>setShowTicketDetails(ticket)}/>)}
    </div>
    {hasNetworkError && <NetworkError/>}
    </>
  )
}

export default ShowTickets
