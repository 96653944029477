import React from 'react'

const LAyOutSample = ({children}) => {
  return (
    <div  style={{width:"100vw", height:"100vh", position:"absolute", backgroundColor: "#2c234e", zIndex:"888",display:"flex",flexDirection:"column",justifyContent:"center", alignItems:"center"}}>
      {children}
    </div>
  )
}

// ( !showChangeLoc &&
//     <div className='survey'>
//       <h3>open ride for return journey?</h3>
//       <div onClick={()=>updateReturnTrip("same")}>Yes, same destination ({poolData.poolerLoc})</div>
//       <div onClick={()=>updateReturnTrip("change")}>Yes, but change location</div>
//       <div onClick={()=>cancelPool()}>Cancel ride</div>
//     </div>
//     ||
//     <div className='survey'>
//       <h3>New location?</h3>
//       <input defaultValue={poolData.poolerLoc} ref={changeLocationRef}/>
//       <button onClick={()=>updateReturnTrip("change")}>change location</button>
//       <button onClick={()=>setShowChangeLoc(false)}>cancel</button>
//     </div>
//    )

// (eventData.ends.seconds - (Math.floor(Date.now() / 1000))) <= 1800 && !poolData.returnTripSurvey 

export default LAyOutSample
