import React from 'react'

const Telegram = ({title,url}) => {

    const modifiedLink = url.replace(/ /g, "%20")
    
      const shareOnTelegram = () => {
        window.open(`https://telegram.me/share/url?url=${encodeURIComponent(modifiedLink)}&text=${encodeURIComponent(title)}`);
      };
    
      
  return (
    <div onClick={shareOnTelegram}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#4285F4"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.483 27.79L26.4837 27.7882L26.5011 27.7447L29.4999 12.6257V12.5766C29.4999 12.1999 29.3607 11.871 29.0578 11.6737C28.7927 11.5011 28.488 11.4888 28.2743 11.505C28.0482 11.5222 27.8351 11.5786 27.6876 11.6255C27.6117 11.6497 27.5482 11.673 27.5027 11.6906C27.4799 11.6994 27.4614 11.7069 27.448 11.7125L27.4348 11.7181L10.715 18.277L10.7104 18.2786C10.7014 18.2819 10.6895 18.2864 10.675 18.292C10.6463 18.3032 10.6071 18.3192 10.5612 18.3401C10.4709 18.381 10.3461 18.4441 10.2196 18.5307C10.0048 18.6779 9.59566 19.0262 9.66474 19.5784C9.72196 20.0358 10.0373 20.3263 10.2498 20.4767C10.3638 20.5574 10.4728 20.6155 10.5526 20.6535C10.5931 20.6728 10.6274 20.6875 10.653 20.6979C10.6659 20.7031 10.6766 20.7073 10.6849 20.7105L10.6955 20.7144L10.7023 20.717L13.6275 21.7018C13.6177 21.8853 13.6359 22.0724 13.6845 22.2571L15.1498 27.8155C15.3236 28.4745 15.9198 28.9336 16.6014 28.9331C17.2123 28.9327 17.7541 28.5631 17.9843 28.0117L20.2719 25.5656L24.2009 28.5778L24.2568 28.6022C24.6137 28.758 24.9473 28.8073 25.2522 28.7657C25.5567 28.7241 25.7986 28.5964 25.9804 28.451C26.1593 28.3079 26.2818 28.1464 26.3589 28.0248C26.3981 27.963 26.4273 27.9087 26.4475 27.8678C26.4577 27.8473 26.4657 27.83 26.4717 27.8164L26.4793 27.799L26.482 27.7926L26.483 27.79ZM15.1349 21.8747C15.1016 21.7484 15.1539 21.615 15.2642 21.545L25.1862 15.2441C25.1862 15.2441 25.7698 14.8898 25.7489 15.2441C25.7489 15.2441 25.8531 15.3066 25.5403 15.5984C25.2437 15.8754 18.4687 22.4165 17.7832 23.0784C17.7436 23.1166 17.72 23.1604 17.706 23.2136L16.6002 27.4331L15.1349 21.8747Z" fill="white"/>
    </svg>
    </div>
  )
}

export default Telegram
