
import React from 'react'
const styles = {
    div:{
        minHeight: "50px",
        width: "100%",
        padding : "10px 20px",
        backgroundColor: "#18181A",
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px",
        alignItems: "center"
    },
    name:{
        textTransform : "capitalize",
       
    },
    selectedDiv:{
        width: '15px', height:'15px',borderRadius:'100%',backgroundColor:'#2D2F3C'
    },
   
}
const Items = ({name,count,onClick,isSelected}) => {

   
  return (
    <div style={styles.div} onClick={onClick}>
      <div style={{width:"100%",height: "100%",display:'flex',flexDirection:"column",justifyContent:'center'}}>
      <h1 className='poppins-15px-400' style={styles.name}>{name}</h1>
      <p className='poppins-10px-300'>{count}</p>
      </div>
   
      {isSelected  && <div style={{...styles.selectedDiv, backgroundColor:"#20C963"}}></div>}
      {isSelected === false && <div style={styles.selectedDiv}></div>}
    
    </div>
  )
}

export default Items
