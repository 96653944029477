import React, { useEffect, useState } from 'react'
import IncrementBox2 from './IncrementBox2'
import { useApp } from '../../../contextAPI/AppContext'
const styles = {
    div:{
        width: "100%",
        backgroundColor: "#18181A",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        
    },
    div2:{
        minHeight: "50px",
        width: "100%",
        padding : "15px 20px",
        backgroundColor: "#18181A",
        margin: "10px 0",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "10px",
        alignItems: "center",
        flexDirection: "column",
    },
    name:{
        textTransform : "capitalize"
    },
    selectedDiv:{
        width: '15px', height:'15px',borderRadius:'100%',backgroundColor:'#2D2F3C',marginLeft:"5px"
    },
    progressBarContainer:{
        width: "100%",
        height: "5px",
        borderRadius: "3px",
        backgroundColor:"#D9D9D9",
        display:"flex",

    },
    progressBar:{
      
        height: "5px",
        borderRadius: "3px 0px 0px 3px",
        backgroundColor:"#8E69F0", 
        transition: 'width 0.3s ease'  
    },
    addedProgress:{
        height: "5px",
        borderRadius: "0px 3px 3px 0px",
        backgroundColor:"#B7A4E9", 
        transition: 'width 0.3s ease' 
    }
   
}
const ProgressItem = ({name,totalItemsCount,promisedCount,onClick,editItem, setItem,id,setDud,viewMode, committersCount ,committersName}) => {
    const {itemCount} = useApp()
    const [count, setCount] = useState(itemCount[id] || 0)
    const [committers,setCommitters] = useState([])
    const [showContributors,setShowContributors] = useState(false)

    const onCount = (ops)=>{
        setDud(dud=>++dud)
        setCount(ops)
    }

    useEffect(()=>{
        console.log(committersName, committersCount);
        const committers = []
        for (const key in committersName) {
            if (Object.hasOwnProperty.call(committersName, key)) {
                const element = committersName[key];
                const committer = {}
                committer.name = element
                committer.id = key
                committer.count = committersCount[key]
                console.log(committer)
                committers.push(committer)
            }
        }
        setCommitters(committers)
    },[])
   
  return (
     <div style={styles.div2}>
        <div style={styles.div} onClick={onClick}>
          {/* <div style={{width:"80%",height: "100%",display:'flex',flexDirection:"column",justifyContent:'center'}}> */}
          <h1 className='poppins-15px-400' style={styles.name}>{name}</h1>
          {/* </div> */}
          <div style={{minWidth:"30%",height: "100%",display:'flex',justifyContent:'flex-end',alignItems: "center"}}>
          <p className='poppins-10px-300'>{promisedCount + count +" of " +totalItemsCount}</p>
          <div style={promisedCount >= totalItemsCount? {...styles.selectedDiv, backgroundColor:"#20C963"}:styles.selectedDiv}></div>
          </div>
        </div>
        <div style={styles.progressBarContainer}>
         <div style={{...styles.progressBar, width:`${(((promisedCount)/totalItemsCount)*100)}% ` }}></div>
         {promisedCount === 0 && <div style={{...styles.addedProgress, width:`${(((count)/totalItemsCount)*100)}% `,borderRadius: "3px 0px 0px 3px"}}></div> }
         {promisedCount>0  && <div style={{...styles.addedProgress, width:`${(((count)/totalItemsCount)*100)}% `}}></div>}
        </div>
        {editItem === id && !viewMode && <IncrementBox2 setItem={setItem} count={count} setCount={onCount} setShowContributors={setShowContributors} promisedCount={promisedCount}/>}
        {editItem === id && !viewMode && showContributors && <div style={{width:"100%", paddingTop:"10px", borderTop:"1px solid #ffff", marginTop:"20px"}}>
          
          {committers.map((committer=><p style={{margin:"5px 0"}} className='inter-10px-400' key={committer.id}>{committer.name}<span>({committer.count})</span></p>))}
        </div>}
     </div>
  )
}

export default ProgressItem
