import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { storage } from '../config';


export const handleFileInputChange = (file,imageId) => {

    return new Promise((resolve, reject) => {

     if(!file){
        resolve(null)
        return
     }   

    const storageRef = ref(storage, `events/${imageId}.jpg`)

   const uploadTask = uploadBytesResumable(storageRef,file)

    uploadTask.on('state_changed',(snapshot)=>{
    
        switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'success':
           
               break;
            case 'running':
              console.log('Upload is running');
              break;
            case 'error':
             
              break;

          }
      },
      (error) => {

        reject(error)
        
      },
      () => {
     
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log('File available at', downloadURL);
           resolve(downloadURL)
          
        })
        ;}
      )
    })
    
  };