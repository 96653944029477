
import { collection, doc, getDocs, limit, orderBy, query, serverTimestamp, setDoc, updateDoc, where } from "firebase/firestore"
import { db } from "../config"
import { firebaseTimeStampFormatter } from "../../screens/CreateEvent/utills";
const ticketsRef = collection(db,"tickets")

const currentDate = new Date();
currentDate.setHours(0, 0, 0, 0);


export const saveTicket = async(userId,eventData,userName,phoneNumber,callback)=>{

   
    const {id, eventName, location, eventPrice,eventDate,coverImg, ends, eventVisibility} = eventData

    //save to event

  
   
    const ticketRef = doc(ticketsRef)


    const data = {
      
        ownerId: userId,
        eventId: id,
        eventName,
        eventPrice: eventPrice || "free",
        eventLocation: location , 
        eventDate,
        status: eventVisibility === "public" ?false:"requested",
        userName,
        timePurchased: new serverTimestamp(),
        coverImg,
        ends

    }

    const dataString = JSON.stringify({ticketId: ticketRef.id, ownerId: data.ownerId, eventId: data.eventId})
    
    if(phoneNumber){
        data.phoneNumber = phoneNumber
    }
    try {

        await setDoc(ticketRef, data)
        callback(false,dataString)

    } catch (error) {
        callback(error)
    }
    
    
    
}


export const getTicketFromDb  = async(userId,eventId,callback)=>{
     
   
    const q = query(ticketsRef, where("eventId","==",eventId), where("ownerId","==",userId), where("ends",">=", currentDate))
    

    try {
       const eventTicketDocs = await getDocs(q)
       if(!eventTicketDocs.empty){

        const eventTickets = eventTicketDocs.docs.map(ticket=>({id:ticket.id, ...ticket.data()}))
        callback(null,eventTickets)
       }else{
        callback(null,null)
       }
    } catch (error) {
        callback(error) 
    }
    
}
export const getTickets  = async(eventId,callback)=>{
     

    const currentDate = new Date()
    
    currentDate.setHours(0,0,0,0)
   
    try {
        const q = query(ticketsRef, where("eventId","==",eventId), where("ends",">",currentDate))
        const eventTicketDocs = await getDocs(q)
        if(!eventTicketDocs.empty){

        const eventTickets = eventTicketDocs.docs.map(ticket=>({id:ticket.id, ...ticket.data()}))
        callback(null,eventTickets)
       }else{
        callback(null,null)
       }
    } catch (error) {
        console.log(error)
        callback(error) 
    }
    
}

export const getTicketsByDate = async(eventId,date,callback)=>{

   const upperBound = firebaseTimeStampFormatter(date,"00:00")
   const lowerBound =  new Date(upperBound) 
   lowerBound.setDate(lowerBound.getDate()+1)
   console.log(upperBound,lowerBound)
  
    const q = query(ticketsRef, where("eventId","==",eventId), where("ends",">=",upperBound), where("ends","<=", lowerBound))
    try {
        const eventTicketDocs = await getDocs(q)
        console.log(!eventTicketDocs.empty)
        if(!eventTicketDocs.empty){
         
         const eventTickets = eventTicketDocs.docs.map(ticket=>({id:ticket.id, ...ticket.data()}))
         console.log(eventTickets)
         callback(null,eventTickets)
        }else{
         callback(null,null)
        }
     } catch (error) {
        console.log(error)
         callback(error) 
     }
}



export const getLatestTicketController = async(userId, callback)=>{

    const q = query(ticketsRef, where("ownerId","==",userId),  where("ends" ,">=", currentDate ),limit(1), orderBy("timePurchased"))

    try {
        const eventTicketDocs = await getDocs(q)
        if(!eventTicketDocs.empty){
 
         const eventTickets = eventTicketDocs.docs.map(ticket=>({id:ticket.id, ...ticket.data()}))
         callback(null,eventTickets)
        }else{
         callback(null,null)
        }
    } catch (error) {
        callback(error) 
    }
}

export const getTicketsFromDb = async(userId,callback)=>{

   
    const q = query(ticketsRef,  where("ownerId","==",userId), where("ends" ,">=", currentDate ))
    

    try {
       const eventTicketDocs = await getDocs(q)
       if(!eventTicketDocs.empty){

        const eventTickets = eventTicketDocs.docs.map(ticket=>({id:ticket.id, ...ticket.data()}))
        callback(null,eventTickets)
       }else{
        callback(null,null)
       }
    } catch (error) {
        callback(error) 
    }
    
}

export const acceptPrivateTicketRequest = async(ticketId,statusUpdate,callback) => {
     console.log(ticketId, statusUpdate)
    try {
    const ticketsRef = collection(db,"tickets")
    const ticketRef = doc(ticketsRef,ticketId)
   
    await updateDoc(ticketRef,{status: statusUpdate})
    callback(false)
            
    } catch (error) {
        console.log(error)
        callback(error)
    }
 
}
