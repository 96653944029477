import React from 'react'
import HistoryTab from './HistoryTab'

const styles = {
    tab: {
        width:"100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px"
    }
}



const TabsContainer = ({selectedTab, setSelectedTab}) => {

    const onClick = (tab)=>{
        console.log(tab)
       setSelectedTab(tab)
    }
  return (
    <div style={styles.tab}>
    {
      selectedTab === 1 &&
       <>
       
        <HistoryTab title="Event History" isSelected={true} onClick={()=>onClick(1)}/>
        <HistoryTab title="Hosted Events" isSelected={false} onClick={()=>onClick(2)}/>
        </>
    }
     {
      selectedTab === 2 &&
       <>
        <HistoryTab title="Hosted Events" isSelected={true} onClick={()=>onClick(2)}/>
        <HistoryTab title="Event History" isSelected={false} onClick={()=>onClick(1)}/>
       
        </>
    }
    </div>
  )
}

export default TabsContainer
