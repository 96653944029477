import React from 'react'

const styles = {
    navBlock:{
        width: "45px",
        height: "40px",
        backgroundColor:"#2C2E3A",
        borderRadius: "8px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }
}

const NavFunction = ({onClick, type}) => {
  return (
    <div style={styles.navBlock} onClick={()=>onClick()}>
     {
     type === "back" 
     &&
     <svg xmlns="http://www.w3.org/2000/svg" width="11" height="18" viewBox="0 0 11 18" fill="none">
            <path d="M3.41422 8.9999L10.4142 1.9999L9 0.585693L0.585785 8.9999L9 17.4141L10.4142 15.9999L3.41422 8.9999Z" fill="white"/>
    </svg>
     ||
     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
     <path d="M8.00006 6.93945L1.6361 0.575439L0.575439 1.6361L6.93936 8.00005L0.575439 14.364L1.6361 15.4247L8.00006 9.06075L14.3641 15.4247L15.4247 14.364L9.06076 8.00005L15.4247 1.6361L14.3641 0.575439L8.00006 6.93945Z" fill="white"/>
     </svg>
    }

    </div>
  )
}

export default NavFunction
