import React, { useEffect } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'

const styles = {
   

    circle: {
        width: "150px",
        height: "150px",
        backgroundColor: "#4B2CBA",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
        marginBottom:"100px"
    },
    infoDiv: {
        marginTop: "20px"
    }

}
const VerificationComplete = () => {

  const {setNavFunction, setNavType} = useNav()
  const navigate = useNavigate()

  useEffect(()=>{
       
    //check if this page was navigated properly
  


  setNavFunction(()=>{
    return ()=>navigate("/kyc-list")
  })
  setNavType("back")

},[])
  return (
    <div className='island'>
     
        <div style={styles.circle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="36" viewBox="0 0 54 36" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M52.1263 1.20679C53.3467 2.42716 53.3467 4.40583 52.1263 5.6262L22.9596 34.7927C21.7392 36.0132 19.7608 36.0132 18.5403 34.7927L1.87362 18.1261C0.65325 16.9057 0.65325 14.9273 1.87362 13.7069C3.094 12.4865 5.07267 12.4865 6.29304 13.7069L20.75 28.1636L47.7071 1.20679C48.9275 -0.0135899 50.9058 -0.0135899 52.1263 1.20679Z" fill="#D9D9D9"/>
            </svg>
        </div>
  
      <p className='poppins-200px-600'>Verification successful</p>
      <div style={styles.infoDiv}>
        <p className='poppins-white-medium'>
        You have been verified 
        </p>
      </div>
    </div>
  )
}

export default VerificationComplete
