import React, { useEffect } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import ProfilePicture from '../../components/ProfilePicture'
import ProfileInfo from '../../components/ProfileInfo'
import { useAuth } from '../../contextAPI/AuthContext'
import { useNavigate, useNavigation } from 'react-router-dom'
import ConfirmationBox from '../../components/ConfirmationBox'


const styles = {
    flexEndDiv:{
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
        padding: "10px 0",
        
    }
}

const Profile = () => {

    const {setTitle, setShowNav, setNavFunction, setNavType} = useNav()
 
   
    const {user,isLoggedIn} = useAuth()
    const {displayName, email, waNum, phoneNumber, username,verification} = user
    const navigate = useNavigate()
    
    useEffect(()=>{
        setTitle("Profile")
        setShowNav(true)
        
        setNavFunction(()=>()=> navigate("/"))
        setNavType("back")

    },[])

   
  return (
    <>
    
    {!isLoggedIn && <ConfirmationBox errorType={true} title="Login" para="login to create profile"  actionButtons={[{onClick: ()=>navigate("/login"), title: "Login"},{onClick: ()=>navigate("/"), title: "cancel"}]} />}
    <div className='container'>
       
       <div style={styles.flexEndDiv}>
        <div onClick={()=>navigate("/edit-profile")}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M18.2583 2.57651C17.5761 1.98064 16.8085 1.64014 15.8704 1.64014C14.9322 1.64014 13.9941 2.06576 13.3118 2.74676L3.3335 12.7915L3.93049 13.3875L4.52749 13.9833L6.83018 16.2817L7.42717 16.8776L8.02416 17.4735L18.0878 7.42866C19.4523 6.06666 19.5377 3.8534 18.2583 2.57651Z" fill="white"/>
            <path d="M5.52291 17.1631L3.80704 15.4472L3.15338 14.7936L2.49971 14.1399C2.33629 14.3851 2.25459 14.7119 2.17288 14.957L0.865552 18.9607C0.783841 19.2876 0.865552 19.5326 1.02896 19.7778C1.19238 19.9412 1.51921 20.0229 1.84604 19.9412L5.84974 18.7156C6.17658 18.6339 6.4217 18.4705 6.66683 18.3887L6.09487 17.8168L5.52291 17.1631Z" fill="white"/>
        </svg>
        </div>
       </div>
       <div style={{marginBottom: "20px"}}>
        <ProfilePicture img={user.profile}/>
       </div>
       <ProfileInfo title={displayName || "username"} subtitle={username || "username"}/>
       <ProfileInfo title={phoneNumber || waNum || "phone number"} subtitle={verification?.phoneNumber === "completed"&& "verified" || "unverified"}/>
       <ProfileInfo title={email || "Email"} subtitle={verification?.email === "completed"&& "verified" || "unverified"}/>
       <ProfileInfo title={"Account"} subtitle={"unverified"}/>
 
    </div>
    </>
  )
}

export default Profile
