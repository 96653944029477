import React, { useEffect } from 'react'
import HailerCard from './Components/HailerCard'
import { useNav } from '../../../contextAPI/NavContaxt'

const style = {
  color:"white", 
  position:"relative", 
  height:"40vh", 
  width:"80vw", 
  margin:"60px 0 20px"
}

const Hailers = ({hailers,addNewPassenger,rejectPassenger, setViewHailers, isDisabled}) => {

  const {setNavFunction,setNavType} = useNav()
  

  useEffect(()=>{
    setNavFunction(()=>()=>setViewHailers(false))
    setNavType("cancel")
  },[])

  return (
    <div className='container'>
     
      <div style={style}>
    {
      hailers.map(hailer=> <HailerCard cardInfo={hailer} accept={addNewPassenger} reject={rejectPassenger} type= "hailer"/>)
    }
    </div>

   
  </div>
  )
} 

export default Hailers
