import React, { useEffect, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import EventCoverPictures from './EventCoverPictures'
import { useCreateEvent } from '../../contextAPI/CreateEventContext'
import { useNavigate } from 'react-router-dom'

// import { storage } from '../firebase/config';
// import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import {generateRandomString} from '../../Utils/util'
// import UploadProgress from '../../components/UploadProgress'

const styles = {
    container: {
        
    },
    grid:{
        height:"85px",
        width:"85px",
        backgroundColor:"#2C234E",
        borderRadius: "15px",
        border:"4px dashed #4C2CBF",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        flexDirection:"column",
        margin:"10px"
    }
}

const ChoosePicture = ({setChoosePicture}) => {

    const {setNavFunction,setNavType,setTitle} = useNav()
    const [pickPicture,setPickPicture] = useState(null)
    const { setEventDetails} = useCreateEvent()
    const navigate = useNavigate()
    const eventTypes = ["Birthday", "Wedding", "Hang Out", "Concert", "Random"]

    useEffect(()=>{
        if(!pickPicture){
            setNavFunction(()=>()=>{
                setChoosePicture(false)
                setNavFunction(()=>()=>navigate("/"))
                setNavType("back")
            })
            setNavType("cancel")
            setTitle("Event cover picture")
        }
    },[pickPicture])


    // let uploadTask
    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        const fileURL = URL.createObjectURL(file)
   
        setEventDetails(prev=>({...prev,eventCoverImg:fileURL,eventCoverImgFile:file}))
        console.log(fileURL)
        setChoosePicture(false)
    };


  const handleUploadButtonClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', handleFileInputChange);
    fileInput.click();
  };
    
    
      
 


 if(pickPicture){
   return(
    <EventCoverPictures pickPicture={pickPicture} setPickPicture={setPickPicture} setChoosePicture={setChoosePicture}/>
   )
 }
  return (
    <>
    <div className='island'>
      <div style={{display:"flex", width:"100%",flexWrap:"wrap", justifyContent:"center"}}>

            {
                eventTypes.map(type=>{
                    return (
                        <div key={type} style={styles.grid} onClick={()=>setPickPicture(type)}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" stroke="white"/>
                                <path d="M15 20V10" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M10 15H20" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                            <p className='poppins-15px-500' style={{color:"#ffffff"}}>{type}</p>
                        </div>
                    )
                })
            }

                    <div  style={{...styles.grid, backgroundColor:"grey", }} onClick={handleUploadButtonClick}>
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" stroke="white"/>
                                <path d="M15 20V10" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                <path d="M10 15H20" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                            </svg>
                            <p className='poppins-15px-500' style={{color:"#ffffff"}}>Upload</p>
                    </div>

      </div>
      
    </div>
    {/* {showUploadProgress && <showUploadProgress progress={progress} />} */}
    </>
  )
}

export default ChoosePicture
