import React, { useEffect, useState } from 'react'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import { useLocation, useNavigate } from 'react-router-dom'
import { useNav } from '../../contextAPI/NavContaxt'
import { useAuth } from '../../contextAPI/AuthContext'
import InfoPage from '../../components/InfoPage'
import { checkEventExists } from '../../firebase/controller/eventsController'
import ConfirmationBox from '../../components/ConfirmationBox'
const styles = {

    infoDiv:{
        display: "flex",
        alignItems: "center",
        justifyContent:"flex-start",
        marginLeft: "20px",
        marginTop: "20px",

      },
      info:{
        width: "65%",
        marginLeft: "10px",
        color:"#FFFFFF"
      },
      learnMore:{
        color: "#8E69F0",
        textDecoration: "underline",
      }
    
}
 
const WishBland = () => {

    const navigate = useNavigate()
    const {setTitle,setNavFunction, setShowNav,setNavType} = useNav()
    const {user} = useAuth()
    const [showDetail,setShowDetail] = useState(false)
    const urlParams = new URLSearchParams(window.location.search)
    const createdBy = (useLocation()?.state?.createdBy || urlParams?.get('createdby')  || localStorage.getItem("createdBy") )
    const [eventname] = useState(urlParams?.get("eventname") || localStorage.getItem("eventName"))
    const eventId = urlParams?.get('eventId')
    const [error, setError] = useState(null)
    
    
    
    useEffect(()=>{
        
      console.log(urlParams?.get('eventId'))
      if( eventId && eventname && createdBy){
        
        console.log(eventId)
         checkEventExists(eventId,(err,data)=>{
            if(!err && data){
              console.log(data)
              localStorage.setItem("eventId",eventId)
              localStorage.setItem("createdBy", data.createdBy)
            }else if(!err &&!data){
              navigate('/notfound')
            }else{
               setError("An Error occured")
            }
         })
      
      }
        else if (!createdBy){

          navigate('/notfound')
        }

        localStorage.setItem("createdBy",createdBy)
        setTitle("WishBlend")
        setShowNav(true)
        setNavFunction(()=>()=>navigate("/event-page"))
    },[])

    const onSetShowDetails = ()=>{
      setShowDetail(false)
      setNavFunction(()=>()=>navigate("/event-page"))
      setNavType('back')
    }

    if(showDetail){
      return(
          <InfoPage type="wishblend" onClick={onSetShowDetails} img="wishblendill.jpg"/>
      )
  }
   
  return (
      <>
        {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
        <div className="island">
        <h3 className="poppins-20px-500 island-title">{eventname}</h3>
        <div style={{width:"90%"}}>
        {createdBy === user.id  && <LongPurpleBtn onClick={() => navigate("/create-wish-blend",{state:{action:"edit"}})} name="Edit WishBlend"/>}
            {createdBy !== user.id && <LongPurpleBtn onClick={() => navigate("/suggest-item")} name="Suggest Item"/>}
            {createdBy === user.id  && <LongPurpleBtn onClick={() => navigate("/suggested-item")} name="Suggested Item"/>}
             <LongPurpleBtn onClick={() => navigate("/get-items")}   name="Get items"/>
        </div>
        
        <div className="poppins-12px-300" style={styles.infoDiv}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 10.75C12.4142 10.75 12.75 11.0858 12.75 11.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V11.5C11.25 11.0858 11.5858 10.75 12 10.75Z" fill="white"/>
                <path d="M12 9C12.5523 9 13 8.55228 13 8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8C11 8.55228 11.4477 9 12 9Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25 12C3.25 7.16751 7.16751 3.25 12 3.25C16.8325 3.25 20.75 7.16751 20.75 12C20.75 16.8325 16.8325 20.75 12 20.75C7.16751 20.75 3.25 16.8325 3.25 12ZM12 4.75C7.99594 4.75 4.75 7.99594 4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75Z" fill="white"/>
            </svg>
            <p style={styles.info}>
            Select if you would like to edit your
            WishBlend list or you want to get 
            item for the event.
            <span style={styles.learnMore} onClick={()=>setShowDetail(true)}>Learn more.</span> 
            </p>
        </div>
        </div>
     </>
  )
}

export default WishBland
