import Dexie from "dexie";


const db = new Dexie('MyDatabase');
db.version(1).stores({
  cache: 'key,expiration,data',
  tickets: 'key,expiration,data',
  hostedEvent:'key,expiration,data',
  carpoolEvents:'key,expiration,data',
  dbCheck: 'key,expiration',
  eventContext: 'key,expiration,data',
   
});

export const addToCache = async (key, expiration, data,store) => {
  console.log(key, expiration, data,store)
  await db[store].put({ key, expiration, data });
};

export const deleteFromCache = async (key, store) => {
  await db[store].delete(key);
};
export const getFromCache = async (key,store) => {
  const cacheEntry = await db[store].get(key);
  const date = new Date().setHours(0,0,0,0)

  
  if (!cacheEntry || cacheEntry.expiration < date) {
    if(cacheEntry){
    await clearExpiredCacheEntries(store); // Clear expired entries before returning null
    }
    return null; // Cache miss or expired
  }
  return cacheEntry.data;
};




export const getClosestExpiration = async (storeName) => {
  
  const date = new Date().setHours(0,0,0,0)
  const closestEntry = await db[storeName]
    .orderBy('expiration')
    .first();

  
  if (!closestEntry || closestEntry.expiration < date) {
    if(closestEntry){
    clearExpiredCacheEntries("tickets")
    }
    return null; // Cache empty or expired

  }
  return closestEntry.data;
};

export const getAllFromStore = async (storeName) => {
  return await db[storeName].toArray();
};

export const getDbCheck = async(key) => {
  const checkEntry = await db.dbCheck.get(key)
  const date = new Date().setHours(0,0,0,0)
  console.log(checkEntry)
  if(!checkEntry || checkEntry.expiration < date){
    if(checkEntry){
      clearExpiredCacheEntries("dbCheck")
    }
    return null
  }
  return true
}

export const setDbCheck = async(key)=>{

  await db.dbCheck.put({ key, expiration: Date.now()});
}


export const clearExpiredCacheEntries = async (store) => {
  console.log(store)
  const date = new Date().setHours(0,0,0,0)
   await db[store].where('expiration').belowOrEqual(date).delete();
};

export const clearCache = async () => {
  // await db.cache.clear();
  await db.dbCheck.clear()
  await db.carpoolEvents.clear()
  await db.hostedEvent.clear()
  await db.tickets.clear()
};

export const resetCache = async()=>{
  await db.cache.clear();
  await db.dbCheck.clear()
  await db.carpoolEvents.clear()
  await db.hostedEvent.clear()
  await db.tickets.clear()
}

export const clearStore = async (store)=>{
  await db[store].clear()
}