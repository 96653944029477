import React from 'react'

const styles = {
    card:{ 
      minHeight: "83px",
      width: "90%",
      backgroundColor: "#BDD7C7",
      borderRadius: "20px",
      padding: "11px 20px",
      color: "black",
      marginBottom:"10px",
   },

   accepted:{
     backgroundColor: "#BDD7C7"
   },

   rejected:{
     backgroundColor: "#DFC1C1"
   },

   pending:{
    backgroundColor: "#CEC8DC"
   }
   ,
   nameDIV:{
      height: "20%",
  
   },
   middleDiv:{
      height: "50%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent:"space-between"
  
   },
   lastDiv:{
      height: "15%",
      display: "flex",
      justifyContent: "right",
      alignItems: "center"
   }
  
  }


const CarpoolCardPoolee = ({cardInfo,onClick}) => {
    const {id,poolerName,poolerLoc,eventName, status, formattedDate, eventLocation} = cardInfo
     
   
    let statusStyle;
    switch (status) {
        case 'accepted':
            statusStyle = styles.accepted;
            break;
        case 'rejectedByPooler':
            statusStyle = styles.rejected;
            break;
        case 'requested':
            statusStyle = styles.pending;
            break;
        default:
            statusStyle = {};
    }

    console.log(statusStyle)
    return (
      <div style={{...styles.card, ...statusStyle}} onClick={()=>onClick(cardInfo)}>
        <div style={styles.nameDIV}>
          <p className='poppins-12px-400' style={{color: "#2F2F2F"}}>{poolerName}</p>
        </div>
        <div style={styles.middleDiv}>
          <p className='poppins-10px-300' style={{color: "#616263"}}>{poolerLoc  + " to " + eventLocation}</p>
          <p>Free</p>
        </div>
        <div style={styles.lastDiv}>
          {/* <span className='poppins-10px-300' style={{color:"#FF3A3A"}}>Cancel request</span> */}
          { status === "requested" &&
          <p style={{color: "#8E69F0", display:"flex", alignItems:"center"}} className='poppins-10px-300'>
             
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.90625 3.79175C6.90625 3.56738 6.72436 3.3855 6.5 3.3855C6.27564 3.3855 6.09375 3.56738 6.09375 3.79175V6.50008C6.09375 6.64016 6.1659 6.77032 6.28469 6.84458L7.90969 7.86021C8.09998 7.9791 8.3506 7.92131 8.4695 7.73102C8.5884 7.54073 8.5306 7.2901 8.34031 7.17121L6.90625 6.27491V3.79175Z" fill="#8E69F0"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.50008 1.7605C3.88248 1.7605 1.7605 3.88248 1.7605 6.50008C1.7605 9.11768 3.88248 11.2397 6.50008 11.2397C9.11769 11.2397 11.2397 9.11768 11.2397 6.50008C11.2397 3.88248 9.11769 1.7605 6.50008 1.7605ZM2.573 6.50008C2.573 4.33122 4.33122 2.573 6.50008 2.573C8.66897 2.573 10.4272 4.33122 10.4272 6.50008C10.4272 8.66897 8.66897 10.4272 6.50008 10.4272C4.33122 10.4272 2.573 8.66897 2.573 6.50008Z" fill="#8E69F0"/>
                </svg>
              <span style={{marginLeft:"5px"}}>Requested</span>
          </p>
          }
          {
            status === "accepted" &&
            <p style={{color: "#22C963", display:"flex", alignItems:"center"}} className='poppins-10px-300' >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4.5" stroke="#22C963"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.09343 4.07322C7.19107 4.17085 7.19107 4.32915 7.09343 4.42678L4.7601 6.7601C4.66247 6.85773 4.5042 6.85773 4.40656 6.7601L3.07322 5.42677C2.97559 5.32913 2.97559 5.17087 3.07322 5.07323C3.17085 4.9756 3.32915 4.9756 3.42678 5.07323L4.58333 6.22977L6.7399 4.07322C6.83753 3.97559 6.9958 3.97559 7.09343 4.07322Z" fill="#22C963"/>
            </svg>
            <span style={{marginLeft:"5px"}}>Accepted</span>
            </p>
            
          }
          {
            status === "rejectedByPooler" &&
            <p style={{color: "#FF3A3A", display:"flex", alignItems:"center"}} className='poppins-10px-300' >
            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_2369_1301)">
                    <path d="M8.50007 6.11706L5.05293 2.66992L4.66992 3.05293L8.11706 6.50007L4.66992 9.94724L5.05293 10.3302L8.50007 6.88308L11.9472 10.3302L12.3302 9.94724L8.88308 6.50007L12.3302 3.05293L11.9472 2.66992L8.50007 6.11706Z" fill="#FF3A3A"/>
                </g>
                <defs>
                    <filter id="filter0_d_2369_1301" x="-2" y="0" width="21" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2369_1301"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2369_1301" result="shape"/>
                    </filter>
                </defs>
            </svg>
            <span style={{marginLeft:"5px"}}>Rejected</span>
            </p>
            
          }
        
        </div>
        <div>
          <p className='poppins-10px-300' style={{color:"#8E69F0", width:"100%", textAlign:"center"}}>{eventName || formattedDate}</p>
        </div>
      </div>
    )
}

export default CarpoolCardPoolee
