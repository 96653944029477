// NetworkErrorContext.js
import React, { createContext, useState, useContext } from 'react';

const NetworkErrorContext = createContext();

export const useNetworkError = () => {
  return useContext(NetworkErrorContext);
};

export const NetworkErrorProvider = ({ children }) => {
  const [hasNetworkError, setHasNetworkError] = useState(false);
  const [showGlobalNetworkError, setShowGlobalNetworkError] = useState(false)

  return (
    <NetworkErrorContext.Provider value={{ hasNetworkError, setHasNetworkError,showGlobalNetworkError, setShowGlobalNetworkError }}>
      {children}
    </NetworkErrorContext.Provider>
  );
};
