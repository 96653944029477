import React from 'react'


const styles = {
    btn: {
        width: "30px",
        height: "20px",
        borderRadius: "5px",
        backgroundColor:"#8E69F0",
    },
    div:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginTop: "10px"
    },
    inp:{
        width: "30px",
        height: "20px",
        backgroundColor:"#18181A",
        border:"none",
        outline: "none",
        color: "white",
        textAlign: "center",
        padding: "0"
    }
}
const IncrementBox2 = ({setItem,count, setCount,setShowContributors, promisedCount}) => {
 
    
   
    
    const onChange = (op)=>{
        if(op == "-" && count > 0){
            setCount(prev=>--prev)
        }else if(op == "+"){
            setCount(prev=>++prev)
        }
        setItem(op)
    }
  return (
    <div style={styles.div}>
      <div> 
        <button style={styles.btn} onClick={()=>onChange("-")}>-</button> 
        <input style={styles.inp} value={count} />
        <button style={styles.btn} onClick={()=>onChange("+")}>+</button> 
      </div> 
      {promisedCount > 0 && <p className='inter-10px-400' style={{color:"#8E69F0"}} onClick={()=>setShowContributors(prev=>!prev)}>view contributors</p>}
    </div>
  )
}

export default IncrementBox2
