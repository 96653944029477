import React from 'react'

const styles = {
    inputContainer: {
        display: "flex",
        justifyContent: "center",
    },
    inputBox: {
        height:"30px",
        width:"30px",
        margin: "0 5px",
        borderRadius: "5px",
        backgroundColor: "#2D2F3C",
        color: "#FFFFFF",
        border:"none",
        outline: "none",
        padding: "0",
        textAlign: "center",
    }
}



const CodeInput = ({onChange, inpRef1, inpRef2,inpRef3,inpRef4,inpRef5,inpRef6}) => {
  return (
    <div style={styles.inputContainer}>
      <input style={styles.inputBox} maxLength={1} pattern="\d{1}" inputMode="numeric" onChange={onChange} ref={inpRef1} id='1' autoFocus ></input>
      <input style={styles.inputBox} maxLength={1} pattern="\d{1}" inputMode="numeric" onChange={onChange} ref={inpRef2} id='2'></input>
      <input style={styles.inputBox} maxLength={1} pattern="\d{1}" inputMode="numeric" onChange={onChange} ref={inpRef3} id='3'></input>
      <input  style={styles.inputBox} maxLength={1} pattern="\d{1}" inputMode="numeric" onChange={onChange} ref={inpRef4} id='4'></input>
      {
        inpRef5 && inpRef6 && <>
        <input style={styles.inputBox} maxLength={1} pattern="\d{1}" inputMode="numeric" onChange={onChange} ref={inpRef5} id='5'></input>
       <input  style={styles.inputBox} maxLength={1} pattern="\d{1}" inputMode="numeric" onChange={onChange} ref={inpRef6} id='6'></input>
       </>
      }
    </div>
  )
}

export default CodeInput
