import React from 'react'
import AddEventFeatureBtn from '../AddEventFeatureBtn'


const styles = {
  eventFeature:{
    minWidth: "90px",
    height: "90px",
    /* max-height: "100%", */
    margin: "0 5px",
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingBottom: "2px",
    position: "relative",
   },
   featureTitle:{
     color: "#8E69F0",
     fontFamily: "Inter",
     fontSize: "13px",
     fontStyle: "normal",
     fontWeight: "300",
     lineHeight: "normal",
     textAlign: "center",
   },
   efImg:{
     width: "100%",
     height: "90%",
     display: "flex",
     alignItems: "center",
     justifyContent: "center",
   
   },
   efImgImg:{
     maxWidth: "80%", /* Make the image responsive and maintain  its aspect ratio */
     height: "auto", 
   }
}

const EventFeatures = ({onClick, featureType, isEditEvent,setShowCBforCarpool}) => {

    const featureTypes = {
        'carpool':{
            title: "Carpool",
            image: "/carpoolIcon.png",
            isAvailable: true,
        },
        'getTicket': {
            title: "Get Ticket",
            image: "/ticket.png",
            isAvailable: true,
        },
        'experienceEvent': {
            title: "Experience Event",
            image: "/eeIcon.png",
            isAvailable: false,
        },
        'wishBlend':{
          title: "WishBlend",
          image: "/wishBlendIcon.png",
          isAvailable: true,
        },
        'games':{
          title: "Games",
          image: "/game.svg",
          isAvailable: false,
        },
        "scanTicket":{
          title: "Scan tickets",
          image: "/ticket.png",
          isAvailable: true,
        }
    }
  return (

      <div style={styles.eventFeature} onClick={()=>onClick()}> 
        {isEditEvent &&  featureTypes[featureType].isAvailable && <AddEventFeatureBtn featureType={featureType} setShowCBforCarpool={setShowCBforCarpool}/> }
        <div style={styles.efImg}>
          <img style={styles.efImgImg} src={featureTypes[featureType].image}></img>
        </div>
        <p style={styles.featureTitle}>{featureTypes[featureType].title}</p>
      </div>

  )
}

export default EventFeatures
