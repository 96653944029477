// useNetworkStatus.js
import { useState, useEffect } from 'react';

const useNetworkStatus = (checkInterval = 10000) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
 

  const checkInternetConnection = async () => {
    try {
      const response = await fetch('https://www.google.com', { mode: 'no-cors' });
      if (response.ok || response.type === 'opaque') {
      
        setIsOnline(true);
      } else {
        setIsOnline(false);
        
      }
    } catch (error) {
      setIsOnline(false);
      
    }
  };

  useEffect(() => {
    const handleOnline = () => checkInternetConnection();
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Initial check
    checkInternetConnection();

    // Periodic checks
    const intervalId = setInterval(checkInternetConnection, checkInterval);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      clearInterval(intervalId);
    };
  }, [checkInterval]);

  return isOnline;
};

export default useNetworkStatus;
