import React, { useEffect, useState } from 'react'
import Tab1 from './Tab1'
import Tab2 from './Tab2'
import Tab3 from './Tab3'
import TabShift from './TabShift'
import ButtomNav from './ButtomNav'
import { useNav } from '../../contextAPI/NavContaxt'
import Tab from './Tab'



const Intro = () => {
    const [selectedTab,setSelectedTab] = useState(0)
    const tabs = [<Tab1 setSelectedTab={setSelectedTab} />,<Tab2 setSelectedTab={setSelectedTab}/>,<Tab3 setSelectedTab={setSelectedTab}/>]
    const {setShowNav,setShowMenu,setTitle} = useNav()
    useEffect(()=>{
        setShowNav(false)
        setShowMenu(false)
        setTitle("NovusUI")
      
    },[])

  return (
    <div className='container' style={{paddingTop:"30px"}}>
    {
      tabs[selectedTab]
    }
     <TabShift tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab}/>
     <ButtomNav setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabs={tabs}/>
    </div>
  )
}

export default Intro
