import React, { useEffect, useState } from 'react'
import StarRating from '../../../components/StarRating'
import { formatTimestamp } from '../../../Utils/util'
import {motion} from "framer-motion"


const PoolerCard = ({poolData,eventDate}) => {

    const {poolerName, poolerLoc, eventName, eventLocation, costPerSeat, poolerDest,poolDate,returnTripSurvey } = poolData
    const [starRating] = useState(5)
    const [date,setDate] = useState("")

    useEffect(()=>{
      if(eventDate){
      const date = formatTimestamp(eventDate || poolDate)
      setDate(date)
      }
    },[eventDate])
    
  return (
    <motion.div 
    style={{minHeight:"180px", width:"100%", margin:"20px 0", backgroundColor:"#18181A", borderRadius:"20px", padding:"15px 15px 5px", display:"flex", flexDirection:"column"}}
    initial={{ scale: 0.9 }}
    animate={{  scale: 1 }}
    exit={{ scale: 0.9 }}
    transition={{ duration: 0.2 }}
    >
      <div style={{display:"flex", height:"80%", borderBottom:" 2px solid #A0A3AF", padding:"10px 0"}}>
        <div style={{width:"40px", height:"40px", backgroundColor:"#ffffff", borderRadius:"100%"}}>
          <img src={poolData.profile} style={{width:"100%", height:"100%", borderRadius:"100%",objectFit:"cover"}}></img> 
        </div>
        <div style={{marginLeft:"15px", flex:"1", display:"flex", flexDirection:"column",justifyContent:"space-evenly"}}>
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                <div>
                <StarRating onClick={()=>{}} starRating={starRating} />
                </div>
                <p className='poppins-14px-400' style={{color:"#8E69F0"}}>Pooler</p>
            </div>
            <p className='poppins-14px-400'>{poolerName}</p>
            <p className='poppins-10px-300'>From <span className='poppins-12px-400'>{ returnTripSurvey?.status ? eventLocation : poolerLoc}</span></p>
            <p className='poppins-10px-300'>To <span className='poppins-12px-400'>{( returnTripSurvey?.status ? returnTripSurvey.formerLoc :  eventLocation) || poolerDest }</span></p>
           
           
        </div>
      </div>
      <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", flex:1, padding:"10px 20px"}}>
        <div style={{display:"flex", justifyContent:"space-between"}}>
             <p className='poppins-10px-300'>{date}</p>
            <p className='poppins-10px-300'>{eventName}</p>
        </div>
        <div style={{display:"flex", width:"100%", justifyContent:"space-between"}}>
        
        <p className='poppins-10px-300'>{costPerSeat === 0 ? "Free": costPerSeat}</p>
        </div>
      </div>
    </motion.div>
  )
}

export default PoolerCard
