import {  GeoPoint, and, collection, doc,  endAt,  getDocs,increment,limit,or,orderBy,query, runTransaction, startAt, updateDoc, where } from "firebase/firestore"
import { db } from "../config"
import { formatTimestamp } from "../../Utils/util"



const poolsRef = collection(db,"pool")

export const fetchAllPool = async(userId, storedEvent,coordinates,callback) =>{
   
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
   
  

  const center = new GeoPoint(coordinates.latitude, coordinates.longitude)
   
   const bound = centerBounds(center, 20)
    try {
        const q =  query(poolsRef, 
            and(where("eventId","==",storedEvent), 
            where("status","==","created"), 
            or(where("repeat","==","As event repeats"),and(where("repeat","==","Once"), where("poolDate",">",currentDate)))),
            orderBy("locationCoordinate"),
            startAt(bound.min),
            endAt(bound.max),
            ) 

        const poolDocs = await getDocs(q)

        let pools = []
        poolDocs.forEach(pool=>{
            pools.push({poolId:pool.id, ...pool.data()})
        })
        console.log(pools)
        pools = pools.filter(pool=> (pool.poolAdmin !== userId && (!pool.poolees || !pool.poolees[userId]  || pool.poolees[userId] === "cancelled")))
        console.log(pools)
        return callback(null, pools)
    } catch (error) {
        callback(error)
    }
   
}



export const createEventCarpool = async(data) =>{

    const {eventId, poolAdmin, coordinates,profile} = data
   
    
    const locationCoordinate = new GeoPoint(coordinates.latitude,coordinates.longitude)
        
    const usersRef= collection(db, "users")
    const userRef = doc(usersRef, poolAdmin)

    const userPoolHistory = collection(userRef,"userPoolHistory")

    const  poolQueryPoolee = query(userPoolHistory,where("eventId","==",eventId), where("active","==", true),where("role","==","poolee"),limit(1))

    const pooleeDocs = await getDocs(poolQueryPoolee)
    

    let poolPoolee = null

    if(!pooleeDocs.empty){
        console.log(pooleeDocs.docs[0].data())
        poolPoolee = {...pooleeDocs.docs[0].data(),id:pooleeDocs.docs[0].id}
    }
    // console.log(poolPoolee.,poolPooler.docs)
        
    // create the pool request in db
    const poolCollection = collection(db,"pool")
    const poolDoc = doc(poolCollection)

            // pooler specific data

            try {
                await runTransaction(db,async(transaction)=>{

                    //userEventDocData?.role === "poolee"  
                    if(poolPoolee){
                        console.log(poolPoolee)
                        const poolRef = doc(poolsRef,poolPoolee.id)
                        const poolHailersRef = collection(poolRef, "poolHailers")
                        const poolHailerRef = doc(poolHailersRef,poolAdmin)

                        transaction.update(poolHailerRef,{poolHailerStatus:"rejected"})

                        const usersRef = collection(db,"users")
                        const userRef = doc(usersRef,poolAdmin)
                        const poolHistoryRef = collection(userRef,  "userPoolHistory")
                        const userPoolRef = doc(poolHistoryRef,poolPoolee.id)
                        transaction.update(userPoolRef,{status:"rejectedByUser",active:false})

                        if(poolPoolee.status === "joined"){
                            transaction.update(poolRef,{pooleeCount: increment(-1)})
                        }else if(poolPoolee.status === "requested"){
                            transaction.update(poolRef,{hailerCount: increment(-1)})
                        }
                    }
                    
                    const poolData = {...data, locationCoordinate, hailerCount: 0, pooleeCount:0}

                    if(profile){
                        poolData.profile = profile
                    }
                    console.log(poolData)
                    transaction.set(poolDoc,poolData)
     
                    localStorage.setItem("activePool", poolDoc.id)
                    return null
                })
            } catch (error) {
                throw error
            }

}

export const checkForActiveEventPool = async(eventId,userId)=>{

    const poolQueryPooler = query(poolsRef, where("eventId","==",eventId), where("poolAdmin","==",userId), where("status","in",["created","closed"]),limit(1))
    try {
        const poolerDocs = await getDocs(poolQueryPooler)
         if(poolerDocs.empty){
            return null
         }else{
            return {...poolerDocs.docs[0].data(),id:poolerDocs.docs[0].id}
         }
    } catch (error) {
        throw error
    }
 
}

export const createDefaultPool = async(data,userId, addressFor,coordinates)=>{

    const {poolDate,  poolerLoc,} = data
    const locationCoordinate = new GeoPoint(coordinates.latitude,coordinates.longitude)
    
    console.log(poolDate)
  
    try {
        const poolRef = doc(poolsRef)
        await runTransaction(db,async(transaction)=>{  

          
            transaction.set(poolRef,{...data,locationCoordinate})

    
            const usersRef = collection(db, "users")
            const userRef = doc(usersRef,userId)
           
          
           

           if(addressFor){
            switch(addressFor){
                case "Home": transaction.update(userRef,{homeAdrress:poolerLoc})
                break
                case "Work": transaction.update(userRef,{workAdrress:poolerLoc})
                break
                case "Centres": transaction.update(userRef,{centersAdrress:poolerLoc})
            }
             
           }
           
           console.log(poolRef.id)
           
        })
        return poolRef.id
    } catch (error) {
        console.log(error,error.code)
        throw error
    }

   
}


export const getAllDefaultCarpool = async(userId,coordinates) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const center = new GeoPoint(coordinates.latitude, coordinates.longitude)

    const bound = centerBounds(center, 20)

    const q = query(poolsRef, 
        where("poolType","==","default"),
        where("poolAdmin", "!=",userId),
        where("status","in",["created","closed"]),
        where("poolDate",">=",currentDate),
        orderBy("locationCoordinate"),
        startAt(bound.min),
        endAt(bound.max),
    )
   
    try {
        const defaultPoolDocs = await getDocs(q)
        const defaultPools = defaultPoolDocs.docs.map(pool=>({...pool.data(),poolId:pool.id,formattedDate : formatTimestamp(pool.data().poolDate)})).filter(pool=>(pool.poolAdmin !== userId && (!pool.poolees || pool.poolees[userId] === "cancelled")))
        // filter(pool=> (pool.poolAdmin !== userId && pool.poolees[userId] === "cancelled"))
        console.log(defaultPools)
        return defaultPools
    } catch (error) {
        throw error
    }
    
}


export const checkInController = async( poolHailerRef, checkInType)=>{ 
  
    try {
       await updateDoc(poolHailerRef,{[checkInType]:new Date()})
       return null
    } catch (error) {
        throw error
    }
}

export const checkForCarpoolLimit = async(userId)=>{
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    console.log(currentDate)
   
    const q = query(poolsRef, where("poolAdmin","==",userId), where("poolType","==","default"), where("status","in",["created","closed"]), where("poolDate",">=",currentDate))
   
    try {
        const poolsDoc = await getDocs(q)
        if(poolsDoc.size >= 3){
            return  true
        }else{
           return false
        } 
    } catch (error) {
        throw error
    }
   
}
function centerBounds(center, radiusInKm) {
    const latRadian = center.latitude * Math.PI / 180;
    const lonRadian = center.longitude * Math.PI / 180;
    const earthRadius = 6371; // Radius of the earth in km
    const angularDistance = radiusInKm / earthRadius;
  
    const minLat = latRadian - angularDistance;
    const maxLat = latRadian + angularDistance;
  
    const deltaLon = Math.asin(Math.sin(angularDistance) / Math.cos(latRadian));
    const minLon = lonRadian - deltaLon;
    const maxLon = lonRadian + deltaLon;
   
    return {
      min: new GeoPoint((minLat * 180) / Math.PI, (minLon * 180) / Math.PI),
      max: new GeoPoint((maxLat * 180) / Math.PI, (maxLon * 180) / Math.PI)
    }
  }
  