import React, { useEffect } from 'react'

const styles = {
    selectedTab:{
        width: "25%",
        height: "100%",
        backgroundColor: "#8E69F0"
    },
    normalTab:{
        width: "25%",
        height: "100%",
        backgroundColor: "#FFFFFF",
        transition: "background-color 0.7s ease"
    
    },

    tabContainer:{
        marginTop:"40px",
        width: "80px",
        height: "5px",
        display: "flex",
        justifyContent: "space-between"
    }


}



const TabShift = ({tabs,selectedTab, setSelectedTab}) => {

    useEffect(()=>{
        console.log(tabs.length)
    },[])
  return (
    <div style={styles.tabContainer}>
      {
       tabs.map((tab,index)=>index=== selectedTab? <div style={styles.selectedTab}></div> :<div style={styles.normalTab} onClick={()=>setSelectedTab(index)}></div>)
      }
    </div>
  )
}

export default TabShift
