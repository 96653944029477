import React from 'react'
import XBtn from '../../../components/Buttons/XBtn'
import LongPurpleBtn2 from '../../../components/Buttons/LongPurpleBtn2'

const styles = {
    container : {
        position: "absolute",
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex:"9999"
    },
    transDiv: {
       flex:"1",
        opacity: "0.27",
        width: "100%",
        backgroundColor: "#4C2CBF"
    },
    middleDiv:{
     
        width:"100%",
        backgroundColor:"#2c234e",
        display:"flex",
        flexDirection:  "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"0 20px"
    },
    customOptions: {
        backgroundColor: "#18181A",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "15px",
        color: "#ffffff",
        width:"80vw"
    },
    generalMargin: {
      margin: "10px 0"
    },

    errorType:{
      border:"1px solid #ff3a3a",

    }

   
}

const repeatDays = ["Sunday","Monday","Tuesday", "Wednesday", "Thursday", "Friday","Saturday"]

const CustomRepeat = ({customOption, setShowCustom, setCustomOption, setRepeatType, setShowOption,inactive}) => {
  return (
    <div style={styles.container}>
    <div style={styles.transDiv}>

    </div>
    <div style={ styles.middleDiv}>
      <div style={styles.customOptions}>
          {
              repeatDays.map((day, index)=>(
                  
                     customOption.includes(index) &&
                      
                      <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", margin:"10px 0", color:"#8E69F0"}} onClick={()=>setCustomOption(index)}>
                          <p>{day}</p>
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7.5" cy="7.5" r="7.5" fill="#8E69F0"/>
                          </svg>
                      </div>
                     ||
                     <div style={{width:"100%", display:"flex", justifyContent:"space-between", alignItems:"center", margin:"10px 0"}} onClick={()=>setCustomOption(index)}>
                          <p>{day}</p>  
                          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="7.5" cy="7.5" r="7.5" fill="#2D2F3C"/>
                          </svg>
  
                      </div>

              ))
          }
          <LongPurpleBtn2 name="Save" 
          onClick={()=>{
            setRepeatType("Custom")
            setShowCustom(false)
            setShowOption(false)
            }}
            inactive={inactive}
            />
      </div>
     <div style={{marginTop:"20px"}}>
      <XBtn type="circle" onClick={()=>{setShowCustom(false)}}/>
      </div>
    </div>
    <div  style={styles.transDiv}>

    </div>
  </div>
  )
}

export default CustomRepeat

