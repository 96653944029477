import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import LongPurpleBtn from '../components/Buttons/LongPurpleBtn'
import { useNav } from '../contextAPI/NavContaxt'
import { useAuth } from '../contextAPI/AuthContext'
import Spinner from '../components/Spinner'

const NotFoundScreen = () => {

    const {state} = useLocation()
    const [eventLink, setEventLink] = useState(null)
    const {setTitle} = useNav()
    const {isLoggedIn} = useAuth()
    const [isLoading, setIsLoading] = useState(true)
    const urlParams = new URLSearchParams(window.location.search);
     
   useEffect(()=>{
    if(!isLoggedIn){
     
      if(urlParams.get("eventId") && urlParams.get("eventName")){
        localStorage.setItem("eventId",urlParams.get("eventId"))
        localStorage.setItem("eventName",urlParams.get("eventName") )
        localStorage.setItem("shortcutEventId",urlParams.get("eventId"))
        localStorage.setItem("viewMode","event")
      }
      navigate('/')
      
      return
    }
    setIsLoading(false)
    setTitle("Not Found")

    if(state){
        setEventLink(state.eventLink)
    }
   },[])
    const navigate = useNavigate()

    if(isLoading){
    return(<Spinner/>)
    }
  return (
    <div className='island'>
      <h1 className='poppins-20px-500'>Resource Not Found</h1>
      <LongPurpleBtn  onClick={()=>{eventLink ? navigate(eventLink):navigate("/")}} name="go home"/>

    </div>
  )
}

export default NotFoundScreen
