import React from 'react'
import "./styles.css"
const BlinkingLocationIcon = () => {
  return (
    <div className='circular-div'>
      

    </div>
  )
}

export default BlinkingLocationIcon
