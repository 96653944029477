import React, { useEffect } from 'react'
import KYCBox from '../../../components/KYCBox'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../../contextAPI/NavContaxt'

const styles = {
    pageTitle: {
        margin:"20px 0",
        width:"85%",
       textAlign: "center",
    },
    btnBox: {
        width:"100%",
        marginTop:"50px",
        
    },
    inputDiv:{
        width:"100%",
        padding: "20px 0"
    },
    infoBox: {
        color:"#FFFFFF",
        margin: "10px 0",

    },
    infoBoxDiv:{
        width: "100%",
        padding: "0 20px",
        marginBottom: "20px"
    }
    ,
    resendCode: {
        textAlign: "right",
        width:"100%",
        paddingTop: "10px",
        color: "#8E69F0",
        font: "Poppins",
        fontWeight: "300",
        fontSize: "15px"
    },
    btnBox: {
        width:"100%",
        position: "absolute",
        bottom:"40px",
        padding: "0 20px",
        
    }

}

const Id = () => {

    const {setShowNav} = useNav()

    useEffect(()=>{
        setShowNav(true)
    },[])
    const navigate = useNavigate()
  return (
    <div className='container'>
        <div style={styles.pageTitle}>
         <h1 className='poppins-20px-500'>Upload documents</h1>
        </div>
        <div style={styles.infoBoxDiv}>
            <p className='poppins-14px-400' style={styles.infoBox}>Kindly upload a means on identification for security reasons</p>
        </div>
       <KYCBox title="Proof of identity" info="You can submit a national ID card, Driver's license, or Passport" onClick={()=>navigate("/choose-id")}/>
       <KYCBox title="Take selfie" info="Take a selfie or upload a clear picture of your face" onClick={()=>navigate("/upload-id", {state:{type: "SF"}})}/>
       <div style={styles.btnBox}>
        <LongPurpleBtn name="Next"/>
       </div>
    </div>
  )
}

export default Id
