import React, { createContext, useContext, useEffect, useState } from 'react';
import { addToCache, clearStore, getFromCache } from '../Utils/indexedDBService';



const CreateEventContext = createContext();



export const CreateEventProvider = ({ children }) => {
    const [eventNameErr, setEventNameErr] = useState(null)
    const [eventLocationErr, setEventLocationErr] = useState(null)
    const [eventDescriptionErr, setDescriptionErr] = useState(null)
    const [eventDateErr, setEventDateErr] = useState(null)
    const [eventSTErr, setEventSTErr] = useState(null)
    const [eventETErr, setEventETErr] = useState(null)
    const [linkErr, setLinkErr] = useState(null)
 

    const [eventDetails, setEventDetailsFunction] = useState({
      featuresSelected:[],
      eventName:"",
      eventLoc:"",
      eventDesc:"",
      eventDate:"",
      eventStartTime:"",
      eventEndTime:"",
      eventRepeatType:"",
      ticketCount:0,
      eventCoverImg: "concert1.png",
      eventCoverImgFile:null,
      eventVisibility:"private",
      link:"",
    })

    useEffect(()=>{
    
      getEventContextFromCache()
      
    },[])

    async function getEventContextFromCache(){
      const cachedData = await getFromCache("eventContext","eventContext")
      if(cachedData){
        setEventDetailsFunction(cachedData)
      }
      
    }

    const clearContext = async ()=>{
      setEventNameErr(null)
      setEventLocationErr(null)
      setDescriptionErr(null)
      setEventDateErr(null)
      setEventSTErr(null)
      setEventETErr(null)
      setEventDetailsFunction({
        featuresSelected:[],
        eventName:"",
        eventLoc:"",
        eventDesc:"",
        eventDate:"",
        eventStartTime:"",
        eventEndTime:"",
        eventRepeatType:"",
        ticketCount:0,
        eventCoverImg: "concert1.png",
        eventCoverImgFile:null,
        eventVisibility:"private",
        link:"",
      })
      await clearStore("eventContext")

    }

    const setEventDetails = (callback)=>{
       const result = callback(eventDetails)
       setEventDetailsFunction(result)
       addToCache("eventContext","2080-10-10",result,"eventContext")
    }

  return (
    <CreateEventContext.Provider value={{
                                        eventNameErr, setEventNameErr,
                                        eventLocationErr, setEventLocationErr,
                                        eventDescriptionErr, setDescriptionErr,
                                        eventDateErr, setEventDateErr,
                                        eventSTErr, setEventSTErr,
                                        eventETErr, setEventETErr,
                                        clearContext,
                                        eventDetails, setEventDetails,
                                        linkErr,setLinkErr
                                        }}>
      {children}
    </CreateEventContext.Provider>
  );
};

export const useCreateEvent = () => {
  return useContext(CreateEventContext);
};
