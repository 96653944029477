import React from 'react'
import Tips from './Tips'
import {motion} from "framer-motion"
const TipsForPoolees = ({noBlink}) => {
  return (
    <div style={{width:"100%", color:"#ffffff"}}>
      <h3  className='poppins-15px-400' style={{ marginBottom:"20px"}}>Tips for a jolly ride</h3>
      <div style={{ display:"flex", justifyContent:"space-between", marginBottom:"20px"}}>
        <motion.div 
        style={{backgroundColor:"#8E69F0",borderRadius:"20px", width:"45%", padding: "10px"}}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ type: 'spring', stiffness: 200, damping: 10 }}
        >
          <h3 className='poppins-15px-400'>Security Notes</h3>
          <Tips text="For security reasons, always check in when you enter a ride."/>
          <Tips text="Share your location to friends and family via WhatsApp live location"/>
        </motion.div>
        <motion.div 
        style={{backgroundColor:"#4B2CBA", borderRadius:"20px", width:"45%", padding: "10px"}}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ type: 'spring', stiffness: 200, damping: 10,  }}
        >
        <h3 className='poppins-15px-400'>Guide Notes</h3>

          <Tips text="Ensure you check for pick up point and be at the correct location."/>
          <Tips text="Ensure you keep to meeting time."/>
        </motion.div>
      </div>
      <motion.p 
      className='poppins-15px-400'
      animate={{ scale: [1, 1.1, 1]  }}
        transition={noBlink ?{}:{ duration: 1, repeat: 2 }}
        
      >
        The chat will be opened 24hrs before the carpool time.
      </motion.p>
      <motion.div 
        style={{ margin:"30px 0", border:"1px solid #D9D9D9", borderRadius:"10px", padding:"15px 30px"}}
        initial={{ opacity: 0, scale: 0.8 }}
       animate={{ opacity: 1, scale: 1 }}
       exit={{ opacity: 0, scale: 0.8 }}
       transition={{ type: 'spring', stiffness: 200, damping: 10 }}
      >
      <h3 className='poppins-15px-400'>Be a responsible poolee 😊</h3>
          <Tips text="Don't bring an extra passenger, If you want to bring an extra person you have to make a new request for them."/>
          <Tips text="If you have any luggage, kindly alert your pooler."/>
          <Tips text="Ensure you keep to meeting time"/>
          <Tips text="Even if the ride is free, offer to buy fuel amongst other things."/>
          <Tips text="Always offer a helping hand, in case of car emergency."/>
          <Tips text="Be polite"/>
          
      </motion.div>
    </div>
  )
}

export default TipsForPoolees
