import React, { useEffect, useRef, useState } from 'react'
import GreyInputBox from './InputsBox/GreyInputBox'
import LongPurpleBtn2 from './Buttons/LongPurpleBtn2'
import { useApp } from '../contextAPI/AppContext'
import CheckInBtn from './CheckInBtn/CheckInBtn'
import { confirmCheckIn } from '../screens/ContactYourRide/utils'

const styles = {
  messageDiv:{
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
    borderRadius: "20px",
    backgroundColor:"#18181A",
    padding: "15px 15px",
    marginBottom: "20px",
  }
}

const MessageBox = ({displayName,validateMessage,validate,leaveAMsg,onClick, textareaValue, onChangeTextArea, type,poolHailerStatus,checkedIn,checkIn, setShowConfirmCheckIn}) => {

    const textRef = useRef()
    const {poolId} = useApp()
    
    useEffect(()=>{
      console.log(poolId)
      if(textRef.current){
        textRef.current.value = textareaValue || ""
      }
        
    },[textareaValue])
   
    

  return (
    <div style={styles.messageDiv}>
        <h4 className="page-title" style={{fontSize:"15px"}}>{`Hi ${displayName},`}</h4>
        {validate && poolHailerStatus ==="created"&& <p className="popping-300-normal">{validateMessage}</p>}
        {validate && poolHailerStatus ==="created"&& <GreyInputBox placeholder="I can meet you at Berger busstop" inputRef={textRef} onChange={onChangeTextArea}/>}
        {type === "pooler" && <LongPurpleBtn2 onClick={onClick} name="open chat"/>}
        {validate && poolHailerStatus === "joined" && !type && <CheckInBtn checkedIn={checkedIn} onClick={setShowConfirmCheckIn}/>}
    </div>

  )
}

export default MessageBox
