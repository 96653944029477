import React, { useEffect } from 'react'
import { useAuth } from '../../../contextAPI/AuthContext'
import VerificationComplete from '../VerificationComplete'
import VerificationInProcess from '../VerificationInProcess'
import Id from './Id'
import BackFunctionBtn from '../../../components/Buttons/BackFunctionBtn'
import { useNavigate } from 'react-router-dom'
import { useNav } from '../../../contextAPI/NavContaxt'





const ConfirmId = () => {

    const {user} = useAuth()

    const {verification} = user
    const navigate = useNavigate()

    const {setNavFunction, setNavType} = useNav()

    useEffect(()=>{
      setNavFunction(()=>{
        return ()=>navigate("/kyc-list")
      })
      setNavType("back")
    },[])
    
  return (
    <>
     {
        
      //  <BackFunctionBtn onClick={()=> navigate("/kyc-list")}/>

    }
     {verification?.id === "completed" && <VerificationComplete/>}
     {verification?.id === "pending" && <VerificationInProcess/>}
     {verification?.id !== "completed" && verification?.id !== "pending" && <Id/> }
    </>
  )
}

export default ConfirmId
