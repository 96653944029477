import React from 'react'
import LongPurpleBtn from "./Buttons/LongPurpleBtn"
import XBtn from "./Buttons/XBtn"
import MoreInfo from './MoreInfo'
import {motion} from "framer-motion"
const styles = {
    container : {
        position: "absolute",
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex:"9999"
    },
    transDiv: {
       flex:"1",
        opacity: "0.27",
        width: "100%",
        backgroundColor: "#4C2CBF"
    },
    middleDiv:{
     
        width:"100%",
        backgroundColor:"#2c234e",
        display:"flex",
        flexDirection:  "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"0 20px"
    },
    confirmationBox: {
        backgroundColor: "#18181A",
        padding: "40px 20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: "15px",
        color: "#ffffff"
    },
    generalMargin: {
      margin: "10px 0"
    },

    errorType:{
      border:"1px solid #ff3a3a",

    }

   
}

const ConfirmationBox = ({title,para,actionButtons, setShowConfirmationBox,cancelBtn,infoBox, errorType,index}) => {
  return (
    <div style={styles.container}>
      <div style={styles.transDiv}>

      </div>
      <div style={ styles.middleDiv}>
        <motion.div 
        style={errorType ? {...styles.confirmationBox, ...styles.errorType} : styles.confirmationBox}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.3 }}
         
        >
            <h1  style={styles.generalMargin} className='poppins-20px-500'>{title}</h1>
            <p style={styles.generalMargin} className='poppins-15px-200'>{para}</p>
            {infoBox && <MoreInfo style={styles.generalMargin} info={infoBox} />}
            {actionButtons?.map(btn=><LongPurpleBtn style={styles.generalMargin} onClick={btn.onClick} name={btn.title}/>)}
        </motion.div>
       {cancelBtn &&<motion.div 
       style={{marginTop:"20px"}}
       initial={{ opacity: 0, scale: 0.8 }}
       animate={{ opacity: 1, scale: 1 }}
       exit={{ opacity: 0, scale: 0.8 }}
       transition={{ type: 'spring', stiffness: 500, damping: 25 }}
       >
        <XBtn type="circle" onClick={()=>setShowConfirmationBox(false)}/>
        </motion.div>}
      </div>
      <div  style={styles.transDiv}>

      </div>
    </div>
  )
}

export default ConfirmationBox
