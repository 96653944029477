import { Timestamp, collection, doc, getDoc} from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate} from 'react-router-dom'
import { db } from '../../firebase/config';
import { useAuth } from '../../contextAPI/AuthContext';
import { useApp } from '../../contextAPI/AppContext';
import { useMsg } from '../../contextAPI/ErrorMsgContext';
import DownloadPrompt from '../../components/DownloadPrompt';
import EventLargeCard from "./Components/EventLargeCard"
import { useNav } from '../../contextAPI/NavContaxt';
import EventFeatures from '../../components/EventFeatures/EventFeatures';
import About from './Components/About';
import Spinner from '../../components/Spinner';
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import StarRating from '../../components/StarRating';
import TextRating from './Components/TextRating';
import { submitRating } from '../../firebase/controller/eventsController';
import ViewRatings from './Components/ViewRatings';
import ConfirmationBox from '../../components/ConfirmationBox';
import { addToCache, getFromCache } from '../../Utils/indexedDBService';
import { useNetworkError } from '../../contextAPI/NetworkErrorContext';
import Link from './Components/Link';


const styles = {
  aboutAndFeatures:{
    flex: "1",
    width: "inherit",
    display:"flex",
    flexDirection:"column",
    justifyContent:"space-between"
  },
  eventFeatures:{
   
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
    width: "inherit",
    justifyContent: "space-between",
  },

  eventFeaturesContainer:{
 
    padding: "10px 0",
    flex: "1",
    display: "flex",
    justifyContent:"flexStart",
    overflowX: "scroll",
    overflowY: "hidden",
  }
}


 const EventScreen =  () => {
    
  const navigate = useNavigate()
  const {user} = useAuth()  
 
  const {setMsgType} = useMsg()
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showConfirmationBox, setShowConfirmationBox]= useState(false)
  const {setShowNav, setTitle, setNavFunction, setNavType} = useNav()
  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams?.get('eventId') || localStorage.getItem("eventId")
  // const [eventData,setEventData] = useState(null)
  const {eventData, setEventData,setViewMode,viewMode} = useApp() // changed from usestate to useapp
  const eventCollection = collection(db, "events")
  const eventDocRef = doc(eventCollection,eventId)
  const [isLoading,setIsLoading] = useState(true)
  const [starRating, setStarRating] = useState(0)
  const [viewRating,setViewRating] = useState(false)
  const [error, setError] = useState(null)
  const {setShowGlobalNetworkError} = useNetworkError()
 
  const containerRef = useRef(null);


  useEffect(() => {
    const timer = setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scroll({
          top: containerRef.current.scrollHeight,
          behavior: 'smooth'
        });
      }
    }, 1000); // Add a delay of 100ms
    return () => clearTimeout(timer);
  }, []);


  const starRate = (rating) => {
    setStarRating(rating)
    submitRating(eventId,user?.id,{star:rating},(user?.username || user?.displayName),user?.profile,(err)=>{
        if(!err){

        }else{
            console.log(err)
        }
    })
}

  const checkEventExist = async()=>{
    try {
      
      const eventDoc = await getDoc(eventDocRef)
    
       console.log(eventId)
      if(!eventDoc.exists()){ 
        navigate("/notfound",{state:{eventsLink:"/"}})
          return
      }
       
      console.log(eventDoc.data())
      setEventData({...eventDoc.data(), id: eventDoc.id})
      localStorage.setItem("createBy", eventDoc.data().createdBy)
     
      const expirationDate = eventDoc.data().ends.seconds * 1000 < Date.now() ? eventDoc.data().ends.seconds * 1000 :  Date.now() 
      await addToCache(eventId, expirationDate,{...eventDoc.data(), id: eventDoc.id},"cache")
      setIsLoading(false)
    } catch (error) {
      console.log(error.code)
      if(error.code === "unavailable"){
        setShowGlobalNetworkError(true)
        
      }else{
        setError("An error occurred")
      }
      
    } 
  }

   const fetchData = async () => {
    const cachedData = await getFromCache(eventId,"cache");
   
    console.log(cachedData);
    if (cachedData) {
      localStorage.setItem("createBy", cachedData.createdBy)
      setEventData({...cachedData, eventDate: new Timestamp(cachedData.eventDate.seconds, cachedData.eventDate.nanoseconds),ends: new Timestamp(cachedData.ends.seconds, cachedData.ends.nanoseconds)})
      setIsLoading(false)
      return
    }
  
    checkEventExist()

  };

  useState(()=>{
   fetchData()
  },[])

 
 
  useEffect(() => {

    if(!viewRating && eventData){
   if(urlParams.get("eventId") && urlParams.get("eventName")){
      localStorage.setItem("eventId",urlParams.get("eventId"))
      localStorage.setItem("shortcutEventId",urlParams.get("eventId"))
      localStorage.setItem("eventName",urlParams.get("eventName") )
      if(!viewMode){
        localStorage.setItem("viewMode","event")
        setViewMode("event")
      }
   }
    
    setTitle("Event Details")
    setShowNav(true)

    if(eventData.createdBy === user?.id){
      setNavFunction(()=>()=> navigate("/event-owner-hs"))
      
    }else{
      setNavFunction(()=>{
        return ()=> navigate("/")
      })
    }
    
    setNavType("back")
   
    const handleBeforeInstallPrompt = (event) => {
      // Prevent the default browser prompt
      event.preventDefault();

      // Stash the event so it can be triggered later
      setDeferredPrompt(event);

      // Show the install button
      setShowInstallButton(true);
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }
  }, [viewRating, eventData]);

 

  // const chooseRole = async() => {
  //   //store the event chosen in database and local host
  //   setIsLoading(true)
    
  //   //check if event exists //later check for event status too
  //   try {

  //     const usersCollection = collection(db, "users")
  //     const userDoc = doc(usersCollection,user?.id)
  //     const userEvents = collection(userDoc,"userevents")
  //     const userEventDoc = doc(userEvents,eventData?.id)

  //     const userEventDocSnapshot = await getDoc(userEventDoc)
   
  //     console.log(!userEventDocSnapshot?.data()?.role)
  //     if(!userEventDocSnapshot?.data()?.role ){
  //       console.log(eventData)
  //           navigate("/role",{state:{eventData}})
  //     } 
  //     else{
        
  //           //save role to local host if role exists
  //           navigate("/carpool")
  //     }

  //   } catch (error) {
  //       setMsgType("failure")
  //       setIsLoading(false)
  //   }
    
  // }
  
 

  if(viewRating){
    return(
      <ViewRatings setViewRating={setViewRating} eventId={eventId}/>
    )
  }

  if((eventData?.ends.seconds - (Math.floor(Date.now() / 1000))) <= 0){
    return(
      <>
      {isLoading && <Spinner/>}
      {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
      {!isLoading  && showInstallButton && <DownloadPrompt setDeferredPrompt={setDeferredPrompt} deferredPrompt={deferredPrompt} setShowInstallButton={setShowInstallButton}/>}
      {!isLoading &&!showInstallButton && <div className='container' id='events'>
             <EventLargeCard eventDate={eventData?.eventDate} eventName={eventData?.eventName} location={eventData?.location} coverImg={eventData?.coverImg} eventVisibility={eventData?.eventVisibility} createdByUser={eventData.createdBy === user?.id}/>
             {/**container for  about and features */}
            <div style={styles.aboutAndFeatures}>
               {/**about events */}
             <About eventDescription={eventData?.description}/>
             {/**event features */}
             {eventData?.link && <Link link={eventData?.link}/>}
             <div style={styles.eventFeatures}>
              <h6 className='inter-light'>Rate this event</h6>
              <div style={{padding:"40px", display:"flex", justifyContent:"space-between", width:"100%"}}>
                <div style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"space-between"}}>
                  <StarRating starRating={starRating} onClick={starRate} />
                </div>
              </div>
              <TextRating eventId={eventId} userId={user?.id} username={user?.username || user?.displayName} profile={user?.profile}/>
             </div>
             <p  style={{textDecoration:"underline", color:"#8D68F0"}} onClick={()=>setViewRating(true)}>view event ratings</p>
            </div> 
  
      </div>}
      </>
    )
  }

  return (
    <>
    {showConfirmationBox && <ConfirmationBox  title="Login required" actionButtons={[{title:"go to login",onClick:()=>navigate("/login")}]} setShowConfirmationBox={setShowConfirmationBox} cancelBtn={true}/>}
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    {isLoading && <Spinner/>}
    {!isLoading && <div className='container' id='events' ref={containerRef}>
           <EventLargeCard eventDate={eventData?.eventDate} eventName={eventData?.eventName} location={eventData?.location} coverImg={eventData?.coverImg} eventVisibility={eventData?.eventVisibility} createdByUser={eventData.createdBy === user?.id}/>
           {/**container for  about and features */}
          <div style={styles.aboutAndFeatures}>
             {/**about events */}
           <About eventDescription={eventData?.description}/>
           {/* other link */}
           {eventData?.link && <Link link={eventData?.link}/>}
           {/**event features */}
           <div>
           <div style={styles.eventFeatures}>
           {eventData?.featureAvailable?.length >0 && <h6 className='inter-light'>Available for this event</h6>}
            <div style={styles.eventFeaturesContainer}>
            {eventData?.featureAvailable?.includes("carpool") &&
            <EventFeatures event={eventData} 
            onClick={()=>{
              if(!user?.id){
                setShowConfirmationBox(true)
              }else{
              localStorage.setItem("viewMode","carpool")
              localStorage.setItem("filterType","event")
              setViewMode("carpool")
              navigate("/carpools")
              }
            }} featureType="carpool"/>
            }
            {eventData?.featureAvailable?.includes("wishBlend") &&
            <EventFeatures event={eventData} 
              onClick={()=>{
                // if(!user?.id){
                //   console.log("clicked2")
                //   setShowConfirmationBox(true)
                // }else{
                navigate("/wishblend",{state:{createdBy:eventData.createdBy}})
                // }
              }} 
              featureType="wishBlend"/>}
            {eventData?.featureAvailable?.includes("getTicket") 
            && 
            <EventFeatures 
            event={eventData} 
            onClick={()=>{
              if(!user?.id){
                setShowConfirmationBox(true)
              }else{
              user?.id === eventData.createdBy ? navigate("/scan-ticket") : navigate("/get-ticket")
              }
            }} 
            featureType={eventData.createdBy === user?.id ? "scanTicket":"getTicket"}/>}
            {/*<EventFeatures event={event} handleClick={()=>navigate("/comingsoon")} featureType="experienceEvent"/> */}
            </div>
           
           </div>
           
           {eventData.createdBy === user?.id &&  <LongPurpleBtn name="Edit event" onClick={()=> navigate("/editevent",{state:{eventData}})}/>}
           </div>
          </div> 

    </div>}
    </>
  )
}

export default EventScreen