import React from 'react'

const styles = {
  card:{ 
    minHeight: "83px",
    width: "90%",
    backgroundColor: "#D9D9D9",
    borderRadius: "20px",
    padding: "11px 20px",
    color: "black",
    marginBottom:"10px",
 },
 nameDIV:{
    height: "20%",
    
 },
 middleDiv:{
    height: "60%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between"

 },
 lastDiv:{
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
 }

}

const CarpoolCard = ({cardInfo, onClick}) => {

    const {id,poolerName,poolerLoc,eventName, poolerDest,formattedDate} = cardInfo
  return (
    <div style={styles.card} onClick={()=>onClick(cardInfo)}>
      <div style={styles.nameDIV}>
        <p className='poppins-12px-400' style={{color: "#2F2F2F"}}>{poolerName}</p>
      </div>
      <div style={styles.middleDiv}>
        <p className='poppins-10px-300' style={{color: "#616263"}}>{poolerLoc} to {poolerDest}</p>
        <p>Free</p>
      </div>
      <div style={styles.lastDiv}>
        
        <p className='poppins-10px-300' style={{color:"#8E69F0"}}>{eventName || formattedDate}</p>

      </div>
    </div>
  )
}

export default CarpoolCard
