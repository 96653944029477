
import { useEffect, useState } from "react"
// import "../../../index.css"
import { useNavigate } from "react-router-dom"
import { db } from "../../../firebase/config"
import { collection, doc,  increment,  onSnapshot, runTransaction } from "firebase/firestore"
import { useAuth } from "../../../contextAPI/AuthContext"
import Hailers from "./Hailers"
import PoolerHS from "./PoolerHS"
import { useMsg } from "../../../contextAPI/ErrorMsgContext"
import Spinner from "../../../components/Spinner"

import { useApp } from "../../../contextAPI/AppContext"
import { sendMsgToRegToken, sendMsgToTopic, unRegisterFromTopic } from "../../../api"
import ConfirmationBox from "../../../components/ConfirmationBox"




const ContactAsPooler = ({yourPoolId:poolId})=>{
    
  
    const navigate = useNavigate()
    const {user,token} = useAuth()
    const [hailers, setHailer] = useState([])
    const [passengers, setPassengers] = useState([])
    const {messageToken} = user
    const [rejectedPassengers, setRejectedPassengers] = useState([])
    const [viewHailers, setViewHailers] = useState(false)
    const [poolStatus, setPoolStatus] = useState(null)
    const [poolData, setPoolData] = useState(null)
    const [isDisabled, setIsDisabled] = useState(false)
    const poolCollection = collection(db,"pool")
    const poolDoc = doc(poolCollection,poolId)
    const poolHailersSubcollection = collection(poolDoc, 'poolHailers') 
    const {setMsgType} = useMsg()
    const [isWaiting, setIsWaiting] = useState(true)
    const {eventData} = useApp()
    const usersRef = collection(db,"users")
    const [error, setError] =useState(null)

   

  
    
    useEffect(()=>{
   

        
        (async function(){
        
          try {
           
            // getsnapshot of pool
           
            const unsubscribePoolDocSnapShot = onSnapshot(poolDoc,(snapshot)=>{
              
                if(snapshot.exists()){
                  console.log(snapshot.data())
                   

                  if(snapshot.data().poolDate){
                    setPoolData({...snapshot.data()})
                  }else{
                    setPoolData({...snapshot.data(), poolDate: eventData.eventDate})
                  }


                  // if(snapshot.data().status === "cancelled"){
                  //   console.log("here again")
                  //   navigate("/role",{state:{eventData}})
                  //   return
                  // }
                  setIsWaiting(false)
                }
            },(error)=>{
              console.log(error)
              setMsgType("failure")
            })
          
            
            
            return()=> unsubscribePoolDocSnapShot()
          } catch (error) {
            setError("Error getting carpool Data")
            setMsgType("failure")
          }
          
          
        })()
       
 
      
 
    
    },[])



    useEffect(()=>{
     
          const unsubscribeHaillers = onSnapshot(poolHailersSubcollection, (snapshot) => {
            
            setRejectedPassengers([])
            setHailer([])
            setPassengers([])
            
            snapshot.docs.forEach((change) => {
              // Handle changes (added, modified, removed)
              const data = change.data()
              console.log(data)
              const hailerData = {
                id:change.id,
                ...data
              }
              if( ["created","accepted","joined"].includes(data.poolHailerStatus)  ){
                setPassengers((prev)=>[...prev,hailerData])

              }else if( data.poolHailerStatus === "rejected"){
                setRejectedPassengers((prev)=>[...prev,hailerData])
              }
               if(data.poolHailerStatus === "created"){
                setHailer((prev)=>[...prev,hailerData])
              }
              
            });
          },(error)=>{
            console.log(error)
            setError("Error getting hailers")
          });
          return () => unsubscribeHaillers();
  
    },[])

   const addNewPassenger = async (hailer) => {
      // change status of hailer to accepted

      const remainingHailer = hailers.filter(h=> h.id !== hailer.id)

      setHailer(remainingHailer)
      console.log(poolDoc.id)
      const hailerRef = doc(poolHailersSubcollection,hailer.id)
      console.log(hailer.id)
      
      const userRef = doc(usersRef,hailer.id)
      const userEventRidesRef = collection(userRef,"userPoolHistory")
      const eventRideRef = doc(userEventRidesRef,poolId)

      try {

        await runTransaction(db, async(transaction)=>{
        
          transaction.update(hailerRef,{poolHailerStatus:"accepted", acceptedByPoolerBy : new Date()})
          transaction.update(eventRideRef,{status: "accepted"})
          transaction.update(poolDoc,{hailerCount: increment(-1)})
        })
      //todo: update the hailersArray

      //  // update hailerDocRef in pool collection
      //   await updateDoc(hailerRef,{poolHailerStatus:"accepted", acceptrdByPoolerBy : new Date()})

      //   //update hailer's EventRide in user collection/rides collection
      //   await updateDoc(eventRideRef,{status: "accepted"})

      const newHailers = hailers.filter(h=> h.id !== hailer.id)
      setHailer(newHailers)

      if(hailer.messageToken){
        const notification = {
          title: "Hurray! new ride",
          body:`${user.username || user.displayName} has accepted your pool request for ${eventData.eventName}. Click Join ride`
        }
        sendMsgToRegToken(hailer.messageToken,notification,token,(error)=>{
          if(!error){

          }
        })
      }
       
      } catch (error) {
        console.log(error)
        setError("Error accepting request")
      }
    
      
   }

   const rejectHailer = async(hailer,type)=>{
      console.log(type)
      //update hailers status to rejected 
      const remainingHailer = hailers.filter(h=> h.id !== hailer.id)
      setHailer(remainingHailer)

      const remainingPassenger = passengers.filter(p=> p.id !== hailer.id)
      setPassengers(remainingPassenger)
      const hailerRef = doc(poolHailersSubcollection,hailer.id)

      const usersRef = collection(db,"users")
      const userRef = doc(usersRef,hailer.id)
      const userEventRidesRef = collection(userRef,"userPoolHistory")
      const eventRideRef = doc(userEventRidesRef,poolId)
     console.log(user.id)
      try {

        await runTransaction(db,async(transaction)=>{
          transaction.update(eventRideRef,{status: "rejectedByPooler"})
          if(type === "remove"){
            transaction.update(hailerRef,{poolHailerStatus:"removed", removedFromPoolBy: new Date()})
            if(hailer.messageToken){
              unRegisterFromTopic(poolId+"poolee", hailer.messageToken,token,(err)=>{
                if(!err){
                  console.log("successfully unregistered")
                }
              })
            }
            transaction.update(poolDoc,{pooleeCount: increment(-1)})
          }else{
           transaction.update(hailerRef,{poolHailerStatus:"rejected", rejecteBypoolerBy: new Date()})
           transaction.update(poolDoc,{hailerCount: increment(-1)})
          }

          
        })
        // await updateDoc(eventRideRef,{status: "rejectedByPooler"})
        // if(type === "remove"){
        //   await updateDoc(hailerRef,{poolHailerStatus:"removed", removedFromPoolBy: new Date()})
        // }else{
        //  await updateDoc(hailerRef,{poolHailerStatus:"rejected", rejecteBypoolerBy: new Date()})
        // }
        console.log(hailer.messageToken)
        if(hailer.messageToken && !type){
          const notification = {
            title: "Request rejected",
            body:`Ride rejected, check for others for ${eventData.eventName} event`
          }
          sendMsgToRegToken(hailer.messageToken,notification,token,(error)=>{
            if(!error){
              
            }
          })
        }else if(hailer.messageToken && type == "remove"){
          const notification = {
            title: "You were removed",
            body:`${user.username || user.displayName} removed you from a ${eventData.eventName} carpool`
          }
          sendMsgToRegToken(hailer.messageToken,notification,token,(error)=>{
            if(!error){
              
            }
          }) 
        }
        
      } catch (error) {
        console.log(error)
       setError("Error rejecting Hailer")
      }
   }

   const cancelPool = async()=>{
    setIsDisabled(true)
    try {
     

      await runTransaction(db,async(transaction)=>{
        transaction.update(poolDoc,{status:"cancelled"})

      })


      setPoolStatus("cancelled")
      if(messageToken){
      unRegisterFromTopic(poolId+"pooler",messageToken,token,(error)=>{
        if(!error){
          console.log("successfully unregistered")
        }
      })
      }
      const notification = {
        title: 'Oops!',
        body: "Pool has been cancelled"
      }
      
      sendMsgToTopic(poolId + "poolee",notification,token,(err)=>{
        if(!err){
          console.log("msg sent successfully")
        }
      })
      navigate("/carpools")
    } catch (error) {
      console.log(error)
      setError("Error cancelling pool")
    }
    setIsDisabled(false)
   }

   const reopen = async()=>{
    setIsDisabled(true)
    try {
      await runTransaction(db, async(transaction)=>{
        transaction.update(poolDoc,{status:"created"})

      })
   
      setPoolStatus("created")
    } catch (error) {
      console.log(error)
      setError("Error reopening pool")
    }
    setIsDisabled(false)
   }

    return(
        <>
       
      {
        isWaiting &&
        <Spinner/>
       ||
       
       (
      viewHailers && 
        
          <Hailers hailers={hailers} addNewPassenger={addNewPassenger} setViewHailers={setViewHailers} passengers={passengers} rejectPassenger={rejectHailer} isDisabled={isDisabled} />
       ||
       
         <PoolerHS passengers={passengers} hailers={hailers}  cancelPool={cancelPool} rejectPassenger={rejectHailer} isDisabled={isDisabled} poolDocRef={poolDoc} poolId={poolId} setIsDisabled={setIsDisabled} poolData={poolData}  addNewPassenger={addNewPassenger} />
       ) 
        }
       
        {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
        </>
    )
}

export default ContactAsPooler