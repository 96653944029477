
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ContactYourRide from '../screens/ContactYourRide/ContactYourRideScreen';
import Login from '../screens/LoginScreen';

import UserInfo from "../screens/UserScreen/UserScreen"
import { useAuth } from '../contextAPI/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { useEffect } from 'react';
import { auth, db } from '../firebase/config';

import { onAuthStateChanged, signInAnonymously } from 'firebase/auth';
import EventScreen from '../screens/EventsScreen/EventScreens';
import ClearToken from './ClearAuthToken';

import 'firebase/auth';

import NavBar from './NavBar';

import NotFoundScreen from '../screens/NotFoundScreen';
import EventsScreen from '../screens/EventsScreen/EventsScreen';
import ComingsoonPage from './ComingsoonPage';
import CreateEvent from '../screens/CreateEvent/CreateEvent';
import Profile from '../screens/Profile/Profile';
import EditProfile from '../screens/Profile/EditProfile';
import KYCList from '../screens/KYC/KYCList';
import ConfirmBasicInfo from '../screens/KYC/BasicInfo/ConfirmBasicInfo';
import ConfirmEmail from '../screens/KYC/Email/ConfirmEmail';
import ConfirmEmailCode from '../screens/KYC/Email/ConfirmEmailCode';
import ConfirmPhoneNumber from '../screens/KYC/PhoneNumber/ConfirmPhoneNumber';
import ConfirmPhoneNumberCode from '../screens/KYC/PhoneNumber/ConfirmPhoneNumberCode';
import ConfirmId from '../screens/KYC/ID/ConfirmId';
import ChooseId from '../screens/KYC/ID/ChooseId';
import UploadId from '../screens/KYC/ID/UploadId';
import VerificationInProcess from '../screens/KYC/VerificationInProcess';
import VerificationComplete from '../screens/KYC/VerificationComplete';
import History from '../screens/History/History';
import Spinner from './Spinner';
import Intro from '../screens/LoginIntro/Intro';
import CreateWishBlend from '../screens/WishBlend/CreateWishBlend';
import SuccessfulPage from '../screens/SuccessfulPage';
import WishBland from '../screens/WishBlend/WishBland';
import SuggestWishBlend from '../screens/WishBlend/SuggestWishBlend';
import SuggestedItems from '../screens/WishBlend/SuggestedItems';
import GetItems from '../screens/WishBlend/GetItems';
import GetInvite from '../screens/Invite/GetInvite';
import CreateTIcket from '../screens/Ticket/CreateTIcket';
import EventOwnerHS from '../screens/EventsScreen/EventOwnerHS';
import ScanTicket from '../screens/Ticket/ScanTicket';
import GetTicket from '../screens/Ticket/GetTicket';
import ChoosePicture from '../screens/CreateEvent/ChoosePicture';
import Scanner from '../screens/Ticket/Scanner';
 import Notification from './Notification';
import EditEvent from '../screens/CreateEvent/EditEvent';
import Carpools from '../screens/Carpools/Carpools';
import CarpoolForEvents from '../screens/CarpoolForEvents/CarpoolForEvents';
import Community from '../screens/Community/Community';
import WelcomeUser from '../screens/WelcomeUser';
import { useApp } from '../contextAPI/AppContext';
import Tips from '../screens/Tips';
import TicketScreen from '../screens/TicketsScreen/TicketScreen';
import CreatePool from '../screens/CreateCarpool/CreatePool';
import { clearCache } from '../Utils/indexedDBService';







const Nav = ()=>{

    const {isLoggedIn, setUser,  setIsLoggedIn, setToken,loading, setLoading,signIn, setSignIn} = useAuth()
    
    const {viewMode, intro} = useApp()
    
    useEffect(()=>{
     
      const refreshToken = async () => {
        const user = auth.currentUser;
      
          try {
            const token = await user.getIdToken(true);
            console.log(token);
            setToken(token);
          } catch (error) {
            console.error("Error refreshing token:", error);
          }
         
      };
    
      const intervalId = setInterval(refreshToken, 1000 * 60 * 60); // Refresh every 30 minutes
      
      return () => {
        clearInterval(intervalId); // Clear the interval when the component is unmounted
    
      };

    },[signIn])

    useEffect(() => {
      
    
      // Initial token refresh
      

       const unsubscribe =  onAuthStateChanged(auth, user => {
          if (user) {
           
            const userRef = doc(db, "users", user.uid);
            user.getIdToken(true).then((token)=>{
              console.log(token)
              setToken(token)
            }).catch(error=>{
            
              console.log(error)

            })
   
            getDoc(userRef)
            .then((docSnapshot) => {
              if (docSnapshot.exists()) {
                // Document data is available in docSnapshot.data()
                const userData = docSnapshot.data();
           
                setUser(userData)
                setIsLoggedIn(true)
                setLoading(false)
                
              }
              // else{
              //   console.log(user.uid)
              //   setUser({id:user.uid})
              //   setLoading(false)
                
              // } 
            })
            .catch((error) => {
              console.error("Error getting document:", error);
            });
            
            
    
          } else{
            setLoading(false)
          }
          // else {
         
          //   signInAnonymously(auth)
          //   .then(() => {
  
          //     setSignIn(true)
          //     console.log("signed in")
          //   })
          //   .catch((error) => {
          //       const errorCode = error.code;
          //       const errorMessage = error.message;
          //       console.log(errorCode,errorMessage)
          //       // ...
               
  
               
          //   });
           
          // }
          clearCache().then(()=>{}).catch(e=>console.log(e))
        },(error)=>{
         console.log(error)
        });

        return () =>  unsubscribe(); // Unsubscribe from the onAuthStateChanged listener
        
       }, []);


      if (loading) {	
        return (	
         <Spinner/>
        )	
      }

    return (
        <Router>
         <NavBar/>
        <Notification/>
        { (!isLoggedIn ) ? 
      
        (
            
        <Routes> 
          {
              <>
                <Route path='/login' element={<Login/>}/>
                <Route path="*" element={<NotFoundScreen />} />
                <Route path={"/"} element={<Intro/>}/>
                <Route path="/event-page" element={<EventScreen/>}/>
                <Route path="/invite" element={<GetInvite/>}/>
                <Route path= "/wishblend" element={<WishBland/>}/>
                <Route path='/get-items' element={<GetItems/>}/>
                <Route path='/suggest-item' element={<SuggestWishBlend/>}/>
                <Route path={"/success"} element={<SuccessfulPage/>}/>
              </>
          }

        </Routes>
        
        ) : (
       
          <Routes>
            
            {
              viewMode  && (
                (
                  <>
                  <Route path='/login' element={<EventsScreen/>}/>
            <Route exact path="/userinfo" element={<UserInfo/>} />
            {viewMode==="event" && <Route  path={'/'} element={<EventsScreen/>}/>}
            <Route path="/event-page" element={<EventScreen/>}/>
            <Route path ={"/carpool"} element={<ContactYourRide/>}/>
            {viewMode==="carpool" && <Route  path={'/'} element={<Carpools/>}/>}
            <Route  path={'/carpools'} element={<Carpools/>}/>
            <Route path={"/notfound"} element={<NotFoundScreen/>}/>
            <Route path={"/comingsoon"} element={<ComingsoonPage/>}/>
            <Route path={"/createevent"} element={<CreateEvent/>}/>
            <Route path={"/editevent"} element={<EditEvent/>}/>
            <Route path={"/profile"} element={<Profile/>}/>
            <Route path={"/edit-profile"} element={<EditProfile/>}/>
            <Route path={"/kyc-list"} element={<KYCList/>}/>
            <Route path={"/basic-info"} element={<ConfirmBasicInfo/>}/>
            <Route path={"/confirm-email"} element={<ConfirmEmail/>}/>
            <Route path={"/confirm-email-code"} element={<ConfirmEmailCode/>}/>
            <Route path={"/confirm-phone-number"} element={<ConfirmPhoneNumber/>}/>
            <Route path={"/confirm-phone-number-code"} element={<ConfirmPhoneNumberCode/>}/>
            <Route path={"/confirm-id"} element={<ConfirmId/>}/>
            <Route path={"/choose-id"} element={<ChooseId/>}/>
            <Route path={"/upload-id"} element={<UploadId/>}/>
            <Route path={"/photo-view"} element={<ConfirmPhoneNumberCode/>}/>
            <Route path="verifying" element={<VerificationInProcess/>}/>
            <Route path="verified" element={<VerificationComplete/>}/>
            <Route path={"/id-view"} element={<ConfirmPhoneNumberCode/>}/>
            <Route path={"/history"} element={<History/>}/>
            <Route path={"/spinforme"} element={<Spinner/>}/>
            <Route path={"/create-wish-blend"} element={<CreateWishBlend/>}/> 
            <Route path= "/wishblend" element={<WishBland/>}/>
            <Route path={"/success"} element={<SuccessfulPage/>}/>
            <Route path='/suggest-item' element={<SuggestWishBlend/>}/>
            <Route path='/suggested-item' element={<SuggestedItems/>}/>
            <Route path='/get-items' element={<GetItems/>}/>
            {/* <Route path={"/admin"} element={user.role === "admin" && <AdminScreen/> || <NotPermittedScreen/>}/> */}
            <Route path="/invite" element={<GetInvite/>}/>
            <Route path='/create-ticket' element={<CreateTIcket/>}/>
            <Route path="/event-owner-hs" element={<EventOwnerHS/>}/>
            <Route path="/scan-ticket" element={<ScanTicket/>}/>
            <Route path="/scanner" element={<Scanner/>}/>
            <Route path="/get-ticket" element={<GetTicket/>}/>
            <Route path="choose-cover-picture" element={<ChoosePicture/>}/>
            <Route path='/poolforevent' element={<CarpoolForEvents/>}/>
            <Route path="*" element={<NotFoundScreen />} />
            <Route path= "/community" element={<Community/>}/>
            <Route path='/tickets' element={<TicketScreen/>}/>
            <Route path='/tips' element={<Tips/>}/>
            <Route path='/create-pool' element={<CreatePool/>}/>
            </>
                )
              )

            }
            {
            
              (!viewMode|| (viewMode !== "event" && viewMode !=="carpool")) &&
              (
                <>
                  <Route path='/login' element={<WelcomeUser/>}/>
                  <Route path='/' element={<WelcomeUser/>}/>
                  <Route path="*" element={<NotFoundScreen />} />
                  <Route path="/event-page" element={<EventScreen/>}/>
                </>
              )
            }
          
          </Routes>
        )}
          {/* <MessageBox/> */}
      </Router>
    )
}

export default Nav