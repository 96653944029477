
import {  useNavigate } from "react-router-dom"
import "../../index.css"
import { useEffect, useState } from "react"
import { useAuth } from "../../contextAPI/AuthContext"
import ContactAsPooler from "./ContactAsPooler/ContactAsPooler"
import ContactAsPoolee from "./ContactAsPoolee/ContactAsPoolee"
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore"
import { db } from "../../firebase/config"

import { useApp } from "../../contextAPI/AppContext"
import { useMsg } from "../../contextAPI/ErrorMsgContext"
import Spinner from "../../components/Spinner"
import ConfirmationBox from "../../components/ConfirmationBox"


const ContactYourRide = ()=>{

    const navigate = useNavigate()
    const poolId = localStorage.getItem("activePool")
    const {yourPoolId, setYourPoolId,setPoolId,setEventData} = useApp()
    const [isWaiting, setIsWaiting] = useState(true)
    const [gottenEventData, setGottenEventData] = useState(false)
    const [gottenPoolData,setGottenPoolData] = useState(false)
    const {user} = useAuth()
    const eventId = localStorage.getItem("eventId")
    const eventCollectionRef = collection(db,"events")
    const eventDocRef = doc(eventCollectionRef,eventId)
    const [role, setRole] = useState(localStorage.role)
    const [error, setError] = useState(null)
    // check if any of the context is on defined
    
    useEffect(()=>{
        console.log(eventId)
        const {verification} =  user
        if(verification?.basicInfo !== "completed" ){
            
            navigate("/edit-profile")
        }
        
        if(!poolId){
            navigate("/not-found")
        }

     },[])

  
    useEffect(()=>{
          

        try {
         
            const unsubscribeEventSnapShop = onSnapshot(eventDocRef,(snapshot)=>{
                console.log(snapshot.exists())
                if(snapshot.exists()){
                  const eventData = {
                    eventId,
                    ...snapshot.data()
                  }
                  console.log(eventData)
                  setEventData(eventData)
                  setGottenEventData(true)
                  
                }else{
                   
                   setError("Event doesn't exist")
                   setEventData(null)
                   console.log("event doesnt exist")
                   setGottenEventData(true)
                }
            },(error)=>{
               console.log(error)
                setError("Error getting event")
            })
        
            return()=> unsubscribeEventSnapShop()
            } catch (error) {
              console.log(error)
              setError("Error getting event")
            }
       
       
    
        
    },[eventId])

    
    useEffect(()=>{
     
        console.log(poolId)
        if(poolId){
       
        const getActivePoolPoolee = async()=>{
            const usersCollection = collection(db, "users")
                const userDoc = doc(usersCollection,user.id)
                const userPoolHistory = collection(userDoc,"userPoolHistory")
                console.log(poolId)
                const userPoolRef = doc(userPoolHistory,poolId)

                try {
                    const userPool = await getDoc(userPoolRef)
                    userPool.data()
                    
                    
                  
                    if(userPool.data()?.role === "poolee"){
                        
                    
                        setPoolId(userPool.id)
                        setRole("poolee")
                        setGottenPoolData(true)
                    }
                   
                } catch (error) {
                    console.log(error)
                }
               
        }

        const getActivePoolPooler = async()=>{
            
            const poolsRef = collection(db,"pool")
            const poolRef = doc(poolsRef,poolId)
            try {
                
                const userPool = await getDoc(poolRef)

            if(userPool){
                console.log("getting this guy")
                setYourPoolId(userPool.id)
                setRole("pooler")
                setGottenPoolData(true)
            }
            } catch (error) {
                console.log(error)
            }
            
           
        }


        if(role === "poolee"){
            getActivePoolPoolee()
        }else{
            getActivePoolPooler()
        }
  

        // const getUserEventSnapShot = async() => {
            
        //     try {
        
        //         const usersCollection = collection(db, "users")
        //         const userDoc = doc(usersCollection,user.id)
        //         const userEvents = collection(userDoc,"userevents")
        //         const userEventDoc = doc(userEvents,eventId)
        //          setUserEventDocRef(userEventDoc)
        //         const userEventDocSnapshot =  await getDoc(userEventDoc)
        //         console.log(userEventDocSnapshot.data())
        //         // const userDocSnapshot = await getDoc(userDoc)
        //         if(!userEventDocSnapshot.exists()){
                  
        //             navigate("/notfound")
        //             return
        //         }
        //         const {role,poolId,yourPoolId}= userEventDocSnapshot.data()
        //         console.log(poolId)
        //         // const {approved} = userDocSnapshot.data()
        
        //         setRole(role)

        //             setPoolId(poolId)
           
        //             setYourPoolId(yourPoolId)

        //         if(( poolId  || yourPoolId )){
        //             console.log(true)
        //             setIsWaiting(false)
        //         }
                   
                
        //     } catch (error) {
        //          setMsgType("failure")
        //     }
            
        // }
        
        // getUserEventSnapShot()
    }

    },[poolId])


    useEffect(()=>{

        console.log(gottenEventData,gottenPoolData)
        if(gottenEventData && gottenPoolData){
            setIsWaiting(false)
        }

    },[gottenEventData,gottenPoolData])



    return(

       
        role =="poolee" ?
                  
            (isWaiting && <Spinner/>)
            ||
            ( <ContactAsPoolee setSwitchScreen={()=>navigate("/carpools")}/> ) 
 
        :
         (isWaiting  && <Spinner/>)
            ||
        (<ContactAsPooler yourPoolId={yourPoolId}/>)
        
        
    
    )

}

export default ContactYourRide


