


import React, { useEffect, useRef, useState } from 'react'
import GreyInputBox from '../../../components/InputsBox/GreyInputBox'
import FormError from '../../../components/FormError'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contextAPI/AuthContext'
import { validatePhone } from '../../../Utils/formValidation'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { sendPhoneVerificationToken } from '../../../api'

const styles = {
    pageTitle: {
        margin:"20px 0",
        width:"85%",
    
    },
    btnBox: {
        width:"100%",
        marginTop:"50px",
        
    },
    inputDiv:{
        width:"100%",
        padding: "20px 0"
    }
}

const PhoneNumber = () => {
   
     const [error, setError] = useState(null)
     const phoneRef = useRef()
     const navigate = useNavigate()
     const [isBtnDisabled, setIsBtnDisabled] = useState(false)
     const {user,setUser,token} = useAuth()
     const {phoneNumber,id} = user


     useEffect(()=>{
        phoneRef.current.value = phoneNumber
     },[])


    const confirmPhoneNumber  = () => {
       
        const error = validatePhone(phoneRef.current.value)
        setError(error)
        if(error){
            setIsBtnDisabled(true)
        }
        return error
    }

    const onNext = async() => {
       const error =  confirmPhoneNumber()
       
       if(error){
        
        return
        
       }
           
        setIsBtnDisabled(true)
            const data = {

            }
         
            if(phoneNumber !== phoneRef.current.value.trim()){
                data.phoneNumber = phoneRef.current.value
    
            try {
                const usersRef = collection(db, "users")
                const userRef = doc(usersRef, id)
           
                await updateDoc(userRef,data,{ merge: true })
                
                setUser(prev=>({
                 ...prev,
                 ...data
                }))
    
                sendPhoneVerificationToken(token,phoneRef.current.value,(error)=>{
                    if(!error){
                        navigate("/confirm-phone-number-code",{state:{validateNextPage:true}})
                    }else{
                        setIsBtnDisabled(false)
                    }
                })
               
            } catch (error) {
                setIsBtnDisabled(false)
            }
            }else{

                
                sendPhoneVerificationToken(token,phoneRef.current.value,(error)=>{
                    if(!error){
                        navigate("/confirm-phone-number-code")
                    }else{
                        setIsBtnDisabled(false)
                    }
                })

            }
                 
        
    }
  return (
    <div className='container'>
        <div style={styles.pageTitle}>
            <h1 className='poppins-20px-500'>Verify your phone number</h1>
            <div style={styles.inputDiv}>
                <GreyInputBox placeholder="08035762810" inputRef={phoneRef} onChange={confirmPhoneNumber}/>
                <FormError error={error}/>
            </div>
            <div style={styles.btnBox}>
            <LongPurpleBtn name="Next" onClick={onNext} inactive={isBtnDisabled}/>
            </div>
        </div>
    </div>
  )
}

export default PhoneNumber

