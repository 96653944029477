import React, { useEffect, useRef, useState } from 'react'
import { useNav } from '../../contextAPI/NavContaxt'
import { useApp } from '../../contextAPI/AppContext'
import { useNavigate } from 'react-router-dom'
import LongPurpleBtn from '../../components/Buttons/LongPurpleBtn'
import GreyInputBox from '../../components/InputsBox/GreyInputBox'
import Items from './Components/Items'
import LongLilacBtn from '../../components/Buttons/LongLilacBtn'
import { generateRandomString } from '../../Utils/util'
import { createEventWishBlends } from '../../firebase/controller/eventsController'
import ConfirmationBox from '../../components/ConfirmationBox'
import { useAuth } from '../../contextAPI/AuthContext'

const SuggestWishBlend = () => {
 
    const {setTitle,setShowNav,setNavFunction, setNavType} = useNav()
    const [itemNameLength, setItemNameLength] = useState(0)
    const itemNameRef = useRef()
    const {items, setItems} = useApp([])
    const [editItem, setEditItem] = useState(null)
    const [viewMode, setViewMode] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const eventId = localStorage.getItem("eventId")
    const navigate = useNavigate()
    const {isLoggedIn} = useAuth()
    const [error, setError] = useState(null)

    useEffect(()=>{
        setItems([])
        if(!eventId){
            navigate("/notfound")
        }
        itemNameRef.current.value = ""
        setTitle("WishBlend")

        setNavFunction(()=>()=>navigate("/wishblend"))
        setNavType("back")

        setShowNav(true)
    },[])
    
    const onEditItem = (item)=>{
        itemNameRef.current.value = item.itemName
        setEditItem(item.id)
        
    }
    
    const onSetItem = () =>{

        if(editItem){
            console.log(editItem)
            const newItems= items.map(item=>{
                if(item.id === editItem){
                    return {
                        id: editItem,
                        itemName:itemNameRef.current.value, 
                        status: "suggested",
                        promisedCount: 0,
                        itemCount:0
                    }
                }else{
                    return item
                }
            })
            setItems(newItems)
            setEditItem(null)
        }else{
            setItems(prev=>[...prev,{id:generateRandomString(10),itemName:itemNameRef.current.value.toLowerCase() ,status: "suggested", promisedCount: 0, itemCount:0}])
        }
        
        itemNameRef.current.value = ""
        setItemNameLength(0)
     
    }

    const saveSuggestion = () => {
         console.log(items)
         setIsDisabled(true)
         createEventWishBlends(eventId,items,(err)=>{
            if(err){
                console.log(err)
                setIsDisabled(false)
                setError("an error occured")
            }else{
                navigate("/success",{state:{path:"/wishblend",text:"Saved Successfully"}})
            }
         })
        
    }

  return (
    <>
    {!isLoggedIn && <ConfirmationBox errorType={true} title="Login" para="Login to suggest item" actionButtons={[{onClick: ()=>navigate("/login"), title: "Login"},{onClick: ()=>navigate("/wishblend"), title: "cancel"}]} />}
    {error && <ConfirmationBox title={"Oops! an error occurred"} para={`${error}. swipe down on your screen to refresh`} />}
    <div className='container' style={{padding: "30px 0 60px"}}>
        <h1 className='poppins-20px-500' style={{margin:"20px"}}>Suggest Ingredients</h1>
      { !viewMode && <div style={{padding:"10px 20px 0", width:"100%"}}>
    
        <GreyInputBox placeholder="item Name" inputRef={itemNameRef} onChange={()=>setItemNameLength(itemNameRef.current.value.length)} />
      

       { !editItem  && itemNameLength >0 && <h3 className='poppins-15px-200' style={{width:"100%", color: "#8E69F0", margin:"10px 0"}} onClick={onSetItem}>Add new Item</h3>}
       { editItem  && itemNameLength >0 && <h3 className='poppins-15px-200' style={{width:"100%", color: "#8E69F0", margin:"10px 0"}} onClick={onSetItem}>Edit Item</h3>}
       </div>}
       <div style={viewMode && {height:"inherit",overflow: "scroll", width:"100%",paddingBottom: "60px"}||{height:"inherit",overflow: "scroll", width:"100%"}}>
            {
                items.map((item)=><Items key={item.id} name={item.itemName}  onClick={()=>!viewMode ? onEditItem(item):{}}/>)
            }
       </div>
       <div style={{position:"fixed",bottom: "20px", width:"100%", padding: "0 20px"}}>
         {!viewMode &&<LongPurpleBtn name="Next" inactive={items.length == 0} onClick={()=>setViewMode(true)}/>}
         {viewMode && <LongLilacBtn name="Edit" onClick={()=>setViewMode(false)}/>}
         {viewMode &&<LongPurpleBtn name="Blend" inactive={items.length == 0 || isDisabled} onClick={saveSuggestion}/>}
       </div>
    </div>
    </>
  )
}

export default SuggestWishBlend
