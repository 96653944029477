// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { ReCaptchaEnterpriseProvider, initializeAppCheck} from "firebase/app-check"
import {connectAuthEmulator, getAuth} from "firebase/auth"
import {collection, connectFirestoreEmulator, getFirestore} from "firebase/firestore"
import {getMessaging, getToken, onMessage} from "firebase/messaging"
import { useAuth } from "../contextAPI/AuthContext";
import {getStorage} from "firebase/storage"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration


  let firebaseConfig = {
   
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  }

  




// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app)

const db = getFirestore(app)
// connectFirestoreEmulator(db, '127.0.0.1', 8083)
// connectAuthEmulator(auth,"http://127.0.0.1:9099")
const messaging = getMessaging(app)
const storage = getStorage(app)

// try {
//   const appCheck = initializeAppCheck(app,{
//     provider: new ReCaptchaEnterpriseProvider('6Ld-OA8qAAAAAAIizzhk2JTmVv8TCyZOQBk4UDVP'),
//     isTokenAutoRefreshEnabled: true
//   })
  
// } catch (error) {
//   console.log(error)
// }

const requestPermission = ()=> {
    console.log('Requesting permission...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        // setMsgToken(currentToken)
        console.log('Notification permission granted.');
        requestForToken()
       
      }
    })
  }

const requestForToken = async() =>{
    return getToken(messaging, { vapidKey: "BBAAD3gYJnbwjzP24RWOuvoHkP3aDdMJFeOpwNzNdKo-oEsaObkP7H6aSg4iYK0Bpgdrio3utEzY8QqMeqkOp1I" }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          
          console.log("tis token: ", currentToken )
          return currentToken
          // ...
        } else {
          // Show permission request UI
          requestPermission()
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
}


const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
    
  });

export  {
    auth,
    db,
    messaging,
    storage,
    requestForToken,
    onMessageListener
}