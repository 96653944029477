import React from 'react'
import FormError from '../FormError'

const styles = {
  padding: {
    paddingBottom: "5px"
  },
   formInput:{
    backgroundColor: "#202024",
    border: "none",
    padding: "0",
    height: "fit-content",
    width: "100%",
    resize: "none",
    outline: "none",
  }
}
function FormElement1({title, placeHolder, value,error,onChange,type}) {

  return (
    <div style={styles.padding} >
      <h4 className='inter-400'>{title}</h4>
      <textarea style={styles.formInput} className='poppins-20px-500' placeholder={placeHolder} value={value} onChange={(e)=>onChange(prev=>({...prev,[type]:e.target.value}))}/>
      <FormError error={error}/>
    </div>
  )
}

export default FormElement1
