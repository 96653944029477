import React from 'react'
import SendBtn from './SendBtn'

const styles = {
    chatInputDiv :{
        height: "12%",
        width: "100%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor:"#18181A",
        /* padding: 10px 0; */
      },
    
      chatInput:{
        width: "70%",
        height: "60%",
        borderRadius: "30px",
        backgroundColor:"#5A5A5A",
        color: "#f0f0f0",
        outline: "none",
        border: "none",
      }
}

const ChatInput = ({chatValue, handleChatInput,sendMsg}) => {
  return (
    <div style={styles.chatInputDiv}>
    <input style={styles.chatInput} placeholder='your message' value={chatValue} onChange={handleChatInput} />
     <SendBtn sendMsg={sendMsg}/>
  </div>
  )
}

export default ChatInput
