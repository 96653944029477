import React, { useEffect, useRef, useState } from 'react'
import GreyInputBox from '../../../components/InputsBox/GreyInputBox'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import FormError from '../../../components/FormError'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contextAPI/AuthContext'
import { validateEmail } from '../../../Utils/formValidation'
import { collection, doc, updateDoc } from 'firebase/firestore'
import { db } from '../../../firebase/config'
import { sendEmailVerificationToken } from '../../../api'


const styles = {
    pageTitle: {
        margin:"20px 0",
        width:"85%",
    
    },
    btnBox: {
        width:"100%",
        marginTop:"50px",
        
    },
    inputDiv:{
        width:"100%",
        padding: "20px 0"
    }
}
const Email = () => {

    
     const [error, setError] = useState(null)
     const emailRef = useRef()
     const navigate = useNavigate()
     const [isBtnDisabled, setIsBtnDisabled] = useState(false)

     const {user,setUser, token} = useAuth()
     const {email,id} = user
     
     console.log(email)

     useEffect(()=>{
        emailRef.current.value = email || ""

     },[])

    const confrimEmail = ()=>{
       
        const error = validateEmail(emailRef.current.value)
        setError(error)
        if(error){
            setIsBtnDisabled(true)
        }
        return error
        
    }

    const onNext = async()=> {
        
        const error = confrimEmail()

        if(error)
            return null

        const data = {

        }
        
        setIsBtnDisabled(true)
        if(email !== emailRef.current.value.trim()){
            data.email = emailRef.current.value.trim()
            try {
                const usersRef = collection(db, "users")
                const userRef = doc(usersRef, id)
           
                await updateDoc(userRef,data,{ merge: true })
                
                setUser(prev=>({
                 ...prev,
                 ...data
                }))
    
                sendEmailVerificationToken(token,emailRef.current.value,(error)=>{
                    if(!error){
                        navigate("/confirm-email-code",{state:{validateNextPage:true}})
                    }else{
                        setIsBtnDisabled(false)
                    }
                })
               
            } catch (error) {
                setIsBtnDisabled(false)
            }
        }else{
            sendEmailVerificationToken(token,emailRef.current.value,(error)=>{
                if(!error){
                    navigate("/confirm-email-code",{state:{validateNextPage:true}})
                }else{
                    setIsBtnDisabled(false)
                }
            })

        }
             

             
        
        
        
    }

  return (
    <div className='container'>
        <div style={styles.pageTitle}>
            <h1 className='poppins-20px-500'>Verify your email</h1>
            <div style={styles.inputDiv}>
                <GreyInputBox placeholder="email" inputRef={emailRef} onChange={confrimEmail}/>
                <FormError error={error}/>
            </div>
            <div style={styles.btnBox}>
            <LongPurpleBtn name="Next" onClick={onNext} inactive={isBtnDisabled}/>
            </div>
        </div>
    </div>
  )
}

export default Email
