import React from 'react'

const styles = {
   
   container: {
        minHeight:"18%",
        display:"flex",
        justifyContent: "center",
        alignItems:"center",
    },
    infoBox:{
      width: "80%",
      minHeight: "60%",
      display:"flex",
      justifyContent: "center",
      alignItems:"center",
      textAlign:"center",
      backgroundColor:"#252528",
      borderRadius:"20px",
      padding: "0 20px",
      color: "#ababab"
    }
    
}
const InfoBox = ({info}) => {

  console.log(info)
  return (
    <div style={styles.container}>
        <div style={styles.infoBox}>
            <p className='poppins-10px-300'> {info?.chat || "use @info in front of you massage to put an important information here"}</p>
        </div>
    </div>
  )
}

export default InfoBox
 