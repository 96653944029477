import React, { useEffect } from 'react'


const style = {
    
    width: "100%",
    padding: "20px",
    borderBottom: "20px" ,
    backgroundColor:" #18181A",
    borderRadius: "20px",
    marginTop:"10px",
    overflow:"hidden",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

}



const Link = ({link}) => {
  
  const regex = /^(http:\/\/|https:\/\/)/;
   const linkDir = !regex.test(link) ? "https://" + link : link.startsWith("http://") ? link.replace("http://", "https://") : link

  return (
    <div style={style}>
        <h2 className='poppins-15px-500'>Added link</h2>
        <a className='inter-light' href={linkDir} target='_blank'>
            {link}
        </a>
    </div>
  )
}

export default Link
