import React from 'react'

const Gmail = ({title,url}) => {

    const modifiedLink = url.replace(/ /g, "%20")
    
      const shareOnGmail = () => {
        window.open(`mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(modifiedLink)}`);
      };
     
  return (
    <div onClick={shareOnGmail}>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="white"/>
            <path d="M26.3765 11.7915L19.5729 17.2885L12.6138 11.7915V11.793L12.6222 11.8004V19.4976L19.4944 25.1135L26.3765 19.7146V11.7915Z" fill="#EA4335"/>
            <path d="M28.1632 10.4546L26.3765 11.7917V19.7147L31.9987 15.2458V12.5538C31.9987 12.5538 31.3162 8.70891 28.1632 10.4546Z" fill="#FBBC05"/>
            <path d="M26.3763 19.7145V29.9908H30.6855C30.6855 29.9908 31.9117 29.8601 32 28.4131V15.2456L26.3763 19.7145Z" fill="#34A853"/>
            <path d="M12.6222 30.0001V19.4979L12.6138 19.4905L12.6222 30.0001Z" fill="#C5221F"/>
            <path d="M12.6138 11.7932L10.8369 10.4635C7.68386 8.71784 7 12.5613 7 12.5613V15.2533L12.6138 19.4904V11.7932Z" fill="#C5221F"/>
            <path d="M12.6138 11.7935V19.4907L12.6222 19.4981V11.8009L12.6138 11.7935Z" fill="#C5221F"/>
            <path d="M7 15.2546V28.4221C7.08688 29.8706 8.31447 29.9999 8.31447 29.9999H12.6236L12.6138 19.4903L7 15.2546Z" fill="#4285F4"/>
    </svg>
    </div>
  )
}

export default Gmail
