import React from 'react'

const style = {
   backgroundColor: "#18181A",
   width: "100%",
   padding: "20px 20px",
   borderRadius: "10px",
   display: "flex",
   flexDirection: "column",
   margin: "10px 0"

}
const ProfileInfo = ({title, subtitle, onClick}) => {
  return (
    <div style={style} onClick={onClick}>
      <h1 className='poppins-15px-500'>{title}</h1>
      <h3 className='poppins-10px-300'>{subtitle}</h3>
    </div>
  )
}

export default ProfileInfo
