import React from 'react'

const style = {
    backgroundColor: "#2D2F3C",
    outline: "none",
    color: "#A0A3AF",
    border: "none",
    width: "100%",
    height: "40px",
    borderRadius: "15px"
}







const GreyInputBox = ({placeholder, inputRef,onChange, required,type}) => {
  return (
    <input style={style} placeholder={placeholder} ref={inputRef} onChange={onChange} required={required} type={type || "text"} ></input>
  )
}

export default GreyInputBox
