import React from 'react'
import { useAuth } from '../../contextAPI/AuthContext'
import { collection, doc, getDoc } from 'firebase/firestore'
import { db } from '../../firebase/config'
import { useNavigate } from 'react-router-dom'

const styles = {
    container :{
        borderRadius: "10px",
        minheight: "50px",
        width: "100%",
        display: "flex",
        padding: "5px 10px",
        backgroundColor : "#18181A",
        margin: "5px 0",
        justifyContent: "space-between"
    },
    eventDetails:{
       width: "65%",
       height: "100%",
      marginLeft: "10px",
      display:"flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    
    fare:{
        width: "30%",
        display: "flex",
        justifyContent: "center",
        alignitems : "center",
        height: "100%",
        display:"flex",
        flexDirection: "column",
        justifyContent: "center",
    }
}




const EventHistory = ({event}) => {
   
  

    const {location, eventName, createdBy} = event


    const {user} = useAuth()
    const eventCollection = collection(db, "events")
    const navigate = useNavigate()


    const gotToEvent = async() => {
        //store the event chosen in database and local host
        // const event = events.find(event=> event.id === eventId)
    
        //check if event exists //later check for event status too
        try {
       
        const eventDocRef = doc(eventCollection,event.id)
        const eventDoc = await getDoc(eventDocRef)
        console.log(eventDoc.exists())
       
        if(!eventDoc.exists()){ 
          
          
                navigate("/notfound",{state:{eventsLink:"/events"}})
          
            return
        }
            
       
        localStorage.setItem("eventName",event.eventName)
        localStorage.setItem("eventId",event.id)
        localStorage.setItem("shortcutEventId",event.id)
        localStorage.setItem("createdBy",event.createdBy)
        //setChosenEvent(eventDoc)
    
        if(event.createdBy === user.id){
     
          navigate("/event-owner-hs")
          return
        }
        navigate("/event-page") 
        } catch (error) {
            console.error(error)
         
        }
        
    
        
      }
  return (
    <div style={styles.container} onClick={gotToEvent}>
    <div style={styles.eventDetails}>
      <p className='poppins-15px-500'>{eventName}</p>
      <p className='poppins-10px-275'>
          {location}
      </p>
    
    </div>
 
    <div style={styles.fare}>
      <p style={{alignSelf:"center"}} className='poppins-10px-275'>{user.id === createdBy && "Created by you"}</p>
    </div>
  </div>
  )
}

export default EventHistory
