import React from 'react'
import LongPurpleBtn from './Buttons/LongPurpleBtn'
import { useApp } from '../contextAPI/AppContext'
import { useNavigate } from 'react-router-dom'

const CarpoolCTA = ({user,eventData}) => {
 
  const {setViewMode} = useApp()
  const navigate = useNavigate()
  
  return (
    <div style={{backgroundColor:"#18181a", width:"100%", flex:1, borderRadius:"15px", padding:"10px", marginBottom:"10px"}}>
      <h1 className='poppins-17px-300' style={{ marginBottom:"10px"}}>
        Hello {user.username || user.displayName} 👋
      </h1>
      <p className='poppins-14px-40'>
      Exciting news! You're attending <span style={{fontSize:"17px"}}>{eventData.eventName}</span> 😏! 
      How about contributing to our environmental goals by reducing your carbon footprint 
      and maybe even making new friends along the way? 
      Use our carpool feature to attend the event with someone else. 
      It's a great way to connect and help the environment at the same time!
      </p>
      <div style={{width:"100%", marginTop:"10px"}}>
      <LongPurpleBtn name="Try out Carpool" onClick={()=>{
        localStorage.setItem("viewMode","carpool")
        localStorage.setItem("filterType","event")
        localStorage.setItem("shortcutEventId", eventData.id)
        localStorage.setItem("eventName", eventData.eventName)
        setViewMode("carpool")
        navigate("/")
      }}/>
      </div>
    </div>
  )
}

export default CarpoolCTA
