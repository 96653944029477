import React, { useEffect, useState } from 'react'
import TipsForPoolees from '../components/TipsForAJollyRide/TipsForPoolees'
import { useNav } from '../contextAPI/NavContaxt'
import PoolTypeSwitch from './CreateCarpool/components/PoolTypeSwitch'
import TipsForPoolers from '../components/TipsForAJollyRide/TipsForPoolers'
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../Utils/util'
import { useNavigate } from 'react-router-dom'
import BottomNavCarpool from '../components/BottomNav/BottomNavCarpool'

const Tips = () => {

  const {setTitle}= useNav()
  const [poolSwitch, setPoolSwitch] = useState("For Pooler")
  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const navigate = useNavigate()

  useEffect(()=>{
    setTitle("Tips")
  },[])
  return (
  
    <div className='container'
      onTouchStart={(e)=>{handleTouchStart(e,setStartX)}}
      onTouchMove={(e)=>{handleTouchMove(e,setEndX)}}
      onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,(direction)=>{
      if(direction ==="left"){
        if(poolSwitch ==="For Pooler"){
          setPoolSwitch("For Poolee")
        }else{
          navigate("/community")
        }
        
      }else{
        if(poolSwitch ==="For Pooler"){
          navigate("/")
        }else{
          setPoolSwitch("For Pooler")
          
        }
      }
    })}
    >
    <PoolTypeSwitch poolSwitch={poolSwitch}  setPoolSwitch={setPoolSwitch} text1="For Pooler" text2="For Poolee"/>
    <div style={{margin:"40px 0"}}
      
    >
        {poolSwitch ==="For Poolee" && <TipsForPoolees noBlink={true}/>}
        {poolSwitch ==="For Pooler" && <TipsForPoolers noBlink={true}/>}
     </div>
     <BottomNavCarpool selectedIndex={1}/>
    </div>
    

  )
}

export default Tips
