import React, { useState } from 'react'


const styles = {
    container: {
        width:"100%",
        display:"flex",
        justifyContent: "flex-start",
        alignItems:"center",
        marginBottom:"10px",
        overflow:"hidden"
    },
   

}
const Detail = ({title, value}) => {

  return (
    <div style={styles.container}>
     
        <h4 className='poppins-20px-500' style={{width:"60%"}}>{title}</h4>
        <p className='inter-light' style={{width:"40%"}}>{value}</p>
    </div>
  )
}

export default Detail
