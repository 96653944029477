import React from 'react'


const ProfileDiv1 = ({profile}) => {


  return (
    <div style={{width:"40px", height:"40px", backgroundColor:"#ffffff", borderRadius:"100%", marginBottom:"10px"}}>
          <img src={profile} style={{width:"100%", height:"100%", borderRadius:"100%",objectFit:"cover"}}></img> 
    </div>
  )
}

export default ProfileDiv1
