import React from 'react'

const styles = {
    eventCal :{
        width: "40px",
        height: "40px",
        backgroundColor: "#FEFEFE",
        position: "absolute",
        right:"16px",
        top: "20px",
        borderRadius: "5px",
        color: "#8E69F0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px",
        fontSize: "small",
      },
      lilac: {
        color: "#8E69F0",
      }
}
const EventCal = ({day,shortMonth}) => {
  return (
    <div style={styles.eventCal}>
    <p className='poppins-15px-400' style={styles.lilac}>{day}</p>
    <p className='poppins-10px-400' style={styles.lilac}>{shortMonth}</p>
   </div>
  )
}

export default EventCal
