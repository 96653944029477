import React from 'react'
import Detail from './EventDetail'
const style = {
    margin: {
      margin:"30px 0"
    }
}

const TicketDetails = ({eventData}) => {
  return (
    <div style={{flex:"1", width:"100%"}} >
    <div style={style.margin}>
        <Detail title="Event Name" value={eventData?.eventName}/>
    </div>
    <div style={style.margin}>
        <Detail title="Event Price" value={eventData?.ticket?.price}/>
    </div>
    <div style={style.margin}>
        <Detail title="Event Location" value={eventData?.location}/>
    </div>
   
  </div>
  )
}

export default TicketDetails
