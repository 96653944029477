import React, { useEffect } from 'react'

const styles = {
    btn: {
        width: "40px",
        height: "40px",
        borderRadius: "10px"
    },
    div:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        marginTop: "10px"
    },
    inp:{
        width: "40px",
        height: "40px",
        backgroundColor:"#202024",
        border:"none",
        outline: "none",
        color: "white",
        textAlign: "center",
        padding: "0"
    }
}
const IncrementBox1 = ({setItemCount, itemCount}) => {
    useEffect(()=>{
        console.log(itemCount)
    },[itemCount])
  return (
    <div style={styles.div}>
      <button style={{...styles.btn, backgroundColor:"#2C2E3A"}} onClick={()=>setItemCount(prev=>prev > 0 ? --prev:0)}>-</button> 
      <input style={styles.inp} value={itemCount} />
      <button style={{...styles.btn,backgroundColor:"#8E69F0"}} onClick={()=>setItemCount(prev=>prev < 50 ? ++prev:50)}>+</button> 
    </div>
  )
}

export default IncrementBox1
