import React, { createContext, useContext, useState } from 'react';


const ErrMsgContext = createContext();

export const ErrorMsgProvider = ({ children }) => {
  
const [msgType,setMsgType] = useState(null)
const [msg, setMsg] = useState("")
 

  return (
    <ErrMsgContext.Provider value={{msgType,setMsgType,msg, setMsg}}>
      {children}
    </ErrMsgContext.Provider>
  );
};

export const useMsg = () => {
  return useContext(ErrMsgContext);
};
