import React, { useEffect, useState } from 'react'
import LongPurpleBtn from '../../../components/Buttons/LongPurpleBtn'
import ProfileInfo from '../../../components/ProfileInfo'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../contextAPI/AuthContext'
import VerificationComplete from '../VerificationComplete'
import BackFunctionBtn from '../../../components/Buttons/BackFunctionBtn'
import { findNextKYC } from '../../../Utils/util'
import { useApp } from '../../../contextAPI/AppContext'
import { useNav } from '../../../contextAPI/NavContaxt'

const styles = {
    pageTitle: {
        margin:"20px 0",
        width:"85%",
       textAlign: "center",
    },
   
    inputDiv:{
        width:"100%",
        padding: "20px 0"
    },
    infoBox: {
        color:"#FFFFFF",
        margin: "10px 0",

    },
    infoBoxDiv:{
        width: "100%",
        padding: "0 20px",
        marginBottom: "20px"
    }
    ,
    resendCode: {
        textAlign: "right",
        width:"100%",
        paddingTop: "10px",
        color: "#8E69F0",
        font: "Poppins",
        fontWeight: "300",
        fontSize: "15px"
    },
    btnBox: {
        width:"100%",
        position: "absolute",
        bottom:"40px",
        padding: "0 20px",
        marginTop:"50px",
    }

}


const ChooseId = () => {

    const navigate = useNavigate()
    const {user} = useAuth()
    const {verification} = user
    const [next, setNext] = useState("/kyc-list")
    const {verificationList, nextList} = useApp()
    const KYCnumber = 3
    const {setNavFunction, setNavType} = useNav()

   

    useEffect(()=>{

        const next = findNextKYC(KYCnumber, verificationList)
        console.log(next)
        if(next !== -1){
            setNext(nextList[next])
        }
        setNavFunction(()=>{
            return ()=>navigate(verification?.id === "completed" ? navigate("/kyc-list") : navigate("/confirm-id"))
          })
          setNavType("back")
        
    },[])

  return (
    <>
     {
        
        // <BackFunctionBtn onClick={()=>verification?.id === "completed" ? navigate("/kyc-list") : navigate("/confirm-id")}/>

    }
    {verification?.id === "completed" && <VerificationComplete/>}
    {verification?.id !== "completed" &&
    <div className='container'>
        <div style={styles.pageTitle}>
         <h1 className='poppins-20px-500'>Upload documents</h1>
        </div>
        <div style={styles.infoBoxDiv}>
            <p className='poppins-14px-400' style={styles.infoBox}>Kindly upload a means on identification for security reasons</p>
        </div>
       <ProfileInfo title="National ID card"  onClick={()=>navigate("/upload-id",{state:{type:"NID"}})}/>
       <ProfileInfo title="Driver's License"  onClick={()=>navigate("/upload-id",{state:{type:"DL"}})}/>
       <ProfileInfo title="International Passport"  onClick={()=>navigate("/upload-id",{state:{type:"IP"}})}/>
       <div style={styles.btnBox}>
        <LongPurpleBtn name="Next" onClick={()=>navigate(next)}/>
       </div>
    </div>
    }
    </>
  )
}

export default ChooseId
