import { arrayUnion, collection, doc, increment, runTransaction } from "firebase/firestore"
import { db } from "../config"


const usersRef = collection(db,"users")
const poolsRef = collection(db,"pool")


export const carpoolRequestTransaction = async(data)=>{

    const {eventName, eventId,profile, poolId, eventLocation,userId, poolerLoc, poolerName,costPerSeat, username, phoneNumber,active,location,destination,poolAdmin, poolDate,repeat,poolType, poolerDest} = data

   

    const userPoolData = {
      poolerLoc,
      poolerName, 
      costPerSeat, 
      status:"requested", 
      active, 
      role:"poolee",
      poolAdmin ,
      poolType,
      
    }

    if(poolType === "pool"){
      userPoolData.eventName = eventName
      userPoolData.eventId = eventId
    }else if(poolType === "default"){
      userPoolData.poolDate = poolDate
      userPoolData.repeat = repeat
      userPoolData.poolerDest = poolerDest
    }

    if(eventLocation){
      userPoolData.eventLocation = eventLocation
    }
    
   try {
    

    await runTransaction(db,async(transaction)=>{

        

    
        const userRef = doc(usersRef,userId)
        const userPoolHistoryRef = collection(userRef,"userPoolHistory")
        console.log(poolId)
        const userPoolRef = doc(userPoolHistoryRef,poolId)
       
      
        transaction.set(userPoolRef,userPoolData, {merge:true})
       
        //save data to poolHailers

        const poolRef = doc(poolsRef, poolId)

        transaction.update(poolRef,{[`poolees.${userId}`]: "created",hailerCount: increment(1)})
       
        const poolHailersRef = collection(poolRef, "poolHailers")
        const poolHailerRef = doc(poolHailersRef,userId)
       const poolHailerData = {
        username,
        poolHailerStatus:"created", 
        phoneNumber,
        createdOn: new Date(),
       }

       if(profile){
        poolHailerData.profile = profile
       }
       if(location){
        poolHailerData.location = location
       }else if(poolType == "pool"){
        poolHailerData.location = "eventLoc"
       }
       if(destination){
        poolHailerData.destination = destination
       }else if(poolType == "pool"){
        poolHailerData.destination = "eventDest"
       }



        transaction.set(poolHailerRef,poolHailerData)
        localStorage.setItem("activePool", poolId)
    })
   } catch (error) {
     throw error
   }
}