import React, { useEffect, useState } from 'react'
import { Html5Qrcode } from 'html5-qrcode'
import { scanTicket } from '../../firebase/controller/eventsController'
import { useNavigate } from 'react-router-dom'
import { formatTimestamp } from '../../Utils/util'
import { useNav } from '../../contextAPI/NavContaxt'



const styles = {
    container: {
        height:"100%",
        width: "100%",
        backgroundColor:"#202024",
        display:"flex",
        flexDirection:"column",
        alignItems:"center"
    }
}


const Scanner = () => {
  const [isScannerRunning, setIsScannerRunning] = useState(false);
  const [ticketDetail,setTicketDetail] = useState({})
 
  const eventId = localStorage.getItem("eventId")
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const [scanner, setScanner] = useState(null)
  const {setNavFunction} = useNav()
  const [cameraId, setCameraId] = useState(null)

  useEffect(()=>{
    if(!eventId){
          navigate("/")
          return
      }
    setNavFunction(()=>()=> {
       navigate("/scan-ticket")
    })
    setScanner(new Html5Qrcode("reader"))
    
  },[])

  useEffect(()=>{
      if(scanner){
        qrCodeScanner()
        return()=>{
          console.log(scanner)
          scanner.stop().then((ignore) => {
            // QR Code scanning is stopped.
          }).catch((err) => {
            // Stop failed, handle it.
            console.log(err)
          });
        }
        
      }
  },[scanner])

  
  
 
  
  function qrCodeScanner(){
   
   setIsScannerRunning(true)
   
    Html5Qrcode.getCameras().then(devices => {
      /**
       * devices would be an array of objects of type:
       * { id: "id", label: "label" }
       */
      console.log(devices, devices.length)
      if (devices && devices.length) {
        
        const backCamera = devices.filter(device => device.label.includes('back') || device.label.includes('rear'));
        const preferredCamera = devices.find(device => device.label.includes('preferredCameraLabel'));
        const cameraId = preferredCamera ? preferredCamera.id :(backCamera.length == 0 ?  devices[0].id: (backCamera.length > 1 ? backCamera[1].id :backCamera[0].id)  ); // Use the first camera if back camera not found
        setCameraId(cameraId)
        // .. use this to start scanning.
        scanner.start(
          cameraId, 
          {
            fps: 10,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
          },
          (decodedText, decodedResult) => {
         
            // do something when code is read
             

            scanner.stop().then((ignore) => {
              // QR Code scanning is stopped.
              setIsScannerRunning(false);
              qrCodeScanner()
            }).catch((err) => {
              // Stop failed, handle it.
              setIsScannerRunning(false);
              setError("there is an error with the camera, please refresh")
              console.log(err)
            })

            const ticket = JSON.parse(decodedText)
            console.log(typeof(ticket));
              if(typeof(ticket) === 'object'){
             
              console.log(ticket)
              if(eventId === ticket?.eventId){
              scanTicket(ticket.ticketId,(err,data)=>{
                if(!err && data){
                  console.log(data)
                  setTicketDetail(data)
                 
                  
                }else if(!err && !data){
                 
                   setTicketDetail(null)
                }else{
                  console.log(err)
                  setError("Error scanning ticket, something isn't right")
                }
              })
            }else{
              setError("invalid ticket, there's an imposter in our midst")
            }
          }else{
             setError("invalid ticket, there's an imposter in our midst")
          }
          },
          (errorMessage) => {
            // parse error, ignore it.
            
          })
       
      }
    }).catch(err => {
      // handle err
        setError("Error starting camera")
      console.log(err)
    });
  }

 

  
  
 
 
  // if(ticketDetail?.status === false &&  showMessage){
  //   return(
  //     <ConfirmationBox title="Ticket confirmed successfully" actionButtons={actionButtons}/>
  //   )
  // }
   
  // if(ticketDetail?.status === true && showMessage){
  //   return(
  //     <ConfirmationBox title="Ticket confirmed failed" para="Ticket has been used " actionButtons={actionButtons}/>
  //   )
  // }
  //  if(showMessage){
  //   return(
  //     <ConfirmationBox title="Invalid Ticket" para="There is an imposter in our midst" actionButtons={actionButtons}/>
  //   )
  // }

  return (
    <>
     
    <div style={styles.container} >
      
          <div id='reader' style={{width:"100%",height:"250px",display:"flex"}}></div>
          <div style={{display:"flex", flexDirection:"column",alignItems:"flex-start",paddingTop:"10px", width:"90%",flex:"1",color:"#ffffff"}}>
            <p className='poppins-14px-400' style={{margin:"10px 0"}}>{ticketDetail.id}</p>
            <p className='poppins-14px-400' style={{margin:"10px 0"}}>{ticketDetail.userName}</p>
            <p className='poppins-14px-400' style={{margin:"10px 0"}}>{ticketDetail.scannedAt && formatTimestamp(ticketDetail.scannedAt)}</p>
            {ticketDetail.status === true && <p className='poppins-14px-400' style={{margin:"10px 0", color:"red"}}>Already scanned</p> }
            {ticketDetail.status === false && <p className='poppins-14px-400' style={{margin:"10px 0", color:"green"}}>Accepted</p> }
            {ticketDetail.status === "requested" && <p className='poppins-14px-400' style={{margin:"10px 0", color:"red"}}>Needs Approval</p> }
            <p className='poppins-14px-400' style={{margin:"10px 0",color:"red"}}>{error}</p>
          </div>
           

    </div>
    </>
  )
}

export default Scanner
