import React from 'react'



const StarRating = ({starRating, onClick}) => {

 
  return (
    <>
        
        {
            Array.from({ length: 5 }).map((object, index)=> 
            {  
            if(index+1 <=starRating){
                return(
                    <svg width="20" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>onClick(index+1)}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4864 4.11437C11.1608 2.9521 12.8392 2.95211 13.5136 4.11437L15.579 7.67373C15.7694 8.00182 16.0984 8.22571 16.4735 8.28236L19.9031 8.80036C21.3981 9.02617 21.9225 10.9138 20.7582 11.8783L18.2593 13.9487C17.9086 14.2392 17.7437 14.6976 17.8289 15.1451L18.5289 18.821C18.8031 20.261 17.2914 21.3798 15.9944 20.6968L12.5824 18.9003C12.2178 18.7083 11.7822 18.7083 11.4176 18.9003L8.00554 20.6968C6.70853 21.3798 5.1969 20.261 5.4711 18.821L6.17108 15.1451C6.25628 14.6976 6.09136 14.2392 5.74062 13.9487L3.24172 11.8783C2.07743 10.9138 2.60184 9.02617 4.09683 8.80036L7.52648 8.28236C7.90154 8.22571 8.23059 8.00182 8.42096 7.67373L10.4864 4.11437ZM12.2162 4.8672C12.1199 4.70116 11.8801 4.70116 11.7838 4.8672L9.71836 8.42656C9.29954 9.14835 8.57563 9.64091 7.75049 9.76553L4.32085 10.2836C4.10728 10.3158 4.03236 10.5855 4.19869 10.7233L6.69759 12.7936C7.46922 13.4329 7.83205 14.4413 7.6446 15.4256L6.94462 19.1016C6.90545 19.3073 7.12139 19.4672 7.30668 19.3696L10.7188 17.573C11.5207 17.1507 12.4793 17.1507 13.2812 17.573L16.6933 19.3696C16.8786 19.4672 17.0945 19.3073 17.0553 19.1016L16.3554 15.4256C16.1679 14.4413 16.5307 13.4329 17.3024 12.7936L19.8013 10.7233C19.9676 10.5855 19.8927 10.3158 19.6791 10.2836L16.2495 9.76553C15.4243 9.64091 14.7004 9.14835 14.2816 8.42656L12.2162 4.8672Z" fill="white"/>
                        <path d="M8.39998 6.92448L8.39998 6.92448C8.28576 7.12133 8.08833 7.25567 7.86331 7.28965L7.86329 7.28965L2.77697 8.05788C1.49556 8.25142 1.04606 9.86932 2.04403 10.6961L5.83311 13.8353C5.83311 13.8353 5.83311 13.8353 5.83312 13.8353C6.04357 14.0097 6.14251 14.2847 6.09139 14.5532L6.58257 14.6467L6.09139 14.5532L5.10022 19.7584C4.86518 20.9926 6.1609 21.9515 7.27258 21.3662L7.2726 21.3662L12.062 18.8443C12.062 18.8443 12.062 18.8443 12.062 18.8443C12.2807 18.7292 12.5421 18.7292 12.761 18.8443L17.5503 21.3662L17.5503 21.3662C18.6619 21.9515 19.9577 20.9926 19.7226 19.7584L18.7315 14.5532C18.6804 14.2847 18.7793 14.0097 18.9897 13.8353C18.9897 13.8353 18.9897 13.8353 18.9898 13.8353L22.7788 10.6961L22.4697 10.323L22.7788 10.6961C23.7767 9.86935 23.3274 8.25143 22.0459 8.05788L16.9596 7.28965L16.9596 7.28965C16.7346 7.25567 16.5372 7.12135 16.4229 6.92445L13.7088 2.24721C13.1309 1.25094 11.6921 1.25093 11.1141 2.24717C11.1141 2.24718 11.1141 2.24719 11.114 2.24721L8.39998 6.92448Z" fill="#FFD521" stroke="#FFD521"/>
                    </svg>
                )
            }else{
                return(
                    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>onClick(index+1)}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.4864 1.11413C9.1608 -0.0481396 10.8392 -0.0481297 11.5136 1.11413L13.579 4.67349C13.7694 5.00158 14.0984 5.22547 14.4735 5.28212L17.9031 5.80012C19.3981 6.02592 19.9225 7.91354 18.7582 8.87804L16.2593 10.9484C15.9086 11.2389 15.7437 11.6973 15.8289 12.1448L16.5289 15.8207C16.8031 17.2607 15.2914 18.3795 13.9944 17.6965L10.5824 15.9C10.2178 15.708 9.7822 15.708 9.4176 15.9L6.00559 17.6965C4.70858 18.3795 3.19695 17.2607 3.47115 15.8207L4.17113 12.1448C4.25633 11.6973 4.09141 11.2389 3.74067 10.9484L1.24177 8.87804C0.0774804 7.91354 0.60189 6.02592 2.09688 5.80012L5.52653 5.28212C5.90159 5.22547 6.23064 5.00158 6.42101 4.67349L8.4864 1.11413ZM10.2162 1.86696C10.1199 1.70092 9.8801 1.70092 9.7838 1.86696L7.71841 5.42632C7.29959 6.14811 6.57568 6.64067 5.75054 6.76529L2.3209 7.28334C2.10733 7.31554 2.03241 7.58524 2.19874 7.72304L4.69764 9.79334C5.46927 10.4326 5.8321 11.441 5.64465 12.4253L4.94467 16.1013C4.9055 16.307 5.12144 16.4669 5.30673 16.3693L8.7188 14.5727C9.5207 14.1504 10.4793 14.1504 11.2812 14.5727L14.6933 16.3693C14.8786 16.4669 15.0945 16.307 15.0553 16.1013L14.3554 12.4253C14.1679 11.441 14.5307 10.4326 15.3024 9.79334L17.8013 7.72304C17.9676 7.58524 17.8927 7.31554 17.6791 7.28334L14.2495 6.76529C13.4243 6.64067 12.7004 6.14811 12.2816 5.42632L10.2162 1.86696Z" fill="white"/>
                    </svg>
                )
            } 
            
            }
            )
        }
        
    </>
  )
}

export default StarRating
