import React, { useEffect } from 'react'
import { useNav } from '../contextAPI/NavContaxt'
import { useNavigate } from 'react-router-dom'

const ComingsoonPage = () => {

    const {setShowNav, setTitle, setNavFunction, setNavType} = useNav()
    const navigate = useNavigate()

    useEffect(()=>{
        
        setTitle("Coming soon")
        setNavFunction(()=>()=>navigate("/createevent"))
        setNavType("back")
    },[])
  return (
    <div className='island'>
   
      <p className='page-sub-title'>We are working on some
new features.</p>
     
    </div>

  )
}

export default ComingsoonPage
