
import { Timestamp, collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore"

import { db } from "../firebase/config"
import { useState } from "react"



export const completelyRandomPath =  "evelyndear"


export const randomRequests = async()=>{
     
    let x =0

    while(x<10){

    const poolInfo = {
        poolerLoc: randomLoc(),
        convPULoc: generateRandomString(10),
        requesterId: generateRandomString(11),
        status: "created",
        seats: 4,
        poolType: randomPoolType(),  
    }

    if(poolInfo.poolType === "carpool"){
      delete poolInfo.seats
    }
    
    const requestCollection = collection(db,"request")
    const requestDoc = doc(requestCollection)
    await setDoc(requestDoc,poolInfo)
   
    x++
  }
    
}


export function generateRandomString(length) {


    const characters = "1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
  
    return result;
  }

  

  const randomLoc = () => {
    const locs = ["ikeja", "berger", "wawa", "magodo"];
    const randomIndex = Math.floor(Math.random() * locs.length);
    return locs[randomIndex];
  };

  const randomPoolType = ()=> {
    const type =["pool","carpool","carpoolOffer"]

    const randomIndex = Math.floor(Math.random() * type.length);
    return type[randomIndex]
  }

  
 export const findNextKYC = (KYCnumber, verificationList)=> {
    let next = KYCnumber

    for(let x = 0; x < verificationList.length -1; x++){
        if(next ===  verificationList.length -1){


            next = 0
        }else{
            next++
        }

        if(!verificationList[next]){
         
            return next
        }

    }
    
    return -1
    
  }


  // export const chooseRole = async(eventData,user,setIsLoading,navigate, setViewMode) => {
    
  //   //store the event chosen in database and local host
  //   setIsLoading(true)
    
    
    
  //   //check if event exists //later check for event status too
  //   try {

  //     const usersCollection = collection(db, "users")
  //     const userDoc = doc(usersCollection,user.id)

  //     //userpoolhistory
  //     const userPoolHistoryRef = collection(userDoc, "userPoolHistory")

  //     const q = query(userPoolHistoryRef, where("eventId","==",eventData?.id), where("active","==", true))
  //     const userPools = await getDocs(q)
  //     let userPool
     
     

  //     // this can only have one data in it
  //     userPools.forEach(pool=>{
  //         userPool ={
  //           ...pool.data(),
  //           poolId: pool.id
  //         }
  //     })

  //    console.log()
  //     // const userEvents = collection(userDoc,"userevents")
  //     // const userEventDoc = dxoc(userEvents,eventData?.id)

  //     // const userEventDocSnapshot = await getDoc(userEventDoc)
   
  //     if(!userPool){
  //       localStorage.setItem("viewMode","carpool")
  //       setViewMode("carpool")
    
  //       navigate("/carpools",{state:{eventData}})
  //     }
  //     // if(!userEventDocSnapshot?.data()?.role  || (userEventDocSnapshot?.data()?.role === "poolee" && userEventDocSnapshot?.data()?.poolId === "pending") ){
  //     //   console.log(eventData)
  //     //       navigate("/carpools",{state:{eventData}})
  //     // }  
  //     else{
        
  //        localStorage.setItem("activePool",userPool.poolId)
  //        localStorage.setItem("role", userPool.role)
  //           //save role to local host if role exists
  //           navigate("/carpool")
  //     }

  //   } catch (error) {
  //       console.log(error)
  //       setIsLoading(false)
  //   }
    
  // }



  export const getLocation = (callback) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {

          callback(null,{
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          })
        
        },
        error => {
          callback(error);
        }
      );
    } else {
      callback('Geolocation is not supported by this browser.');
    }
  };
 

  export const formatTimestamp = (timestamp)=> {
    console.log(timestamp)
    const date = timestamp.toDate();
    const now = new Date();
    const diff = date - now;
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    if (diffDays === -1) {
      return `Today, at ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
    } else if (diffDays === 0) {
      return `Tomorrow, at ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
    } else if (diffDays === -2) {
      return `Yesterday, at ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
    } else {
      return `${date.toLocaleDateString([], {day: 'numeric', month: 'long'})}, at ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
    }
  }


 

    

  
    export const handleTouchStart = (e,setStartX) => {
      setStartX(e.touches[0].clientX);
    };
  
    export const handleTouchMove = (e, setEndX) => {
      setEndX(e.touches[0].clientX);
    };
  
    export const handleTouchEnd = (startX,endX, setStartX,setEndX,callback) => {
      if (startX && endX) {
        const diff = endX - startX;
        if (diff > 100) {
          // Swipe right
           callback("right")
        } else if (diff < -100) {
          // Swipe left
          console.log('Swiped left');
          callback("left")
        }
      }
      setStartX(null);
      setEndX(null);
    };
  

    export const convertPoolTime = (poolDate,eventDate)=>{

     const pooltime = extractTimeMilliseconds(poolDate)
     const eventDateMidNight = removeTimeFromTimestamp(eventDate)
    const newTimestampInMilliseconds = pooltime + eventDateMidNight

     const newTimeStamp = new Timestamp(newTimestampInMilliseconds/1000,0)
     return newTimeStamp
    }  

    function extractTimeMilliseconds(timestamp) {
      const date = new Date(timestamp.seconds * 1000);
      return date.getHours() * 3600000 + date.getMinutes() * 60000 + date.getSeconds() * 1000;
    }
    
    // Helper function to remove time from a Timestamp object
    function removeTimeFromTimestamp(timestamp) {
      const date = new Date(timestamp.seconds * 1000);
      date.setHours(0, 0, 0, 0); // Set time to 00:00:00
      return date.getTime(); // Get the modified time in milliseconds
    }
    
   
    export function percentEncodeURL(url) {
      const specialCharacters = {
          ' ': '%20',
          '"': '%22',
          '#': '%23',
          '$': '%24',
          '%': '%25',
          "'": '%27',
          '(': '%28',
          ')': '%29',
          '*': '%2A',
          '+': '%2B',
          ',': '%2C',
          ';': '%3B',
          '<': '%3C',
          '>': '%3E',
          '@': '%40',
          '[': '%5B',
          ']': '%5D',
          '^': '%5E',
          '`': '%60',
          '{': '%7B',
          '|': '%7C',
          '}': '%7D',
          '~': '%7E'
      };
  
      return url.split('').map(char => specialCharacters[char] || char).join('');
  }
  
 
  