
export const confirmCheckIn = (pool,poolHailer) => {
   
    const {poolDate, returnTripSurvey} = pool
    const {checkIn,checkInReturn} = poolHailer
    

    console.log(poolDate)
    
    if(returnTripSurvey?.status){
        
        console.log("this here")
        if(checkInReturn){
              
            console.log(returnTripSurvey.time, checkInReturn)
            if(returnTripSurvey.time < checkInReturn.seconds *1000){
                
                return {status:true,type:"checkInReturn"}
            }
           
            return {status:false,type:"checkInReturn"}
        }
       
        return {status:false,type:"checkInReturn"}
    }

    if(checkIn){

          if(poolDate < checkIn){
            return {status:true,type:"checkIn"}
          }
        return {status:false,type:"checkIn"}
    }

    return {status:false,type:"checkIn"}
}