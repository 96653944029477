import React from 'react'

const styles = {
  card:{ 
    height: "100px",
    width: "80%",
    backgroundColor: "#D9D9D9",
    borderRadius: "20px",
    padding: "11px 20px",
    color: "black",
    marginBottom:"10px",
 },
 nameDIV:{
    height: "20%",

 },
 middleDiv:{
    height: "60%",
    width: "80%"
 },
 lastDiv:{
    height: "20%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
 }

}
const HailerCard = ({cardInfo, reject,accept,isPooler,eventLoc}) => {

  console.log(eventLoc)
    const {id,username,location,shortMsg,destination} = cardInfo
  return (
    <div style={styles.card}>
      <div style={styles.nameDIV}>
        <p className='poppins-12px-400' style={{color: "#2F2F2F"}}>{username}</p>
      </div>
      <div style={styles.middleDiv}>
        <p className='poppins-10px-300' style={{color: "#616263"}}>{location=== "eventLoc" ? eventLoc : location} - {destination === "eventDest"? eventLoc: destination}</p>
        {isPooler && <p className='poppins-10px-300' style={{color: "##2C2E3A"}}>{shortMsg}</p>}
      </div>
      {isPooler && <div style={styles.lastDiv}>
        <span style={{color: "#70D77B"}} className='poppins-10px-300' onClick={()=>accept(cardInfo)}>Accept</span>
        <span style={{color: "#FF3A3A"}} className='poppins-10px-300' onClick={()=>reject(cardInfo)}>Decline</span>
      </div>}
    </div>
  )
}

export default HailerCard
