import React from 'react'

const style = {
    backgroundColor: "#18181A",
    border: "1px solid #FF3A3A",
    borderRadius: "10px",
    height:"50px",
    width: "100%"
}
const RedOutlineBtn = ({name,onClick}) => {
  return (
    <button style={style} onClick={onClick}>
        {name}
    </button>
  )
}

export default RedOutlineBtn
