import React, { useEffect, useRef, useState } from 'react'
import TabImg from '../../components/TabImg'
import TabDesc from './TabDesc'
import {motion} from "framer-motion"
import { handleTouchEnd, handleTouchMove, handleTouchStart } from '../../Utils/util'
const Tab2 = ({setSelectedTab}) => {


  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const divRef = useRef(null);

  useEffect(()=>{
   const unsubscribe = setTimeout(()=>{
      
      setSelectedTab(2)
  },5000)
    return()=>clearTimeout(unsubscribe)
   },[])
  
    const desc = "The get ticket feature helps users, host and attendees alike to seamless get ticket and create ticket for their event."
  return (
    <motion.div 
    key='tab2' 
    style={{display:"flex",flexDirection:"column", justifyContent: "flex-start", alignItems:"center",height:"70%"}}
    ref={divRef}
    onTouchStart={(e)=>{handleTouchStart(e,setStartX)}}
    onTouchMove={(e)=>{handleTouchMove(e,setEndX)}}
    onTouchEnd={()=>handleTouchEnd(startX,endX,setStartX,setEndX,(direction)=>{
      if(direction ==="left"){
        setSelectedTab(2)
      }else{
        setSelectedTab(0)
      }
    })}
    initial={{ opacity: 0, scale: 0.8 }}
       animate={{ opacity: 1, scale: 1 }}
       exit={{ opacity: 0, scale: 0.8 }}
       transition={{ type: 'spring', stiffness: 150, damping: 25 }}
    >
      <TabImg src="/ticket.svg"/>
      <TabDesc title="Get Ticket" desc={desc}/>
    </motion.div>
  )
}

export default Tab2
