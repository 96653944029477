import React from 'react'
const styles = {
    dropDown: {
        backgroundColor: "#2D2F3C",
        color: "#A0A3AF",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        ineHeight: "normal",
        border: "none",
        outline: "none",
        width:"inherit",
        height:"inherit"
        
    },
    div: {
        width:"90%",
        height: "40px",
        borderRadius: "15px",
        backgroundColor: "#2D2F3C",
        display: "flex",
        justifyContent: "center",
        margin: "5px 0"
    }

}
const DropDownForm = ({inputRef, opts,title}) => {

  return (
    //className='form-type-1' 
    <div style={styles.div}>
    <select id="repeat" name="repeat" style={styles.dropDown} ref={inputRef}>
        <option value="" disabled selected hidden>{title}</option>
        {opts?.map(opt=><option value={opt?.value}>{opt?.placeholder}</option>)}
        
    </select>
</div>
  )
}

export default DropDownForm
