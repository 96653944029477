import React from 'react'


const styles = {
    formInput: {
        backgroundColor: "#202024",
        color:"#ffffff",
        border: "none",
        padding: "0",
        height: "40px",
        resize: "none",
        outline: "none",
        marginLeft:"40px",
        width: "40%",
    },
    container: {
        width:"100%",
        padding: "15px 0",
        display:"flex",
        justifyContent: "flex-start",
        alignItems:"center",

    },
}
const FormInputText = ({onChange,value, title}) => {
  return (
    <div style={styles.container}>
      <h4 className='poppins-20px-500' style={{width:"50%"}}>{title}</h4>
      <input style={styles.formInput} type='number' value={value} onChange={(e)=>onChange(prev=>({...prev,ticketCount:e.target.value}))}></input>
    </div>
  )
}

export default FormInputText
