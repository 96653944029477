import React from 'react'
import Whatsapp from '../../components/Svgs/Whatsapp'
import Messanger from '../../components/Svgs/Messanger'
import Telegram from '../../components/Svgs/Telegram'
import Gmail from '../../components/Svgs/Gmail'
import { percentEncodeURL } from '../../Utils/util'

const styles = {
    container : {
        position: "absolute",
        height: "100vh",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        zIndex:"9999"
    },
    transDiv: {
       flex:"1",
        opacity: "0.27",
        width: "100%",
        backgroundColor: "#4C2CBF"
    },
    bottomDiv:{
     
        width:"100%",
        backgroundColor:"#2c234e",
        display:"flex",
        flexDirection:  "column",
        justifyContent: "space-between",
        alignItems: "center"
    },
    copyBtn: {
        backgroundColor:"#18181A",
        borderRadius: "15px",
        width: "70%",
        height:"60px",
        marginBottom: "40px",
        display:"flex",
        justifyContent: "center",
        alignItems: "center"
    },
    socialsDiv:{
        borderTopLeftRadius:"15px",
        backgroundColor:"#18181A", 
        height:"100px", width:"100%", 
        borderTopRightRadius:"15px",
        display:"flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding:"0 60px"
    }
}



const ShareInvite = ({setIsShare,showCopyLink, personalized}) => {

    const eventName = localStorage.getItem("eventName")
    const eventId = localStorage.getItem("eventId")
    const message = `you've been invited to attend *${eventName}*. Click the link below for more details:`
    const url = `https://novusui.com/event-page?eventName=${eventName}&eventId=${eventId}`
    const modifiedLink = percentEncodeURL(url)
    
    
    const copyToClipboard = async () => {
        try {
          await navigator.clipboard.writeText(`Hello, ${message} \n `+modifiedLink);
          alert('Copied to clipboard!');
          setIsShare(false)
        } catch (err) {
          console.error('Failed to copy: ', err);
        }
        
    };
  return (
    <div style={styles.container}>
       <div style={styles.transDiv} onClick={()=>setIsShare(false)}>
    
      </div>
      <div style={styles.bottomDiv}> 
        {showCopyLink && 
        <button style={styles.copyBtn} onClick={copyToClipboard}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"10px"}}>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3839 3.75H10V22.5H23.75V9.11611L18.3839 3.75ZM11.25 21.25V5H17.8661L22.5 9.63389V21.25H11.25Z" fill="white"/>
                <path d="M7.5 8.125V25H19.375V26.25H6.25V8.125H7.5Z" fill="white"/>
            </svg>
            Copy link
        </button>}
        <div style={styles.socialsDiv}>
            <Whatsapp title={personalized ? `Hello ${personalized}, ${message}\n`:`Hello, ${message}\n`} url={url}/>
            <Telegram title={personalized ? `Hello ${personalized}, ${message}\n`:`Hello, ${message}\n`} url={url}/>
            <Gmail title={personalized ? `Hello ${personalized}, ${message}\n`:`Hello, ${message}\n`} url={url}/>
        </div>
      </div>
    </div>
  )
}

export default ShareInvite
