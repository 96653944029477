import React from 'react'
import Location from './Location';
import { useNavigate } from 'react-router-dom';


const styles = {
  eventLargeCard:{
    
    width: "100%",
    backgroundColor: "#18181A",
    borderRadius: "20px",
    marginBottom: "22px",
    paddingBottom:"20px"
  },
  eventLargeCardDate:{
    padding: "10px 20px",
    display: "flex",
    justifyContent:"space-between",
  },
  eventLargeCardTitle:{
    padding:"0 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    
  },

  eventImage:{
  
    borderRadius: "20px",
    position:"relative",
    overflow:"hidden"
  },
   eventImageImg:{
    width: "100%",
    height: "100%",
    objectFit:"cover"
  },
  eventLargeCardContent:{
    height: "auto",
  }

}

const EventLargeCard = ({eventDate,eventName,location,coverImg, eventVisibility, createdByUser}) => {
    const timestamp = eventDate;
    const date = new Date(timestamp.seconds * 1000);
    const shortMonth = date.toLocaleString('en-US', { month: 'short' });
    const day = date.toLocaleString('en-US', { day: 'numeric' });
    const year = date.toLocaleString('en-US', { year: 'numeric' });
    const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    const navigate = useNavigate()

  return (
    <div style={styles.eventLargeCard}>
            <div style={styles.eventImage}>
                <Location location={location}/>
                <img src={coverImg || "/concert.png"} style={styles.eventImageImg} loading='lazy'></img>
               
            </div>
            <div style={styles.eventLargeCardContent}>
            <div style={styles.eventLargeCardDate}>
                <h6 className='inter-purple'>{`${day} ${shortMonth} ${year}`}</h6>
                <h6 className='inter-purple'>{time}</h6>
            </div>
            <div style={styles.eventLargeCardTitle}>
              <h2 className='poppins-normal'>{eventName}</h2>
              {(eventVisibility==="public" || createdByUser)  && <div style={{marginLeft:"10px"}}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  onClick={()=>navigate("/invite")}>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.25 5.5C14.25 3.70507 15.7051 2.25 17.5 2.25C19.2949 2.25 20.75 3.70507 20.75 5.5C20.75 7.29493 19.2949 8.75 17.5 8.75C16.5404 8.75 15.6779 8.33409 15.083 7.6727L12.3657 9.15487L9.32515 10.8923C9.59552 11.3664 9.75 11.9152 9.75 12.5C9.75 12.7963 9.71034 13.0834 9.63603 13.3562L15.083 16.3273C15.6779 15.6659 16.5404 15.25 17.5 15.25C19.2949 15.25 20.75 16.7051 20.75 18.5C20.75 20.2949 19.2949 21.75 17.5 21.75C15.7051 21.75 14.25 20.2949 14.25 18.5C14.25 18.2036 14.2897 17.9166 14.364 17.6438L8.91704 14.6727C8.32212 15.3341 7.45963 15.75 6.5 15.75C4.70507 15.75 3.25 14.2949 3.25 12.5C3.25 10.7051 4.70507 9.25 6.5 9.25C7.15068 9.25 7.7567 9.44122 8.26492 9.77052L11.6343 7.84514L14.364 6.35625C14.2897 6.08344 14.25 5.79635 14.25 5.5ZM17.5 3.75C16.5335 3.75 15.75 4.5335 15.75 5.5C15.75 6.4665 16.5335 7.25 17.5 7.25C18.4665 7.25 19.25 6.4665 19.25 5.5C19.25 4.5335 18.4665 3.75 17.5 3.75ZM6.5 10.75C5.5335 10.75 4.75 11.5335 4.75 12.5C4.75 13.4665 5.5335 14.25 6.5 14.25C7.4665 14.25 8.25 13.4665 8.25 12.5C8.25 11.5335 7.4665 10.75 6.5 10.75ZM15.75 18.5C15.75 17.5335 16.5335 16.75 17.5 16.75C18.4665 16.75 19.25 17.5335 19.25 18.5C19.25 19.4665 18.4665 20.25 17.5 20.25C16.5335 20.25 15.75 19.4665 15.75 18.5Z" fill="#8E69F0"/>
              </svg>
              </div>}
            </div>
            </div>
    </div>
  )
}

export default EventLargeCard
