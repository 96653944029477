import React from 'react'

const styles = {
  card:{ 
    height: "80px",
    width: "80%",
    backgroundColor: "#D9D9D9",
    borderRadius: "20px",
    padding: "11px 20px",
    color: "black",
    marginBottom:"10px",
 },
 nameDIV:{
    height: "20%",

 },
 middleDiv:{
    height: "60%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent:"space-between"

 },
 lastDiv:{
    height: "20%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
 }

}
const PassengerCard = ({cardInfo, onClick, makeCall,isPooler,eventLoc}) => {

    const {id,username,location,destination} = cardInfo
  return (
    <div style={styles.card}>
      <div style={styles.nameDIV}>
        <p className='poppins-12px-400' style={{color: "#2F2F2F"}}>{username}</p>
      </div>
      <div style={styles.middleDiv}>
        <p className='poppins-10px-300' style={{color: "#616263"}}>{location=== "eventLoc" ? eventLoc : location} - {destination === "eventDest"? eventLoc: destination}</p>
        {isPooler &&<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>makeCall(cardInfo.phoneNumber || cardInfo.availableNumber)}>
          <path d="M5.00004 9.86053C6.91644 14.0344 10.3264 17.3529 14.5659 19.1519L15.2455 19.4547C16.8004 20.1475 18.6281 19.6212 19.5764 18.2077L20.4645 16.884C20.7533 16.4536 20.6654 15.8739 20.262 15.5485L17.2501 13.1187C16.8078 12.7618 16.1573 12.845 15.8188 13.3016L14.8871 14.5589C12.4963 13.3795 10.5552 11.4385 9.37594 9.04768L10.6331 8.11596C11.0898 7.77754 11.1729 7.12702 10.816 6.68464L8.38623 3.6727C8.06088 3.26942 7.48137 3.18145 7.051 3.47002L5.71816 4.36372C4.29582 5.31742 3.77244 7.16027 4.48117 8.71922L4.99927 9.85884L5.00004 9.86053Z" fill="black"/>
        </svg>}
      </div>
     { isPooler && <div style={styles.lastDiv}>
        <span style={{color: "#FF3A3A"}} className='poppins-10px-300' onClick={()=>onClick(cardInfo)}>Remove</span>
      </div>}
    </div>
  )
}

export default PassengerCard
