import React from 'react'
import WavingHandSVG from '../../../components/WavingHandSVG'

const styles = {
    card:{ 
      minHeight: "83px",
      width: "90%",
      backgroundColor: "#BDD7C7",
      borderRadius: "20px",
      padding: "11px 20px",
      color: "black",
      marginBottom:"10px",
   },
   nameDIV:{
      height: "20%",
  
   },
   middleDiv:{
      height: "50%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent:"space-between"
  
   },
   lastDiv:{
      height: "15%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
   }
  
  }

const CarpoolCardPooler = ({cardInfo,onClick}) => {
 
  console.log(cardInfo)
      const {id,poolerName,poolerLoc,eventName, eventLocation,poolerDest, hailerCount, formattedDate} = cardInfo
  return (
    <div style={styles.card} onClick={()=>onClick(cardInfo)}>
      <div style={styles.nameDIV}>
        <p className='poppins-12px-400' style={{color: "#2F2F2F"}}>Your Pool</p>
      </div>
      <div style={styles.middleDiv}>
        <p className='poppins-10px-300' style={{color: "#616263"}}>{poolerLoc} - {`\n${eventLocation || poolerDest}`}</p>
        <p>Free</p>
      </div>
      <div style={styles.lastDiv}>
      
        {/* <img src='/hailer2.gif' style={{width:"30px", height:"30px", }}/> */}
        {hailerCount > 0 && <WavingHandSVG hailerCount={hailerCount}/>}
         
        <p style={{color: "#20C963", display:"flex", alignItems:"center"}} className='poppins-10px-300' onClick={()=>{}}>
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="5" cy="5" r="4.5" stroke="#20C963"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.09343 4.07322C7.19107 4.17085 7.19107 4.32915 7.09343 4.42678L4.7601 6.7601C4.66247 6.85773 4.5042 6.85773 4.40656 6.7601L3.07322 5.42677C2.97559 5.32913 2.97559 5.17087 3.07322 5.07323C3.17085 4.9756 3.32915 4.9756 3.42678 5.07323L4.58333 6.22977L6.7399 4.07322C6.83753 3.97559 6.9958 3.97559 7.09343 4.07322Z" fill="#20C963"/>
            </svg>
            <span style={{marginLeft:"5px"}}>Active</span>
        </p>
        {/* <span>
            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_2369_1301)">
                    <path d="M8.50007 6.11706L5.05293 2.66992L4.66992 3.05293L8.11706 6.50007L4.66992 9.94724L5.05293 10.3302L8.50007 6.88308L11.9472 10.3302L12.3302 9.94724L8.88308 6.50007L12.3302 3.05293L11.9472 2.66992L8.50007 6.11706Z" fill="#FF3A3A"/>
                </g>
                <defs>
                    <filter id="filter0_d_2369_1301" x="-2" y="0" width="21" height="21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2369_1301"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2369_1301" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </span> */}
      </div>
      <div>
        <p className='poppins-10px-300' style={{color:"#8E69F0", width:"100%", textAlign:"center"}}>{eventName||formattedDate}</p>
      </div>
    </div>
  )
  
}

export default CarpoolCardPooler
