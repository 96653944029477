import React, { useState } from 'react'
import {motion} from 'framer-motion'
const StatusCard = ({msg,color}) => {

  const [vibrate, setVibrate] = useState(false);

  const handleVibrate = () => {
    setVibrate(true);
    setTimeout(() => setVibrate(false), 300); // Duration of the vibration
  };

  return (
    <motion.div 
    onClick={handleVibrate}
    style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%", height:"50px", color:`${color}`, border:`1px solid ${color.primary}`, borderRadius:"10px", backgroundColor:`${color.secondary}`}}
    animate={vibrate ? { x: [0, -10, 10, -10, 10, 0] } : {}}
    transition={{ duration: 0.3 }}
    >
       <p>{msg}</p>
    </motion.div>
  )
}

export default StatusCard
