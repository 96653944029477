import React, { useEffect } from 'react'
import TabImg from './TabImg'
import LongPurpleBtn from './Buttons/LongPurpleBtn'
import { useNav } from '../contextAPI/NavContaxt'

const info = {
    carpool: {
        title: "carpool",
        info: ["Welcome to our NovusUI  your go-to platform for seamless event experiences! 🎉 ","Introducing our Carpool feature, making transportation a breeze! 🚗","Save money, reduce your carbon footprint, and connect with fellow attendees by sharing rides to and from events. Simply create or join a carpool, (To create a carpool click the pooler button and to join a pool, click the poolee button.) coordinate with others, and enjoy the journey together. Let's make getting there as much fun as the event itself!","Ready to share the ride? Let's Carpool and make every event unforgettable, starting with the journey. Happy travels! 🌍🚀 #CarpoolAdventures #EventFun"]
    },
    wishblend: {
      title :"wishblend",
      info: ["Welcome to our NovusUI – your go-to platform for seamless event experiences! 🎉 ", "WishBlend is a unique feature on the NovusUI event app that revolutionizes the way events are organized and experienced. It allows event organizers to create a list of desired items for their event, ranging from food and drinks to decorations and entertainment. Attendees can then browse this list and choose which items they'd like to bring to the event, fostering a sense of community engagement and collaboration.", "With WishBlend, organizers have the flexibility to customize the list based on the specific needs and theme of their event, ensuring that it reflects their preferences and requirements. Attendees can easily view the list through the NovusUI app and select the items they'd like to contribute, enhancing the diversity and variety of offerings at the event.","Real-time updates enable organizers to track contributions and manage the event effectively, while also providing transparency and convenience for attendees. This seamless coordination of contributions enhances the overall event experience, creating a more interactive and inclusive atmosphere for everyone involved. 🌍🚀 #WishblendFun #EventFun"]
    },
    getTicket: {
      title: "Get Ticket",
      info : ["Welcome to our NovusUI – your go-to platform for seamless event experiences! 🎉 ","The Get Ticket feature on NovusUI streamlines event attendance by providing a hassle-free ticketing solution.","Organizers can easily set up ticketing options tailored to their event's needs, whether it's free registration or paid admission.Real-time ticket availability updates ensure transparency, while seamless integration enhances user experience. Attendees can conveniently purchase tickets directly through the app, eliminating the need for separate ticketing platforms.", "This feature simplifies event planning, increases attendee engagement, and ensures smooth entry management, making it an essential tool for organizers and attendees alike."]
    }

}
const InfoPage = ({type,onClick,img}) => {

  const {setNavFunction, setNavType} = useNav()
  useEffect(()=>{
    setNavFunction(()=>onClick)
    setNavType("cancel")
  },[])
  return (
    <div className='container'>
      <TabImg src={img}/>
      <h1 style={{margin:"20px 0"}} className='poppins-20px-500'>More about {info[type].title}</h1>
      <div style={{overflow:"scroll", flex:"1"}}>
      {
        info[type].info.map(i=><p style={{marginBottom:"20px", padding:"0 10px"}} className='poppins-12px-300'>{i}</p>)
      }
      </div>
      <div style={{width:"100%", padding:"10px 0"}}>
      <LongPurpleBtn onClick={onClick} name="okay"/>
      </div>
    </div>
  )
}

export default InfoPage
