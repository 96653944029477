import React, { useState } from 'react'
import { acceptPrivateTicketRequest } from '../../../firebase/controller/ticketController'

const Attendees = ({name, status,id}) => {
 console.log(id)
  const [changingStatus, setChangingStatus] = useState(false)
  const [ticketStatus, setTicketStatus] = useState(status)

  const rejectTicket = ()=>{
     
    setChangingStatus(true)

    acceptPrivateTicketRequest(id,"rejected",(err)=>{
      if(err){
        setChangingStatus(false)
        return
      }
      setChangingStatus(false)
      setTicketStatus("rejected")
       
    })
  }

  const acceptTicket = ()=>{
    setChangingStatus(true)
    acceptPrivateTicketRequest(id,false,(err)=>{
      if(err){
        setChangingStatus(false)
        return
      }
      setChangingStatus(false)
      setTicketStatus(false)
       
    })
  }
  const dud = ()=>{}

  return (
    <>
   {(ticketStatus === true  || ticketStatus === false) && <div key={id} style={{height:"50px", width:"100%",backgroundColor:"#18181A",borderRadius:"15px",display:"flex", alignItems:"center", justifyContent:"space-between", padding:"0 30px", margin:"10px 0",textTransform:"capitalize"}}>
        <p>{name}</p>
        {ticketStatus && <p style={{color:"#20C963"}}>IN</p>}
    </div>}
    {
      ticketStatus === "requested" && 
       
      <div style={{height:"50px", display:"flex" ,textTransform:"capitalize",margin:"10px 0"}}>
        <div style={{flex:1, backgroundColor:"#18181A",borderRadius:"15px" ,display:"flex", marginRight:"10px", justifyContent:"flex-start", alignItems:"center",padding:"0 30px"}}>
          <p>{name}</p>
        </div>
        <div style={{width:"59px",borderRadius:"15px", display:"flex", justifyContent:"center", alignItems:"center",backgroundColor:"#18181A",marginRight:"10px"}} onClick={()=>{changingStatus ? dud() :acceptTicket()}}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0303 7.96967C18.3232 8.26256 18.3232 8.73744 18.0303 9.03033L11.0303 16.0303C10.7374 16.3232 10.2626 16.3232 9.96967 16.0303L5.96967 12.0303C5.67678 11.7374 5.67678 11.2626 5.96967 10.9697C6.26256 10.6768 6.73744 10.6768 7.03033 10.9697L10.5 14.4393L16.9697 7.96967C17.2626 7.67678 17.7374 7.67678 18.0303 7.96967Z" fill="#20C963"/>
          </svg>
        </div>
        <div style={{width:"59px",borderRadius:"15px", display:"flex" ,justifyContent:"center", alignItems:"center", backgroundColor:"#18181A"}} onClick={()=>{changingStatus ? dud() :rejectTicket()}}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#FF3A3A" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M8.46446 8.46436L15.5355 15.5354" stroke="#FF3A3A" stroke-width="1.5" stroke-linecap="round"/>
            </svg>
        </div>
      </div>
    }
    {
      ticketStatus === "rejected" && <div key={id} style={{height:"50px", width:"100%",backgroundColor:"#18181A",borderRadius:"15px",display:"flex", alignItems:"center", justifyContent:"space-between", padding:"0 30px", margin:"10px 0",textTransform:"capitalize"}}>
      <p>{name}</p>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.46445 15.5354L15.5355 8.46436" stroke="#FF3A3A" stroke-width="1.5" stroke-linecap="round"/>
              <path d="M8.46446 8.46436L15.5355 15.5354" stroke="#FF3A3A" stroke-width="1.5" stroke-linecap="round"/>
      </svg>
      
  </div>
    }
    </>
  )
}

export default Attendees
